import { action, computed, makeObservable, observable } from 'mobx';
import { AutopilotParameter, MagicValueType, OrderTaskPriorityType } from '../apiTypes';
import repository from '../repository';

type priorityModalValue = {
    orderTaskId: string;
    priority: OrderTaskPriorityType | null;
};

type EditOrderModalInitialValue = {
    targetId?: string;
    targetUrl?: string;
};

interface IPriorityModalStore {
    modalOpen: boolean;
    priorityModalValue: priorityModalValue;
};

const INITIAL_STATES: IPriorityModalStore = {
    modalOpen: false,
    priorityModalValue: {
        orderTaskId: '',
        priority: null,
    },
};

class PriorityModalStore implements IPriorityModalStore {
    private _modalOpen = INITIAL_STATES.modalOpen;
    private _priorityModalValue = INITIAL_STATES.priorityModalValue;

    constructor() {
        makeObservable<
            PriorityModalStore,
            '_modalOpen' | '_priorityModalValue'
        >(this, {
            _modalOpen: observable,
            _priorityModalValue: observable,
            modalOpen: computed,
            priorityModalValue: computed,
            handleModalOpen: action.bound,
            handlePriorityModalValue: action.bound,
        });
    };

    get modalOpen() {
        return this._modalOpen;
    };

    get priorityModalValue() {
        return this._priorityModalValue;
    };

    handleModalOpen(open: boolean) {
        this._modalOpen = open;
    };

    handlePriorityModalValue(value: priorityModalValue) {
        this._priorityModalValue = value;
    };
};

const priorityModalStore = new PriorityModalStore();
export default priorityModalStore;