import React, { useEffect, useMemo, useRef, useState } from 'react';
import Mask from '../../components/mask';
import { CreateManualPromotionDto, DashboardDailyPromotionDto } from '../../apiTypes';
import repository from '../../repository';
import { Button, Checkbox, DatePicker, Input, message, Modal, Select } from 'antd';
import moment from 'moment';
import { FixedSizeList } from 'react-window';
import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import getScrollbarWidth from '../../helper/getScrollbarWidth';
import MaUTable from '@mui/material/Table';
import { ArrowDownOutlined, ArrowUpOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import confirm from 'antd/lib/modal/confirm';
import Flex from '../../components/flex';
import getRandomOrderNumber from '../../helper/getRandomOrderNumber';

const ROW_SIZE = 90;

const DATE_FORMAT = 'YYYY-MM-DD';

const Test = styled.div<{ index?: number }>`
.MuiTableRow-root {
    transition: 2s all ease;
}

${props => props.index && `
    .MuiTableRow-root:nth-child(5) {
        background-color: #c5c5c5;
    }
`};
`;

// @ts-ignore
function Table({ columns, data }) {
    const defaultColumn = useMemo(() => ({
        width: 200,
    }), []);

    const listRef = useRef<FixedSizeList>(null);
    const [ index, setIndex ] = useState<number>();

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        getTableBodyProps,
        totalColumnsWidth,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    const scrollBarSize = useMemo(() => getScrollbarWidth(), []);

    useEffect(() => {
        if (index) {
            setTimeout(() => {
                setIndex(undefined);
            }, 500);
        }
    }, [ index ]);

    return (
        <>
            <MaUTable
                {...getTableProps()}
                stickyHeader
            >
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // @ts-ignore
                                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <div>
                                        {column.render('Filter')}
                                    </div>
                                    <span>
                                        {/* @ts-ignore */}
                                        {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <Test index={index}>
                    <TableBody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);

                            return (
                                <TableRow
                                    {...row.getRowProps({
                                        style: {
                                            backgroundColor: row.values.taskStatus === 'pending_promotion' ? undefined : '#c8c8c8',
                                        },
                                    })}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.id === 'check') {
                                            return (
                                                <TableCell
                                                    {...cell.getCellProps()}
                                                    style={{
                                                        cursor: 'pointer',
                                                        // @ts-ignore
                                                        width: cell.column.originalWidth,
                                                    }}
                                                >
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            )
                                        } else {
                                            return (
                                                <CopyToClipboard
                                                    text={cell.value}
                                                    onCopy={(text, result) => {
                                                        if (result) {
                                                            message.success(`${cell.value}`);
                                                        } else {
                                                            message.error(`${cell.value}`);
                                                        }
                                                    }}
                                                >
                                                    <TableCell
                                                        {...cell.getCellProps()}
                                                        style={{
                                                            cursor: 'pointer',
                                                            // @ts-ignore
                                                            width: cell.column.originalWidth,
                                                        }}
                                                    >
                                                        { cell.column.id === 'index' ? i + 1 : cell.render('Cell') }
                                                    </TableCell>
                                                </CopyToClipboard>
                                            )}
                                        }
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Test>
            </MaUTable>
        </>
    );
};

function FreePromotion() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ dailyList, setDailyList ] = useState<Array<DashboardDailyPromotionDto>>([]);
    const [ date, setDate ] = useState<{
        from: string;
        to: string;
    }>({
        from: moment().format(DATE_FORMAT),
        to: moment().format(DATE_FORMAT),
    });
    const [ checkList, setCheckList ] = useState<Array<{
        orderId: string;
        orderTaskId: string;
    }>>([]);
    const [ createPromotionModal, setCreatePromotionModal ] = useState<boolean>(false);
    const [ createPromotionValue, setCreatePromotionValue] = useState<CreateManualPromotionDto>({
        customerName: '',
        customerPhone: '',
        targetId: '',
        orderNo: 0,
        memNo: 0,
    });

    const getDailyPromotionData =  async () => {
        try {
            const response = await repository.getDailyFreePromotions(date.from, date.to);
            // @ts-ignore
            response.sort(d => d.taskStatus === 'pending_promotion' ? -1 : 1);
            if (response) {
                const _dailyList = response.map((item, i) => {
                    return {
                        index: i + 1,
                        ...item,
                    }
                });
                _dailyList.sort((a, b) => moment(a.createdAt).diff(b.createdAt));
                // @ts-ignore
                setDailyList(_dailyList);
            }
        } catch (error) {
            message.error(`${error}`);
        }
    };

    const showReassignProcess = () => {
        if (checkList.length === 0) {
            return;
        }
        confirm({
            title: `큐에 넣으시겠습니까? ${checkList.length}개`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.updateProcessDailyFreePromotions({
                            orderTaskIds: checkList.map(d => d.orderTaskId)
                        });
                        if (response) {
                            message.success('성공적으로 큐에 넣었습니다.');
                            await getDailyPromotionData();
                            setCheckList([]);
                        } else {
                            message.error('넣기 실패');
                            setCheckList([]);
                        }
                    } catch (error) {
                        message.error(`${error}`);
                        setCheckList([]);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const showDeleteProcess = () => {
        if (checkList.length === 0) {
            return;
        }
        confirm({
            title: `큐에서 삭제하시겠습니까?? ${checkList.length}개`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = (await Promise.all(checkList.map(d => {
                            return repository.deleteDailyFreePromotions(d.orderId);
                        })));
                        if (response.findIndex(d => d === false) === -1) {
                            message.success('성공적으로 삭제했습니다.');
                            await getDailyPromotionData();
                            setCheckList([]);
                        } else {
                            message.error('넣기 실패');
                            setCheckList([]);
                        }
                    } catch (error) {
                        message.error(`${error}`);
                        setCheckList([]);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const makePromotion = async () => {
        if (createPromotionValue.customerName === ''
            || createPromotionValue.customerPhone === ''
            || createPromotionValue.targetId === ''
            || createPromotionValue.orderNo === 0
            || createPromotionValue.memNo === 0) {
            return;
        }
        try {
            const rs = await repository.createManualPromotion(createPromotionValue);
            if (rs) {
                message.success('생성완료');
            }
        } catch (err) {
            console.log('err', err);
            message.error(`makePromotion ${err}`);
        };
    };

    const columns = useMemo(() => [
        {
            Header: 'check',
            // accessor: 'index',
            Filter: () => {
                return (
                    <Checkbox
                        onChange={(e) => {
                            if (e.target.checked) {
                                setCheckList(dailyList.map(d =>
                                    ({
                                        orderId: d.orderId,
                                        orderTaskId: d.orderTaskId,
                                    })
                                ));
                            } else {
                                setCheckList([]);
                            }
                        }}
                        checked={dailyList.length !== 0 && checkList.length === dailyList.length}
                    />
                )
            },
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <Checkbox
                        onChange={(e) => {
                            if (e.target.checked) {
                                setCheckList(prev => [...prev.filter(d => d.orderId !== cell.row.values.orderId), {
                                    orderId: cell.row.values.orderId,
                                    orderTaskId: cell.row.values.orderTaskId,
                                }]);
                            } else {
                                setCheckList(prev => prev.filter(d => d.orderId !== cell.row.values.orderId));
                            }
                        }}
                        checked={checkList.findIndex(d => d.orderId === cell.row.values.orderId) !== -1}
                    />
                );
            },
            width: 60,
        },
        {
            Header: 'index',
            accessor: 'index',
            Filter: () => {},
            width: 50,
        },
        {
            Header: '남은시간',
            accessor: 'createdAt',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                let message = '';
                const currentTime = moment();
                const endTime = moment(cell.value).add(24, 'hours');
                const timeDiff = endTime.diff(currentTime);
                if (timeDiff <= 0) {
                    message = 'over';
                } else {
                    const remainingTime = moment.duration(timeDiff);
                    const hoursRemaining = remainingTime.hours();
                    const minutesRemaining = remainingTime.minutes();
                    if (hoursRemaining > 0) {
                        message = `${hoursRemaining}h`;
                    } else {
                        message = `${minutesRemaining}m`;
                    }
                }

                return (
                    <div>{message}</div>
                );
            },
            width: 100,
        },
        {
            Header: '작업 진행 상황',
            accessor: 'taskStatus',
            Filter: (props: any) => {
                const { column: { setFilter } } = props;
                return (
                    <Select
                        onClick={e => e.stopPropagation()}
                        onChange={setFilter}
                        defaultValue='all'
                        style={{ width: 100 }}
                    >
                        <Select.Option value=''>all</Select.Option>
                        <Select.Option value='finished'>finished</Select.Option>
                        <Select.Option value='impossible'>impossible</Select.Option>
                        <Select.Option value='locked'>locked</Select.Option>
                        <Select.Option value='not_finished'>not finished</Select.Option>
                        <Select.Option value='pending_promotion'>pending_promotion</Select.Option>
                    </Select>
                )
            },
            width: 140,
        },
        {
            Header: 'id',
            accessor: 'targetId',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '주문번호',
            accessor: 'orderId',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '작업번호',
            accessor: 'orderTaskId',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '플렛폼',
            accessor: 'platformType',
            Filter: () => {},
            width: 80,
        },
        {
            Header: '작업 종류',
            accessor: 'taskType',
            Filter: (props: any) => {
                const { column: { setFilter } } = props;
                const toKorean = (value: string) => {
                    switch (value) {
                        case 'like':
                            return '좋아요';
                        case 'like_reels':
                            return '좋아요(릴스)';
                        case 'follow':
                            return '팔로우';
                    };
                };

                return (
                    <Select
                        onClick={e => e.stopPropagation()}
                        onChange={value => setFilter(toKorean(value))}
                        defaultValue='all'
                        style={{ width: 100 }}
                    >
                        <Select.Option value=''>all</Select.Option>
                        <Select.Option value='follow'>follow</Select.Option>
                        <Select.Option value='like'>like</Select.Option>
                        <Select.Option value='like_reels'>reels</Select.Option>
                    </Select>
                )
            },
            width: 150,
        },
        {
            Header: 'url',
            accessor: 'targetUrl',
            Filter: () => {},
            width: 50,
        },
        {
            Header: '진행한 갯수',
            accessor: 'progressCnt',
            Filter: () => {},
            width: 70,
        },
        {
            Header: '진행할 갯수',
            accessor: 'totalCnt',
            Filter: () => {},
            width: 70,
        },
        {
            Header: '스케줄링된 갯수',
            accessor: 'remainScheduleCnt',
            Filter: () => {},
            width: 70,
        },
        {
            Header: '상품명',
            accessor: 'productName',
            Filter: () => {},
            width: 200,
        },
    ], [ checkList ]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getDailyPromotionData();
            setCheckList([]);
            setLoading(false);
        })();
    }, [ date ]);

    useEffect(() => {
        setCreatePromotionValue(prev => ({
            ...prev,
            customerPhone: prev.customerPhone.replaceAll('-', ''),
        }));
    }, [ createPromotionValue.customerPhone ]);

    if (loading) {
        return <Mask />
    }

    return (
        <>
            <Modal
                open={createPromotionModal}
                title='급식 넣기'
                onCancel={() => {
                    setCreatePromotionModal(false);
                }}
                onOk={() => {
                    (async () => {
                        setCreatePromotionModal(false);
                        await makePromotion();
                    })();
                }}
            >
                <div>
                    <div>주문번호</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Input
                            placeholder='orderNo'
                            value={createPromotionValue.orderNo}
                            onChange={e => setCreatePromotionValue(prev => ({
                                ...prev,
                                orderNo: parseInt(e.target.value),
                            }))}
                            type='number'
                        />
                        <Button
                            onClick={() => setCreatePromotionValue(prev =>({
                                ...prev,
                                orderNo: getRandomOrderNumber(),
                            }))}
                        >랜덤</Button>
                    </div>
                    <div>구매자명</div>
                    <Input
                        placeholder='customerName'
                        value={createPromotionValue.customerName}
                        onChange={e => setCreatePromotionValue(prev => ({
                            ...prev,
                            customerName: e.target.value,
                        }))}
                    />
                    <div>회원번호(고도몰 회원번호)</div>
                    <Input
                        placeholder='memNo'
                        value={createPromotionValue.memNo}
                        onChange={e => setCreatePromotionValue(prev => ({
                            ...prev,
                            memNo: parseInt(e.target.value),
                        }))}
                        type='number'
                    />
                    <div>핸드폰 번호</div>
                    <Input
                        placeholder='customerPhone'
                        value={createPromotionValue.customerPhone}
                        onChange={e => setCreatePromotionValue(prev => ({
                            ...prev,
                            customerPhone: e.target.value,
                        }))}
                    />
                    <div>targetId</div>
                    <Input
                        placeholder='targetId'
                        value={createPromotionValue.targetId}
                        onChange={e => setCreatePromotionValue(prev => ({
                            ...prev,
                            targetId: e.target.value,
                        }))}
                    />
                </div>
            </Modal>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 10,
            }}>
                <div style={{ marginBottom: 10 }}>
                    <DatePicker.RangePicker
                        style={{ width: 240 }}
                        defaultValue={[
                            moment(date.from, DATE_FORMAT),
                            moment(date.to, DATE_FORMAT),
                        ]}
                        format={DATE_FORMAT}
                        onChange={date => {
                            if (!date || !date[0] || !date[1]) {
                                return;
                            }
                            setDate({
                                from: date[0].format(DATE_FORMAT),
                                to: date[1].format(DATE_FORMAT),
                            });
                        }}
                    />
                    <Button
                        onClick={() => {
                            setCreatePromotionModal(true);
                            setCreatePromotionValue({
                                customerName: '',
                                customerPhone: '',
                                targetId: '',
                                orderNo: 0,
                                memNo: 0,
                            });
                        }}
                        style={{ marginLeft: 10 }}
                    >
                        급식 생성
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={() => {
                            setCheckList(dailyList.filter(item =>
                                item.taskStatus === 'pending_promotion'
                            ).map(d => ({
                                orderId: d.orderId,
                                orderTaskId: d.orderTaskId,
                            })));
                        }}
                    >
                        팬딩만
                    </Button>
                    <Button
                        onClick={() => showReassignProcess()}
                        style={{ margin: '0 10px' }}
                    >
                        리스트 큐에 넣기
                    </Button>
                    <Button
                        onClick={() => showDeleteProcess()}
                        style={{ backgroundColor: 'red', color: 'white' }}
                    >
                        큐에서 삭제
                    </Button>
                </div>
            </div>
            <Table
                columns={columns}
                data={dailyList}
            />
        </>
    );
};

export default FreePromotion;
