import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Divider, Form, Input, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DailyPromotionAbuserDto } from '../../apiTypes';
import repository from '../../repository';
import { CloseOutlined } from '@ant-design/icons';

const RootContainer = styled.div`
display: flex;
flex-direction: column;
padding: 50px;
@media screen and (max-width: 500px) {
    padding: 20px;
}
`;

const ErrorP = styled.p`
color: red;
`;

interface FormData {
    memNo: number | undefined;
    targetId: string | undefined;
    phoneNumber: string;
    memo: string,
};

function PromotionBlackList() {
    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        reset,
    } = useForm<FormData>({
    });

    const [abuserList, setAbuserList] = useState<Array<DailyPromotionAbuserDto>>();

    const getDailyPromotionAbusers = async () => {
        try {
            const rs = await repository.getDailyPromotionAbusers();
            console.log('rs', rs);
            if (!rs || rs.length === 0) {
                return;
            }
            setAbuserList(rs);
        } catch (err) {
            console.log('get abusers err', err);
            message.error('어뷰저 가져오기 에러');
        }
    };

    const createDailyPromotionAbusers = async (data: FormData) => {
        try {
            if ((data.memNo === undefined) && (data.targetId === undefined || data.targetId === '')) {
                message.error('값을 확인해주세요');
            }
            let payload: any = {};
            if (data.memNo) {
                payload['memNo'] = data.memNo;
            }
            if (data.targetId !== undefined && data.targetId !== '') {
                payload['targetId'] = data.targetId;
            }
            if (data.phoneNumber !== undefined && data.phoneNumber !== '') {
                payload['phoneNumber'] = data.phoneNumber;
            }
            if (data.memo !== undefined && data.memo !== ''){
                payload['memo'] = data.memo;
            }
            console.log('payload', payload);
            const rs = await repository.createDailyPromotionAbuser(payload);
            if (rs) {
                message.success('넣기 성공');
                reset();
            }
        } catch (err) {
            console.log('create err', err);
            message.error(`${err}`);
        };
    };

    const deleteDailyPromotion = async (id: string) => {
        try {
            const rs = await repository.deleteDailyPromotion(id);
            if (rs) {
                message.success('삭제했습니다.');
            }
        } catch (err) {
            console.log('어뷰저 삭제 에러', err);
            message.error(`${err}`);
        }
    };

    const registerSubmit = async (data: FormData) => {
        console.log('data', data);
        if (window.confirm('제출시겠습니까?')) {
            await createDailyPromotionAbusers(data);
        }
    };

    useEffect(() => {
        setValue('phoneNumber', watch('phoneNumber')?.replaceAll('-', ''));
    }, [ watch('phoneNumber') ]);

    useEffect(() => {
        ( async () => {
            await getDailyPromotionAbusers();
        })();
    }, []);

    return (
        <>
            <RootContainer>
                <form onSubmit={handleSubmit(registerSubmit)}>
                    <Divider orientation='left' style={{ fontWeight: 'bold' }}>블랙리스트 추가</Divider>
                    <Controller
                        name='memNo'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Form.Item
                                label='유저번호'
                                labelCol={{ span: 5 }}
                            >
                                <Input
                                    {...register('memNo', {
                                        validate: value => ((value !== undefined || (watch('targetId') !== undefined && watch('targetId') !== '')))
                                        || '유저번호 혹은 인스타 ID중 하나는 입력해야 합니다.'
                                    })}
                                    value={value}
                                    onChange={e => onChange(e.target.value === '' ? undefined : parseInt(e.target.value))}
                                    placeholder='memNo'
                                    type='number'
                                    id='orderNo'
                                />
                            </Form.Item>
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name='memNo'
                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                    />
                    <Controller
                        name='targetId'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Form.Item
                                label='인스타 ID'
                                labelCol={{ span: 5 }}
                            >
                                <Input
                                    {...register('targetId', {
                                        validate: value => (((value !== undefined && value !== '') || watch('memNo') !== undefined))
                                        || '유저번호 혹은 인스타 ID중 하나는 입력해야 합니다.'
                                    })}
                                    value={value}
                                    onChange={onChange}
                                    placeholder='targetId'
                                    type='text'
                                    id='targetId'
                                />
                            </Form.Item>
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name='targetId'
                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                    />
                    <Controller
                        name='phoneNumber'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Form.Item
                                label='핸드폰'
                                labelCol={{ span: 5 }}
                            >
                                <Input
                                    value={value}
                                    onChange={onChange}
                                    placeholder='phoneNumber'
                                    type='text'
                                    id='phoneNumber'
                                />
                            </Form.Item>
                        )}
                    />
                    <Controller
                        name='memo'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Form.Item
                                label='메모'
                                labelCol={{ span: 5 }}
                            >
                                <Input
                                    value={value}
                                    onChange={onChange}
                                    placeholder='memo'
                                    type='text'
                                    id='memo'
                                />
                            </Form.Item>
                        )}
                    />
                    <div
                        style={{ display: 'flex', justifyContent: 'right' }}
                    >
                        <Button
                            htmlType='submit'
                            type='primary'
                        >
                            제출하기
                        </Button>
                    </div>
                </form>
                <Divider orientation='left' style={{ fontWeight: 'bold' }}>블랙리스트</Divider>
                { abuserList && abuserList.map(d => (
                    <div style={{ borderBottom: '1px lightgray solid' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <div>{moment(d.createdAt).format('YYYY-MM-DD/HH:mm')}</div>
                                <div>고객번호 / IGID: {d.memNo} {d.targetId && ' / ' + d.targetId}</div>
                            </div>
                            <button
                                onClick={() => {
                                    if (window.confirm('삭제하시겠습니까?')) {
                                        ( async () => {
                                            await deleteDailyPromotion(d.id);
                                            await getDailyPromotionAbusers();
                                        })();
                                    }
                                }}
                            >
                                <CloseOutlined style={{ color: 'red' }}/>
                            </button>
                        </div>
                        { d.phoneNumber && <div>전화번호: {d.phoneNumber}</div> }
                        { d.memo && <div>메모: {d.memo}</div>}
                        {/* <div>{d.id}</div> */}
                    </div>
                ))}
            </RootContainer>
        </>
    );
};

export default PromotionBlackList;
