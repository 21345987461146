import { BellFilled, BellOutlined, CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Input, message, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FlashNotificationDto, UpdateFlashNotificationDto } from '../../apiTypes';
import repository from "../../repository";

const RootContainer = styled.div`
display: flex;
flex-direction: column;
padding: 50px;
@media screen and (max-width: 500px) {
    padding: 50px 20px;
}
`;

function FlashNotification () {

    const [ createNotificationValue , setCreateNotificationValue ] = useState<string>();
    const [ createNotificationModal, setCreateNotificationModal ] = useState<boolean>(false);
    const [ notificationList, setNotificationList ] = useState<Array<FlashNotificationDto>>();
    const [ selectedNotification, setSelectedNotification ] = useState<UpdateFlashNotificationDto>({
        id: '',
        content: '',
        isPublished: false,
    });

    const getNotifications = async () => {
        try {
            const rs = await repository.getNotifications();
            console.log(rs);
            if (rs && rs.length > 0) {
                setNotificationList(rs);
            }
        } catch (err) {
            message.error(`${err}`);
            console.log('getNotification', err);
        };
    };

    const createNotification = async (content: string) => {
        if (content === '' || !content) {
            message.error('공지 내용을 입력해주세요!');
            return;
        }
        try {
            const rs = await repository.createFlashNotification({ content });
            console.log(rs);
            message.success('내용이 입력되었습니다.');
        } catch (err) {
            message.error(`${err}`);
            console.log('createNotification', err);
        };
    };

    const updateNotification = async (payload: UpdateFlashNotificationDto) => {
        if (!payload.content || payload.content === '') {
            message.error('공지 내용을 입력해주세요.');
            return;
        }
        if (!payload.id || payload.id === '') {
            message.error('id 에러');
            return;
        }
        try {
            const rs = await repository.updateFlashNotification(payload);
            console.log('updaters', rs);
            message.success('업데이트했습니다.');
        } catch (err) {
            message.error(`${err}`);
            console.log('updateNotification', err);
        }
    };

    useEffect(() => {
        (async () => {
            await getNotifications();
        })();
    }, []);

    return  (
        <>
            <RootContainer>
                <Modal
                    open={createNotificationModal}
                    title='공지사항 생성'
                    onOk={() => {
                        (async () => {
                            await createNotification(createNotificationValue ?? '');
                            await getNotifications();
                        })();
                        setCreateNotificationValue('');
                        setCreateNotificationModal(false);
                    }}
                    onCancel={() => {
                        setCreateNotificationValue('');
                        setCreateNotificationModal(false);
                    }}
                >
                    <div>생성할 공지사항을 입력하세요</div>
                    <Input
                        value={createNotificationValue}
                        onChange={(e) => setCreateNotificationValue(e.target.value)}
                    />
                </Modal>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0' }}>
                    <Button
                        onClick={() => setCreateNotificationModal(true)}
                    >
                        공지사항 생성
                    </Button>
                </div>
                <div style={{ margin: '30px 0' }}>
                    <div>공지사항 리스트</div>
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', borderBottom: '1px black solid', textAlign: 'center' }}>
                        <div style={{ width: 80 }}>생성날짜</div>
                        <div style={{ flex: 1 }}>내용</div>
                        <div style={{ width: 50 }}>공개</div>
                        <div style={{ width: 80 }}></div>
                    </div>
                    { notificationList && notificationList.map((d) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',
                                borderBottom: '1px lightgray dashed', justifyContent: 'space-between' }}>
                                <div style={{ width: 80}}>{moment(d.createdAt).format('YYYY/MM/DD (HH:mm)')}</div>
                                <div style={{ flex: 1 }}>
                                    { selectedNotification && selectedNotification.id === d.id
                                        ? <Input
                                            value={selectedNotification.content}
                                            onChange={(e) => {
                                                setSelectedNotification((prev) => {
                                                    return {
                                                        ...prev,
                                                        content: e.target.value,
                                                    }
                                                });
                                            }}
                                            />
                                        : <div style={{ padding: '0 10px' }}>{d.content}</div>
                                    }
                                </div>
                                <div style={{ width: 50, textAlign: 'center' }}>
                                    { selectedNotification.id !== '' && selectedNotification.id === d.id
                                        ? selectedNotification.isPublished
                                            ? <BellFilled
                                                onClick={() => setSelectedNotification((prev) => {
                                                    return {
                                                        ...prev,
                                                        isPublished: false,
                                                    }
                                                })}
                                            />
                                            : <BellOutlined
                                                onClick={() => setSelectedNotification((prev) => {
                                                    return {
                                                        ...prev,
                                                        isPublished: true,
                                                    }
                                                })}
                                            />
                                        : d.isPublished ? <BellFilled /> : <BellOutlined/>
                                    }
                                </div>
                                { selectedNotification.id !== '' && selectedNotification && selectedNotification.id === d.id
                                    ? <div style={{ display: 'flex', flexDirection: 'row', width: 80, justifyContent: 'space-between' }}>
                                        <button
                                            style={{ color: 'red', padding: '5px 10px', border: '1px lightgray solid', backgroundColor: '#ffcccc' }}
                                            onClick={() => setSelectedNotification({
                                                id: '',
                                                content: '',
                                                isPublished: false,
                                            })}
                                        >
                                            <CloseOutlined />
                                        </button>
                                        <button
                                            style={{ color: 'green', padding: '5px 10px', border: '1px lightgray solid', backgroundColor: '#ccffcc' }}
                                        >
                                            <CheckOutlined
                                                onClick={() => {
                                                    (async () => {
                                                        await updateNotification(selectedNotification);
                                                        await getNotifications();
                                                    })();
                                                    setSelectedNotification({
                                                        id: '',
                                                        content: '',
                                                        isPublished: false,
                                                    });
                                                }}
                                            />
                                        </button>
                                    </div>
                                    : 
                                    <div style={{ display: 'flex', width: 80, justifyContent: 'flex-end' }}>
                                        <Button
                                            style={{ width: 50 }}
                                            onClick={() => setSelectedNotification({
                                                id: d.id,
                                                content: d.content,
                                                isPublished: d.isPublished,
                                            })}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            </RootContainer>
        </>
    );
};

export default FlashNotification;