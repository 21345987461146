import { Input, Modal, message } from 'antd';
import { observer } from 'mobx-react';
import repository from '../repository';
import editCountStore from '../stores/editCountStore';
import { useCallback } from 'react';
import { ArrowRightOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

interface ICountModal {
    refresh: (orderTaskId: string) => Promise<void>;
}

const EditCountModal: React.FC<ICountModal> = observer((props) => {
    const handleDisplayingAndTotalCntChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        editCountStore.handleEditCountModalValue({
            displayingCnt: parseInt(e.target.value),
            totalCnt: Math.ceil(parseInt(e.target.value) * 1.05),
        });
    }, []);

    const handleTotalCntChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        editCountStore.handleEditCountModalValue({
            ...editCountStore.editCountModalValue,
            totalCnt: parseInt(e.target.value),
        });
    }, []);

    return (
        <Modal
            open={editCountStore.editCountModalOpen}
            title='작업량 수정'
            onOk={() => {
                if (!editCountStore.editCountModalValue || !editCountStore.editCountInitialModalValue.orderTaskId
                    || !editCountStore.editCountModalValue.totalCnt || !editCountStore.editCountModalValue.displayingCnt) {
                    message.error('값에 문제가 있습니다.');
                    return false;
                }
                if (editCountStore.editCountModalValue.totalCnt < editCountStore.editCountModalValue.displayingCnt) {
                    message.error('실제 갯수가 보이는 갯수보다 커야합니다.');
                    return false;
                }
                if ((editCountStore.editCountInitialModalValue.progressCnt + editCountStore.editCountInitialModalValue.remainScheduleCnt) > editCountStore.editCountModalValue.totalCnt) {
                    message.error(`실제 갯수가 ${editCountStore.editCountInitialModalValue.progressCnt + editCountStore.editCountInitialModalValue.remainScheduleCnt}보다 더 커야합니다.`);
                    return false;
                }
                ( async () => {
                    try {
                        const rs = await repository.updateProgressCount(editCountStore.editCountInitialModalValue.orderTaskId, editCountStore.editCountModalValue);
                        console.log('rs', rs);
                        if (rs) {
                            message.success('수정했습니다.');
                            await props.refresh(editCountStore.editCountInitialModalValue.orderTaskId);
                            editCountStore.clear();
                        }
                    } catch (err) {
                        message.error(`${err}`);
                    }
                })();
            }}
            onCancel={() => {
                editCountStore.clear();
            }}
        >
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10, padding: '0 10px', borderRight: '1px lightgray solid' }}>
                        <div>{`${editCountStore.editCountInitialModalValue.progressCnt} / ${editCountStore.editCountInitialModalValue.totalCnt}`}</div>
                        <div style={{ color: '#F77737', fontWeight: 'bold', fontSize: 16, textAlign: 'center' }}>
                            { editCountStore.editCountInitialModalValue.remainScheduleCnt > 0
                                ? (
                                    <>
                                        <PlayCircleOutlined style={{ marginRight: 4, color: '#F77737' }} />
                                        <span>{editCountStore.editCountInitialModalValue.remainScheduleCnt > 0 ? editCountStore.editCountInitialModalValue.remainScheduleCnt : '-'}</span>
                                    </>
                                )
                                : (
                                    <PauseCircleOutlined style={{ marginRight: 4, color: '#c2c2c2' }} />
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <div>아이디 : {editCountStore.editCountInitialModalValue.targetId}
                            <a
                                target='_blank'
                                href={`https://www.instagram.com/${editCountStore.editCountInitialModalValue.targetId}`}
                                rel="noreferrer noopener"
                                style={{ marginLeft: 10 }}
                            >
                            링크</a>
                        </div>
                        { editCountStore.editCountInitialModalValue.targetUrl !== '' &&
                            <div>URL : {editCountStore.editCountInitialModalValue.targetUrl}
                                <a
                                    target='_blank'
                                    href={`https://www.instagram.com/reel/${editCountStore.editCountInitialModalValue.targetUrl}`}
                                    rel="noreferrer noopener"
                                    style={{ marginLeft: 10 }}
                                >
                                링크</a>
                            </div>
                        }
                    </div>
                </div>
                <div>보여야 할 갯수</div>
                <Input
                    placeholder='displayingCnt'
                    value={editCountStore.editCountModalValue.displayingCnt}
                    onChange={handleDisplayingAndTotalCntChange}
                    type='number'
                />
                <div>실제로 찍을 갯수</div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div>{editCountStore.editCountInitialModalValue.totalCnt}</div>
                    <ArrowRightOutlined style={{ margin: '0 10px' }} />
                    <Input
                        placeholder='totalCnt'
                        value={editCountStore.editCountModalValue.totalCnt}
                        onChange={handleTotalCntChange}
                        type='number'
                    />
                </div>
            </div>
        </Modal>
    );
});

export default EditCountModal;