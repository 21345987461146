import { ManualOrderDataPlatformTypeEnum, ManualOrderDataTaskTypeEnum } from '../../apiTypes';
import repository from '../../repository';
import { Button, Checkbox, Divider, Form, Input, message, Radio, TimePicker } from 'antd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import styled from '@emotion/styled';
import confirm from 'antd/lib/modal/confirm';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';
import getRandomOrderNumber from '../../helper/getRandomOrderNumber';

interface FormData {
    orders: Array<{
        platformType: ManualOrderDataPlatformTypeEnum;
        taskType: ManualOrderDataTaskTypeEnum;
        progressCnt: string | undefined;
        displayingCnt: string | undefined;
        targetUrl: string;
        targetId: string;
        isForeigner: boolean;
        purchaseCnt: string | undefined;
        isService: boolean;
        isReserved: boolean;
        reservedCnt: number | undefined;
    }>;
    needAligoSMS: boolean;
    customerName: string;
    orderPhone: string;
    price: string | undefined;
    orderNo: number;
};

export interface OrderPayload {
    orders: Array<{
        platformType: ManualOrderDataPlatformTypeEnum;
        taskType: ManualOrderDataTaskTypeEnum;
        progressCnt: number;
        displayingCnt: number;
        targetUrl: string;
        targetId: string;
        isForeigner: boolean;
        purchaseCnt: number;
        isService: boolean;
        reservedDt?: string;
    }>;
    needAligoSMS: boolean;
    customerName: string;
    orderPhone: string;
    price: number;
    orderNo: number;
};

const RootContainer = styled.div`
display: flex;
flex-direction: column;
padding: 50px;
@media screen and (max-width: 500px) {
    padding: 50px 20px;
}
`;

const FormItemContainer = styled.div`
display: flex;
flex-direction: row;
width: 350px;
align-items: center;
padding: 5px 0;
`;

const FormItemLeftLabel = styled.label`
width: 70px;
word-break: keep-all;
font-weight: bold;
`;

const ErrorP = styled.p`
color: red;
`;

function ManualOrderForm() {
    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        reset,
    } = useForm<FormData>({
        defaultValues: {
            orders: [
                // {
                //     // @ts-ignore
                //     platformType: 'insta',
                //     // @ts-ignore
                //     taskType: 'like',
                //     progressCnt: undefined,
                //     displayingCnt: undefined,
                //     targetUrl: '',
                //     targetId: '',
                //     isForeigner: false,
                //     purchaseCnt: '1',
                //     isService: false,
                //     isReserved: false,
                //     reservedCnt: undefined,
                // }
            ],
        },
    });
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'orders'
    });

    const deleteConfirm = (index: number) => {
        confirm({
            title: '이 필드를 지우시겠습니까?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                remove(index);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    const _PostManualOrder = async (data: OrderPayload) => {
        try {
            if (window.confirm('주문하시겠습니까?')) {
                await repository.postManualOrder(data);
                message.success('수동주문 성공');
                reset();
            }
        } catch (err) {
            console.log('err', err);
            message.error('값에 문제가 있습니다.');
        }
    };

    const registerSubmit = async (data: FormData) => {
        if (data.orders.length < 1) {
            message.error('필드가 없습니다.');
            return;
        }
        const payload = {
            orders: data.orders.map((order) => {
                if (order.isReserved && order.reservedCnt) {
                    let reservedOrder = [];
                    for (let i in Array.from({ length: order.reservedCnt }, (v, i) => i )) {
                        // console.log('i', i);
                        reservedOrder.push({
                            platformType: order.platformType,
                            taskType: order.taskType,
                            progressCnt: Math.ceil(parseInt(order.progressCnt ?? '0') / order.reservedCnt),
                            displayingCnt: Math.ceil(parseInt(order.displayingCnt ?? '0') / order.reservedCnt),
                            targetUrl: order.taskType === 'follow' ? '' : order.targetUrl.trim(),
                            targetId: order.targetId.trim(),
                            isForeigner: order.isForeigner,
                            purchaseCnt: parseInt(order.purchaseCnt ?? '0'),
                            isService: order.isService,
                            reservedDt: moment().add(i, "d").startOf("day").format(),
                        });
                    };
                    // console.log('reservedOrder', reservedOrder);
                    return reservedOrder;
                } else {
                    return {
                        platformType: order.platformType,
                        taskType: order.taskType,
                        progressCnt: parseInt(order.progressCnt ?? '0'),
                        displayingCnt: parseInt(order.displayingCnt ?? '0'),
                        targetUrl: order.taskType === 'follow' ? '' : order.targetUrl.trim(),
                        targetId: order.targetId.trim(),
                        isForeigner: order.isForeigner,
                        purchaseCnt: parseInt(order.purchaseCnt ?? '0'),
                        isService: order.isService,
                    };
                }
            }).flat(),
            needAligoSMS: data.needAligoSMS,
            customerName: data.customerName.trim(),
            orderPhone: data.orderPhone.trim() ?? '',
            price: parseInt(data.price ?? '0'),
            orderNo: data.orderNo,
        };
        console.log(payload);
        await _PostManualOrder(payload);
    };

    useEffect(() => {
        setValue('orderPhone', watch('orderPhone')?.replaceAll('-', ''));
    }, [ watch('orderPhone') ]);

    return (
        <>
            <RootContainer>
                <form onSubmit={handleSubmit(registerSubmit)}>
                    <Divider orientation='left' style={{ fontWeight: 'bold' }}>고객 정보</Divider>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Controller
                                name='orderNo'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Item
                                        label='주문번호'
                                        labelCol={{ span: 24 }}
                                    >
                                        <Input
                                            {...register('orderNo', {
                                                required: '주문번호를 입력해주세요. 주문번호가 없는 주문은 랜덤을 눌러주세요.',
                                            })}
                                            value={value}
                                            onChange={onChange}
                                            placeholder='orderNo'
                                            type='number'
                                            id='orderNo'
                                        />
                                        <Button
                                            onClick={() => setValue('orderNo', getRandomOrderNumber())}
                                        >랜덤</Button>
                                    </Form.Item>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name='orderNo'
                                render={({ message }) => <ErrorP>{message}</ErrorP>}
                            />
                            <Controller
                                name='customerName'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Item
                                        label='고객명'
                                        labelCol={{ span: 24 }}
                                    >
                                        <Input
                                            value={value}
                                            onChange={onChange}
                                            placeholder='customerName'
                                            type='text'
                                            id='customerName'
                                        />
                                    </Form.Item>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name='customerName'
                                render={({ message }) => <ErrorP>{message}</ErrorP>}
                            />
                            <Controller
                                name='orderPhone'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Item
                                        label='전화번호'
                                        labelCol={{ span: 24 }}
                                    >
                                        <Input
                                            {...register('orderPhone', {
                                                validate: (value) => ((value && watch('needAligoSMS')) || !watch('needAligoSMS'))
                                                || '번호를 입력해주세요'
                                            })}
                                            value={value}
                                            onChange={e => {
                                                setValue('needAligoSMS', e.target.value !== '');
                                                onChange(e);
                                            }}
                                            placeholder='orderPhone'
                                            type='phone'
                                            id='orderPhone'
                                        />
                                    </Form.Item>
                                )}
                            />
                            <Controller
                                name='needAligoSMS'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                        checked={value}
                                        defaultChecked={false}
                                        id='needAligoSMS'
                                        onChange={e => onChange(e.target.checked)}
                                        style={{ marginBottom: 24 }}
                                    >
                                        알림톡
                                    </Checkbox>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name='orderPhone'
                                render={({ message }) => <ErrorP>{message}</ErrorP>}
                            />
                            <Controller
                                name='price'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Item
                                        label='명목상 구매가격'
                                        labelCol={{ span: 24 }}
                                    >
                                        <Input
                                            {...register('price', {
                                                required: '가격을 입력해주세요',
                                            })}
                                            value={value}
                                            onChange={onChange}
                                            placeholder='price'
                                            type='number'
                                            id='price'
                                        />
                                    </Form.Item>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`price`}
                                render={({ message }) => <ErrorP>{message}</ErrorP>}
                            />
                        <div style={{ height: 20 }} />
                        <Divider orientation='left' style={{ fontWeight: 'bold' }}>주문 정보</Divider>
                        <div>
                            { fields.map(({ id }, index) => (
                                <div style={{
                                    position: 'relative',
                                    border: '1px solid #d9d9d9',
                                    padding: 20,
                                    marginBottom: 20,
                                    borderRadius: 4,
                                }}>
                                    { index > 0 &&
                                        <button
                                            type='button'
                                            onClick={() => deleteConfirm(index)}
                                            style={{
                                                padding: 10,
                                                position: 'absolute',
                                                top: -20,
                                                right: -20,
                                            }}
                                        >
                                            <CloseCircleOutlined style={{
                                                fontSize: 26,
                                                color: '#a9a9a9',
                                                backgroundColor: 'white',
                                            }} />
                                        </button>
                                    }
                                    <Controller
                                        name={`orders.${index}.taskType`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Form.Item
                                                    label='주문종류'
                                                    labelCol={{ span: 24 }}
                                                >
                                                    <Radio.Group
                                                        options={[
                                                            { label: '좋아요', value: 'like' },
                                                            { label: '팔로우', value: 'follow' },
                                                            { label: '릴스', value: 'like_reels' },
                                                            // { label: '댓글', value: 'comment' },
                                                        ]}
                                                        onChange={e => onChange(e.target.value)}
                                                        value={value}
                                                        optionType='button'
                                                    />
                                                </Form.Item>
                                            )
                                        }}
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Controller
                                            name={`orders.${index}.isForeigner`}
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <Checkbox
                                                        checked={value}
                                                        defaultChecked={false}
                                                        id='needAligoSMS'
                                                        onChange={e => onChange(e.target.checked)}
                                                        style={{ marginBottom: 24 }}
                                                    >
                                                        외국인
                                                    </Checkbox>
                                                )
                                            }}
                                        />
                                        <Controller
                                            name={`orders.${index}.isService`}
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox
                                                    checked={value}
                                                    defaultChecked={false}
                                                    id='isService'
                                                    onChange={e => onChange(e.target.checked)}
                                                    style={{ marginBottom: 24 }}
                                                >
                                                    서비스
                                                </Checkbox>
                                            )}
                                        />
                                        <Controller
                                            name={`orders.${index}.isReserved`}
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox
                                                    checked={value}
                                                    defaultChecked={false}
                                                    id='isReserved'
                                                    onChange={e => onChange(e.target.checked)}
                                                    style={{ marginBottom: 24 }}
                                                >
                                                    예약분할
                                                </Checkbox>
                                            )}
                                        />
                                    </div>
                                    { watch(`orders.${index}.isReserved`) &&
                                        <div>
                                            <Controller
                                                name={`orders.${index}.reservedCnt`}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Item
                                                        label='나눌 일수'
                                                        labelCol={{ span: 24 }}
                                                    >
                                                        <Input
                                                            {...register(`orders.${index}.reservedCnt`, {
                                                                validate: (value) => ((value && (watch(`orders.${index}.isReserved`))))
                                                                    || '일수를 입력해주세요'
                                                            })}
                                                            value={value}
                                                            onChange={onChange}
                                                            type='number'
                                                            id={`orders.${index}.reservedCnt`}
                                                        />
                                                    </Form.Item>
                                                )}
                                            />
                                            <ErrorMessage
                                            errors={errors}
                                            name={`orders.${index}.reservedCnt`}
                                            render={({ message }) => <ErrorP>{message}</ErrorP>}
                                            />
                                        </div>
                                    }
                                    <Controller
                                        name={`orders.${index}.progressCnt`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='실제 개수'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.progressCnt`, {
                                                        required: '개수를 입력해주세요',
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='progressCnt'
                                                    type='number'
                                                    id={`orders.${index}.progressCnt`}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.progressCnt`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Controller
                                        name={`orders.${index}.displayingCnt`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='외부용 개수'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.displayingCnt`, {
                                                        required: '개수를 입력해주세요',
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='displayingCnt'
                                                    type='number'
                                                    id={`orders.${index}.displayingCnt`}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.displayingCnt`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Controller
                                        name={`orders.${index}.targetId`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='아이디'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.targetId`, {
                                                        required: '닉네임을 입력해주세요',
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='targetId'
                                                    type='text'
                                                    id={`orders.${index}.targetId`}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.targetId`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Controller
                                        name={`orders.${index}.targetUrl`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='타겟 URL'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.targetUrl`, {
                                                        validate: (value) => ((value && (watch(`orders.${index}.taskType`) !== 'follow'))
                                                            || watch(`orders.${index}.taskType`) === 'follow')
                                                        || 'url을 입력해주세요'
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='targetUrl'
                                                    type='text'
                                                    id={`orders.${index}.targetUrl`}
                                                    disabled={watch(`orders.${index}.taskType`) === 'follow'}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.targetUrl`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Controller
                                        name={`orders.${index}.purchaseCnt`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='구매개수'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.purchaseCnt`, {
                                                        required: '개수를 입력해주세요',
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='purchaseCnt'
                                                    type='number'
                                                    id={`orders.${index}.purchaseCnt`}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.purchaseCnt`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            append({
                                                // @ts-ignore
                                                platformType: 'insta',
                                                // @ts-ignore
                                                taskType: watch(`orders.${index}.taskType`),
                                                progressCnt: watch(`orders.${index}.progressCnt`),
                                                displayingCnt: watch(`orders.${index}.displayingCnt`),
                                                targetUrl: '',
                                                targetId: watch(`orders.${index}.targetId`),
                                                isForeigner: watch(`orders.${index}.isForeigner`),
                                                purchaseCnt: '1',
                                                isService: false,
                                                isReserved: false,
                                                reservedCnt: undefined,
                                                reservedDt: undefined,
                                            })
                                        }}
                                        icon={<PlusOutlined />}
                                        style={{ flex: 1 }}
                                    >
                                        복제하기
                                    </Button>
                                </div>
                            ))}
                        </div>
                        <Button
                            type="dashed"
                            onClick={() => append({
                                // @ts-ignore
                                platformType: 'insta',
                                // @ts-ignore
                                taskType: 'like',
                                progressCnt: undefined,
                                displayingCnt: undefined,
                                targetUrl: '',
                                targetId: '',
                                isForeigner: false,
                                purchaseCnt: '1',
                                isService: false,
                                isReserved: false,
                                reservedCnt: undefined,
                                reservedDt: undefined,
                            })}
                            icon={<PlusOutlined />}
                            style={{ flex: 1 }}
                        >
                            주문 추가
                        </Button>
                        <Button
                            type='primary'
                            htmlType='submit'
                            style={{ marginTop: 40 }}
                        >
                            제출하기
                        </Button>
                    </div>
                </form>
            </RootContainer>
        </>
    );
};

export default ManualOrderForm;
