import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import MaUTable from '@mui/material/Table';
import { AbuserDto } from '../../apiTypes';
import repository from '../../repository';
import Mask from '../../components/mask';
import { Empty, message } from 'antd';
import moment from 'moment';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

// @ts-ignore
function Table({ columns, data }) {
    const { getTableProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
    );

    return (
        <MaUTable
            style={{ minWidth: 1500 }}
            {...getTableProps()}
            stickyHeader
        >
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            return (
                                // @ts-ignore
                                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {/* @ts-ignore */}
                                        {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                    </span>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <TableRow {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                if (
                                    cell.column.id === 'id' ||
                                    cell.column.id === 'email' ||
                                    cell.column.id === 'instaPassword' ||
                                    cell.column.id === 'emailPassword' ||
                                    cell.column.id === 'instaName'
                                ) {
                                    return (
                                        <CopyToClipboard
                                            text={cell.value}
                                            onCopy={(text, result) => {
                                                if (result) {
                                                    message.success(`${cell.value}`);
                                                } else {
                                                    message.error(`${cell.value}`);
                                                }
                                            }}
                                        >
                                            <TableCell {...cell.getCellProps()} style={{ cursor: 'pointer' }}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        </CopyToClipboard>
                                    )
                                }

                                return (
                                    <TableCell {...cell.getCellProps()} style={{ cursor: 'pointer' }}>
                                        { cell.column.id === 'index' ? i + 1 : cell.render('Cell') }
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </MaUTable>
    );
};

function UpdateSession() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ sessionList, setSessionList ] = useState<Array<AbuserDto>>([]);

    const columns = React.useMemo(() => [
        {
            Header: 'index',
            accessor: 'index',
        },
        {
            Header: 'id',
            accessor: 'id',
        },
        {
            Header: '이메일',
            accessor: 'email',
        },
        {
            Header: 'insta P/W',
            accessor: 'instaPassword',
        },
        {
            Header: 'rediff P/W',
            accessor: 'emailPassword',
        },
        {
            Header: '닉네임',
            accessor: 'instaName',
        },
        {
            Header: '마지막 활성화 일자',
            accessor: 'lastActivatedDt',
        },
    ], []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const _sessionList = await repository.getNeedSessionList();
            // @ts-ignore
            setSessionList(_sessionList.map((listItem, i) => {
                return {
                    index: i + 1,
                    id: listItem.id,
                    email: listItem.email,
                    emailPassword: listItem.emailPassword,
                    instaPassword: listItem.instaPassword,
                    instaName: listItem.instaName,
                    accountStatus: listItem.accountStatus,
                    lastActivatedDt: moment(listItem.lastActivatedDt).format('YYYY년 MM월 DD일 HH시 mm분'),
                    setupMeta: listItem.setupMeta,
                };
            }));
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Mask />
    }

    return (
        <div>
            { sessionList.length === 0
                ? <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Empty /></div>
                : <Table
                    columns={columns}
                    data={sessionList}
                />
            }
        </div>
    );
};

export default UpdateSession;
