import styled from '@emotion/styled';
import { Button, Input, message } from 'antd';
import { useState, useMemo } from 'react';
import Flex from '../../components/flex';
import { observer } from 'mobx-react';

const Layout = styled(Flex)`
width: 100%;
padding: 50px 20px 0 50px;
gap: 20px;
flex-direction: column;

@media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}
`;

const Template = styled.pre`
word-wrap: break-word;
white-space: pre-wrap;
white-space: -moz-pre-wrap;
white-space: -pre-wrap;
white-space: -o-pre-wrap;
word-break: break-all;
background: #f2f2f2;
padding: 20px;
border-radius: 20px;
max-width: 500px;
`;

const generator = (name?: string) => {
    const _name = name || 'ㅇㅇㅇ';
    return (
`${_name}님, 안녕하세요.
운영하고 계신 ${_name} 인스타그램 보고 연락드리게 되었습니다.

다름이 아니라, ${_name}님에게 피드 관리 서비스 제안을 드리고자 합니다.

⬇️⬇️⬇️
말씀드리기 앞서 ${_name}님께서 저희에 대해 궁금해하실 것 같아,

🧡💛한국인 좋아요 50회💛🧡

를 원하시는 게시물에 바로 진행해드리는 이벤트를 진행하고 있습니다(회원가입 필요 X) DM 답변 또는 하단에 적힌 카카오톡 링크 또는 유선전화 번호를 통해 게시물 링크만 말씀해주시면 바로 작업 시작해드리겠습니다.

➡️ https://insta-leader.kr/
저희는 국내 인스타그램 유저 유입 활성화 마케팅 국내 누적 판매량 1등 업체, 인스타대장입니다. 여러 사장님들을 꾸준히 책임져드리고 있으며, 수많은 게시물을 인기게시물로 만들었습니다.

이번에 ${_name}님과 같이 인스타그램 마케팅에 대한 이해도가 높으신 분들을 위해 월 단위 좋아요/팔로워 유입 구독 서비스를 출시했습니다. 그중 ${_name}님에게 제일 맞을 것 같은 서비스,

🎁 [한국인 좋아요 100회 구독] 🎁

❗️7일 내 구매 철회 시 전액 환불❗️

조건으로 제안 드립니다.

🔴 기간 🔴
30일 단위로 구매 가능

🟠 비용 🟠
부가세 포함 33,600원

🟡 상품설명 🟡
- 30일간 매일 업로드하는 첫 게시물에 대해 한국인 좋아요 100회를 자연스럽게 유입
- 별도로 게시물 업로드 사실을 말씀해 주실 필요 없이 저희 쪽에서 업로드 여부를 확인하여 유저 유입을 시켜드립니다.
- 아래 창구를 통해 연락 주시면 "7일 이내 구매 철회 시 전액 환불"을 포함하여 별도의 할인 혜택을 제공해드리겠습니다.
- 여러 유형의 구독 상품을 포함하여, 인스타그램 활성화를 위한 다양한 상품 준비되어 있으니 찾아와주시면 감사합니다!!

감사합니다.
인스타대장 드림,

<카카오톡>
https://pf.kakao.com/_ZZZexj

<전화>
070-8065-4831`
    );
};

const DM = observer(() => {
    const [ name, setName ] = useState<string>();

    const generatedText = useMemo(() => {
        return generator(name);
    }, [ name ]);

    const handleCopyClipBoard = async () => {
        if (!name) {
            message.error('고객 이름(상호)를 입력해주세요.');
            return;
        }

        try {
            await navigator.clipboard.writeText(generatedText);
            message.success('클립보드에 복사되었습니다.');
        } catch (e) {
            message.error('복사에 실패하였습니다.');
        }
    };


    return (
        <Flex style={{ width: '100%' }}>
            <Layout>
                <div>
                    <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='고객 이름(상호) 입력'
                    />
                    <div style={{ marginTop: 20 }}>
                        <Button type='primary' onClick={handleCopyClipBoard}>복사하기</Button>
                    </div>
                </div>
                <Template>
                    { name
                        ? (
                            <>
                                { generatedText.split(name).map((x, i, arr) => {
                                    return (
                                        <>
                                            <span>{x}</span>
                                            { i !== arr.length - 1 &&
                                                <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>{name}</span>
                                            }
                                        </>
                                    )
                                })}
                            </>
                        )
                        : generatedText
                    }
                </Template>
            </Layout>
        </Flex>
    );
});

export default DM;