import { action, computed, makeObservable, observable } from "mobx";

type EditCountModalValue = {
    displayingCnt: number;
    totalCnt: number;
};

type EditCountInitialModalValue = {
    orderTaskId: string;
    progressCnt: number;
    totalCnt: number;
    remainScheduleCnt: number;
    targetId: string;
    targetUrl: string;
};

interface IEditCountStore {
    editCountModalOpen: boolean;
    editCountModalValue: EditCountModalValue;
    editCountInitialModalValue: EditCountInitialModalValue;
};

const INITIAL_STATES: IEditCountStore = {
    editCountModalOpen: false,
    editCountModalValue: {
        displayingCnt: 0,
        totalCnt: 0,
    },
    editCountInitialModalValue: {
        orderTaskId: '',
        progressCnt: 0,
        totalCnt: 0,
        remainScheduleCnt: 0,
        targetId: '',
        targetUrl: '',
    },
};

class EditCountStore implements IEditCountStore {

    private _editCountModalOpen = INITIAL_STATES.editCountModalOpen;
    private _editCountModalValue = INITIAL_STATES.editCountModalValue;
    private _editCountInitialModalValue = INITIAL_STATES.editCountInitialModalValue;

    constructor() {
        makeObservable<
            EditCountStore,
            '_editCountModalOpen' | '_editCountModalValue' | '_editCountInitialModalValue'
        >(this, {
            _editCountModalOpen: observable,
            _editCountModalValue: observable,
            _editCountInitialModalValue: observable,
            editCountModalOpen: computed,
            editCountModalValue: computed,
            editCountInitialModalValue: computed,
            handleEditCountModalOpen: action.bound,
            handleEditCountModalValue: action.bound,
            handleEditCountInitialModalValue: action.bound,
        });
    };

    get editCountModalOpen() {
        return this._editCountModalOpen;
    };

    get editCountModalValue() {
        return this._editCountModalValue;
    };

    get editCountInitialModalValue() {
        return this._editCountInitialModalValue;
    };

    handleEditCountModalOpen(open: boolean) {
        this._editCountModalOpen = open;
    };

    handleEditCountModalValue(value: EditCountModalValue) {
        this._editCountModalValue = value;
    };

    handleEditCountInitialModalValue(value: EditCountInitialModalValue) {
        this._editCountInitialModalValue = value;
    };

    clear() {
        this._editCountModalOpen = INITIAL_STATES.editCountModalOpen;
        this._editCountModalValue = INITIAL_STATES.editCountModalValue;
        this._editCountInitialModalValue = INITIAL_STATES.editCountInitialModalValue;
    };

};

const editCountStore = new EditCountStore();
export default editCountStore;