import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import MaUTable from '@mui/material/Table';
import repository from '../../repository';
import Mask from '../../components/mask';
import { Button, message } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from '@emotion/styled';
import Flex from '../../components/flex';
import { AsHistoryMapAsHistoryMap, CreateManualOrderDto } from '../../apiTypes';
import getRandomOrderNumber from '../../helper/getRandomOrderNumber';

/**
    * @export
    * @enum {string}
    */
export const enum ManualOrderDataTaskTypeEnum {
    Like = 'like',
    Follow = 'follow',
    LIKE_REELS = 'like_reels',
}

/**
  *
  * @export
  * @interface CreateAsHistoryDto
  */
export interface CreateAsHistoryDto {
    /**
     *
     * @type {string}
     * @memberof CreateAsHistoryDto
     */
    memo: string;
    /**
     *
     * @type {number}
     * @memberof CreateAsHistoryDto
     */
    amount: number;
    /**
     *
     * @type {ManualOrderDataTaskTypeEnum}
     * @memberof CreateAsHistoryDto
     */
    taskType: ManualOrderDataTaskTypeEnum;
    /**
     *
     * @type {string}
     * @memberof CreateAsHistoryDto
     */
    targetId: string;
    /**
     *
     * @type {string}
     * @memberof CreateAsHistoryDto
     */
    targetUrl?: string;
}

const ROW_SIZE = 65;

const TargetIdButton = styled.button`
:hover {
    opacity: 0.5;
}
`;

// @ts-ignore
function Table({ columns, data, isNextAsLoading, hasNextAs }) {
    const defaultColumn = useMemo(() => ({
        // width: 200,
    }), []);

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        getTableBodyProps,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    return (
        <MaUTable
            {...getTableProps()}
            stickyHeader
        >
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            if (['latestCreatedAt', 'progressed'].includes(column.id)) {
                                return null;
                            }

                            return (
                                // @ts-ignore
                                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <div>
                                        {column.render('Filter')}
                                    </div>
                                    <span>
                                        {/* @ts-ignore */}
                                        {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                    </span>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                { rows.map((row, i) => {
                    prepareRow(row);

                    const {
                        count,
                        progressed,
                    } = row.values;

                    const getBackgroundColor = () => {
                        if (progressed >= count) {
                            return '#405DE610';
                        }

                        if (progressed > 0) {
                            return '#f2f20019';
                        }

                        return undefined;
                    };

                    return (
                        <TableRow
                            {...row.getRowProps({
                                style: {
                                    backgroundColor: getBackgroundColor(),
                                },
                            })}
                        >
                            { row.cells.map(cell => {
                                if (['latestCreatedAt', 'progressed'].includes(cell.column.id)) {
                                    return null;
                                }

                                return (
                                    <TableCell
                                        {...cell.getCellProps()}
                                        style={{
                                            cursor: 'pointer',
                                            // @ts-ignore
                                            width: cell.column.originalWidth,
                                        }}
                                    >
                                        { cell.render('Cell') }
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </MaUTable>
    );
}

function AsList() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ asList, setAsList ] = useState<Array<any>>([]);
    const [ bannedAmount, setBannedAmount ] = useState<number>(0);
    const [ asHistory, setAsHistory ] = useState<Array<AsHistoryMapAsHistoryMap>>([]);
    const [ currentPage, setCurrentPage ] = useState<number>(1);
    const [ minAmount, setMinAmount ] = useState<number>(3);

    const order = async (targetId: string, count: number) => {
        const orderPayload: CreateManualOrderDto = {
            orders: [
                {
                    // @ts-ignore
                    platformType: 'insta',
                    // @ts-ignore
                    taskType: 'follow',
                    progressCnt: count,
                    displayingCnt: count,
                    targetId: targetId,
                    isForeigner: false,
                    purchaseCnt: 1,
                },
            ],
            price: 0,
            needAligoSMS: false,
            customerName: 'AS_CUSTOMER_NAME',
            orderPhone: 'AS_ORDER_PHONE',
            orderNo: getRandomOrderNumber(),
        };

        await repository.postManualOrder(orderPayload);
    };

    const doAs = useCallback((targetId: string, count: number, progressed?: number) => {
        try {
            (async () => {
                const billing = await repository.getAllBilling(targetId);
                if (billing <= 0) {
                    const agreement = window.confirm('구매 이력이 없는 고객입니다. 정말 진행하시겠습니까?');
                    if (!agreement) {
                        return;
                    }
                }

                const remainCount = count - (progressed ?? 0);
                const answer = window.prompt(
                    `${targetId} 계정에 진행할 as 수량을 입력해주세요.`,
                    `${Math.max(remainCount, 0)}`,
                );

                if (answer) {
                    const answeredAmount = parseInt(answer);
                    if (answeredAmount <= 0) {
                        message.error('as 수량은 0보다 큰 값이어야 합니다.');
                        return;
                    }

                    const historyPayload: CreateAsHistoryDto = {
                        memo: 'AS_MEMO',
                        amount: answeredAmount,
                        taskType: ManualOrderDataTaskTypeEnum.Follow,
                        targetId,
                    };

                    if (answeredAmount > remainCount) {
                        if (window.confirm('as가 필요한 수량을 초과합니다. 계속 진행하시겠습니까?')) {
                            setLoading(true);
                            await order(targetId, answeredAmount);
                            message.success('as 주문 생성에 성공하였습니다.');
                            try {
                                // @ts-ignore
                                const res = await repository.addAsHistory(historyPayload);
                                await getAsHistory();
                                message.success('as 내역 업데이트에 성공하였습니다.');
                            } catch (err) {
                                message.error('as 내역 업데이트에 실패하였습니다.');
                            }
                        }
                        return;
                    }

                    setLoading(true);
                    await order(targetId, answeredAmount);
                    message.success('as 주문 생성에 성공하였습니다.');
                    try {
                        // @ts-ignore
                        const res = await repository.addAsHistory(historyPayload);
                        await getAsHistory();
                        message.success('as 내역 업데이트에 성공하였습니다.');
                    } catch (err) {
                        message.error('as 내역 업데이트에 실패하였습니다.');
                    }
                } else {
                }
            })();
        } catch (err) {
            console.log(err);
        }
    }, []);

    const columns = React.useMemo(() => [
        {
            Header: '작업대상',
            accessor: 'targetId',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <TargetIdButton onClick={(event) => {
                            event.stopPropagation();
                            window.open(
                                `https://www.instagram.com/${cell.value}`,
                                '_blank',
                                'noreferrer noopener',
                            );
                        }}>
                            <div style={{
                                width: 120,
                                wordBreak: 'break-word',
                                textAlign: 'left',
                            }}>
                                <div>{cell.value}</div>
                                <div>{moment(cell.row.values.latestCreatedAt).format('YYYY.MM.DD')}</div>
                            </div>
                        </TargetIdButton>
                    </div>
                );
            },
            width: 150,
        },
        {
            Header: 'as 진행률',
            accessor: 'count',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                const {
                    targetId,
                    count,
                    progressed,
                } = cell.row.values;

                return (
                    <div>
                        {progressed} / {count}
                    </div>
                );
            },
            width: 100,
        },
        {
            Header: '',
            accessor: 'dummy',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                const {
                    targetId,
                    count,
                    progressed,
                    billing,
                } = cell.row.values;

                return (
                    <div>
                        <Flex gap={5}>
                            <Button onClick={() => {
                                doAs(targetId, count, progressed);
                            }}>
                                A/S
                            </Button>
                            <div style={{ width: 80, textAlign: 'center' }}>
                                { billing === undefined
                                    ? (
                                        <Button onClick={() => {
                                            getAllBilling(targetId);
                                        }}>
                                            구매확인
                                        </Button>
                                    )
                                    : (
                                        <div>{billing?.toLocaleString()}</div>
                                    )
                                }
                            </div>
                        </Flex>
                    </div>
                );
            },
            width: 180,
        },
        {
            Header: '',
            accessor: 'progressed',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div></div>
                );
            },
            width: 0,
        },
        {
            Header: '',
            accessor: 'latestCreatedAt',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div></div>
                );
            },
            width: 0,
        },
        {
            Header: '',
            accessor: 'billing',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div></div>
                );
            },
            width: 0,
        },
    ], []);

    const startDate = useMemo(() => {
        const currentDay = moment().date();
        const startDateDay = 3 * (Math.floor(currentDay / 3));
        const fixed = `${moment().year()}-${moment().month() + 1}-${startDateDay > 9 ? startDateDay : `0${startDateDay}`}`;
        const _startDate = moment(fixed).clone().subtract(3 * (currentPage - 1), 'days').format('YYYY-MM-DD');

        return _startDate;
    }, [ currentPage ]);

    const getAsHistory = async () => {
        const _asHistory = await repository.getAsHistory(startDate);
        setAsHistory(_asHistory);
    }

    const getAsHistoryByTargetId = useCallback((targetId: string) => {
        return asHistory.find(h => h.targetId === targetId);
    }, [ asHistory ]);

    const getProgressedAmountByTargetId = useCallback((targetId: string) => {
        const targetHistory = getAsHistoryByTargetId(targetId);
        return targetHistory?.asHistories.reduce((a, c) => a + c.amount, 0) ?? 0;
    }, [ asHistory ]);

    const getAllBilling = useCallback(async (targetId: string) => {
        const billing = await repository.getAllBilling(targetId);
        setAsList(prev => {
            return prev.map(p => {
                if (p.targetId === targetId) {
                    return {
                        ...p,
                        billing,
                    };
                }
                return p;
            });
        });
    }, []);

    const getAsList = useCallback(async () => {
        const asList = await repository.getAsList(startDate);
        setBannedAmount(asList.length);

        const userCounts: any = {};
        const latestCreatedAt: any = {};
        asList.forEach(item => {
            item.abuserFollowships.forEach(follow => {
                const targetId = follow.userId;
                const createdAt = new Date(follow.createdAt);
                // @ts-ignore
                if (!latestCreatedAt[targetId] || createdAt > latestCreatedAt[targetId]) {
                    // @ts-ignore
                    latestCreatedAt[targetId] = createdAt;
                }

                // @ts-ignore
                userCounts[targetId] = (userCounts[targetId] || 0) + 1;
            });
        });

        const realList = Object.entries(userCounts).map(([targetId, counts]) => {
            const createdAt = latestCreatedAt[targetId];
            return [targetId, counts, createdAt];
        });

        // @ts-ignore
        realList.sort((a,b) => moment(b[2]).diff(moment(a[2])));

        setAsList(realList.map(x => {
            const _progressed = getProgressedAmountByTargetId(x[0]);
            return {
                targetId: x[0],
                count: x[1],
                latestCreatedAt: x[2],
                progressed: _progressed,
                dummy: '',
                billing: undefined,
            };
        }));
    }, [ asHistory ]);

    useEffect(() => {
        (async () => {
            await getAsHistory();
        })();
    }, [ currentPage ]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getAsList();
            setLoading(false);
        })();
    }, [ asHistory ]);

    return (
        <>
            <div style={{
                paddingLeft: 40,
            }}>
                <Flex
                    style={{ width: 350, marginBottom: 10 }}
                    gap={10}
                >
                    <Button onClick={() => {
                        (async () => {
                            try {
                                const targetId = window.prompt('인스타그램 id 입력');
                                if (!targetId) {
                                    throw new Error('targetId를 입력해주세요.');
                                }

                                const amount = window.prompt(`${targetId} 계정에 진행할 as 수량을 입력해주세요.`);
                                const answeredAmount = parseInt(amount ?? '');
                                if (answeredAmount <= 0 || Number.isNaN(answeredAmount)) {
                                    throw new Error('적절하지 않은 as 수량입니다.');
                                }

                                const historyPayload: CreateAsHistoryDto = {
                                    memo: 'AS_MEMO',
                                    amount: answeredAmount,
                                    taskType: ManualOrderDataTaskTypeEnum.Follow,
                                    targetId,
                                };

                                setLoading(true);
                                // @ts-ignore
                                const res = await repository.addAsHistory(historyPayload);
                                await getAsHistory();
                                message.success('as 내역 업데이트에 성공하였습니다.');
                            } catch (err) {
                                // @ts-ignore
                                message.error(err.message);
                            }
                        })();
                    }}>
                        히스토리 추가
                    </Button>
                    <Button onClick={() => {
                        (async () => {
                            try {
                                const targetId = window.prompt('인스타그램 id 입력');
                                if (!targetId) {
                                    throw new Error('targetId를 입력해주세요.');
                                }

                                const allHistory = await repository.getAllAsHistory({
                                    targetId,
                                    taskType: 'follow',
                                });

                                window.alert(JSON.stringify(
                                    [
                                        {
                                            '합계수량': allHistory.reduce((a, c) => a + c.amount, 0),
                                        },
                                        ...allHistory.map(h => ({
                                            amount: h.amount,
                                            createdAt: moment(h.createdAt).format('YYYY-MM-DD'),
                                        })),
                                    ],
                                    null,
                                    2,
                                ));
                            } catch (err) {
                                // @ts-ignore
                                message.error(err.message);
                            }
                        })();
                    }}>
                        히스토리 검색
                    </Button>
                </Flex>
                <Flex
                    style={{ width: 350 }}
                    gap={10}
                >
                    <Button
                        onClick={() => {
                            const amount = window.prompt('최소수량을 입력해주세요.');
                            const _amount = parseInt(amount ?? '');
                            if (!Number.isNaN(_amount)) {
                                setMinAmount(_amount);
                            }
                        }}
                    >
                        최소수량 설정
                    </Button>
                    <div>현재 최소수량: {minAmount}</div>
                </Flex>
                <Flex
                    justifyContent='space-between'
                    style={{
                        width: 350,
                        boxSizing: 'border-box',
                        padding: '20px 0',
                    }}
                >
                    <Button
                        onClick={() => setCurrentPage(prev => prev - 1)}
                        disabled={currentPage === 0}
                    >
                        이전
                    </Button>
                    <Flex
                        column
                        style={{
                            fontSize: 12,
                            color: '#777777',
                        }}
                    >
                        <div>{currentPage}페이지</div>
                        <div>{startDate} ~ {moment(startDate).add('days', 2).format('YYYY-MM-DD')}</div>
                        <div style={{
                            color: 'rgb(255, 48, 64)',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}>
                            -{bannedAmount}
                        </div>
                    </Flex>
                    <Button
                        onClick={() => setCurrentPage(prev => prev + 1)}
                    >
                        다음
                    </Button>
                </Flex>
                <Table
                    columns={columns}
                    data={asList.filter(l => l.count >= minAmount)}
                    isNextAsLoading={false}
                    hasNextAs={false}
                />
            </div>
            { loading && <Mask /> }
        </>
    );
}

export default AsList;