import { Button, DatePicker, Modal, TimePicker, message } from 'antd';
import { observer } from 'mobx-react';
import repository from '../repository';
import { useCallback } from 'react';
import { Moment } from 'moment';
import editReservedStore from '../stores/editReservedStore';
import moment from 'moment';

interface IReservedModal {
    refresh: (orderTaskId: string) => Promise<void>;
}

const EditReservedModal:React.FC<IReservedModal> = observer((props) => {
    const handleDateAndTimeChange = useCallback((data: Moment | null) => {
        editReservedStore.handleEditReservedModalValue({
            ...editReservedStore.editReservedModalValue,
            reservedDt: data ? moment(data).format() : undefined,
        });
    }, []);

    const handleButtonAddTimeChange = useCallback((time: number, type: 'minutes' | 'hours') => {
        editReservedStore.handleEditReservedModalValue({
            ...editReservedStore.editReservedModalValue,
            reservedDt: editReservedStore.editReservedModalValue.reservedDt
                ? moment(editReservedStore.editReservedModalValue.reservedDt).add(time, type).format()
                : undefined,
        });
    }, []);

    const handleButtonSubtractTimeChange = useCallback((time: number, type: 'minutes' | 'hours') => {
        editReservedStore.handleEditReservedModalValue({
            ...editReservedStore.editReservedModalValue,
            reservedDt: editReservedStore.editReservedModalValue.reservedDt
                ? moment(editReservedStore.editReservedModalValue.reservedDt).subtract(time, type).format()
                : undefined,
        });
    }, []);

    return (
        <Modal
            open={editReservedStore.editReservedModalOpen}
            title='예약 변경'
            onCancel={() => {
                editReservedStore.clear();
            }}
            onOk={() => {
                ( async () => {
                    try {
                        if (editReservedStore.editReservedModalValue.orderTaskId === '') {
                            message.error('orderTaskId가 이상합니다.');
                            return;
                        }
                        try {
                            const rs = await repository.updateOrderReservedDt(editReservedStore.editReservedModalValue.orderTaskId, {
                                // @ts-ignore
                                reservedDt: editReservedStore.editReservedModalValue.reservedDt === undefined
                                    ? null : editReservedStore.editReservedModalValue.reservedDt,
                            });
                            if (rs) {
                                message.success('수정했습니다.');
                            }
                        } catch (err) {
                            message.error(`${err}`);
                        }
                        await props.refresh(editReservedStore.editReservedModalValue.orderTaskId);
                        editReservedStore.clear();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            }}
        >
            <div>
                <div>변경할 시간 undefined로 보내면 바로 시작가능</div>
                <div>예약된 시간: {editReservedStore.editReservedModalValue.initialReservedDt === ''
                    ? '예약된 시간이 없습니다.' : moment(editReservedStore.editReservedModalValue.initialReservedDt).format('YYYY-MM-DD(HH:mm)')}</div>
                <DatePicker
                    value={editReservedStore.editReservedModalValue.reservedDt === undefined ? undefined
                        : moment(editReservedStore.editReservedModalValue.reservedDt)}
                    format={'YYYY-MM-DD'}
                    onChange={handleDateAndTimeChange}
                />
                <TimePicker
                    value={editReservedStore.editReservedModalValue.reservedDt === undefined ? undefined
                        : moment(editReservedStore.editReservedModalValue.reservedDt)}
                    format={'HH:mm'}
                    onChange={handleDateAndTimeChange}
                />
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <Button
                        onClick={() => {
                            handleButtonAddTimeChange(10, 'minutes');
                        }}
                    >
                        + 10분
                    </Button>
                    <Button
                        onClick={() => {
                            handleButtonAddTimeChange(30, 'minutes');
                        }}
                    >
                        + 30분
                    </Button>
                    <Button
                        onClick={() => {
                            handleButtonAddTimeChange(1, 'hours');
                        }}
                    >
                        + 1시간
                    </Button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <Button
                        onClick={() => {
                            handleButtonSubtractTimeChange(10, 'minutes');
                        }}
                    >
                        - 10분
                    </Button>
                    <Button
                        onClick={() => {
                            handleButtonSubtractTimeChange(30, 'minutes');
                        }}
                    >
                        - 30분
                    </Button>
                    <Button
                        onClick={() => {
                            handleButtonSubtractTimeChange(1, 'hours');
                        }}
                    >
                        - 1시간
                    </Button>
                </div>
            </div>
        </Modal>
    );
});

export default EditReservedModal;