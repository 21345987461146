import { TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import MaUTable from '@mui/material/Table';
import { AccountSetupInfoDto } from '../../apiTypes';
import repository from '../../repository';
import Mask from '../../components/mask';
import { Empty, message } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CircularProgressbar } from 'react-circular-progressbar';

// @ts-ignore
function Table({ columns, data }) {
    const { getTableProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
    );

    return (
        <MaUTable
            style={{ minWidth: 2000 }}
            {...getTableProps()}
            stickyHeader
        >
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // @ts-ignore
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {/* @ts-ignore */}
                                    {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                </span>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <TableRow {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <CopyToClipboard
                                        text={cell.value}
                                        onCopy={(text, result) => {
                                            if (result) {
                                                message.success(`${cell.value}`);
                                            } else {
                                                message.error(`${cell.value}`);
                                            }
                                        }}
                                    >
                                        <TableCell
                                            {...cell.getCellProps()}
                                            style={{
                                                cursor: 'pointer',
                                                // @ts-ignore
                                                width: cell.column.originalWidth,
                                            }}
                                        >
                                            { cell.value === true && <CheckCircleOutlined style={{ fontSize: 16, color: '#52c41a' }} /> }
                                            { cell.value === false && <CloseCircleOutlined style={{ fontSize: 16, color: '#eb2f96' }} /> }
                                            { typeof cell.value !== 'boolean' && cell.render('Cell') }
                                        </TableCell>
                                    </CopyToClipboard>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </MaUTable>
    );
};

function SetupAccount() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ unprogressedList, setUnprogressedList ] = useState<Array<AccountSetupInfoDto>>([]);

    const columns = React.useMemo(
        () => [
        // {
        //   Header: '전체 이미지',
        //   accessor: 'totalImages',
        // },
        // {
        //   Header: '업로드된 이미지',
        //   accessor: 'progressedImages',
        // },
        // {
        //   Header: '이미지를 다 업로드 했는가?',
        //   accessor: 'isProgressedDoneImages',
        // },
        {
            Header: '계정 정보',
            columns: [
            {
                Header: 'index',
                accessor: 'index',
            },
            {
                Header: 'id',
                accessor: 'id',
            },
            {
                Header: '이메일',
                accessor: 'email',
            },
            {
                Header: 'insta P/W',
                accessor: 'instaPassword',
            },
            {
                Header: 'rediff P/W',
                accessor: 'emailPassword',
            },
            {
                Header: '닉네임',
                accessor: 'instaName',
            },
            {
                Header: '기본이름',
                accessor: 'baseName',
            },
            {
                Header: '자기소개',
                accessor: 'desc',
                width: 200,
            },
            {
                Header: '비공개 계정 여부',
                accessor: 'isPrivateAccount',
            },
            ],
        },
        {
            Header: '작업 진행 여부',
            columns: [
            {
                Header: '프로필 이미지',
                accessor: 'isProgressedProfile',
            },
            {
                Header: '닉네임',
                accessor: 'isProgressedUsername',
            },
            {
                Header: '계정 비공개',
                accessor: 'isProgressedPrivate',
            },
            {
                Header: '기본이름',
                accessor: 'isProgressedBasename',
            },
            {
                Header: '자기소개',
                accessor: 'isProgressedDesc',
            },
            {
                Header: '첫 포스트 삭제',
                accessor: 'isProgressedDeletePost',
            },
            ],
        },
        ],
        []
    );

    useEffect(() => {
        (async () => {
            setLoading(true);
            const _unprogressedList = (await repository.searchAbusers('accountStatus', 'preparing')) ?? [];
            // @ts-ignore
            setUnprogressedList(_unprogressedList.map((listItem, i) => {
                return {
                    index: i + 1,
                    id: listItem.id,
                    email: listItem.email,
                    emailPassword: listItem.emailPassword,
                    instaPassword: listItem.instaPassword,
                    instaName: listItem.instaName,
                    baseName: listItem.baseName,
                    isPrivateAccount: listItem.isPrivateAccount,
                    desc: listItem.instaDesc,
                    // @ts-ignore
                    isProgressedProfile: listItem.setupMeta.isProgressedProfile,
                    // @ts-ignore
                    isProgressedUsername: listItem.setupMeta.isProgressedUsername,
                    // @ts-ignore
                    isProgressedPrivate: listItem.setupMeta.isProgressedPrivate,
                    // @ts-ignore
                    isProgressedBasename: listItem.setupMeta.isProgressedBasename,
                    // @ts-ignore
                    isProgressedDesc: listItem.setupMeta.isProgressedDesc,
                    // 기본값 undefined여서
                    // @ts-ignore
                    isProgressedDeletePost: listItem.setupMeta.isProgressedDeletePost === true,
                };
            }));
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Mask />
    }

    const total = unprogressedList.length;
    const b = unprogressedList.filter(d => d.isProgressedProfile).length;
    const c = unprogressedList.filter(d => d.isProgressedUsername).length;
    const d = unprogressedList.filter(d => d.isProgressedPrivate).length;
    const e = unprogressedList.filter(d => d.isProgressedBasename).length;
    const f = unprogressedList.filter(d => d.isProgressedDesc).length;
    const g = unprogressedList.filter(d => d.isProgressedDeletePost).length;

    return (
        <div>
            { unprogressedList.length === 0
                ? <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Empty /></div>
                : (
                    <div>
                        <div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 40,
                                padding: 20,
                            }}>
                                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ textAlign: 'center', marginBottom: 10 }}>전체</div>
                                    <CircularProgressbar
                                        value={Math.trunc((b + c + d + e + f + g) / total / 5 * 100)}
                                        text={`${Math.trunc((b + c + d + e + f + g) / total / 5 * 100)}%`}
                                        strokeWidth={10}
                                        styles={{
                                            path: {
                                                stroke: '#ffa500',
                                            },
                                            trail: {
                                                stroke: '#e9e9e9',
                                            },
                                            text: {
                                                fill: '#424242',
                                            },
                                        }}
                                        />
                                </div>
                                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ textAlign: 'center', marginBottom: 10 }}>첫 포스트 삭제</div>
                                    <CircularProgressbar
                                        value={Math.trunc(g / total * 100)}
                                        text={`${Math.trunc(g / total * 100)}%`}
                                        strokeWidth={10}
                                        styles={{
                                            path: {
                                                stroke: '#1890ff',
                                            },
                                            trail: {
                                                stroke: '#e9e9e9',
                                            },
                                            text: {
                                                fill: '#424242',
                                            },
                                        }}
                                        />
                                </div>
                                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ textAlign: 'center', marginBottom: 10 }}>프로필 이미지</div>
                                    <CircularProgressbar
                                        value={Math.trunc(b / total * 100)}
                                        text={`${Math.trunc(b / total * 100)}%`}
                                        strokeWidth={10}
                                        styles={{
                                            path: {
                                                stroke: '#1890ff',
                                            },
                                            trail: {
                                                stroke: '#e9e9e9',
                                            },
                                            text: {
                                                fill: '#424242',
                                            },
                                        }}
                                        />
                                </div>
                                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ textAlign: 'center', marginBottom: 10 }}>자기소개</div>
                                    <CircularProgressbar
                                        value={Math.trunc(f / total * 100)}
                                        text={`${Math.trunc(f / total * 100)}%`}
                                        strokeWidth={10}
                                        styles={{
                                            path: {
                                                stroke: '#1890ff',
                                            },
                                            trail: {
                                                stroke: '#e9e9e9',
                                            },
                                            text: {
                                                fill: '#424242',
                                            },
                                        }}
                                        />
                                </div>
                                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ textAlign: 'center', marginBottom: 10 }}>기본이름</div>
                                    <CircularProgressbar
                                        value={Math.trunc(e / total * 100)}
                                        text={`${Math.trunc(e / total * 100)}%`}
                                        strokeWidth={10}
                                        styles={{
                                            path: {
                                                stroke: '#1890ff',
                                            },
                                            trail: {
                                                stroke: '#e9e9e9',
                                            },
                                            text: {
                                                fill: '#424242',
                                            },
                                        }}
                                        />
                                </div>
                                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ textAlign: 'center', marginBottom: 10 }}>닉네임</div>
                                    <CircularProgressbar
                                        value={Math.trunc(c / total * 100)}
                                        text={`${Math.trunc(c / total * 100)}%`}
                                        strokeWidth={10}
                                        styles={{
                                            path: {
                                                stroke: '#1890ff',
                                            },
                                            trail: {
                                                stroke: '#e9e9e9',
                                            },
                                            text: {
                                                fill: '#424242',
                                            },
                                        }}
                                        />
                                </div>
                                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ textAlign: 'center', marginBottom: 10 }}>비공개처리</div>
                                    <CircularProgressbar
                                        value={Math.trunc(d / total * 100)}
                                        text={`${Math.trunc(d / total * 100)}%`}
                                        strokeWidth={10}
                                        styles={{
                                            path: {
                                                stroke: '#1890ff',
                                            },
                                            trail: {
                                                stroke: '#e9e9e9',
                                            },
                                            text: {
                                                fill: '#424242',
                                            },
                                        }}
                                        />
                                </div>
                            </div>
                        </div>
                        <Table
                            columns={columns}
                            data={unprogressedList}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default SetupAccount;