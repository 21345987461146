import { CaretRightOutlined, CheckOutlined, CloseOutlined, EditOutlined, HeartOutlined, PauseOutlined, PlusOutlined, PlusSquareOutlined, PushpinOutlined, RedoOutlined, RightOutlined, SyncOutlined } from "@ant-design/icons";
import { Badge, Button, Checkbox, Collapse, DatePicker, Drawer, Input, message, Modal, Radio, Select, TimePicker } from "antd";
import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import repository from "../../repository";
import moment from "moment";
import { ChangeSubscriptionDto, ChangeTempAutoLikeCustomerDto, CreateTempAutoLikeCustomerDto, DetectedItemDto, PublishSubscriptionActionDto, SubscriptionActionDto, SubscriptionDto, SubscriptionMemoDto, TempAutoLikeCustomerDto, UrgentSubscriptionDto, ExtendTempAutoLikeCustomerDto } from '../../apiTypes';
import { MemoType } from "../subscribeManagement";
import confirm from "antd/lib/modal/confirm";
import getRandomOrderNumber from "../../helper/getRandomOrderNumber";
import getNormalizeIGPath from "../../helper/getNormalizeIGPath";
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const { Panel } = Collapse;

const DATE_FORMAT = 'YYYY-MM-DD';

enum MembershipStatusType {
    ACTIVE = 'active',
    PAUSED ='paused',
    CANCELED = 'canceled',
    FINISHED = 'finished',
};

enum OrderTaskType {
    like = 'like',
    follow = 'follow',
    like_reels = 'like_reels',
};

const like50 = [
    '한국인 인스타 자동좋아요 50회 (10개)',
    '한국인 인스타 자동좋아요 50회 (30개)',
    '한국인 인스타 자동좋아요 50회 (100개)'
];

const like100 = [
    '한국인 인스타 자동좋아요 100회 (10개)',
    '한국인 인스타 자동좋아요 100회 (30개)',
    '한국인 인스타 자동좋아요 100회 (100개)'
];

const subscriptions = [
    {
        label: '50회',
        options: like50.map(like50 => ({ label: like50, value: like50 })),
    },
    {
        label: '100회',
        options: like100.map(like100 => ({ label: like100, value: like100 })),
    },
];

interface MakeMembershipValue {
    // 주문번호
    orderNo: number;
    // 구매자 이름
    customerName: string;
    // 전화번호
    orderPhone: string;
    // 구매한 상품 이름
    productName: string;
    // 좋아요를 찍을 갯수
    likeCount: number;
    // 남은 횟수권
    leftCount: number;
    // 구매 가격
    price: number;
    // 구매일
    // startDate: string;
    // 맴버십 시작 알림을 보낼것인가
    needAligo: boolean;
    // targetIds 콤마로 구분
    targetIds: string;
};

function stringToMembershipStatusType (text: string) {
    if (text === 'finished') {
        return MembershipStatusType.FINISHED;
    }
    if (text === 'active') {
        return MembershipStatusType.ACTIVE;
    }
    if (text === 'canceled') {
        return MembershipStatusType.CANCELED;
    }
    if (text === 'paused') {
        return MembershipStatusType.PAUSED;
    }
};


function detectedObjectTypeToOrderTaskType (type: string) {
    if (type === 'like' || type === 'ig_post') {
        return 'like';
    } else if (type === 'like_reels' || type === 'ig_reels') {
        return 'like_reels';
    } else if (type === 'follow') {
        return 'follow';
    } else {
        return 'like';
    }
};

const RootContainer = styled.div`
display: flex;
flex-direction: column;
padding: 50px;
@media screen and (max-width: 500px) {
    padding: 50px 20px;
}
`;

const CardGrid = styled.div`
margin: 20px 0;
display: grid;
grid-template-columns: repeat(4, 1fr);
gap: 20px;
@media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
}
`;

const QuestSubscriptionCard = styled.div`
display: flex;
flex-direction: column;
/* height: 100px; */
border: 1px #fcdaca solid;
border-radius: 10px;
padding: 10px;
cursor: pointer;
box-shadow: 1px 1px 1px #fcdaca;
`;

const SubscriptionCard = styled.div`
display: flex;
flex-direction: column;
/* height: 100px; */
border: 1px lightgray solid;
border-radius: 10px;
padding: 15px 10px;
cursor: pointer;
box-shadow: 1px 1px 1px lightgray;
`;

const TopDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 20px;
@media screen and (max-width: 500px) {
    flex-direction: column;
}
`;

const LeftInfoBottomDottedTr = styled.tr`
border-bottom: 1px lightgray dotted;
`;

const LeftInfoFirstTd = styled.td`
width: 100px;
font-weight: bold;
@media screen and (max-width: 500px) {
    width: 100px;
}
`;

const LeftInfoSecondTd = styled.td`
width: 300px;
@media screen and (max-width: 500px) {
    width: 200px;
}
`;

const LeftInfoMembershipBottomDottedTr = styled.tr`
border-bottom: 1px lightgray dotted;
background-color: #aaffaa;
`;

const LeftInfoMembershipFirstTd = styled.td`
width: 100px;
font-weight: bold;
@media screen and (max-width: 500px) {
    width: 100px;
}
`;

const LeftInfoMembershipSecondTd = styled.td`
width: 300px;
@media screen and (max-width: 500px) {
    width: 200px;
}
`;

const ButtonContainer = styled.div`
@media screen and (max-width: 500px) {
    flex-direction: column;
    margin-top: 50px;
    display: flex;
}
`;

const ModalCardGrid = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 20px;
@media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
}
`;

function cardColor (status: MembershipStatusType) {
    if (status === 'active') {
        return '#ffffff';
    }
    if (status === 'canceled') {
        return '#ffbbbb';
    }
    if (status === 'paused') {
        return '#ffffbb';
    }
    if (status === 'finished') {
        return '#bbbbbb';
    }
    return '#ffffff';
};

const initialMakeMembershipValue = {
    orderNo: 0,
    customerName: '',
    orderPhone: '',
    productName: '',
    price: 0,
    needAligo: true,
    leftCount: 0,
    targetIds: '',
    likeCount: 0,
};

const initialManualActionValue = {
    subscriptionId: '',
    detectedDate: '',
    detectedItemId: '',
    targetUrl: '',
    needAligoSMS: true,
    isOnAdmin: false,
    taskType: OrderTaskType.like,
    postedAt: '',
};

function Membership() {
    const [ makeMembershipModal, setMakeMembershipModal ] = useState<boolean>(false);
    const [ makeMembershipValue, setMakeMembershipValue ] = useState<MakeMembershipValue>(initialMakeMembershipValue);
    const [ membershipDetailDrawer, setMembershipDetailDrawer ] = useState<boolean>(false);
    const [ aligoModal, setAligoModal ] = useState<boolean>(false);
    const [ aligoValue, setAligoValue ] = useState<{
            numLeft: number;
            name: string;
            productName: string;
            phoneNumber: string;
        }>({
            numLeft: 0,
            name: '',
            productName: '',
            phoneNumber: '',
        });
    const [ autoLikeList, setAutoLikeList ] = useState<Array<TempAutoLikeCustomerDto>>([]);
    const [ finishedAutoLikeList, setFinishedAutoLikeList ] = useState<Array<TempAutoLikeCustomerDto>>([]);
    const [ searchedAutoLikeSubscriptionList, setSearchedAutoLikeSubscriptionList ] = useState<Array<SubscriptionDto>>([]);
    const [ selectSubscriptionModal, setSelectSubscriptionModal ] = useState<boolean>(false);
    const [ selectedAutoLike, setSelectedAutoLike ] = useState<TempAutoLikeCustomerDto>();
    const [ searchingDate, setSearchingDate ] = useState<string>(moment().format(DATE_FORMAT));
    const [ questSubscriptionList, setQuestSubscriptionList ] = useState<Array<UrgentSubscriptionDto>>([]);
    const [ detailSubscriptionValue, setDetailSubscriptionValue ] = useState<SubscriptionDto>();
    const [ detectedList, setDetectedList ] = useState<Array<DetectedItemDto>>([]);
    const [ selectedDetectedItem, setSelectedDetectedItem ] = useState<DetectedItemDto>();
    const [ actionList, setActionList ] = useState<Array<SubscriptionActionDto>>([]);
    const [ manualActionModal, setManualActionModal ] = useState<boolean>(false);
    const [ manualActionValue, setManualActionValue ] = useState<PublishSubscriptionActionDto>(initialManualActionValue);
    const [ detectedActionModal, setDetectedActionModal ] = useState<boolean>(false);
    const [ detectedActionValue, setDetectedActionValue ] = useState<{
        needAligoSMS: boolean;
        isOnAdmin: boolean;
    }>({
        needAligoSMS: true,
        isOnAdmin: false,
    });
    const [ memos, setMemos ] = useState<Array<SubscriptionMemoDto>>();
    const [ memoModal, setMemoModal ] = useState<boolean>(false);
    const [ memoValue, setMemoValue] = useState<MemoType>({
        subscriptionID: '',
        text: '',
        pinned: false,
    });
    const [ editSubscriptionModal, setEditSubscriptionModal ] = useState<boolean>(false);
    const [ editSubscriptionValue, setEditSubscriptionValue ] = useState<{
        column: string;
        value: string;
    }>({
        column: '',
        value: ''
    });
    const [ editMembershipModal, setEditMembershipModal ] = useState<boolean>(false);
    const [ editMembershipValue, setEditMembershipValue ] = useState<{
        column: string;
        value: string;
    }>({
        column: '',
        value: ''
    });
    const [ plusTargetIdModal, setPlusTargetIdModal ] = useState<boolean>(false);
    const [ plusTargetIdValue, setPlusTargetIdValue ] = useState<string>('');
    const [ extendParentId, setExtendParentId ] = useState<string>('');
    const [ searchParams, setSearchParams ] = useSearchParams();

    const navigate = useNavigate();

    const getQuestSubscriptionList = useCallback( async () => {
        if (searchingDate === '') {
            message.error('올바른 searchingDate가 아닙니다.');
            return;
        }
        try {
            const list = await repository.getUrgentAutoLikeSubscriptions(searchingDate);
            console.log(list);
            if (list && list.length > 0){
                setQuestSubscriptionList(list);
                return;
            }
            setQuestSubscriptionList([]);
        } catch (error) {
            message.error(`${error}`);
        }
    }, [ searchingDate ]);

    const getSubscriptionDetail = useCallback( async (id: string) => {
        if (!id) {
            return;
        }
        console.log(id);
        try {
            const subscription = await repository.getSubscription(id);
            console.log(subscription);
            setDetailSubscriptionValue(subscription);
        } catch (error) {
            message.error(`${error}`);
        }
    }, []);

    const autoLikeCountUse = useCallback( async (autoLikeId: string, data: PublishSubscriptionActionDto) => {
        // console.log('autoLikeCountUse', autoLikeId, data);
        const actionData: PublishSubscriptionActionDto = {
            ...data,
            postedAt: data.postedAt === '' ? undefined : data.postedAt,
            targetUrl: getNormalizeIGPath(data.targetUrl) !== '' ? getNormalizeIGPath(data.targetUrl) : data.targetUrl,
        };
        try {
            const response = await repository.useAutoLike(autoLikeId, actionData);
            if (response) {
                console.log('autoLikeCountUse rs', response);
                message.success('자동좋아요 횟수가 차감되었습니다.');
            } else {
                message.error('자동좋아요 차감 실패');
            }
        } catch (err) {
            message.error(`${err} 게시글이 엑션으로 잘 들어갔는지 확인해야함. 안들어갓다면 수동으로`);
        };
    }, []);

    const getActionList = useCallback( async (id: string) => {
        if (searchingDate === '') {
            message.error('올바른 searchingDate가 아닙니다.');
            return;
        }
        try {
            const list = await repository.getSubscriptionAction(id, searchingDate);
            if (list && list.length > 0){
                setActionList(list);
                return;
            }
            setActionList([]);
        } catch (error) {
            message.error(`${error}`);
        }
    }, [ searchingDate ]);

    const getDetectedList = useCallback(async (id: string) => {
        if (searchingDate === '') {
            message.error('올바른 searchingDate가 아닙니다.');
            return;
        }
        try {
            const list = await repository.getDetectedItems(id, searchingDate);
            console.log(list);
            if (list && list.length > 0){
                setDetectedList(list.sort((a: DetectedItemDto, b: DetectedItemDto) => a.idx - b.idx));
                return;
            }
            setDetectedList([]);
        } catch (error) {
            message.error(`${error}`);
        }
    }, [ searchingDate ]);

    const getMemos = useCallback(async (orderPhone: string) => {
        try {
            const rs = await repository.getSubscriptionMemos(orderPhone);
            if (rs && rs.length > 0){
                const pin_sorted = rs.filter((d: any) => d.content.pinned)
                    .sort((a: any, b: any ) => {
                        return moment(a.createdAt) > moment(b.createdAt) ? -1 :
                            moment(a.createdAt) < moment(b.createdAt) ? 1: 0;
                    });
                const sorted = rs.filter((d: any) => !d.content.pinned)
                    .sort((a: any, b: any ) => {
                        return moment(a.createdAt) > moment(b.createdAt) ? -1 :
                            moment(a.createdAt) < moment(b.createdAt) ? 1: 0;
                    });
                setMemos(pin_sorted.concat(sorted));
                return;
            }
            setMemos(undefined);
        } catch (error) {
            message.error(`${error}`);
        }
    }, []);

    const updateMemos = useCallback(async (memoValue: MemoType, orderPhone: string) => {
        if (!orderPhone) {
            return;
        }
        try {
            const rs = await repository.postSubscriptionMemo({
                orderPhone: orderPhone,
                content: memoValue,
            });
            if (rs) {
                message.success('메모가 생성되었습니다.');
            } else {
                message.error('생성 실패');
            }
        } catch (error) {
            message.error(`${error}`);
        }
    }, []);

    const editSubscription = useCallback(async (id: string, data: ChangeSubscriptionDto) => {
        try {
            const rs = await repository.editSubscription(id, data);
            if (rs) {
                message.success('정보가 수정되었습니다.');
            } else {
                message.error('실패');
            }
        } catch(error) {
            message.error(`${error}`);
        }
    }, []);

    const editMembership = useCallback(async (autoLikeCustomerId: string, data: ChangeTempAutoLikeCustomerDto) => {
        try {
            const rs = await repository.updateAutoLike(autoLikeCustomerId, data);
            if (rs) {
                message.success('정보가 수정되었습니다.');
            } else {
                message.error('실패');
            }
        } catch(error) {
            message.error(`${error}`);
        }
    }, []);

    const deleteAction = (id: string, actionId: string) => {
        if (!id || !actionId) {
            return;
        }
        confirm({
            title: '엑션을 취소하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.deleteActionSubscription(id, actionId);
                        if (response) {
                            message.success('엑션이 삭제되었습니다.');
                        } else {
                            message.error('삭제 실패');
                        }
                        await getSubscriptionDetail(id);
                        await getActionList(id);
                        await getDetectedList(id);
                        await getQuestSubscriptionList();
                        await getAutoLikeList();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const suspendConfirm = () => {
        if (!detailSubscriptionValue || !detailSubscriptionValue.id) {
            return;
        }
        confirm({
            title: '구독을 일시 중단하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.suspendSubscription(detailSubscriptionValue.id);
                        if (response) {
                            message.success('구독이 일시 중단되었습니다.');
                            await updateMemos({
                                subscriptionID: detailSubscriptionValue.id,
                                text: `${moment().format(DATE_FORMAT)} 멤버십 구독 일시중단`,
                                pinned: false,
                            }, detailSubscriptionValue.orderPhone);
                        } else {
                            message.error('변경 실패');
                        }
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        await getQuestSubscriptionList();
                        await getAutoLikeList();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const resumeConfirm = () => {
        if (!detailSubscriptionValue || !detailSubscriptionValue.id) {
            return;
        }
        confirm({
            title: '구독을 다시 시작하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.resumeSubscription(detailSubscriptionValue.id);
                        // response를 왜안주노 이거
                        // if (response) {
                            message.success('구독이 다시 시작되었습니다.');
                            await updateMemos({
                                subscriptionID: detailSubscriptionValue.id,
                                text: `${moment().format(DATE_FORMAT)} 멤버십 구독 다시 시작`,
                                pinned: false,
                            }, detailSubscriptionValue.orderPhone);
                        // } else {
                        //     message.error('구독 다시 시작 실패');
                        // }
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        await getQuestSubscriptionList();
                        await getAutoLikeList();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const revokeConfirm = () => {
        if (!detailSubscriptionValue || !detailSubscriptionValue.id) {
            return;
        }
        confirm({
            title: '구독을 취소하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.revokeSubscription(detailSubscriptionValue.id);
                        if (response) {
                            message.success('구독이 취소되었습니다.');
                            await updateMemos({
                                subscriptionID: detailSubscriptionValue.id,
                                text: `${moment().format(DATE_FORMAT)} 멤버십 구독 취소`,
                                pinned: false,
                            }, detailSubscriptionValue.orderPhone);
                        } else {
                            message.error('취소 실패');
                        }
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        await getQuestSubscriptionList();
                        await getAutoLikeList();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const deleteMemo = (memoId: string) => {
        if (!memoId || !detailSubscriptionValue) {
            return;
        }
        confirm({
            title: '메모를 삭제하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.deleteSubscriptionMemo(memoId);
                        if (response) {
                            message.success('메모가 삭제되었습니다.');
                        } else {
                            message.error('삭제 실패');
                        }
                        await getMemos(detailSubscriptionValue.orderPhone);
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const postAligo = useCallback(async (productName: string, numLeft: number, phoneNumber: string) => {
        const payload = {
            productType: '인스타 자동좋아요',
            productName: productName,
            numLeft: numLeft,
            phoneNumber: phoneNumber
        };
        console.log('payload', payload);
        try {
            const rs = await repository.postMembershipDecrCount(payload);
            console.log(rs);
            message.success('차감 알림을 보냈습니다.');
        } catch (err) {
            message.error(`${err}`);
        }
    }, []);

    const getAutoLikeList = useCallback(async () => {
        try {
            const rs = await repository.getAutoLikeList();
            console.log('getAutoLikeList', rs);
            if (rs) {
                const finishFiltered = rs.filter(d => d.leftCount < 1);
                const sortedRs = rs.filter(d => d.leftCount > 0).sort((a, b) => {
                    return a.leftCount > b.leftCount ? 1
                    : a.leftCount < b.leftCount ? -1 : 0
                });
                setAutoLikeList(sortedRs);
                setFinishedAutoLikeList(finishFiltered);
                return;
            }
        } catch (err) {
            message.error(`${err}`);
        };
    }, []);

    const getAutoLike = useCallback(async (autoLikeCustomerId: string) => {
        try {
            const rs = await repository.getAutoLike(autoLikeCustomerId);
            if (rs) {
                setSelectedAutoLike(rs);
                return;
            }
        } catch (err) {
            message.error(`${err}`);
        }
    }, []);

    const searchAutoIdSubscriptions = useCallback(async (autoLikeCustomerId: string) => {
        try {
            const rs = await repository.searchAutoLikeCustomerIdSubscriptions(autoLikeCustomerId);
            console.log('searchAutoIdSubscriptionsRs', rs);
            if (rs && rs.length > 0) {
                setSearchedAutoLikeSubscriptionList(rs);
                if (rs && rs.length === 1) {
                    navigate(`/membership?id=${rs[0].id}`);
                    ( async () => {
                        await getSubscriptionDetail(rs[0].id);
                        await getActionList(rs[0].id);
                        await getDetectedList(rs[0].id);
                    })();
                    return;
                }
                if (rs.length > 1) {
                    setSelectSubscriptionModal(true);
                }
            }
        } catch (err) {
            message.error(`${err}`);
        }
    }, [ getSubscriptionDetail, getActionList, getDetectedList ]);

    const createAutoLike = useCallback(async (makeMembershipValue: MakeMembershipValue) => {
        const payload: CreateTempAutoLikeCustomerDto = {
            orderNo: makeMembershipValue.orderNo,
            customerName: makeMembershipValue.customerName,
            phoneNumber: makeMembershipValue.orderPhone,
            leftCount: makeMembershipValue.leftCount,
            productName: `한국인 인스타 자동좋아요 ${makeMembershipValue.likeCount}회 (${makeMembershipValue.leftCount}개)`,
            productType: '인스타 자동좋아요',
            settlePrice: makeMembershipValue.price,
            needAligo: makeMembershipValue.needAligo,
            targetIds: makeMembershipValue.targetIds.split(',').map(d => d.trim()),
            subscriptionName: makeMembershipValue.leftCount > 30
                ? `인스타 한국인 좋아요 ${makeMembershipValue.likeCount}회 구독 (365일) - 하루최대 30장`
                : `인스타 한국인 좋아요 ${makeMembershipValue.likeCount}회 구독 (180일) - 하루최대 30장`
        };
        console.log('payload', payload);
        try {
            const rs = await repository.createAutoLike(payload);
            console.log('createAutoLike rs', rs);
            if (rs && rs.id) {
                message.success('맴버십이 생성되었습니다.');
                return;
            }
        } catch (err) {
            message.error(`${err}`);
        };
    }, []);

    const extendCreateAutoLike = useCallback(async (makeMembershipValue: MakeMembershipValue, parentId: string) => {
        const payload: ExtendTempAutoLikeCustomerDto = {
            orderNo: makeMembershipValue.orderNo,
            customerName: makeMembershipValue.customerName,
            phoneNumber: makeMembershipValue.orderPhone,
            leftCount: makeMembershipValue.leftCount,
            productName: `한국인 인스타 자동좋아요 ${makeMembershipValue.likeCount}회 (${makeMembershipValue.leftCount}개)`,
            productType: '인스타 자동좋아요',
            settlePrice: makeMembershipValue.price,
            needAligo: makeMembershipValue.needAligo,
            targetIds: makeMembershipValue.targetIds.split(',').map(d => d.trim()),
            subscriptionName: makeMembershipValue.leftCount > 30
                ? `인스타 한국인 좋아요 ${makeMembershipValue.likeCount}회 구독 (365일) - 하루최대 30장`
                : `인스타 한국인 좋아요 ${makeMembershipValue.likeCount}회 구독 (180일) - 하루최대 30장`,
            parentId: parentId,
        };
        console.log('payload', payload);
        try {
            const rs = await repository.extendAutoLike(payload);
            console.log('extendAutoLike rs', rs);
            if (rs && rs.id) {
                message.success('맴버십이 생성되었습니다.');
                return;
            }
        } catch (err) {
            message.error(`${err}`);
        };
    }, []);

    const addTargetId = useCallback(async (tempAutoLikeCustomerId: string, plusTargetIdValue: string) => {
        if (!tempAutoLikeCustomerId ) {
            message.error('detailSubscriptionValue 값이 없습니다.');
            return;
        }
        if (!plusTargetIdValue || plusTargetIdValue === '' ) {
            message.error('plusTargetIdValue 값이 없습니다.');
            return;
        }
        const payload = {
            targetId: plusTargetIdValue.trim(),
        };
        try {
            const rs = await repository.addTargetIdAutoLike(tempAutoLikeCustomerId, payload);
            console.log('addTargetIdAutoLike rs', rs);
            if (rs) {
                message.success('아이디가 추가되었습니다.');
            }
        } catch (err) {
            console.log('addTargetId err', err);
            message.error(`${err}`);
        };
    }, []);

    useEffect(() => {
        if (detailSubscriptionValue && detailSubscriptionValue.orderPhone !== '') {
            ( async () => {
                await getMemos(detailSubscriptionValue.orderPhone);
            })();
        }
    }, [ detailSubscriptionValue ]);

    useEffect(() => {
        console.log('dd');
        if (searchingDate === '') {
            message.error('searchingDate를 선택해주세요');
            return;
        }
        ( async () => {
            await getQuestSubscriptionList();
            await getAutoLikeList();
            // await getAutoLikeSubscriptionList(searchingDate, 'starting');
        })();
    }, [ searchingDate ]);

    useEffect(() => {
        setMakeMembershipValue(prev => {
            return  {
                ...prev,
                orderPhone: makeMembershipValue.orderPhone?.replaceAll('-', ''),
            }
        });
    }, [ makeMembershipValue.orderPhone ]);

    useEffect(() => {
        const accountId = searchParams.get('id');
        if (accountId) {
            setMembershipDetailDrawer(true);
            getSubscriptionDetail(accountId);
            getActionList(accountId);
            getDetectedList(accountId);
        }
    }, [ searchParams ]);

    return (
        <RootContainer>
            <Modal
                open={manualActionModal}
                title="action 생성하기"
                onOk={() => {
                    if (!detailSubscriptionValue || !detailSubscriptionValue.id
                        || !manualActionValue || !detailSubscriptionValue.tempAutoLikeCustomerId
                        || manualActionValue.detectedDate === '') {
                        message.error('제대로 값을 입력해주세요');
                        return;
                    }
                    (async () => {
                        await autoLikeCountUse(detailSubscriptionValue.tempAutoLikeCustomerId, manualActionValue);
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        await getActionList(detailSubscriptionValue.id);
                        setManualActionValue(initialManualActionValue);
                        setManualActionModal(false);
                        await getQuestSubscriptionList();
                        await getAutoLikeList();
                    })();
                }}
                onCancel={() => {
                    setManualActionValue(initialManualActionValue);
                    setManualActionModal(false);
                }}
            >
                <div>action 을 생성할 구독 id</div>
                <div>{detailSubscriptionValue?.id}</div>
                <div>탐지된 게시물 날짜</div>
                <DatePicker
                    value={manualActionValue.detectedDate === ''
                        ? undefined : moment(manualActionValue.detectedDate)}
                    format={DATE_FORMAT}
                    onChange={data => setManualActionValue(prev => {
                        return {
                            ...prev,
                            detectedDate: data ? moment(data).format(DATE_FORMAT) : '',
                        }
                    })}
                />
                { detailSubscriptionValue?.taskType === 'like' &&
                    <div style={{ margin: '10px 0' }}>
                        <div>postedAt</div>
                        <DatePicker
                            value={manualActionValue.postedAt === ''
                                ? undefined : moment(manualActionValue.postedAt)}
                            format={DATE_FORMAT}
                            onChange={data => setManualActionValue(prev => {
                                console.log('date picker', data);
                                return {
                                    ...prev,
                                    postedAt: data ? moment(data).format(DATE_FORMAT) : '',
                                }
                            })}
                        />
                        <TimePicker
                            value={manualActionValue.postedAt === ''
                                ? undefined : moment(manualActionValue.postedAt)}
                            format={'HH:mm'}
                            onChange={data => setManualActionValue(prev => {
                                console.log('time picker', data);
                                return {
                                    ...prev,
                                    postedAt: data ? moment(data).format() : '',
                                }
                            })}
                        />
                        <div>좋아요 타입 선택</div>
                        <Radio.Group
                            options={[
                                { label: 'Like', value: 'like' },
                                { label: 'Reels_Like', value: 'like_reels' }
                            ]}
                            onChange={e => setManualActionValue(prev => {
                                return {
                                    ...prev,
                                    taskType: e.target.value,
                                }
                            })}
                            value={manualActionValue.taskType}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        <div>좋아요를 찍을 대상 url</div>
                        <Input
                            value={manualActionValue.targetUrl}
                            onChange={e => setManualActionValue(prev => {
                                return {
                                    ...prev,
                                    targetUrl: e.target.value,
                                }
                            })}
                            placeholder="targetUrl"
                        />
                    </div>
                }
                <div>알림톡 발송 여부(기본이 발송 끈다 해도 횟수차감은 알림)</div>
                <Checkbox
                    checked={manualActionValue.needAligoSMS}
                    defaultChecked={true}
                    id='needAligoSMS'
                    onChange={e => setManualActionValue(prev => {
                        return {
                            ...prev,
                            needAligoSMS: e.target.checked,
                        }
                    })}
                />
            </Modal>
            <Modal
                open={detectedActionModal}
                title="detected된 action 생성하기"
                onOk={() => {
                    (async () => {
                        if (!selectedDetectedItem || !detailSubscriptionValue || !detailSubscriptionValue.tempAutoLikeCustomerId) {
                            return;
                        }
                        await autoLikeCountUse(detailSubscriptionValue.tempAutoLikeCustomerId, {
                            subscriptionId: selectedDetectedItem.subscriptionId,
                            detectedDate: selectedDetectedItem.detectedDate,
                            detectedItemId: selectedDetectedItem.id,
                            targetUrl: selectedDetectedItem.targetUrl,
                            needAligoSMS: detectedActionValue.needAligoSMS,
                            isOnAdmin: false,
                            // @ts-ignore
                            taskType: detectedObjectTypeToOrderTaskType(selectedDetectedItem.objectType),
                            postedAt: selectedDetectedItem.postedAt,
                        });
                        await getSubscriptionDetail(selectedDetectedItem.subscriptionId);
                        await getActionList(selectedDetectedItem.subscriptionId);
                        setDetectedActionValue({
                            needAligoSMS: true,
                            isOnAdmin: false,
                        });
                        setSelectedDetectedItem(undefined);
                        setDetectedActionModal(false);
                        await getQuestSubscriptionList();
                        await getAutoLikeList();
                    })();
                }}
                onCancel={() => {
                    setDetectedActionValue({
                        needAligoSMS: true,
                        isOnAdmin: false,
                    });
                    setSelectedDetectedItem(undefined);
                    setDetectedActionModal(false);
                }}
            >
                <div>subscriptionId: {selectedDetectedItem?.subscriptionId},</div>
                <div>postedAt: {moment(selectedDetectedItem?.postedAt).format('YYYY.MM.DD HH:mm')}</div>
                <div>detectedDate: {selectedDetectedItem?.detectedDate}</div>
                <div>detectedItemId: {selectedDetectedItem?.id}</div>
                <div>targetUrl: {selectedDetectedItem?.targetUrl}</div>
                <br/>
                <div>알림톡 발송 여부(기본이 발송 끈다 해도 횟수차감은 알림)</div>
                <Checkbox
                    checked={detectedActionValue.needAligoSMS}
                    defaultChecked={true}
                    id='needAligoSMS'
                    onChange={e => setDetectedActionValue(prev => {
                        return {
                            ...prev,
                            needAligoSMS: e.target.checked,
                        }
                    })}
                />
            </Modal>
            <Modal
                open={memoModal}
                title='메모 작성하기'
                onOk={() => {
                    if (!detailSubscriptionValue) {
                        message.error('잘못된 핸드폰 번호입니다.');
                        return;
                    }
                    (async () => {
                        await updateMemos(memoValue, detailSubscriptionValue.orderPhone);
                        await getMemos(detailSubscriptionValue.orderPhone);
                    })();
                    setMemoModal(false);
                }}
                onCancel={() => {
                    setMemoModal(false);
                }}
            >
                <div>상단 고정?</div>
                <Checkbox
                    checked={memoValue.pinned}
                    defaultChecked={false}
                    onChange={e => setMemoValue(prev => {
                        return {
                            ...prev,
                            pinned: e.target.checked,
                        }
                    })}
                />
                <div>텍스트</div>
                <Input
                    value={memoValue.text}
                    onChange={e => setMemoValue(prev => {
                        return {
                            ...prev,
                            text: e.target.value,
                        }
                    })}
                />
            </Modal>
            <Modal
                open={editSubscriptionModal}
                title='구독정보 수정'
                onOk={() => {
                    if (!detailSubscriptionValue) {
                        return;
                    }
                    (async () => {
                        if (editSubscriptionValue.column === 'customerName') {
                            await editSubscription(detailSubscriptionValue.id, {
                                customerName: editSubscriptionValue.value
                            });
                        } else if (editSubscriptionValue.column === 'targetId') {
                            await editSubscription(detailSubscriptionValue.id, {
                                targetId: editSubscriptionValue.value
                            });
                        } else if (editSubscriptionValue.column === 'orderPhone') {
                            await editSubscription(detailSubscriptionValue.id, {
                                orderPhone: editSubscriptionValue.value
                            });
                        } else if (editSubscriptionValue.column === 'startDate') {
                            await editSubscription(detailSubscriptionValue.id, {
                                startDate: editSubscriptionValue.value
                            });
                        } else if (editSubscriptionValue.column === 'endDate') {
                            await editSubscription(detailSubscriptionValue.id, {
                                endDate: editSubscriptionValue.value
                            });
                        }
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        setEditSubscriptionModal(false);
                        await getQuestSubscriptionList();
                        await getAutoLikeList();
                    })();
                }}
                onCancel={() => {
                    setEditSubscriptionModal(false);
                }}
            >
                <div>{editSubscriptionValue.column}</div>
                { editSubscriptionValue.column.includes('Date') ?
                    <DatePicker
                        showTime
                        onChange={(e) => {
                            setEditSubscriptionValue(prev=> {
                                return {
                                    ...prev,
                                    value: e ? `${moment(e)}` : '',
                                }
                            })
                            console.log(editSubscriptionValue);
                        }}
                        value={editSubscriptionValue.value === ''
                            ? undefined : moment(editSubscriptionValue.value)}
                        format='YYYY-MM-DD HH:mm:ss'
                    />:
                    <Input
                        value={editSubscriptionValue.value}
                        onChange={e => setEditSubscriptionValue(prev => {
                            return {
                                ...prev,
                                value: e.target.value,
                            }
                        })}
                        // @ts-ignore
                        placeholder={detailSubscriptionValue?.[`${editSubscriptionValue.column}`]}
                    />
                }
            </Modal>
            <Modal
                open={editMembershipModal}
                title='맴버십 정보 수정'
                onOk={() => {
                    if (!selectedAutoLike) {
                        return;
                    }
                    (async () => {
                        if (editMembershipValue.column === 'orderNo') {
                            await editMembership(selectedAutoLike.id, {
                                orderNo: parseInt(editMembershipValue.value)
                            });
                        } else if (editMembershipValue.column === 'membershipStatus') {
                            await editMembership(selectedAutoLike.id, {
                                membershipStatus: stringToMembershipStatusType(editMembershipValue.value)
                            });
                        } else if (editMembershipValue.column === 'customerName') {
                            await editMembership(selectedAutoLike.id, {
                                customerName: editMembershipValue.value
                            });
                        } else if (editMembershipValue.column === 'phoneNumber') {
                            await editMembership(selectedAutoLike.id, {
                                phoneNumber: editMembershipValue.value
                            });
                        } else if (editMembershipValue.column === 'leftCount') {
                            await editMembership(selectedAutoLike.id, {
                                leftCount: parseInt(editMembershipValue.value)
                            });
                        } else if (editMembershipValue.column === 'productName') {
                            await editMembership(selectedAutoLike.id, {
                                productName: editMembershipValue.value
                            });
                        }
                        await getAutoLike(selectedAutoLike.id);
                        if (detailSubscriptionValue) {
                            await getSubscriptionDetail(detailSubscriptionValue.id);
                        }
                        await getQuestSubscriptionList();
                        await getAutoLikeList();
                    })();
                    setEditMembershipModal(false);
                }}
                onCancel={() => {
                    setEditMembershipModal(false);
                }}
            >
                <div>{editMembershipValue.column}</div>
                { editMembershipValue.column === 'productName'
                    ? <Select
                        value={editMembershipValue.value}
                        onChange={value => setEditMembershipValue(prev => {
                            return {
                                ...prev,
                                value: value,
                            }
                        })}
                        style={{ width: '100%' }}
                        options={subscriptions}
                    />
                    : editMembershipValue.column === 'membershipStatus'
                        ? <div>
                            <Radio.Group
                                options={Object.entries(MembershipStatusType).map(([ key, value ]) => {
                                    return {
                                        label: key,
                                        value: value,
                                    };
                                })}
                                onChange={e => {
                                    setEditMembershipValue(prev => {
                                        return {
                                            ...prev,
                                            value: e.target.value,
                                        };
                                    })
                                }}
                                value={editMembershipValue.value}
                                optionType='button'
                            />
                        </div>
                        : <Input
                            value={editMembershipValue.value}
                            onChange={e => setEditMembershipValue(prev => {
                                return {
                                    ...prev,
                                    value: e.target.value,
                                }
                            })}
                            // @ts-ignore
                            placeholder={selectedAutoLike?.[`${editMembershipValue.column}`]}
                        />
                }
            </Modal>
            <Modal
                open={aligoModal}
                title='알리고 보내기'
                onOk={() => {
                    if (aligoValue.name === '' || aligoValue.phoneNumber === '' || aligoValue.productName === '') {
                        message.error('내용을 모두 입력해주세요');
                        return;
                    }
                    (async () => {
                        await postAligo(aligoValue.productName, aligoValue.numLeft, aligoValue.phoneNumber);
                        await getAutoLikeList();
                    })();
                    setAligoModal(false);
                    setAligoValue({
                        numLeft: 0,
                        name: '',
                        productName: '',
                        phoneNumber: '',
                    });
                }}
                onCancel={() => {
                    setAligoModal(false);
                    setAligoValue({
                        numLeft: 0,
                        name: '',
                        productName: '',
                        phoneNumber: '',
                    });
                }}
            >
                <div>고객명</div>
                <Input
                    value={aligoValue.name}
                    onChange={e => setAligoValue(prev => {
                        return {
                            ...prev,
                            name: e.target.value,
                        }
                    })}
                    placeholder='name'
                />
                <div>고객 핸드폰 번호</div>
                <Input
                    value={aligoValue.phoneNumber}
                    onChange={e => setAligoValue(prev => {
                        return {
                            ...prev,
                            phoneNumber: e.target.value,
                        }
                    })}
                    placeholder="phoneNumber"
                    type='phone'
                />
                <div>맴버십 상품 이름</div>
                <Select
                    value={aligoValue.productName}
                    onChange={value => setAligoValue(prev => {
                        return {
                            ...prev,
                            productName: value,
                        }
                    })}
                    style={{ width: '100%' }}
                    options={subscriptions}
                />
                <div>남은 수량</div>
                <Input
                    value={aligoValue.numLeft}
                    onChange={e => setAligoValue(prev => {
                        return {
                            ...prev,
                            numLeft: parseInt(e.target.value),
                        }
                    })}
                    type='number'
                />
            </Modal>
            <Modal
                open={makeMembershipModal}
                title={extendParentId === '' ? '맴버십 생성하기' : '멤버십 연장 생성하기'}
                onOk={() => {
                    if ( makeMembershipValue.orderNo === 0
                        || makeMembershipValue.customerName === ''
                        || makeMembershipValue.orderPhone === ''
                        || makeMembershipValue.price < 0
                        || makeMembershipValue.productName === ''
                        || makeMembershipValue.targetIds === ''
                        ) {
                            message.error('정보를 모두 입력해주세요');
                            return;
                    } else {
                        (async () => {
                            try {
                                if (extendParentId === '') {
                                    await createAutoLike(makeMembershipValue);
                                } else {
                                    await extendCreateAutoLike(makeMembershipValue, extendParentId);
                                }
                                await getQuestSubscriptionList();
                                await getAutoLikeList();
                            } catch(err) {
                                message.error(`${err}`);
                            }
                        })();
                        setMakeMembershipValue(initialMakeMembershipValue);
                        setExtendParentId('');
                        setMakeMembershipModal(false);
                    }
                }}
                onCancel={() => {
                    setMakeMembershipValue(initialMakeMembershipValue);
                    setExtendParentId('');
                    setMakeMembershipModal(false);
                }}
            >
                <div style={{ fontSize: 15, fontWeight: 'bold' }}>
                    {extendParentId === '' ? '' : '계승할 멤버십 아이디: ' + extendParentId}
                </div>
                <div style={{ fontSize: 15, fontWeight: 'bold' }}>
                    {extendParentId === '' ? ''
                        : `${selectedAutoLike?.customerName}, ${selectedAutoLike?.phoneNumber}, ${selectedAutoLike?.subscriptions.map(d => d.targetId).join(',')}`}
                </div>
                <div>주문번호</div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Input
                        value={makeMembershipValue.orderNo}
                        type='number'
                        onChange={e => setMakeMembershipValue(prev => {
                            return {
                                ...prev,
                                orderNo: parseInt(e.target.value),
                            }
                        })}
                        placeholder="orderNo"
                    />
                    <Button
                        onClick={() => setMakeMembershipValue(prev => {
                            return {
                                ...prev,
                                orderNo: getRandomOrderNumber(),
                            }
                        })}
                    >랜덤</Button>
                </div>
                <div>구매자명</div>
                <Input
                    value={makeMembershipValue.customerName}
                    onChange={e => setMakeMembershipValue(prev => {
                        return {
                            ...prev,
                            customerName: e.target.value,
                        }
                    })}
                    placeholder='customerName'
                />
                <div>구매자 핸드폰 번호</div>
                <Input
                    value={makeMembershipValue.orderPhone}
                    onChange={e => setMakeMembershipValue(prev => {
                        return {
                            ...prev,
                            orderPhone: e.target.value,
                        }
                    })}
                    placeholder="phoneNumber"
                    type='phone'
                />
                <div>알림톡 발송 여부(기본이 발송)</div>
                <Checkbox
                    checked={makeMembershipValue.needAligo}
                    defaultChecked={true}
                    id='needAligoSMS'
                    onChange={e => setMakeMembershipValue(prev => {
                        return {
                            ...prev,
                            needAligo: e.target.checked,
                        }
                    })}
                />
                <div>{'인스타 ID, 여러 개 인경우 콤마(,) 로 구분'}</div>
                <Input
                    value={makeMembershipValue.targetIds}
                    onChange={e => setMakeMembershipValue(prev => {
                        return {
                            ...prev,
                            targetIds: e.target.value,
                        }
                    })}
                    placeholder='targetId'
                />
                <div>맴버십 상품 이름</div>
                <Select
                    value={makeMembershipValue.productName}
                    onChange={value => setMakeMembershipValue(prev => {
                        return {
                            ...prev,
                            productName: value,
                            leftCount: parseInt(value.split(' ')[value.split(' ').length - 1].replace(/[^0-9]/g, '')),
                            likeCount: parseInt(value.split(' ')[value.split(' ').length - 2].replace(/[^0-9]/g, '')),
                        };
                    })}
                    style={{ width: '100%' }}
                    options={subscriptions}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>찍을 좋아요 갯수</div>
                    <div style={{ flex: 1 }}>남은 자동좋아요 횟수</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Input
                        type='number'
                        value={makeMembershipValue.likeCount}
                        onChange={e => setMakeMembershipValue(prev => {
                            return {
                                ...prev,
                                likeCount: parseInt(e.target.value),
                            }
                        })}
                        placeholder='likeCount'
                    />
                    <Input
                        type='number'
                        value={makeMembershipValue.leftCount}
                        onChange={e => setMakeMembershipValue(prev => {
                            return {
                                ...prev,
                                leftCount: parseInt(e.target.value),
                            }
                        })}
                        placeholder='leftCount'
                    />
                </div>
                <div>결제된 가격</div>
                <Input
                    type='number'
                    value={makeMembershipValue.price}
                    onChange={e => setMakeMembershipValue(prev => {
                        return {
                            ...prev,
                            price: parseInt(e.target.value),
                        }
                    })}
                    placeholder='price'
                />
            </Modal>
            <Modal
                open={selectSubscriptionModal}
                title='구독 선택'
                onOk={() => {
                    setSelectSubscriptionModal(false);
                }}
                onCancel={() => {
                    setSelectSubscriptionModal(false);
                }}
            >
                <ModalCardGrid>
                    { searchedAutoLikeSubscriptionList && searchedAutoLikeSubscriptionList.map(item => {
                        return (
                            <SubscriptionCard
                                onClick={() =>  {
                                    setSelectSubscriptionModal(false);
                                    navigate(`/membership?id=${item.id}`);
                                }}
                            >
                                <div
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(`https://www.instagram.com/${item.targetId}`, '_blank', 'noreferrer noopener');
                                    }}
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',
                                        fontWeight: 'bold', marginBottom: 5, fontSize: 15, wordBreak: 'break-all' }}
                                >
                                    <div>{item.targetId}</div>
                                    <RightOutlined style={{ fontSize: 15 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                                    <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} />
                                    <div>{`${item.servingAmount}회`}</div>
                                </div>
                            </SubscriptionCard>
                        )
                    })}
                </ModalCardGrid>
            </Modal>
            <Modal
                open={plusTargetIdModal}
                title='멤버십 아이디 추가하기'
                onOk={() => {
                    if (!detailSubscriptionValue || !detailSubscriptionValue.tempAutoLikeCustomerId) {
                        message.error('detailSubscriptionValue가 없습니다.');
                        return;
                    }
                    (async () => {
                        await addTargetId(detailSubscriptionValue.tempAutoLikeCustomerId, plusTargetIdValue);
                        setPlusTargetIdModal(false);
                        await getQuestSubscriptionList();
                        await getAutoLikeList();
                    })();
                }}
                onCancel={() => {
                    setPlusTargetIdModal(false);
                }}
            >
                <div>추가할 아이디를 입력하세요</div>
                <Input
                    value={plusTargetIdValue}
                    onChange={e => setPlusTargetIdValue(e.target.value)}
                />
            </Modal>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                <DatePicker
                    defaultValue={moment()}
                    format={DATE_FORMAT}
                    onChange={date => {
                        setSearchingDate(date ? moment(date).format(DATE_FORMAT) : '' )
                    }}
                    value={searchingDate === ''
                        ? undefined : moment(searchingDate)}
                    style={{ width: 200, marginRight: 20 }}
                />
                {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
                    <Button
                        style={{ backgroundColor: 'lightgreen' }}
                        onClick={() => setMakeMembershipModal(true)}
                    >멤버십 생성</Button>
                    <Button
                        style={{ marginLeft: 20, backgroundColor: 'yellow' }}
                        onClick={() => setAligoModal(true)}
                    >횟수차감 알리고</Button>
                {/* </div> */}
                </div>
            </div>
            <Button
                onClick={() => {
                    ( async () => {
                        await getQuestSubscriptionList();
                        message.success('퀘스트 새로고침 성공');
                    })();
                }}
                style={{ marginTop: 15, marginBottom: 10 }}
            >퀘스트 새로고침<SyncOutlined style={{ fontSize: 14, marginLeft: 5 }}/>
            </Button>
            { questSubscriptionList &&
                questSubscriptionList.length > 0 && (
                <CardGrid>
                    { questSubscriptionList.map((item, i) => (
                        <Badge>
                            <QuestSubscriptionCard
                                onClick={() =>  {
                                    navigate(`/membership?id=${item.id}`);
                                }}
                                style={{ backgroundColor: '#fccddd' }}
                            >
                                <div style={{ fontWeight: 'bold', marginBottom: 5, fontSize: 15, wordBreak: 'break-all' }}>{item.targetId}</div>
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', margin: '3px 0', alignItems: 'center', justifyContent: 'flex-start' }}
                                >
                                    <div
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            window.open(`https://www.instagram.com/${item.targetId}`, '_blank', 'noreferrer noopener');
                                        }
                                    }>{item.customerName}</div>
                                    <RightOutlined style={{ fontSize: 10 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} />
                                        <div>{item.perDayLimit > 1 ? `${item.servingAmount}회 * ${item.perDayLimit}` : `${item.servingAmount}회` }</div>
                                    </div>
                                    { item.count && item.count > 1 && (
                                        <div style={{ backgroundColor: '#99ff99' }}>{item.count}개 구매</div>
                                    )}
                                </div>
                            </QuestSubscriptionCard>
                        </Badge>
                    ))}
                </CardGrid>
                )
            }
            <Button
                onClick={() => {
                    ( async () => {
                        await getAutoLikeList();
                        message.success('리스트 새로고침 성공');
                    })();
                }}
                style={{ marginTop: 15, marginBottom: 10 }}
            >멤버십 리스트 새로고침<SyncOutlined style={{ fontSize: 14, marginLeft: 5 }}/>
            </Button>
            { autoLikeList && autoLikeList.length > 0 && (
                <CardGrid>
                    { autoLikeList.map((item, i) => (
                        <Badge>
                            <SubscriptionCard
                                onClick={() =>  {
                                    setSelectedAutoLike(item);
                                    ( async () => {
                                        await searchAutoIdSubscriptions(item.id);
                                    })();
                                }}
                                style={{ backgroundColor: cardColor(item.membershipStatus)}}
                            >
                                <div style={{ marginBottom: 5 }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 15, wordBreak: 'break-all' }}>
                                        {`${item.subscriptions.map(d => d.targetId).join(', ')}`}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '3px 0' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <div
                                            onClick={(event) => {
                                                if (item.subscriptions.length === 1) {
                                                    event.stopPropagation();
                                                    window.open(`https://www.instagram.com/${item.subscriptions[0].targetId}`, '_blank', 'noreferrer noopener');
                                                } else {
                                                    return;
                                                }
                                            }
                                        }>{item.customerName}</div>
                                        <RightOutlined style={{ fontSize: 10 }} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div>{item.leftCount}</div>
                                        <div style={{ color: '#F77737', fontWeight: 'bold' }}>
                                            {`/${parseInt(item.productName.match(/\d+/g)![1])}`}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} />
                                        <div>{`${parseInt(item.productName.match(/\d+/g)![0])}회`}</div>
                                    </div>
                                    <div style={{ color: 'red', fontWeight: 'bold' }}>{moment(item.subscriptions[0].endDate).diff(moment(), 'days') < 10
                                        ? 'D-' + moment(item.subscriptions[0].endDate).diff(moment(), 'days') :''}
                                    </div>
                                </div>
                            </SubscriptionCard>
                        </Badge>
                    ))}
                </CardGrid>
            )}
            <Collapse ghost>
                <Panel header='횟수가 끝난 맴버십' key="1">
                { finishedAutoLikeList && finishedAutoLikeList.length > 0 && (
                    <CardGrid>
                    { finishedAutoLikeList.map((item, i) => (
                        <Badge>
                            <SubscriptionCard
                                onClick={() =>  {
                                    setSelectedAutoLike(item);
                                    ( async () => {
                                        await searchAutoIdSubscriptions(item.id);
                                    })();
                                }}
                                style={{ backgroundColor: cardColor(item.membershipStatus)}}
                            >
                                <div style={{ marginBottom: 5 }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 15, wordBreak: 'break-all' }}>
                                        {`${item.subscriptions.map(d => d.targetId).join(', ')}`}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '3px 0' }}>
                                    <div>{item.customerName}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div>{item.leftCount}</div>
                                        <div style={{ color: '#F77737', fontWeight: 'bold' }}>
                                            {`/${parseInt(item.productName.match(/\d+/g)![1])}`}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} />
                                        <div>{`${parseInt(item.productName.match(/\d+/g)![0])}회`}</div>
                                    </div>
                                </div>
                            </SubscriptionCard>
                        </Badge>
                    ))}
                </CardGrid>
                )}
                </Panel>
            </Collapse>
            <Drawer
                title={`${searchingDate} 맴버십 상세`}
                placement='right'
                closable={true}
                open={membershipDetailDrawer}
                onClose={() => {
                    setMembershipDetailDrawer(false);
                    setSearchParams(prev => {
                        prev.delete('id');
                        return prev;
                    });
                }}
                width='80vw'
            >
                <div>
                    { detailSubscriptionValue && selectedAutoLike && (
                        <TopDiv>
                            <table>
                                <LeftInfoMembershipBottomDottedTr>
                                    <LeftInfoMembershipFirstTd>주문번호</LeftInfoMembershipFirstTd>
                                    <LeftInfoMembershipSecondTd>{selectedAutoLike.orderNo}</LeftInfoMembershipSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditMembershipValue({
                                                    column: 'orderNo',
                                                    value: ''
                                                });
                                                setEditMembershipModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoMembershipBottomDottedTr>
                                <LeftInfoMembershipBottomDottedTr>
                                    <LeftInfoMembershipFirstTd>고객명</LeftInfoMembershipFirstTd>
                                    <LeftInfoMembershipSecondTd>{selectedAutoLike.customerName}</LeftInfoMembershipSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditMembershipValue({
                                                    column: 'customerName',
                                                    value: ''
                                                });
                                                setEditMembershipModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoMembershipBottomDottedTr>
                                <LeftInfoMembershipBottomDottedTr>
                                    <LeftInfoMembershipFirstTd>고객 번호</LeftInfoMembershipFirstTd>
                                    <LeftInfoMembershipSecondTd>{selectedAutoLike.phoneNumber}</LeftInfoMembershipSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditMembershipValue({
                                                    column: 'phoneNumber',
                                                    value: ''
                                                });
                                                setEditMembershipModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoMembershipBottomDottedTr>
                                <LeftInfoMembershipBottomDottedTr>
                                    <LeftInfoMembershipFirstTd>남은 횟수</LeftInfoMembershipFirstTd>
                                    <LeftInfoMembershipSecondTd>{selectedAutoLike.leftCount}</LeftInfoMembershipSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditMembershipValue({
                                                    column: 'leftCount',
                                                    value: ''
                                                });
                                                setEditMembershipModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoMembershipBottomDottedTr>
                                <LeftInfoMembershipBottomDottedTr>
                                    <LeftInfoMembershipFirstTd>상품 이름</LeftInfoMembershipFirstTd>
                                    <LeftInfoMembershipSecondTd>{selectedAutoLike.productName}</LeftInfoMembershipSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditMembershipValue({
                                                    column: 'productName',
                                                    value: ''
                                                });
                                                setEditMembershipModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoMembershipBottomDottedTr>
                                <LeftInfoMembershipBottomDottedTr>
                                    <LeftInfoMembershipFirstTd>멤버십 상태</LeftInfoMembershipFirstTd>
                                    <LeftInfoMembershipSecondTd>{selectedAutoLike.membershipStatus}</LeftInfoMembershipSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditMembershipValue({
                                                    column: 'membershipStatus',
                                                    value: ''
                                                });
                                                setEditMembershipModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoMembershipBottomDottedTr>
                                <LeftInfoMembershipBottomDottedTr>
                                    <LeftInfoMembershipFirstTd>멤버십 연장</LeftInfoMembershipFirstTd>
                                    <LeftInfoMembershipSecondTd>{selectedAutoLike.successorId}</LeftInfoMembershipSecondTd>
                                    { !selectedAutoLike.successorId &&
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    setExtendParentId(selectedAutoLike.id);
                                                    setMakeMembershipModal(true);
                                                }}
                                            >
                                                <PlusOutlined />
                                            </Button>
                                        </td>
                                    }
                                </LeftInfoMembershipBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.id}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>인스타그램 id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd
                                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        {detailSubscriptionValue.targetId}
                                        <Button
                                            onClick={() => {
                                                setPlusTargetIdValue('');
                                                setPlusTargetIdModal(true);
                                            }}
                                        >
                                            <PlusOutlined />
                                        </Button>
                                    </LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'targetId',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구매자명</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.customerName}</LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'customerName',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>휴대폰 번호</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.orderPhone}</LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'orderPhone',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구독 상품</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.name}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구독 진행 상황</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.statusType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>하루에 한도</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.perDayLimit}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>한번에 갯수</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.servingAmount}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>시작일</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{moment(detailSubscriptionValue.startDate).format('YYYY-MM-DD HH:mm:ss')}</LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'startDate',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>종료일</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{moment(detailSubscriptionValue.endDate).format('YYYY-MM-DD HH:mm:ss')}</LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'endDate',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                            </table>
                            <ButtonContainer style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <Button
                                        style={{ backgroundColor: '#ffffcc', marginRight: 10 }}
                                        onClick={suspendConfirm}
                                    >
                                        구독 중단 <PauseOutlined />
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#ccffcc', marginRight: 10 }}
                                        onClick={resumeConfirm}
                                    >
                                        구독 시작 <CaretRightOutlined />
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#ffcccc' }}
                                        onClick={revokeConfirm}
                                    >
                                        구독 취소 <CloseOutlined />
                                    </Button>
                                </div>
                                <Button
                                    style={{ marginTop: 10, backgroundColor: '#ccccff' }}
                                    onClick={() => {
                                        setManualActionModal(true);
                                        setManualActionValue({
                                            subscriptionId: detailSubscriptionValue.id,
                                            // 탐지된 게시물 날짜 ex: 2021-01-02
                                            // detectedDate: moment().format(DATE_FORMAT),
                                            detectedDate: '',
                                            detectedItemId: '',
                                            targetUrl: '',
                                            needAligoSMS: true,
                                            isOnAdmin: false,
                                            // @ts-ignore
                                            taskType: detailSubscriptionValue.taskType === 'follow' ? 'follow' : 'like',
                                        });
                                    }}
                                >
                                    action 발행 <PlusOutlined />
                                </Button>
                                <Button
                                    style={{ marginTop: 10 }}
                                    onClick={() => {
                                        (async () => {
                                            await getDetectedList(detailSubscriptionValue.id);
                                        })();
                                    }}
                                >
                                    디텍트 새로고침<RedoOutlined />
                                </Button>
                                <Button
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                    onClick={() => {
                                        setMemoModal(true);
                                        setMemoValue({
                                            subscriptionID: detailSubscriptionValue.id,
                                            text: '',
                                            pinned: false,
                                        });
                                    }}
                                >
                                    메모 생성
                                </Button>
                                { memos && memos.length > 0 &&
                                    memos.map((memo) => (
                                        <div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>
                                                    { // @ts-ignore
                                                        memo.content.pinned && (<PushpinOutlined style={{ marginRight: 5, color: 'red' }} />
                                                    )}
                                                    {`${moment(memo.createdAt).format(DATE_FORMAT)}`}
                                                </div>
                                                <div
                                                    onClick={() => deleteMemo(memo.id)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <CloseOutlined style={{ color: 'lightgray' }} />
                                                </div>
                                            </div>
                                            {/* <div>{memo.id}</div> */}
                                            {/* <div>{memo.orderPhone}</div> */}
                                            <div style={{ borderBottom: '1px lightgray solid' }}>{
                                            // @ts-ignore
                                            memo.content.text}</div>
                                        </div>
                                    ))
                                }
                            </ButtonContainer>
                        </TopDiv>
                    )}
                </div>
                { actionList &&
                    actionList.length > 0 && (
                    <Collapse onChange={onChange}
                        // style={{ backgroundColor: '#ccccff' }}
                        // defaultActiveKey={actionList.map(d => d.id)}
                    >
                    { actionList.map((item, i) => (
                        <Panel
                            header={`Action${i + 1} - ${item.targetUrl}`}
                            key={item.id}
                            extra={item.status === 'published' && <CloseOutlined
                                onClick={(event) => {
                                    // If you don't want click extra trigger collapse, you can prevent this:
                                    event.stopPropagation();
                                    deleteAction(item.subscriptionId, item.id);
                                }}
                            />}
                            style={{ backgroundColor: item.status === 'published' ? '#ccccff' : '#aaaaaa' }}
                        >
                            <table>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>플랫폼 종류</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.platformType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>인스타그램 Id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.targetId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>대상 주소</LeftInfoFirstTd>
                                    <LeftInfoSecondTd><a
                                        target='_blank'
                                        href={`https://www.instagram.com/p/${item.targetUrl}`}
                                        rel='noreferrer noopener'
                                        >{item.targetUrl}</a></LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.id}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구독 id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.subscriptionId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>detected id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item?.detectedItemId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>주문 id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.orderId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                            </table>
                        </Panel>
                        ))}
                    </Collapse>
                )
                }
                { detectedList &&
                    detectedList.length > 0 && (
                    <Collapse onChange={onChange}
                        // style={{ backgroundColor: '#aaffaa' }}
                    >
                    { detectedList.map((item, i) => (
                         // subscriptionActionId로 subscriptionId과 일치해서 맞는지 판단
                        <Panel
                            header={
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div>{`Detect${item.idx + 1}-${item.detectedDate}`}</div>
                                    { actionList.findIndex(d => d.detectedItemId === item.id) !== -1 &&
                                        <CheckOutlined style={{ color: 'red', fontSize: 20, marginLeft: 5 }}/>
                                    }
                                </div>
                            }
                            key={i}
                            extra={<PlusSquareOutlined
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setSelectedDetectedItem(item);
                                    setDetectedActionModal(true);
                            }} />}
                            style={{ backgroundColor: '#aaffaa' }}
                        >
                            <table>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>대상 종류</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.objectType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>플랫폼 종류</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.platformType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>인스타그램 Id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.targetId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>대상 주소</LeftInfoFirstTd>
                                    <LeftInfoSecondTd><a
                                        target='_blank'
                                        href={`https://www.instagram.com/${item.objectType === 'ig_post' ? 'p' : 'reel'}/${item.targetUrl}`}
                                        rel='noreferrer noopener'
                                        >{item.targetUrl}</a></LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.id}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구독 id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.subscriptionId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                            </table>
                        </Panel>
                    ))}
                    </Collapse>
                )
                }
            </Drawer>

        </RootContainer>
    );
};

export default Membership;