import { CaretRightOutlined, CheckCircleOutlined, CheckOutlined, CloseOutlined, EditOutlined, HeartOutlined, MailOutlined, PauseOutlined, PlusOutlined, PlusSquareOutlined, PushpinOutlined, RedoOutlined, RightOutlined, SyncOutlined, UserAddOutlined, ArrowRightOutlined } from '@ant-design/icons';
import styled from "@emotion/styled";
import { Badge, Button, Checkbox, Collapse, DatePicker, Drawer, Input, message, Modal, Radio, Select, TimePicker } from "antd";
import confirm from "antd/lib/modal/confirm";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DetectedItemDto, SubscriptionDto, SubscriptionActionDto, CreateSubscriptionDto, PublishSubscriptionActionDto, SubscriptionDtoStatusTypeEnum, UrgentSubscriptionDto, SubscriptionMemoDto, CreateSubscriptionMemoDto, ChangeSubscriptionDto, CreatePackageSubscriptionDto, SubscriptionDtoTaskTypeEnum, DashboardSubscriptionDto } from '../../apiTypes';
import repository from "../../repository";
import Flex from '../../components/flex';
import getRandomOrderNumber from '../../helper/getRandomOrderNumber';
import getNormalizeIGPath from '../../helper/getNormalizeIGPath';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
const { Panel } = Collapse;

const DATE_FORMAT = 'YYYY-MM-DD';

enum OrderTaskType {
    like = 'like',
    follow = 'follow',
    like_reels = 'like_reels',
};

const packages = [
    {
        name: '실속 인스타 시작 패키지',
        subscriptions: [
            '인스타 한국인 팔로워 500명 구독 (20일)',
            '인스타 한국인 좋아요 50회 구독 (20일)',
        ],
    },
    {
        name: '몽땅 인스타 시작 패키지',
        subscriptions: [
            '인스타 한국인 팔로워 1000명 구독 (20일)',
            '인스타 한국인 좋아요 50회 구독 (20일)',
        ],
    },
];

const subscriptions = [
    {
        label: '패키지',
        options: packages.map(({ name }) => ({ label: name, value: name })),
    },
];

interface MemoType {
    // 메모를 작성한 구독 ID
    subscriptionID: string;
    // 메모 내용
    text: string;
    pinned: boolean;
};

interface DetectedActionValue {
    needAligoSMS: boolean;
    isOnAdmin: boolean;
};

function cardColor (status: SubscriptionDtoStatusTypeEnum) {
    if (status === 'starting') {
        return '#ffffff';
    }
    if (status === 'canceled') {
        return '#ffbbbb';
    }
    if (status === 'pending') {
        return '#ffffbb';
    }
    if (status === 'finished') {
        return '#bbbbbb';
    }
    return '#ffffff';
};

function questCardColor (task: SubscriptionDtoTaskTypeEnum) {
    if (task === 'follow') {
        return '#c4e4fa';
    }
    return '#fccddd';
};

function cardStateIcon (status: SubscriptionDtoStatusTypeEnum) {
    if (status === 'starting') {
        return (<CaretRightOutlined />);
    }
    if (status === 'canceled') {
        return (<CloseOutlined />);
    }
    if (status === 'pending') {
        return (<PauseOutlined />);
    }
    if (status === 'finished') {
        return (<CheckCircleOutlined />);
    }
};

function dDayCount (day: number) {
    if (day < 0) {
        return `D${day}`;
    }
    if (day > 0) {
        return `D+${day}`;
    }
    if (day === 0) {
        return '마지막';
    }
};

function detectedObjectTypeToOrderTaskType (type: string) {
    if (type === 'like' || type === 'ig_post') {
        return 'like';
    } else if (type === 'like_reels' || type === 'ig_reels') {
        return 'like_reels';
    } else if (type === 'follow') {
        return 'follow';
    } else {
        return 'like';
    }
};

const RootContainer = styled.div`
display: flex;
flex-direction: column;
padding: 50px;
@media screen and (max-width: 500px) {
    padding: 50px 20px;
}
`;

const CardGrid = styled.div`
margin: 20px 0;
display: grid;
grid-template-columns: repeat(4, 1fr);
gap: 15px;
@media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
}
`;

const QuestSubscriptionCard = styled.div<{ backgroundColor?: string }>`
display: flex;
flex-direction: column;
border: 1px #fcdaca solid;
border-radius: 10px;
padding: 15px 10px;
cursor: pointer;
box-shadow: 1px 1px 1px #fcdaca;
background-color: ${(props) => props.backgroundColor};
`;

const SubscriptionCard = styled.div<{ backgroundColor?: string }>`
display: flex;
flex-direction: column;
border: 1px lightgray solid;
border-radius: 10px;
box-shadow: 1px 1px 1px lightgray;
background-color: ${(props) => props.backgroundColor};
`;

const SubscriptionCardTop = styled.div`
padding: 15px 10px 10px 10px;
cursor: pointer;
`;

const SubscriptionCardTopTargetId = styled.div`
margin-bottom: 5px;
font-size: 15px;
word-break: break-all;
font-weight: bold;
`;

const SubscriptionCardBottomSection = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
padding: 10px;
flex: 1;
cursor: pointer;
`;

const SubscriptionCardBottomSectionTop = styled.div`
display: flex;
flex-direction: row;
margin-bottom: 5px;
align-items: center;
justify-content: space-between;
`;

const FlexRowAlignItemsCenter = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`;

const FlexRowJustifyContentBetween = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

const TopDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 20px;
@media screen and (max-width: 500px) {
    flex-direction: column;
}
`;

const LeftInfoBottomDottedTr = styled.tr`
border-bottom: 1px lightgray dotted;
`;

const LeftInfoFirstTd = styled.td`
width: 100px;
font-weight: bold;
@media screen and (max-width: 500px) {
    width: 100px;
}
`;

const LeftInfoSecondTd = styled.td`
width: 300px;
@media screen and (max-width: 500px) {
    width: 200px;
}
`;

const ButtonContainer = styled.div`
@media screen and (max-width: 500px) {
    flex-direction: column;
    margin-top: 50px;
    display: flex;
}
`;

const initialMakeSubscriptionValue = {
    orderNo: 0,
    customerName: '',
    orderPhone: '',
    name: '',
    count: 1,
    targetId: '',
    price: 0,
    disableInitialAligoSMS: false,
};

const initialManualActionValue = {
    subscriptionId: '',
    detectedDate: '',
    detectedItemId: '',
    targetUrl: '',
    needAligoSMS: true,
    isOnAdmin: false,
    taskType: OrderTaskType.like,
    postedAt: '',
};

const initialDetectedActionValue = {
    needAligoSMS: true,
    isOnAdmin: false,
};

function PackageManagement() {
    const [ packageList, setPackageList ] = useState<Array<Array<DashboardSubscriptionDto>>>([]);
    const [ packageList2, setPackageList2 ] = useState<Array<DashboardSubscriptionDto>>([]);
    const [ questPackageList, setQuestPackageList ] = useState<Array<UrgentSubscriptionDto>>([]);
    const [ detectedList, setDetectedList ] = useState<Array<DetectedItemDto>>([]);
    const [ selectedDetectedItem, setSelectedDetectedItem ] = useState<DetectedItemDto>();
    const [ actionList, setActionList ] = useState<Array<SubscriptionActionDto>>([]);
    const [ makePackageModal, setMakePackageModal ] = useState<boolean>(false);
    const [ makePackageValue, setMakePackageValue ] = useState<CreateSubscriptionDto>(initialMakeSubscriptionValue);
    const [ detailSubscriptionValue, setDetailSubscriptionValue ] = useState<SubscriptionDto>();
    const [ detailSubscriptionDrawer, setDetailSubscriptionDrawer ] = useState<boolean>(false);
    const [ manualActionModal, setManualActionModal ] = useState<boolean>(false);
    const [ manualActionValue, setManualActionValue ] = useState<PublishSubscriptionActionDto>(initialManualActionValue);
    const [ detectedActionModal, setDetectedActionModal ] = useState<boolean>(false);
    const [ detectedActionValue, setDetectedActionValue ] = useState<DetectedActionValue>(initialDetectedActionValue);
    const [ memos, setMemos ] = useState<Array<SubscriptionMemoDto>>();
    const [ memoModal, setMemoModal ] = useState<boolean>(false);
    const [ memoValue, setMemoValue] = useState<MemoType>({
        subscriptionID: '',
        text: '',
        pinned: false,
    });
    const [ editSubscriptionModal, setEditSubscriptionModal ] = useState<boolean>(false);
    const [ editSubscriptionValue, setEditSubscriptionValue ] = useState<{
        column: string;
        value: string;
    }>({
        column: '',
        value: ''
    });
    const [ searchingDate, setSearchingDate ] = useState<string>(moment().format(DATE_FORMAT));
    const [ searchParams, setSearchParams ] = useSearchParams();

    const navigate = useNavigate();

    const getQuestSubscriptionList = useCallback( async () => {
        console.log('getQuestSubscriptionList');
        if (searchingDate === '') {
            message.error('올바른 searchingDate가 아닙니다.');
            return;
        }
        try {
            const list = await repository.getQuestPackage(searchingDate);
            // console.log(list);
            if (list && list.length > 0){
                setQuestPackageList(list.sort(d => d.taskType === 'follow' ? 1 : -1));
                return;
            }
            setQuestPackageList([]);
        } catch (error) {
            message.error(`getQuestPackage${error}`);
        }
    }, [ searchingDate ]);

    const getPackageList = useCallback( async ()  => {
        console.log('getPackageList');
        if (searchingDate === '') {
            message.error('올바른 searchingDate가 아닙니다.');
            return;
        }
        try {
            const list = await repository.getPackageList(searchingDate, 'starting');
            // console.log('list', list);
            if (list && list.length > 0){
                const coupledList = list.map((d, index) => {
                    const a = list.filter((item, i) => item.subscriptionOrderId === d.subscriptionOrderId);
                    if (a.length === 1) {
                        return a;
                    }
                    if (a.length === 2 && a[0].id === d.id) {
                        return a;
                    }
                    return [];
                }).filter(d => d && d.length > 0);
                const innerSortingList = coupledList.map(d => {
                    if (d && d[1] === undefined) {
                        return d;
                    }
                    // if (d && moment(d[0].endDate) > moment(d[1].endDate)) {
                    //     return [d[1], d[0]];
                    // }
                    if (d && d[1] && d[0].taskType === 'like' && d[1].taskType === 'follow') {
                        return [d[1], d[0]];
                    }
                    return d;
                });
                const sortedList = innerSortingList.sort((a: Array<DashboardSubscriptionDto>, b: Array<DashboardSubscriptionDto>) => {
                    return moment(a[0].endDate) > moment(b[0].endDate) ? 1
                    : moment(a[0].endDate) < moment(b[0].endDate) ? -1 : 0
                });
                setPackageList(sortedList);
            }
        } catch (error) {
            message.error(`getPackageList${error}`);
        }
    }, [ searchingDate ]);

    const getNotStartingPackageList = useCallback( async (status: 'canceled' | 'pending' | 'finished') => {
        if (searchingDate === '') {
            message.error('올바른 searchingDate가 아닙니다.');
            return;
        }
        try {
            const list = await repository.getPackageList(searchingDate, status);
            // console.log(list);
            if (list && list.length > 0){
                const something_list = list.sort((a: SubscriptionDto, b: SubscriptionDto) => {
                    return moment(a.endDate) < moment(b.endDate) ? 1
                        : moment(a.endDate) > moment(b.endDate) ? -1 : 0
                });
                setPackageList2(something_list);
            }
        } catch (error) {
            message.error(`getNotStartingPackageList ${error}`);
        }
    }, [ searchingDate ]);

    const getSubscriptionDetail = useCallback(async (id: string) => {
        if (!id) {
            return;
        }
        console.log(id);
        try {
            const subscription = await repository.getSubscription(id);
            console.log(subscription);
            setDetailSubscriptionValue(subscription);
        } catch (error) {
            message.error(`getSubscription${error}`);
        }
    }, []);

    const createPackage = useCallback (async (makePackageValue: CreateSubscriptionDto) => {
        try {
            const targetPackage = packages.find(p => p.name === makePackageValue.name);
            if (!targetPackage) {
                throw new Error('존재하지 않는 패키지입니다.');
            }
            const payload: CreatePackageSubscriptionDto = {
                orderNo: makePackageValue.orderNo,
                packageName: targetPackage.name,
                price: makePackageValue.price,
                phoneNumber: makePackageValue.orderPhone,
                subscriptions: targetPackage.subscriptions.map(subscription => ({
                    ...makePackageValue,
                    price: 0,
                    name: subscription,
                })),
            };
            const rs = await repository.createPackage(payload);
            if (rs) {
                message.success('패키지가 생성되었습니다.');
                return
            }
            console.log('패키지 생성 실패', rs);
            message.error(`패키지 생성 실패`);
        } catch (error) {
            console.log('createPackage error', error);
            message.error(`createPackage${error}`);
        }
    }, []);

    const makeAction = useCallback( async (id: string, data: PublishSubscriptionActionDto) => {
        const payload = {
            ...data,
            postedAt: data.postedAt === '' ? undefined : data.postedAt,
            targetUrl: getNormalizeIGPath(data.targetUrl) !== '' ? getNormalizeIGPath(data.targetUrl) : data.targetUrl,
        };
        console.log('payload', payload);
        try {
            const response = await repository.actionSubscription(id, payload);
            if (response) {
                message.success('엑션이 생성되었습니다.');
            } else {
                message.error('생성 실패');
            }
        } catch (error) {
            console.log('makeAction error', error);
            message.error(`makeAction${error}`);
        }
    }, []);

    const getActionList = useCallback( async (id: string) => {
        if (searchingDate === '') {
            message.error('올바른 searchingDate가 아닙니다.');
            return;
        }
        try {
            const list = await repository.getSubscriptionAction(id, searchingDate);
            if (list && list.length > 0){
                setActionList(list);
                return;
            }
            setActionList([]);
        } catch (error) {
            message.error(`getSubscriptionAction${error}`);
        }
    }, [ searchingDate ]);

    const getDetectedList = useCallback( async (id: string) => {
        if (searchingDate === '') {
            message.error('올바른 searchingDate가 아닙니다.');
            return;
        }
        try {
            const list = await repository.getDetectedItems(id, searchingDate);
            console.log(list);
            if (list && list.length > 0){
                setDetectedList(list.sort((a: DetectedItemDto, b: DetectedItemDto) => a.idx - b.idx));
                return;
            }
            setDetectedList([]);
        } catch (error) {
            message.error(`getDetectedList${error}`);
        }
    }, [ searchingDate ]);

    const getMemos = useCallback( async (orderPhone: string) => {
        try {
            const rs = await repository.getSubscriptionMemos(orderPhone);
            if (rs && rs.length > 0){
                const pin_sorted = rs.filter((d: any) => d.content.pinned)
                    .sort((a: any, b: any ) => {
                        return moment(a.createdAt) > moment(b.createdAt) ? -1 :
                            moment(a.createdAt) < moment(b.createdAt) ? 1: 0;
                    });
                const sorted = rs.filter((d: any) => !d.content.pinned)
                    .sort((a: any, b: any ) => {
                        return moment(a.createdAt) > moment(b.createdAt) ? -1 :
                            moment(a.createdAt) < moment(b.createdAt) ? 1: 0;
                    });
                setMemos(pin_sorted.concat(sorted));
                return;
            }
            setMemos(undefined);
        } catch (error) {
            message.error(`getMemos${error}`);
        }
    }, []);

    const updateMemos = useCallback(async (memoValue: MemoType, orderPhone: string) => {
        if (!orderPhone) {
            return;
        }
        try {
            const rs = await repository.postSubscriptionMemo({
                orderPhone: orderPhone,
                content: memoValue,
            });
            if (rs) {
                message.success('메모가 생성되었습니다.');
            } else {
                message.error('생성 실패');
            }
        } catch (error) {
            message.error(`updateMemos${error}`);
        }
    }, [ ]);

    const editSubscription = useCallback( async (id: string, data: ChangeSubscriptionDto) => {
        try {
            const rs = await repository.editSubscription(id, data);
            if (rs) {
                message.success('정보가 수정되었습니다.');
            } else {
                message.error('실패');
            }
        } catch(error) {
            message.error(`${error}`);
        }
    }, [ ]);

    const deleteAction = (id: string, actionId: string) => {
        if (!id || !actionId) {
            return;
        }
        confirm({
            title: '엑션을 취소하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.deleteActionSubscription(id, actionId);
                        if (response) {
                            message.success('엑션이 삭제되었습니다.');
                        } else {
                            message.error('삭제 실패');
                        }
                        await getSubscriptionDetail(id);
                        await getActionList(id);
                        await getDetectedList(id);
                        await getQuestSubscriptionList();
                        await getPackageList();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const suspendConfirm = () => {
        if (!detailSubscriptionValue || !detailSubscriptionValue.id) {
            return;
        }
        confirm({
            title: '구독을 일시 중단하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.suspendSubscription(detailSubscriptionValue.id);
                        if (response) {
                            message.success('구독이 일시 중단되었습니다.');
                            await updateMemos({
                                subscriptionID: detailSubscriptionValue.id,
                                text: `${moment().format(DATE_FORMAT)} 구독 일시중단`,
                                pinned: false,
                            }, detailSubscriptionValue.orderPhone);
                        } else {
                            message.error('변경 실패');
                        }
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        await getQuestSubscriptionList();
                        await getPackageList();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const revokeConfirm = () => {
        if (!detailSubscriptionValue || !detailSubscriptionValue.id) {
            return;
        }
        confirm({
            title: '구독을 취소하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.revokeSubscription(detailSubscriptionValue.id);
                        console.log('response', response);
                        if (response) {
                            message.success('구독이 취소되었습니다.');
                            await updateMemos({
                                subscriptionID: detailSubscriptionValue.id,
                                text: `${moment().format(DATE_FORMAT)} 구독 취소`,
                                pinned: false,
                            }, detailSubscriptionValue.orderPhone);
                        } else {
                            message.error('취소 실패');
                        }
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        await getQuestSubscriptionList();
                        await getPackageList();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const resumeConfirm = () => {
        if (!detailSubscriptionValue || !detailSubscriptionValue.id) {
            return;
        }
        confirm({
            title: '구독을 다시 시작하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.resumeSubscription(detailSubscriptionValue.id);
                        // response를 왜안주노 이거
                        // if (response) {
                            message.success('구독이 다시 시작되었습니다.');
                            await updateMemos({
                                subscriptionID: detailSubscriptionValue.id,
                                text: `${moment().format(DATE_FORMAT)} 구독 다시 시작`,
                                pinned: false,
                            }, detailSubscriptionValue.orderPhone);
                        // } else {
                        //     message.error('구독 다시 시작 실패');
                        // }
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        await getQuestSubscriptionList();
                        await getPackageList();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const deleteMemo = (memoId: string) => {
        if (!memoId || !detailSubscriptionValue) {
            return;
        }
        confirm({
            title: '메모를 삭제하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.deleteSubscriptionMemo(memoId);
                        if (response) {
                            message.success('메모가 삭제되었습니다.');
                        } else {
                            message.error('삭제 실패');
                        }
                        await getMemos(detailSubscriptionValue.orderPhone);
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const goodbyeConfirm = () => {
        if (!detailSubscriptionValue || !detailSubscriptionValue.id) {
            return;
        }
        confirm({
            title: '작별인사를 하시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.goodbyeSubscription(detailSubscriptionValue.id);
                        if (response) {
                            message.success('문자가 발송되었습니다');
                        } else {
                            message.error('요청 실패');
                        }
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        await getQuestSubscriptionList();
                        await getPackageList();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    useEffect(() => {
        if (detailSubscriptionValue && detailSubscriptionValue.orderPhone !== '') {
            ( async () => {
                await getMemos(detailSubscriptionValue.orderPhone);
            })();
        }
    }, [ detailSubscriptionValue ]);

    useEffect(() => {
        if (searchingDate === '') {
            message.error('searchingDate를 선택해주세요');
            return ;
        }
        (async () => {
            await getQuestSubscriptionList();
            await getPackageList();
        })();
    }, [ searchingDate ]);

    useEffect(() => {
        setMakePackageValue(prev => ({
            ...prev,
            orderPhone: prev.orderPhone.replaceAll('-', ''),
        }));
    }, [ makePackageValue.orderPhone ]);

    useEffect(() => {
        const accountId = searchParams.get('id');
        if (accountId) {
            setDetailSubscriptionDrawer(true);
            getSubscriptionDetail(accountId);
            getActionList(accountId);
            getDetectedList(accountId);
        }
    }, [ searchParams ]);

    return (
        <RootContainer>
            <Modal
                open={makePackageModal}
                title="패키지 생성하기"
                onOk={() => {
                    if ( makePackageValue.orderNo === 0
                        || makePackageValue.customerName === ''
                        || makePackageValue.count <= 0
                        || makePackageValue.name === ''
                        || makePackageValue.orderPhone === ''
                        || makePackageValue.targetId === ''
                        || makePackageValue.price < 0
                        ) {
                        message.error('정보를 모두 입력해주세요.');
                        return;
                    } else {
                        (async () => {
                            try {
                                await createPackage(makePackageValue);
                                await getQuestSubscriptionList();
                                await getPackageList();
                            } catch (error) {
                                message.error(`${error}`);
                            };
                        })();
                        setMakePackageModal(false);
                        setMakePackageValue(initialMakeSubscriptionValue);
                    }
                }}
                onCancel={() => {
                    setMakePackageValue(initialMakeSubscriptionValue);
                    setMakePackageModal(false);
                }}
            >
                <div>주문번호</div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Input
                        value={makePackageValue.orderNo}
                        type='number'
                        onChange={e => setMakePackageValue(prev => {
                            return {
                                ...prev,
                                orderNo: parseInt(e.target.value),
                            }
                        })}
                        placeholder="orderNo"
                    />
                    <Button
                        onClick={() => setMakePackageValue(prev => {
                            return {
                                ...prev,
                                orderNo: getRandomOrderNumber(),
                            }
                        })}
                    >랜덤</Button>
                </div>
                <div>구매자명</div>
                <Input
                    value={makePackageValue.customerName}
                    onChange={e => setMakePackageValue(prev => {
                        return {
                            ...prev,
                            customerName: e.target.value,
                        }
                    })}
                    placeholder="customerName"
                />
                <div>구매자 핸드폰 번호</div>
                <Input
                    value={makePackageValue.orderPhone}
                    onChange={e => setMakePackageValue(prev => {
                        return {
                            ...prev,
                            orderPhone: e.target.value,
                        }
                    })}
                    placeholder="orderPhone"
                />
                <div>구독 상품 이름</div>
                <Select
                    style={{
                        width: '100%',
                    }}
                    value={makePackageValue.name}
                    onChange={value => setMakePackageValue(prev => {
                        return {
                            ...prev,
                            name: value,
                            price: value === '실속 인스타 시작 패키지' ? 58500 : 108000,
                        }
                    })}
                    options={subscriptions}
                />
                <div>구매 수량</div>
                <Input
                    value={makePackageValue.count}
                    type='number'
                    onChange={e => setMakePackageValue(prev => {
                        return {
                            ...prev,
                            count: parseInt(e.target.value),
                        }
                    })}
                    placeholder="count"
                />
                <div>작업 수행 대상 id</div>
                <Input
                    value={makePackageValue.targetId}
                    onChange={e => setMakePackageValue(prev => {
                        return {
                            ...prev,
                            targetId: e.target.value,
                        }
                    })}
                    placeholder="targetId"
                />
                <div>결제된 가격</div>
                <Input
                    value={makePackageValue.price}
                    type='number'
                    onChange={e => setMakePackageValue(prev => {
                        return {
                            ...prev,
                            price: parseInt(e.target.value),
                        }
                    })}
                    placeholder="price"
                />
                <div>구독 시작 알림톡 발송을 끌것인가?</div>
                <Checkbox
                    checked={makePackageValue.disableInitialAligoSMS}
                    defaultChecked={false}
                    id='disableInitialAligoSMS'
                    onChange={e => setMakePackageValue(prev => {
                        return {
                            ...prev,
                            disableInitialAligoSMS: e.target.checked,
                        }
                    })}
                />
            </Modal>
            <Modal
                open={manualActionModal}
                title="action 생성하기"
                onOk={() => {
                    if (!detailSubscriptionValue || !detailSubscriptionValue.id
                        || !manualActionValue || manualActionValue.detectedDate === '') {
                        message.error('제대로 값을 입력해주세요');
                        return;
                    }
                    (async () => {
                        await makeAction(detailSubscriptionValue.id, manualActionValue);
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        await getActionList(detailSubscriptionValue.id);
                        setManualActionValue(initialManualActionValue);
                        setManualActionModal(false);
                        await getQuestSubscriptionList();
                        await getPackageList();
                    })();
                }}
                onCancel={() => {
                    setManualActionValue(initialManualActionValue);
                    setManualActionModal(false);
                }}
            >
                <div>action 을 생성할 구독 id</div>
                <div>{detailSubscriptionValue?.id}</div>
                <div>탐지된 게시물 날짜</div>
                <DatePicker
                    value={manualActionValue.detectedDate === ''
                        ? undefined : moment(manualActionValue.detectedDate)}
                    format={DATE_FORMAT}
                    onChange={data => setManualActionValue(prev => {
                        return {
                            ...prev,
                            detectedDate: data ? moment(data).format(DATE_FORMAT) : '',
                        }
                    })}
                />
                { detailSubscriptionValue?.taskType === 'like' &&
                    <div style={{ margin: '10px 0' }}>
                        <div>postedAt</div>
                        <DatePicker
                            value={manualActionValue.postedAt === ''
                                ? undefined : moment(manualActionValue.postedAt)}
                            format={DATE_FORMAT}
                            onChange={data => setManualActionValue(prev => {
                                console.log('date picker', data);
                                return {
                                    ...prev,
                                    postedAt: data ? moment(data).format(DATE_FORMAT) : '',
                                }
                            })}
                        />
                        <TimePicker
                            value={manualActionValue.postedAt === ''
                                ? undefined : moment(manualActionValue.postedAt)}
                            format={'HH:mm'}
                            onChange={data => setManualActionValue(prev => {
                                console.log('time picker', data);
                                return {
                                    ...prev,
                                    postedAt: data ? moment(data).format() : '',
                                }
                            })}
                        />
                        <div>좋아요 타입 선택</div>
                        <Radio.Group
                            options={[
                                { label: 'Like', value: 'like' },
                                { label: 'Reels_Like', value: 'like_reels' }
                            ]}
                            onChange={e => setManualActionValue(prev => {
                                return {
                                    ...prev,
                                    taskType: e.target.value,
                                }
                            })}
                            value={manualActionValue.taskType}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        <div>좋아요를 찍을 대상 url</div>
                        <Input
                            value={manualActionValue.targetUrl}
                            onChange={e => setManualActionValue(prev => {
                                return {
                                    ...prev,
                                    targetUrl: e.target.value,
                                }
                            })}
                            placeholder="targetUrl"
                        />
                    </div>
                }
                <div>알림톡 발송 여부(기본이 발송)</div>
                <Checkbox
                    checked={manualActionValue.needAligoSMS}
                    defaultChecked={true}
                    id='needAligoSMS'
                    onChange={e => setManualActionValue(prev => {
                        return {
                            ...prev,
                            needAligoSMS: e.target.checked,
                        }
                    })}
                />
                <div>서비스 여부 (서비스의 경우 하루 총량 제한을 안둔다)</div>
                <Checkbox
                    checked={manualActionValue.isOnAdmin}
                    defaultChecked={false}
                    id='isOnAdmin'
                    onChange={e => setManualActionValue(prev => {
                        return {
                            ...prev,
                            isOnAdmin: e.target.checked,
                        }
                    })}
                />
            </Modal>
            <Modal
                open={detectedActionModal}
                title="detected된 action 생성하기"
                onOk={() => {
                    (async () => {
                        if (!selectedDetectedItem) {
                            return;
                        }
                        await makeAction(selectedDetectedItem.subscriptionId, {
                            subscriptionId: selectedDetectedItem.subscriptionId,
                            detectedDate: selectedDetectedItem.detectedDate,
                            detectedItemId: selectedDetectedItem.id,
                            targetUrl: selectedDetectedItem.targetUrl,
                            needAligoSMS: detectedActionValue.needAligoSMS,
                            isOnAdmin: detectedActionValue.isOnAdmin,
                            // @ts-ignore
                            taskType: detectedObjectTypeToOrderTaskType(selectedDetectedItem.objectType),
                            postedAt: selectedDetectedItem.postedAt,
                        });
                        await getSubscriptionDetail(selectedDetectedItem.subscriptionId);
                        await getActionList(selectedDetectedItem.subscriptionId);
                        setDetectedActionValue(initialDetectedActionValue);
                        setSelectedDetectedItem(undefined);
                        setDetectedActionModal(false);
                        await getQuestSubscriptionList();
                        await getPackageList();
                    })();
                }}
                onCancel={() => {
                    setDetectedActionValue(initialDetectedActionValue);
                    setSelectedDetectedItem(undefined);
                    setDetectedActionModal(false);
                }}
            >
                <div>subscriptionId: {selectedDetectedItem?.subscriptionId},</div>
                <div>postedAt: {moment(selectedDetectedItem?.postedAt).format('YYYY.MM.DD HH:mm')}</div>
                <div>detectedDate: {selectedDetectedItem?.detectedDate}</div>
                <div>detectedItemId: {selectedDetectedItem?.id}</div>
                <div>targetUrl: {selectedDetectedItem?.targetUrl}</div>
                <br/>
                <div>알림톡 발송 여부(기본이 발송)</div>
                <Checkbox
                    checked={detectedActionValue.needAligoSMS}
                    defaultChecked={true}
                    id='needAligoSMS'
                    onChange={e => setDetectedActionValue(prev => {
                        return {
                            ...prev,
                            needAligoSMS: e.target.checked,
                        }
                    })}
                />
                <div>서비스 여부 (서비스의 경우 하루 총량 제한을 안둔다)</div>
                <Checkbox
                    checked={detectedActionValue.isOnAdmin}
                    defaultChecked={false}
                    id='isOnAdmin'
                    onChange={e => setDetectedActionValue(prev => {
                        return {
                            ...prev,
                            isOnAdmin: e.target.checked,
                        }
                    })}
                />
            </Modal>
            <Modal
                open={memoModal}
                title='메모 작성하기'
                onOk={() => {
                    if (!detailSubscriptionValue) {
                        message.error('잘못된 핸드폰 번호입니다.');
                        return;
                    }
                    (async () => {
                        await updateMemos(memoValue, detailSubscriptionValue.orderPhone);
                        await getMemos(detailSubscriptionValue.orderPhone);
                    })();
                    setMemoModal(false);
                }}
                onCancel={() => {
                    setMemoModal(false);
                }}
            >
                <div>상단 고정?</div>
                <Checkbox
                    checked={memoValue.pinned}
                    defaultChecked={false}
                    onChange={e => setMemoValue(prev => {
                        return {
                            ...prev,
                            pinned: e.target.checked,
                        }
                    })}
                />
                <div>텍스트</div>
                <Input
                    value={memoValue.text}
                    onChange={e => setMemoValue(prev => {
                        return {
                            ...prev,
                            text: e.target.value,
                        }
                    })}
                />

            </Modal>
            <Modal
                open={editSubscriptionModal}
                title='구독정보 수정'
                onOk={() => {
                    if (!detailSubscriptionValue) {
                        return;
                    }
                    (async () => {
                        if (editSubscriptionValue.column === 'customerName') {
                            await editSubscription(detailSubscriptionValue.id, {
                                customerName: editSubscriptionValue.value
                            });
                        } else if (editSubscriptionValue.column === 'targetId') {
                            await editSubscription(detailSubscriptionValue.id, {
                                targetId: editSubscriptionValue.value
                            });
                        } else if (editSubscriptionValue.column === 'orderPhone') {
                            await editSubscription(detailSubscriptionValue.id, {
                                orderPhone: editSubscriptionValue.value
                            });
                        } else if (editSubscriptionValue.column === 'startDate') {
                            await editSubscription(detailSubscriptionValue.id, {
                                startDate: editSubscriptionValue.value
                            });
                        } else if (editSubscriptionValue.column === 'endDate') {
                            await editSubscription(detailSubscriptionValue.id, {
                                endDate: editSubscriptionValue.value
                            });
                        }
                        await getSubscriptionDetail(detailSubscriptionValue.id);
                        setEditSubscriptionModal(false);
                        await getQuestSubscriptionList();
                        await getPackageList();
                    })();
                }}
                onCancel={() => {
                    setEditSubscriptionModal(false);
                }}
            >
                <div>{editSubscriptionValue.column}</div>
                { editSubscriptionValue.column.includes('Date') ?
                    <DatePicker
                        showTime
                        onChange={(e) => {
                            setEditSubscriptionValue(prev=> {
                                return {
                                    ...prev,
                                    value: e ? `${moment(e)}` : '',
                                }
                            })
                            console.log(editSubscriptionValue);
                        }}
                        value={editSubscriptionValue.value === ''
                            ? undefined : moment(editSubscriptionValue.value)}
                        format='YYYY-MM-DD HH:mm:ss'
                    />:
                    <Input
                        value={editSubscriptionValue.value}
                        onChange={e => setEditSubscriptionValue(prev => {
                            return {
                                ...prev,
                                value: e.target.value,
                            }
                        })}
                        // @ts-ignore
                        placeholder={detailSubscriptionValue?.[`${editSubscriptionValue.column}`]}
                    />
                }
            </Modal>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                    <DatePicker
                        defaultValue={moment()}
                        format={DATE_FORMAT}
                        onChange={date => {
                            setSearchingDate(date ? moment(date).format(DATE_FORMAT) : '' )
                        }}
                        value={searchingDate === ''
                            ? undefined : moment(searchingDate)}
                        style={{ width: 200, marginRight: 20 }}
                    />
                    <Button
                        onClick={() => setMakePackageModal(true)}
                    >패키지 생성</Button>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: 10, fontWeight: 'bold' }}>
                <UserAddOutlined style={{ color: '#0095f6', marginRight: 10 }} />
                { questPackageList && questPackageList.length > 0
                    ? <div style={{ color: '#0095f6' }}>{questPackageList.reduce((acc, curr) => {
                            if (curr.taskType === 'follow') {
                                return acc += curr.servingAmount;
                            }
                            return acc;
                        }, 0)}</div>
                    : <div style={{ color: '#0095f6' }}>0</div>
                }
                { packageList && packageList.length > 0
                    ? <div style={{ marginLeft: 5 }}>{` / ${packageList.reduce((acc, curr) => {
                        if (curr[0].taskType === 'follow') {
                            return acc += curr[0].servingAmount;
                        }
                        if (curr[1] && curr[1].taskType === 'follow') {
                            return acc += curr[1].servingAmount;
                        }
                        return acc;
                    }, 0)}`}</div>
                    : <></>
                }
            </div>
            <Button
                onClick={() => {
                    ( async () => {
                        await getQuestSubscriptionList();
                        message.success('퀘스트 새로고침 성공');
                    })();
                }}
                style={{ marginTop: 15, marginBottom: 10 }}
            >퀘스트 새로고침<SyncOutlined style={{ fontSize: 14, marginLeft: 5 }}/></Button>
            { questPackageList &&
                questPackageList.length > 0 && (
                <CardGrid>
                    { questPackageList.map((item, i) => (
                        <Badge>
                            <QuestSubscriptionCard
                                backgroundColor={questCardColor(item.taskType)}
                                onClick={() =>  {
                                    ( async () => {
                                        navigate(`/package-management?id=${item.id}`);
                                    })();
                                }}
                            >
                                <SubscriptionCardTopTargetId>{item.targetId}</SubscriptionCardTopTargetId>
                                <FlexRowJustifyContentBetween>
                                    <div
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            window.open(`https://www.instagram.com/${item.targetId}`, '_blank', 'noreferrer noopener');
                                        }
                                    }>{item.customerName}<RightOutlined style={{ fontSize: 10 }} /></div>
                                    <FlexRowAlignItemsCenter>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            { item.taskType === 'like' ? <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} />
                                                : <UserAddOutlined style={{ color: '#0095f6', marginRight: 3 }} />
                                            }
                                            <div>{item.perDayLimit > 1 ? `${item.servingAmount} * ${item.perDayLimit}` : `${item.servingAmount}` }</div>
                                            { item.count && item.count > 1 && (
                                                <div style={{ backgroundColor: '#99ff99' }}>{item.count}개 구매</div>
                                            )}
                                        </div>
                                        <div style={{ width: 1, height: 15, backgroundColor: 'black', margin: '0 5px' }}></div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ textAlign: 'right' }}>{dDayCount(moment(moment()).diff(item.endDate, 'days'))}</div>
                                            {/* {cardStateIcon(item.statusType)} */}
                                        </div>
                                    </FlexRowAlignItemsCenter>
                                </FlexRowJustifyContentBetween>
                            </QuestSubscriptionCard>
                        </Badge>
                    ))}
                </CardGrid>
                )
            }
            <Button
                onClick={() => {
                    ( async () => {
                        await getPackageList();
                        message.success('리스트 새로고침 성공');
                    })();
                }}
            >패키지 리스트 새로고침<SyncOutlined
                style={{ fontSize: 14, marginLeft: 5 }}
            /></Button>
            { packageList &&
                packageList.length > 0 && (
                <CardGrid>
                    { packageList.map((item, i) => (
                        <Badge>
                            <SubscriptionCard
                                backgroundColor={cardColor(item[0].statusType)}
                            >
                                <SubscriptionCardTop
                                    onClick={() => {
                                        window.open(`https://www.instagram.com/${item[0].targetId}`,
                                            '_blank','noreferrer noopener'
                                        );
                                    }}
                                >
                                    <SubscriptionCardTopTargetId>{item[0].targetId}</SubscriptionCardTopTargetId>
                                    <div>{item[0].customerName}</div>
                                </SubscriptionCardTop>
                                <div style={{ height: 1, backgroundColor: 'lightgray', margin: '0 10px' }}></div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <SubscriptionCardBottomSection
                                        onClick={() =>  {
                                            ( async () => {
                                                navigate(`/package-management?id=${item[0].id}`);
                                                console.log('item', item);
                                            })();
                                        }}
                                    >
                                        <SubscriptionCardBottomSectionTop>
                                            <FlexRowAlignItemsCenter>
                                                { item[0].taskType === 'like' ? <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} />
                                                    : <UserAddOutlined style={{ color: '#0095f6', marginRight: 5 }} />
                                                }
                                                <div>{item[0].perDayLimit > 1 ? `${item[0].servingAmount} * ${item[0].perDayLimit}` : `${item[0].servingAmount}` }</div>
                                            </FlexRowAlignItemsCenter>
                                            {item[0].activeActionCnt > 0 && (
                                                <FlexRowAlignItemsCenter>
                                                    <div style={{ color: item[0].activeActionCnt === item[0].perDayLimit
                                                        ?'#F77737' : item[0].activeActionCnt < item[0].perDayLimit
                                                        ? 'black' : '#ff1111', fontWeight: 'bold' }}
                                                    >
                                                        {`${item[0].activeActionCnt}`}
                                                    </div>
                                                    <div style={{ color: '#F77737', fontWeight: 'bold' }}>
                                                        {`/${item[0].perDayLimit}`}
                                                    </div>
                                                </FlexRowAlignItemsCenter>
                                            )}
                                        </SubscriptionCardBottomSectionTop>
                                        <div>
                                            <FlexRowAlignItemsCenter>
                                                <div>{dDayCount(moment(moment()).diff(item[0].endDate, 'days'))}</div>
                                                {cardStateIcon(item[0].statusType)}
                                            </FlexRowAlignItemsCenter>
                                            { item[0].count && item[0].count > 1 && (
                                                <div style={{ backgroundColor: '#99ff99' }}>{item[0].count}개 구매</div>
                                            )}
                                        </div>
                                    </SubscriptionCardBottomSection>
                                    { item[1] ? (
                                        <>
                                        <div style={{ width: 1, backgroundColor: 'lightgray', marginBottom: 5}}></div>
                                        <SubscriptionCardBottomSection
                                            onClick={() =>  {
                                                ( async () => {
                                                    navigate(`/package-management?id=${item[1].id}`);
                                                    console.log('item', item);
                                                })();
                                            }}
                                        >
                                            <SubscriptionCardBottomSectionTop>
                                                <FlexRowAlignItemsCenter>
                                                    { item[1].taskType === 'like' ? <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} />
                                                        : <UserAddOutlined style={{ color: '#0095f6', marginRight: 5 }} />
                                                    }
                                                    <div>{item[1].perDayLimit > 1 ? `${item[1].servingAmount} * ${item[1].perDayLimit}` : `${item[1].servingAmount}` }</div>
                                                </FlexRowAlignItemsCenter>
                                                {item[1].activeActionCnt > 0 && (
                                                    <FlexRowAlignItemsCenter>
                                                        <div style={{ color: item[1].activeActionCnt === item[1].perDayLimit
                                                            ?'#F77737' : item[1].activeActionCnt < item[1].perDayLimit
                                                            ? 'black' : '#ff1111', fontWeight: 'bold' }}
                                                        >
                                                            {`${item[1].activeActionCnt}`}
                                                        </div>
                                                        <div style={{ color: '#F77737', fontWeight: 'bold' }}>
                                                            {`/${item[1].perDayLimit}`}
                                                        </div>
                                                    </FlexRowAlignItemsCenter>
                                                )}
                                            </SubscriptionCardBottomSectionTop>
                                            <div>
                                                <FlexRowAlignItemsCenter>
                                                    <div>{dDayCount(moment(moment()).diff(item[1].endDate, 'days'))}</div>
                                                    {cardStateIcon(item[1].statusType)}
                                                </FlexRowAlignItemsCenter>
                                                { item[1].count && item[1].count > 1 && (
                                                    <div style={{ backgroundColor: '#99ff99' }}>{item[1].count}개 구매</div>
                                                )}
                                            </div>
                                        </SubscriptionCardBottomSection>
                                        </>) : <></>
                                    }
                                </div>
                            </SubscriptionCard>
                        </Badge>
                    ))}
                </CardGrid>
                )
            }
            <div>
                <div>
                    <Button onClick={() => {
                        getNotStartingPackageList('finished');
                    }}>끝난 패키지</Button>
                    <Button onClick={() => {
                        getNotStartingPackageList('canceled');
                    }}>취소된 패키지</Button>
                    <Button onClick={() => {
                        getNotStartingPackageList('pending');
                    }}>팬딩된 패키지</Button>
                </div>
                { packageList2 &&
                    packageList2.length > 0 && (
                    <CardGrid>
                        { packageList2.map((item, i) => (
                            <Badge>
                                <QuestSubscriptionCard
                                    onClick={() =>  {
                                        ( async () => {
                                            navigate(`/package-management?id=${item.id}`);
                                            await getSubscriptionDetail(item.id);
                                            await getActionList(item.id);
                                            await getDetectedList(item.id);
                                        })();
                                    }}
                                    backgroundColor={cardColor(item.statusType)}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div style={{ fontWeight: 'bold', marginBottom: 5, fontSize: 15, wordBreak: 'break-all' }}>{item.targetId}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div >{dDayCount(moment(moment()).diff(item.endDate, 'days'))}</div>
                                            {cardStateIcon(item.statusType)}
                                        </div>
                                    </div>
                                    <div
                                        style={{ display: 'flex', flexDirection: 'row', margin: '3px 0', alignItems: 'center', justifyContent: 'space-between' }}
                                    >
                                        <div
                                            style={{ display: 'flex', flexDirection: 'row' }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                window.open(`https://www.instagram.com/${item.targetId}`, '_blank', 'noreferrer noopener');
                                            }}
                                        >
                                            <div>{item.customerName}</div>
                                            <RightOutlined style={{ fontSize: 10 }} />
                                        </div>
                                        {item.activeActionCnt > 0 && (
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ color: item.activeActionCnt === item.perDayLimit
                                                    ?'#F77737' : item.activeActionCnt < item.perDayLimit
                                                    ? 'black' : '#ff1111', fontWeight: 'bold' }}
                                                >
                                                    {`${item.activeActionCnt}`}
                                                </div>
                                                <div style={{ color: '#F77737', fontWeight: 'bold' }}>
                                                    {`/${item.perDayLimit}`}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            { item.taskType === 'like' ? <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} />
                                                : <UserAddOutlined style={{ color: '#0095f6', marginRight: 5 }} />
                                            }
                                            <div>{item.perDayLimit > 1 ? `${item.servingAmount}회 * ${item.perDayLimit}` : `${item.servingAmount}회` }</div>
                                        </div>
                                        { item.count && item.count > 1 && (
                                            <div style={{ backgroundColor: '#99ff99' }}>{item.count}개 구매</div>
                                        )}
                                    </div>
                                </QuestSubscriptionCard>
                            </Badge>
                        ))}
                    </CardGrid>
                    )
                }
            </div>
            <Drawer
                title={`${searchingDate} 구독 상세`}
                placement='right'
                closable={true}
                open={detailSubscriptionDrawer}
                onClose={() => {
                    setDetailSubscriptionDrawer(false);
                    setSearchParams(prev => {
                        prev.delete('id');
                        return prev;
                    });
                }}
                width='80vw'
            >
                <div>
                    { detailSubscriptionValue && (
                        <TopDiv>
                            <table>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.id}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>인스타그램 id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.targetId}</LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'targetId',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구매자명</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.customerName}</LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'customerName',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>휴대폰 번호</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.orderPhone}</LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'orderPhone',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구독 상품</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.name}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>작업 종류</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.taskType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>플랫폼 종류</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.platformType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>작업 대상 국가</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.country}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구독 진행 상황</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.statusType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구매 갯수</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.count}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>하루에 한도</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.perDayLimit}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>한번에 갯수</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{detailSubscriptionValue.servingAmount}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>시작일</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{moment(detailSubscriptionValue.startDate).format('YYYY-MM-DD HH:mm:ss')}</LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'startDate',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>종료일</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{moment(detailSubscriptionValue.endDate).format('YYYY-MM-DD HH:mm:ss')}</LeftInfoSecondTd>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setEditSubscriptionValue({
                                                    column: 'endDate',
                                                    value: ''
                                                });
                                                setEditSubscriptionModal(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </td>
                                </LeftInfoBottomDottedTr>
                            </table>
                            <ButtonContainer style={{ display: 'flex', flexDirection: 'column' }}>
                                { !detailSubscriptionValue.hasSendGoodbye &&
                                    <Button
                                        style={{ backgroundColor: '#cccccc', marginBottom: 10 }}
                                        onClick={goodbyeConfirm}
                                    >
                                        구독 마지막 인사 <MailOutlined />
                                    </Button>
                                }
                                <div>
                                    <Button
                                        style={{ backgroundColor: '#ffffcc', marginRight: 10 }}
                                        onClick={suspendConfirm}
                                    >
                                        중단 <PauseOutlined />
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#ccffcc', marginRight: 10 }}
                                        onClick={resumeConfirm}
                                    >
                                        시작 <CaretRightOutlined />
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#ffcccc' }}
                                        onClick={revokeConfirm}
                                    >
                                        취소 <CloseOutlined />
                                    </Button>
                                </div>
                                <Button
                                    style={{ marginTop: 10, backgroundColor: '#ccccff' }}
                                    onClick={() => {
                                        setManualActionModal(true);
                                        setManualActionValue({
                                            subscriptionId: detailSubscriptionValue.id,
                                            // 탐지된 게시물 날짜 ex: 2021-01-02
                                            // detectedDate: moment().format(DATE_FORMAT),
                                            detectedDate: '',
                                            detectedItemId: '',
                                            targetUrl: '',
                                            needAligoSMS: true,
                                            isOnAdmin: false,
                                            // @ts-ignore
                                            taskType: detailSubscriptionValue.taskType === 'follow' ? 'follow' : 'like',
                                            postedAt: '',
                                        });
                                    }}
                                >
                                    action 발행 <PlusOutlined />
                                </Button>
                                <Button
                                    style={{ marginTop: 10 }}
                                    onClick={() => {
                                        (async () => {
                                            await getDetectedList(detailSubscriptionValue.id);
                                        })();
                                    }}
                                >
                                    디텍트 새로고침<RedoOutlined />
                                </Button>
                                <Button
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                    onClick={() => {
                                        setMemoModal(true);
                                        setMemoValue({
                                            subscriptionID: detailSubscriptionValue.id,
                                            text: '',
                                            pinned: false,
                                        });
                                    }}
                                >
                                    메모 생성
                                </Button>
                                { memos && memos.length > 0 &&
                                    memos.map((memo) => (
                                        <div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>
                                                    { // @ts-ignore
                                                        memo.content.pinned && (<PushpinOutlined style={{ marginRight: 5, color: 'red' }} />
                                                    )}
                                                    {`${moment(memo.createdAt).format(DATE_FORMAT)}`}
                                                </div>
                                                <div
                                                    onClick={() => deleteMemo(memo.id)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <CloseOutlined style={{ color: 'lightgray' }} />
                                                </div>
                                            </div>
                                            {/* <div>{memo.id}</div> */}
                                            {/* <div>{memo.orderPhone}</div> */}
                                            <div style={{ borderBottom: '1px lightgray solid' }}>{
                                            // @ts-ignore
                                            memo.content.text}</div>
                                        </div>
                                    ))
                                }
                            </ButtonContainer>
                        </TopDiv>
                    )}
                </div>
                { actionList &&
                    actionList.length > 0 && (
                    <Collapse onChange={onChange}>
                    { actionList.map((item, i) => (
                        <Panel
                            header={`Action${i + 1} - ${item.targetUrl}`}
                            key={item.id}
                            extra={item.status === 'published' && <CloseOutlined
                                onClick={(event) => {
                                    // If you don't want click extra trigger collapse, you can prevent this:
                                    event.stopPropagation();
                                    deleteAction(item.subscriptionId, item.id);
                                }}
                            />}
                            style={{ backgroundColor: item.status === 'published' ? '#ccccff' : '#aaaaaa' }}
                        >
                            <table>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>플랫폼 종류</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.platformType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>인스타그램 Id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.targetId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>대상 주소</LeftInfoFirstTd>
                                    <LeftInfoSecondTd><a
                                        target='_blank'
                                        href={`https://www.instagram.com/p/${item.targetUrl}`}
                                        rel='noreferrer noopener'
                                        >{item.targetUrl}</a></LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.id}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구독 id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.subscriptionId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>detected id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item?.detectedItemId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>주문 id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.orderId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                            </table>
                        </Panel>
                        ))}
                    </Collapse>
                )
                }
                { detectedList &&
                    detectedList.length > 0 && (
                    <Collapse onChange={onChange}>
                    { detectedList.map((item, i) => (
                         // subscriptionActionId로 subscriptionId과 일치해서 맞는지 판단
                        <Panel
                            header={
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div>{`Detect${item.idx + 1}-${item.detectedDate}`}</div>
                                    { actionList.findIndex(d => d.detectedItemId === item.id) !== -1 &&
                                        <CheckOutlined style={{ color: 'red', fontSize: 20, marginLeft: 5 }}/>
                                    }
                                </div>
                            }
                            key={i}
                            extra={<PlusSquareOutlined
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setSelectedDetectedItem(item);
                                    setDetectedActionModal(true);
                            }} />}
                            style={{ backgroundColor: '#aaffaa' }}
                        >
                            <table>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>대상 종류</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.objectType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>플랫폼 종류</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.platformType}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>인스타그램 Id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.targetId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>대상 주소</LeftInfoFirstTd>
                                    <LeftInfoSecondTd><a
                                        target='_blank'
                                        href={`https://www.instagram.com/${item.objectType === 'ig_post' ? 'p' : 'reel'}/${item.targetUrl}`}
                                        rel='noreferrer noopener'
                                        >{item.targetUrl}</a></LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.id}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                                <LeftInfoBottomDottedTr>
                                    <LeftInfoFirstTd>구독 id</LeftInfoFirstTd>
                                    <LeftInfoSecondTd>{item.subscriptionId}</LeftInfoSecondTd>
                                </LeftInfoBottomDottedTr>
                            </table>
                        </Panel>
                    ))}
                    </Collapse>
                )
                }
            </Drawer>
        </RootContainer>
    );
};

export default PackageManagement;