import './App.css';
import 'react-circular-progressbar/dist/styles.css';
import { Layout, Menu, message, Switch } from 'antd';
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SetupAccount from './pages/setupAccount/index';
import UnlockAccount from './pages/unlockAccount/index';
import UpdateSession from './pages/updateSettion/index';
import SubMenu from 'antd/lib/menu/SubMenu';
import OrderStatus from './pages/orderStatus/index';
import AccountList from './pages/accountList';
import WorkStatus from './pages/workStatus';
import { isMobileOnly } from 'react-device-detect';
import { useCallback, useEffect, useState } from 'react';
import { SettingOutlined, SyncOutlined } from '@ant-design/icons';
import Login from './pages/login';
import { authStore } from './authStore';
import repository from './repository';
import Flex from './components/flex';
import UploadPost from './pages/uploadPost/index';
import AccountDetail from './pages/accountDetail/index';
import FreePromotion from './pages/freePromotion';
import AsList from './pages/asList';
import ProxyControl from './pages/proxyControl';
import ManualOrderForm from './pages/manualOrderForm';
import SubscribeManagement from './pages/subscribeManagement';
import Training from './pages/training';
import Setting, { Interval } from './pages/setting';
import settingStore from './stores/settingStore';
import { observer } from 'mobx-react';
import DM from './pages/dm';
import CompleteOrderStatus from './pages/completeOrderStatus';
import Membership from './pages/membership';
import DepositManagement from './pages/deposit';
import MappedDeposit from './pages/mappedDeposit';
import ManualOrderAutoForm from './pages/manualOrderAutoForm';
import FlashNotification from './pages/flashNotification';
import Lab from './pages/lab';
import ForeignerOrder from './pages/foreignerOrder';
import BannedList from './pages/bannedList';
import TrainingCenter from './pages/trainingCenter';
import { OrderProgressDto } from './apiTypes';
import moment from 'moment';
import CanceledOrderStatus from './pages/canceledOrderStatus';
import PromotionBlackList from './pages/promotionBlackList';
import AdminSetting from './pages/adminSetting';
// import UploadPhoto from './pages/uploadPhoto';
// import CheckPhoto from './pages/checkPhoto';
import PackageManagement from './pages/packageManagement';
import Customer from './pages/customer';
const { Content, Sider } = Layout;

const pages = [
    {
        name: '로그인',
        key: 'login',
        component: <Login />,
    },
    {
        name: '계정 정보',
        key: '/account/detail/:id',
        component: <AccountDetail />
    }
];

const privatePages = [
    {
        name: '계정 관리',
        key: 'account',
        subPages: [
            {
                name: '계정 리스트',
                key: 'account-list',
                component: <AccountList />
            },
            {
                name: '벤 리스트',
                key: 'banned-list',
                component: <BannedList />
            },
            {
                name: '잠금 해제',
                key: 'unlock',
                component: <UnlockAccount />
            },
            {
                name: '쿠키 업데이트',
                key: 'update-session',
                component: <UpdateSession />
            },
            {
                name: '계정 세팅',
                key: 'setup',
                component: <SetupAccount />
            },
            {
                name: '훈련소',
                key: 'training-center',
                component: <TrainingCenter />
            },
            {
                name: '게시물 올리기',
                key: 'upload-post',
                component: <UploadPost />
            },
            {
                name: '계정 투입',
                key: 'training',
                component: <Training />
            },
        ],
    },
    {
        name: '주문 현황',
        key: 'order-status',
        component: <OrderStatus />
    },
    {
        name: '취소, 완료, 외국인 주문',
        key: 'orders',
        subPages: [
            {
                name: '취소 주문 현황',
                key: 'canceled-order-status',
                component: <CanceledOrderStatus />,
            },
            {
                name: '완료 주문 현황',
                key: 'complete-order-status',
                component: <CompleteOrderStatus />,
            },
            {
                name: '외국인 주문',
                key: 'foreigner-order',
                component: <ForeignerOrder />
            },
        ],
    },
    {
        name: '작업 현황',
        key: 'work-status',
        component: <WorkStatus />
    },
    // {
    //     name: '댓글 현황',
    //     key: 'comment-status',
    //     component: <CommentStatus />
    // },
    {
        name: '수동 주문',
        key: 'manual-order',
        subPages: [
            {
                name: '폼 주문',
                key: 'form',
                component: <ManualOrderForm />,
            },
            {
                name: '자동 폼 주문',
                key: 'auto-form',
                component: <ManualOrderAutoForm />,
            },
        ],
    },
    {
        name: '무료 프로모션',
        key: 'free-promotion',
        subPages: [
            {
                name: '프로모션 주문',
                key: 'promotion-order',
                component: <FreePromotion />,
            },
            {
                name: '블랙리스트',
                key: 'promotion-blacklist',
                component: <PromotionBlackList />,
            },
        ],
    },
    {
        name: '구독 관리',
        key: 'subscribe-management',
        component: <SubscribeManagement />
    },
    {
        name: '패키지 관리',
        key: 'package-management',
        component: <PackageManagement />
    },
    {
        name: '입금',
        key: 'deposit',
        subPages: [
            {
                name: '미완료 입금',
                key: 'deposit-management',
                component: <DepositManagement />
            },
            {
                name: '완료 입금 현황',
                key: 'mapped-deposit-management',
                component: <MappedDeposit />
            },
        ],
    },
    {
        name: '멤버십',
        key: 'membership',
        component: <Membership />
    },
    {
        name: 'A/S',
        key: 'as-list',
        component: <AsList />
    },
    {
        name: '고객정보',
        key: 'customer',
        component: <Customer />
    },
    {
        name: '프록시 컨트롤',
        key: 'proxy-control',
        component: <ProxyControl />
    },
    {
        name: '공지사항',
        key: 'flash-notification',
        component: <FlashNotification />
    },
    {
        name: '환경설정',
        key: 'setting',
        component: <Setting />
    },
    {
        name: '실험실',
        key: 'lab',
        component: <Lab />
    },
    // {
    //     name: '계정 사진',
    //     key: 'photo',
    //     subPages: [
    //         {
    //             name: '사진 업로드',
    //             key: 'upload-photo',
    //             component: <UploadPhoto />
    //         },
    //         {
    //             name: '사진 확인',
    //             key: 'check-photo',
    //             component: <CheckPhoto />
    //         },
    //     ],
    // },
    {
        name: 'DM',
        key: 'dm',
        component: <DM />
    },
    {
        name: '관리자계정 설정',
        key: 'admin-setting',
        component: <AdminSetting />
    }
];

const keyMapper = {
    '/account/unlock': 'unlock',
    '/account/update-session': 'update-session',
    '/account/setup': 'setup',
    '/account/training-center': 'training-center',
    '/account/account-list': 'account-list',
    '/account/banned-list': 'banned-list',
    '/account/upload-post': 'upload-post',
    '/account/training': 'training',
    '/order-status': 'order-status',
    '/work-status': 'work-status',
    '/manual-order/form': 'form',
    '/manual-order/auto-form' : 'auto-form',
    '/free-promotion/promotion-order': 'promotion-order',
    '/free-promotion/promotion-blacklist': 'promotion-blacklist',
    '/as-list': 'as-list',
    '/proxy-control': 'proxy-control',
    '/setting': 'setting',
    '/subscribe-management': 'subscribe-management',
    '/package-management': 'package-management',
    // '/comment-status': 'comment-status'
    '/orders/canceled-order-status': 'canceled-order-status',
    '/orders/complete-order-status': 'complete-order-status',
    '/orders/foreigner-order': 'foreigner-order',
    '/membership' : 'membership',
    '/deposit/deposit-management': 'deposit-management',
    '/deposit/mapped-deposit': 'mapped-deposit-management',
    '/flash-notification': 'flash-notification',
    '/lab': 'lab',
    '/admin-setting': 'admin-setting',
    '/customer': 'customer',
    // '/photo/upload-photo': 'upload-photo',
    // '/photo/check-photo': 'check-photo'
};

const WorkableQuota = () => {
    const [ quota, setQuota ] = useState<number>(0);
    const [ loading, setLoading ] = useState<boolean>(false);

    const _getWorkableQuota = async () => {
        setLoading(true);
        const quota = await repository.getWorkableQuota();
        if (typeof quota === 'number') {
            setQuota(quota);
        }
        setTimeout(() => {
            setLoading(false);
        }, 300);
    };

    useEffect(() => {
        _getWorkableQuota();
    }, []);

    // 10초마다 배정가능 스케줄 수 숫자 갱신
    useEffect(() => {
        const timerId = setInterval(() => {
            _getWorkableQuota();
        }, 10 * 1000);
        return () => clearInterval(timerId);
    }, []);

    return (
        <div>
            <div style={{ marginBottom: 4 }}>배정가능 스케줄 수</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <span style={{ color: '#F77737', fontSize: 20, fontWeight: 'bold' }}>
                    { loading ? '-' : quota.toLocaleString()}
                </span>
                <button
                    onClick={_getWorkableQuota}
                    style={{
                        padding: 4,
                    }}
                >
                    <SyncOutlined
                        spin={loading}
                        style={{
                            fontSize: 14,
                        }}
                    />
                </button>
            </div>
        </div>
    );
};

type DashboardStatus = {
    remainCnts: {
        follow: number;
        like: number;
    };
    saturationRate: number;
    subscriptionFollow: {
        remain: number;
        total: number;
    };
};

const ScheduleMonitor = observer(() => {
    const [ enabled, setEnabled ] = useState<boolean>();
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ dashboardStatus, setDashboardStatus ] = useState<DashboardStatus>();

    const _getCanSchedule = useCallback(async () => {
        const _enabled = await repository.getCanSchedule();
        setEnabled(_enabled);
    }, []);


    const _getMagicValue = async () => {
        settingStore.initialize();
    };

    const handleChange = useCallback(async (checked: boolean) => {
        try {
            setLoading(true);
            if (checked) {
                const succeed = await repository.activateSchedule();
                if (!succeed) {
                    throw new Error('스케줄러를 켜는데 실패하였습니다');
                }
                message.success('스케줄러를 켰습니다.');
            } else {
                const succeed = await repository.deactivateSchedule();
                if (!succeed) {
                    throw new Error('스케줄러를 끄는데 실패하였습니다');
                }
                message.success('스케줄러를 껐습니다.');
            }
            await _getCanSchedule();
            setTimeout(() => {
                setLoading(false);
            }, 300);
        } catch (err) {
            setLoading(false);
            // @ts-ignore
            message.error(err.message);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, []);

    const getDashboardStatus = async () => {
        try {
            const dashboardStatus = await repository.getBiscuit('dashboard-status') as unknown as DashboardStatus;
            if (dashboardStatus) {
                setDashboardStatus(dashboardStatus);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        _getCanSchedule();
        _getMagicValue();
        getDashboardStatus();
    }, []);

    // 5초마다 스케줄링 상태 갱신
    useEffect(() => {
        const timerId = setInterval(() => {
            _getCanSchedule();
        }, 5 * 1000);
        const timerId2 = setInterval(() => {
            _getMagicValue();
        }, 10 * 1000);
        const timerId3 = setInterval(() => {
            getDashboardStatus();
        }, 30 * 1000);
        return () => {
            clearInterval(timerId);
            clearInterval(timerId2);
            clearInterval(timerId3);
        }
    }, []);

    return (
        <div>
            <Flex justifyContent='space-between'>
                <div>스케줄러 ON/OFF</div>
                <div>
                    { enabled !== undefined &&
                        <Switch
                            loading={loading}
                            checked={enabled}
                            onChange={handleChange}
                        />
                    }
                </div>
            </Flex>
            <div style={{
                padding: 10,
                background: 'white',
                borderRadius: 10,
                marginTop: 20,
                color: 'black',
            }}>
                <Flex justifyContent='space-between'>
                    <div>
                        스케줄
                        <span style={{ color: '#F77737', fontWeight: 'bold', fontSize: 16, margin: '0 3px 0 6px' }}>
                            {(settingStore.magicValues?.WORKER_COUNT ?? 0) * 10}
                        </span>
                        / 회
                    </div>
                    <Link to='/setting' style={{ color: 'black' }}>
                        <button style={{ padding: 4 }}>
                            <SettingOutlined color='black' />
                        </button>
                    </Link>
                </Flex>
                { dashboardStatus &&
                    <>
                        <Flex
                            justifyContent='space-between'
                            style={{ marginTop: 10 }}
                        >
                            포화율
                            <Flex>
                                <span
                                    style={{
                                        color: (dashboardStatus.saturationRate ?? 1) >= 1.9 ? '#E1306C' : '#119911',
                                        fontWeight: 'bold',
                                        fontSize: 16,
                                        margin: '0 5px 0 6px',
                                    }}
                                >
                                    {Math.floor((dashboardStatus.saturationRate ?? 1) * 100)}%
                                </span>
                                <span>
                                    { settingStore.magicValues?.SCHEDULE_MAX_WORKFLOW === Interval.SCHEDULE_MAX_WORKFLOW.max && '🚀' }
                                </span>
                            </Flex>
                        </Flex>
                        <Flex
                            justifyContent='space-between'
                            style={{ marginTop: 10 }}
                        >
                            좋아요
                            <span style={{ color: 'gray', fontWeight: 'bold', fontSize: 16, margin: '0 3px 0 6px' }}>
                                {dashboardStatus.remainCnts.like.toLocaleString()}
                            </span>
                        </Flex>
                        <Flex
                            justifyContent='space-between'
                            style={{ marginTop: 10 }}
                        >
                            팔로우
                            <span style={{ color: 'gray', fontWeight: 'bold', fontSize: 16, margin: '0 3px 0 6px' }}>
                                {dashboardStatus.remainCnts.follow.toLocaleString()}
                            </span>
                        </Flex>
                        <Flex
                            justifyContent='space-between'
                            style={{ marginTop: 10 }}
                        >
                            구독팔로우
                            <span style={{ color: 'gray', fontWeight: 'bold', fontSize: 16, margin: '0 3px 0 6px' }}>
                                {dashboardStatus.subscriptionFollow.remain.toLocaleString()}
                            </span>
                        </Flex>
                    </>
                }
            </div>
        </div>
    );
});

const Monitor = observer(() => {
    const monitors = [
        {
            link: 'https://devamongkr.grafana.net/d/fRIvzUZMz/proxy-manager?orgId=1&refresh=10s',
            text: '프록시 모니터',
        },
        {
            link: 'https://devamongkr.grafana.net/d/1yoY6tS4z/instaleader-worker?orgId=1&refresh=10s',
            text: '워커 모니터',
        },
        {
            link: 'https://devamongkr.grafana.net/d/yl1DU6n4z/operation?orgId=1&refresh=10s',
            text: '오퍼레이션 모니터',
        },
        {
            link: 'https://devamongkr.grafana.net/d/_LRxVeJ4k/scheduler?orgId=1',
            text: '스케줄러 모니터',
        },
        {
            link: 'https://devamongkr.grafana.net/d/bba78d28-7d92-4ed7-b023-d99c055fd6fb/deposit-checker?orgId=1',
            text: '입금확인 모니터',
        },
        {
            link: 'https://devamongkr.grafana.net/d/bd6048d0-ce47-45d3-880c-c39fa1d67114/autopilot?orgId=1',
            text: '파일럿 모니터',
        },
    ]

    return (
        <div style={{ color: '#ffffff' }}>
            <div style={{
                borderBottom: 'solid 1px gray',
                padding: '12px 24px',
            }}>
                <WorkableQuota />
            </div>
            <div style={{
                borderBottom: 'solid 1px gray',
                padding: '12px 24px',
            }}>
                <ScheduleMonitor />
                {/* <NicknameSetting /> */}
            </div>
            <div style={{
                borderBottom: 'solid 1px gray',
            }}>
                <Menu
                    theme='dark'
                    mode='inline'
                    onSelect={(info) => {
                        window.open(info.key, '_blank');
                    }}
                >
                    <SubMenu key={'모니터'} title={'모니터'}>
                        { monitors.map(monitor => (
                            <Menu.Item key={monitor.link}>
                                {monitor.text}
                            </Menu.Item>
                        ))}
                    </SubMenu>
                </Menu>
            </div>
        </div>
    );
});

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();

    if (authStore.token === null) {
        return (
            <Navigate
                to='/login'
                state={{ from: location }}
                replace
            />
        )
    }

    return children;
};

const App = observer(() => {
    const [ collapsed, setCollapsed ] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();

    const onSelectMenu = (keyPath: Array<string>) => {
        const to = keyPath.reverse().join('/');
        navigate(to);
    };

    useEffect(() => {
        if (!isMobileOnly) {
            setCollapsed(false);
        }
    }, [ isMobileOnly ]);

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/order-status');
        }
        if (isMobileOnly) {
            setCollapsed(true);
        }
    }, [ location.pathname ]);

    useEffect(() => {
        settingStore.initialize();
    }, []);

    return (
        <Layout style={{ minHeight: '100vh', boxSizing: 'border-box' }}>
            { authStore.token &&
                <Sider
                    collapsible
                    collapsedWidth={0}
                    collapsed={collapsed}
                    onCollapse={value => setCollapsed(value)}
                    style={{
                        zIndex: 100,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <div style={{
                        height: '100%',
                        overflowY: 'scroll'
                    }}>
                        <Monitor />
                        <Menu
                            theme='dark'
                            mode='inline'
                            onSelect={(info) => onSelectMenu(info.keyPath)}
                            // @ts-ignore
                            selectedKeys={[keyMapper[location.pathname]]}
                        >
                            { privatePages.map(page => {
                                if (page.subPages) {
                                    return (
                                        <SubMenu key={page.key} title={page.name}>
                                            { page.subPages.map(subPage => (
                                                <Menu.Item key={subPage.key}>
                                                    {subPage.name}
                                                </Menu.Item>
                                            ))}
                                        </SubMenu>
                                    );
                                }

                                return (
                                    <Menu.Item key={page.key}>
                                        {page.name}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    </div>
                </Sider>
            }
            <Layout style={{ background: 'white', paddingLeft: collapsed ? 0 : 200 }}>
                <Content style={{ background: 'white', position: 'relative', overflowX: 'scroll', height: '100vh' }}>
                    <Routes>
                        { pages.map(page => (
                            <Route
                                key={page.key}
                                path={page.key}
                                element={page.component}
                            />
                        ))}
                        { privatePages.map(page => {
                            if (page.subPages) {
                                return (
                                    <Route
                                        key={page.key}
                                        path={page.key}
                                    >
                                        { page.subPages.map(subPage => (
                                            <Route
                                                key={subPage.key}
                                                path={subPage.key}
                                                element={
                                                    <RequireAuth>
                                                        {subPage.component}
                                                    </RequireAuth>
                                                }
                                            />
                                        ))}
                                    </Route>
                                );
                            }

                            return (
                                <Route
                                    key={page.key}
                                    path={page.key}
                                    element={
                                        <RequireAuth>
                                            {page.component}
                                        </RequireAuth>
                                    }
                                />
                            );
                        })}
                    </Routes>
                </Content>
            </Layout>
        </Layout>
    );
});

export default App;