import styled from "@emotion/styled";
import { Pagination, message, Empty, Select, Modal, Input, DatePicker, TimePicker, Button } from 'antd';
import React, { useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from "react-table";
import { CreateDepositDto, DepositPageDto, DepositTargetSourceType } from "../../apiTypes";
import repository from "../../repository";
import MaUTable from '@mui/material/Table';
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ArrowDownOutlined, ArrowUpOutlined, SyncOutlined, PlusOutlined } from '@ant-design/icons';
import moment, { isMoment } from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import Mask from "../../components/mask";

const DATE_FORMAT = 'YYYY-MM-DD';

function depositType (type: DepositTargetSourceType) {
    if (type === 'none') {
        return 'none';
    }
    if (type === 'order') {
        return '주문';
    }
    if (type === 'subscription') {
        return '구독';
    }
    if (type === 'package') {
        return '패키';
    }
};

function depositTypeBackgroundColor (type: DepositTargetSourceType) {
    if (type === 'none') {
        return 'white';
    }
    if (type === 'order') {
        return 'orange';
    }
    if (type === 'subscription') {
        return 'lightgreen';
    }
    if (type === 'package') {
        return 'blue';
    }
};

// @ts-ignore
function DepositTable({ columns, data, refresh}) {
    const defaultColumn = useMemo(() => ({
        width: 100,
    }), []);

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        getTableBodyProps,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    return (
        <>
        <MaUTable
            {...getTableProps()}
            stickyHeader
        >
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // @ts-ignore
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <div>
                                    {column.render('Filter')}
                                </div>
                                <span>
                                    {/* @ts-ignore */}
                                    {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                </span>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                { rows.map((row, i) => {
                    prepareRow(row);
                    const {
                        status,
                    } = row.values;

                    const getBackgroundColor = () => {
                        if (status === 'transferred') {
                            return '#405DE610';
                        }
                        if (status === 'refunded') {
                            return '#e6514010';
                        }
                        if (status === 'part_refunded') {
                            return '#f2f20019';
                        }
                        return undefined;
                    };

                    return (
                        <TableRow
                            {...row.getRowProps({
                                style: {
                                    height: 70,
                                    backgroundColor: getBackgroundColor(),
                                },
                            })}
                        >
                            { row.cells.map(cell => {
                                return (
                                    <CopyToClipboard
                                        text={cell.column.id === 'mappedInfo'
                                            ? (cell.value?.sourceId ?? '') : cell.value
                                        }
                                        onCopy={(text, result) => {
                                            if (result) {
                                                message.success(`${cell.column.id === 'mappedInfo'
                                                    ? (cell.value?.sourceId ?? '') : cell.value
                                                }`);
                                            } else {
                                                message.error(`${cell.column.id === 'mappedInfo'
                                                    ? (cell.value?.sourceId ?? '') : cell.value
                                                }`);
                                            }
                                        }}
                                    >
                                        <TableCell
                                            {...cell.getCellProps()}
                                            style={{
                                                cursor: 'pointer',
                                                // @ts-ignore
                                                width: cell.column.originalWidth,
                                            }}
                                        >
                                            { cell.render('Cell') }
                                        </TableCell>
                                    </CopyToClipboard>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </MaUTable>
        </>
    )

};

function MappedDeposit() {

    const [ depositListData, setDepositListData ] = useState<Array<DepositPageDto>>();
    const [ loading, setLoading ] = useState<boolean>(false);

    const getDepositLists = async (page: number) => {
        setLoading(true);
        try {
            const rs = await repository.getDepositsLists(page, 500, true);
            if (rs) {
                console.log('rs', rs);
                setDepositListData(rs);
            }
        } catch (err) {
            message.error(`${err}`);
        };
        setLoading(false);
    };

    const deleteDeposit = async (depositId: string, depositor: string, amount: number, depositedAt: string) => {
        if (window.confirm(`${moment(depositedAt).format('MM/DD (HH:mm)')}, ${depositor}, ${amount}원 의 주문을 취소하시겠습니까?`)) {
            try {
                const rs = await repository.deleteDeposit(depositId);
                if (rs.status === 'success') {
                    message.success('입금을 취소했습니다.');
                    await getDepositLists(1);
                } else {
                    throw new Error('취소 처리가 실패했습니다.');
                }
            } catch(err) {
                message.error(`${err}`);
            }
        } else {
            return;
        }
    };

    const columns = React.useMemo(() => [
        {
            Header: '입금일자',
            accessor: 'depositedAt',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        { moment(cell.value).format('MM/DD (HH:mm)') }
                    </div>
                );
            },
            width: 120,
        },
        {
            Header: '입금자명',
            accessor: 'depositor',
            Filter: () => {},
            width: 120,
        },
        {
            Header: '입금상태',
            accessor: 'status',
            Filter: (props: any) => {
                const { column: { setFilter } } = props;
                const toStatus = (value: string) => {
                    switch (value) {
                        case 'transferred':
                            return 'transferred';
                        case 'refunded':
                            return 'refunded';
                        case 'part_refunded':
                            return 'part_refunded';
                    };
                };

                return (
                    <Select
                        onClick={e => e.stopPropagation()}
                        onChange={value => setFilter(toStatus(value))}
                        defaultValue='all'
                        style={{ width: 80 }}
                    >
                        <Select.Option value=''>all</Select.Option>
                        <Select.Option value='transferred'>transferred</Select.Option>
                        <Select.Option value='refunded'>refunded</Select.Option>
                        <Select.Option value='part_refunded'>part_refunded</Select.Option>
                    </Select>
                )
            },
        },
        {
            Header: '입금액',
            accessor: 'amount',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div style={{ textAlign: 'right' }}>
                        { cell.value.toLocaleString() }
                    </div>
                );
            },
        },
        {
            Header: '환불액',
            accessor: 'refundedAmount',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div style={{ textAlign: 'right' }}>
                        { cell.value.toLocaleString() }
                    </div>
                );
            },
        },
        {
            Header: '연관주문',
            accessor: 'mappedInfo',
            Filter: () => {},
            width: 200,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                if (!cell.value) {
                    return <></>
                }
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ width: 20, border: '1px gray solid', backgroundColor: depositTypeBackgroundColor(cell.value.sourceType)}}>
                            { cell.value.sourceType ? depositType(cell.value.sourceType) :'' }
                        </div>
                        <div>
                            { cell.value.sourceId ?? '' }
                        </div>
                    </div>
                );
            },
        },
        {
            Header: 'id',
            accessor: 'id',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div>
                            {cell.value}
                        </div>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteDeposit(cell.value, cell.row.values.depositor,
                                    cell.row.values.amount, cell.row.values.depositedAt);
                            }}
                            style={{ border: '1px lightgray solid', color: 'red', backgroundColor: 'white', width: 110, padding: '0 5px' }}
                        >
                            입금 취소
                        </button>
                    </div>
                );
            },
            width: 250,
        },
    ], []);

    useEffect(() => {
        ( async () => {
            await getDepositLists(1);
        })();
    }, []);

    if (loading) {
        return <Mask />
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0', padding: '0 5px' }}>
                <button
                    onClick={() => {
                        ( async () => {
                            await getDepositLists(1);
                        })();
                    }}
                    style={{ border: '1px lightgray solid', padding: '5px 20px', flex: 1 }}
                >새로고침
                    <SyncOutlined
                        spin={false}
                        style={{ fontSize: 14, marginLeft: 5 }}
                    />
                </button>
            </div>
            { depositListData && depositListData.length > 0
                ? <DepositTable
                    columns={columns}
                    data={depositListData}
                    refresh={() => getDepositLists(1)}
                />
                : <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Empty /></div>
            }
            {/* <Pagination
                style={{ display: 'flex', justifyContent: 'center' }}
                simple
                current={depositListData?.meta.page}
                total={depositListData?.meta.itemCount}
                onChange={(page, pageSize) => getDepositLists(page)}
                defaultPageSize={20}
            /> */}
        </>
    );
};

export default MappedDeposit;