import { useEffect, useState } from 'react';
import Mask from '../../components/mask';
import { AbuserChangeHistoryDto, AccountSetupInfoDto, S3Object } from '../../apiTypes';
import repository from '../../repository';
import { Button, Checkbox, Drawer, Image, Input, message, Modal, Radio, Space, Switch } from 'antd';
import { useParams } from 'react-router';
import { ArrowDownOutlined, CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DashboardOutlined, EditOutlined, ExclamationCircleOutlined, LockOutlined, RedoOutlined, SwapRightOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import TextArea from 'antd/lib/input/TextArea';
import styled from '@emotion/styled';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

export enum ProgressedSettingOption {
    DONE_IMAGES = 'doneImages',
    PROFILE = 'profile',
    USERNAME ='userName',
    PRIVATE = 'private',
    BASENAME = 'basename',
    DESC = 'desc',
    DELETE_POST = 'deletePost'
};

const RootContainer = styled.div`
display: flex;
flex-direction: column;
@media screen and (max-width: 500px) {
    width: 100%;
}
`;

const BottomDottedTr = styled.tr`
display: flex;
border-bottom: 1px lightgray dotted;
align-items: center;
`;

const LeftInfoBottomDottedTr = styled.tr`
border-bottom: 1px lightgray dotted;
`;

const LeftInfoFirstTd = styled.td`
width: 100px;
font-weight: bold;
@media screen and (max-width: 500px) {
    width: 200px;
}
`;

const LeftInfoSecondTd = styled.td`
width: 300px;
@media screen and (max-width: 500px) {
    width: 200px;
}
`;

const SettingDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
@media screen and (max-width: 500px) {
    flex-direction: column;
}
`;

const SettingToggleContainer = styled.div`
@media screen and (max-width: 500px) {
    flex-direction: column;
    margin-top: 50px;
    display: flex;
}
`;

const ToggleLeftContainer = styled.td`
width: 220px;
`;

const ToggleRightContainer = styled.td`
display: flex;
justify-content: flex-end;
flex: 1;
`;

const ImageWrapper = styled.div`
position: relative;
aspect-ratio: 1/1;
overflow: hidden;
`;

const ProfileImage = styled.img`
border-radius: 100%;
width: 100%;
height: 100%;
object-fit: cover;
`;

const HumanImage = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
`;

const DefaultImage = styled.img<{selected: boolean}>`
width: 100%;
height: 100%;
padding: ${(props) => props.selected ? '50px' : '0'};
border: ${(props) => props.selected ? '5px red solid' : '0px'};
object-fit: cover;
`;

function AccountDetail() {
    const [ searchParams ] = useSearchParams();
    const id = searchParams.get('id');
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ accountDetail, setAccountDetail ] = useState<AccountSetupInfoDto>();
    const [ isDescModalOpen, setIsDescModalOpen ] = useState<boolean>(false);
    const [ descTextValue, setDescTextValue ] = useState<string>('');
    const [ instaPasswordTextValue, setInstaPasswordTextValue ] = useState<string>('');
    const [ isMailModalOpen, setIsMailModalOpen ] = useState<boolean>(false);
    const [ isInstaPasswordModalOpen, setIsInstaPasswordModalOpen ] = useState<boolean>(false);
    const [ isEmailPasswordModalOpen, setIsEmailPasswordModalOpen ] = useState<boolean>(false);
    const [ mailPasswordTextValue, setMailPasswordTextValue ] = useState<string>('');
    const [ isAbuserStateModalOpen, setIsAbuserStateModalOpen ] = useState<boolean>(false);
    const [ isCookieModalOpen, setIsCookieModalOpen ] = useState<boolean>(false);
    const [ cookieTextValue, setCookieTextValue ] = useState<string>('');
    const [ isMemoModalOpen, setIsMemoModalOpen ] = useState<boolean>(false);
    const [ memoTextValue, setMemoTextValue ] = useState<string>('');
    const [ isHistoryDrawerOpen, setIsHistoryDrawerOpen ] = useState<boolean>(false);
    const [ historyList, setHistoryList ] = useState<Array<AbuserChangeHistoryDto>>([]);
    const [ abuserStateValue, setAbuserStateValue ] = useState<string>('');
    const [ emailGroupTextValue, setEmailGroupTextValue ] = useState<{
        recoveryEmail: string;
        recoveryEmailPass: string;
    }>({
        recoveryEmail: '',
        recoveryEmailPass: ''
    });
    const [ checkBox, setCheckBox ] = useState<boolean>(false);
    const [ deleteImageList, setDeleteImageList] = useState<Array<string>>([]);
    const [ imageSelectMode, setImageSelectMode ] = useState<boolean>(false);
    const [ accountStatusType, setAccountStatusType ] = useState<Array<string>>([]);

    const manageImageList = (imageId: string) => {
        if (deleteImageList.length === 0) {
            setDeleteImageList([ imageId ]);
        } else if (deleteImageList.find(d => d === imageId)) {
            setDeleteImageList(prev => prev.filter(d => d !== imageId));
        } else {
            setDeleteImageList(prev => [...prev, imageId]);
        }
        console.log('deleteImageList', deleteImageList);
    };

    const getAbuserDetail = async () => {
        if (!id) {
            return;
        }
        setLoading(true);
        const _accountDetail = await repository.getAbuserDetail(id);
        console.log(_accountDetail);
        setAccountDetail(_accountDetail);
        setLoading(false);
    };

    const updateDesc = async () => {
        if (!accountDetail) {
            message.error('데이터 에러');
            return;
        }
        const response = await repository.updateAbuser(accountDetail.abuserId, {
            newProgressedImage: [],
            instaDesc: checkBox ? ' ' : descTextValue.trim()
        });
        console.log(response);
        if (response) {
            message.success('자기소개 변경이 성공적으로 되었습니다.');
        } else {
            message.error('데이터 에러');
        }
    };

    const updateEmailPassword = async () => {
        if (!accountDetail) {
            message.error('데이터 에러');
            return;
        }
        const response = await repository.updateAbuser(accountDetail.abuserId, {
            newProgressedImage: [],
            emailPassword: mailPasswordTextValue.trim()
        });
        console.log(response);
        if (response) {
            message.success('이메일 비번이 성공적으로 되었습니다.');
        } else {
            message.error('데이터 에러');
        }
    };

    const updateReCoveryEmail = async () => {
        if (!accountDetail) {
            message.error('데이터 에러');
            return;
        }
        const response = await repository.updateRecoveryByEmail({
            email: accountDetail.email,
            recoveryEmail: emailGroupTextValue.recoveryEmail  === '' ? accountDetail.recoveryEmail : emailGroupTextValue.recoveryEmail,
            recoveryEmailPass: emailGroupTextValue.recoveryEmailPass === '' ? accountDetail.recoveryEmailPass : emailGroupTextValue.recoveryEmailPass,
        });
        if (response) {
            message.success('리커버리 메일 변경이 성공적으로 되었습니다.');
        } else {
            message.error('데이터 에러');
        }
    };

    const updateAbuserState = async (state: string) => {
        if (accountDetail === undefined || accountDetail?.abuserId === undefined) {
            return;
        }
        if (accountDetail.accountStatus === state) {
            return;
        } else {
            const response = await repository.updateAbuserStatus(accountDetail.abuserId, state);
            if (response) {
                message.success(`${state}로 상태 변경되었습니다.`);
            } else {
                message.error('변경 실패');
            }
        }
    };

    const updateInstaPassword = async () => {
        if (!accountDetail) {
            message.error('데이터 에러');
            return;
        }
        if (instaPasswordTextValue === '') {
            return;
        }
        const response = await repository.updateInstaPassword(accountDetail.abuserId, {
            password: instaPasswordTextValue
        });
        if (response) {
            message.success('비밀번호가 변경되었습니다.');
        } else {
            message.error('변경 실패');
        }
    };

    const updateCookie = async () => {
        if (!accountDetail || cookieTextValue === '') {
            message.error('데이터 에러');
            return;
        }
        const parsedCookie = JSON.parse(cookieTextValue.trim());
        const response = await repository.updateSession(accountDetail.abuserId, {cookies: JSON.stringify(parsedCookie)});
        if (response) {
            message.success('쿠키정보 변경되었습니다.');
        } else {
            message.error('변경 실패');
        }
    };

    const updateMemo = async () => {
        if (!accountDetail) {
            message.error('데이터 에러');
            return;
        }
        const response = await repository.updateMemo(accountDetail.abuserId, memoTextValue);
        if (response) {
            message.success('메모정보 변경되었습니다.');
        } else {
            message.error('변경 실패');
        }
    };

    const getHistory = async () => {
        if (!accountDetail) {
            message.error('데이터 에러');
            return;
        }
        const rs = await repository.getAbusersHistory(accountDetail.abuserId);
        setHistoryList(rs);
    };

    const showDeleteConfirm = (item?: S3Object) => {
        if (accountDetail?.abuserId === undefined) {
            return;
        }
        confirm({
            title: item ? '이 사진을 삭제하시겠습니까?' : `${deleteImageList.length}개의 사진을 삭제하시겠습니까?`,
            icon: <ExclamationCircleOutlined />,
            content: item ? <Image
                src={`https://static.staging.among.world/${item.fileUrl}`}
                width={200}
                height={200}
                preview={false}
            /> : '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.updateAbuser(accountDetail.abuserId, {
                            newProgressedImage: [],
                            toRemoveThemePhotoIds: item ? [item.id] : deleteImageList
                        });
                        if (response) {
                            message.success('사진이 삭제되었습니다.');
                        } else {
                            message.error('삭제 실패');
                        }
                        await getAbuserDetail();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const showReassignAbuserNamesConfirm = () => {
        if (accountDetail?.abuserId === undefined) {
            return;
        }
        confirm({
            title: '닉네임을 리셋하시겠습니까?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.resetUserName([accountDetail.abuserId]);
                        if (response) {
                            message.success('닉네임이 변경되었습니다.');
                        } else {
                            message.error('변경 실패');
                        }
                        await getAbuserDetail();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const abuserSettingConfirm = (option: ProgressedSettingOption) => {
        if (accountDetail?.abuserId === undefined) {
            return;
        }

        let payload = {};
        if (option === ProgressedSettingOption.DONE_IMAGES) {
            payload = { isProgressedDoneImages: !accountDetail.isProgressedDoneImages };
        } else if (option === ProgressedSettingOption.PROFILE){
            payload = { isProgressedProfile: !accountDetail.isProgressedProfile };
        } else if (option === ProgressedSettingOption.USERNAME){
            payload = { isProgressedUserName: !accountDetail.isProgressedUsername };
        } else if (option === ProgressedSettingOption.PRIVATE){
            payload = { isProgressedPrivate: !accountDetail.isProgressedPrivate };
        } else if (option === ProgressedSettingOption.BASENAME){
            payload = { isProgressedBasename: !accountDetail.isProgressedBasename };
        } else if (option === ProgressedSettingOption.DESC){
            payload = { isProgressedDesc: !accountDetail.isProgressedDesc };
        } else if (option === ProgressedSettingOption.DELETE_POST){
            payload = { isProgressedDeletePost: !accountDetail.isProgressedDeletePost };
        } else {
            return;
        }

        confirm({
            title: '이 설정을 바꾸시겠습니까?',
            content: <div>{`Progressed${option} => ${JSON.stringify(payload)}`}</div>,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.updateAbuser(accountDetail.abuserId, {
                            newProgressedImage: [],
                            ...payload,
                        });
                        if (response) {
                            message.success('설정이 변경되었습니다.');
                        } else {
                            message.error('변경 실패');
                        }
                        await getAbuserDetail();
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const getAccountStatusType = async () => {
        const response = await repository.getAccountStatusType();
        if (response.length > 0) {
            setAccountStatusType(response);
        }
    };

    useEffect(() => {
        (async () => {
            await getAbuserDetail();
            await getAccountStatusType();
        })();
    }, [ id ]);

    if (accountDetail === undefined) {
        return null;
    }

    if (loading) {
        return <Mask />
    }

    return (
        <>
        <RootContainer>
            <Modal
                open={isDescModalOpen}
                title="자기소개 편집"
                onOk={() => {
                    (async () => {
                        try {
                            await updateDesc();
                            await getAbuserDetail();
                        } catch (error) {
                            message.error(`${error}`);
                        }
                    })();
                    setIsDescModalOpen(false);
                    setDescTextValue('');
                    setCheckBox(false);
                }}
                onCancel={() => {
                    setDescTextValue('');
                    setIsDescModalOpen(false);
                    setCheckBox(false);
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <p>{accountDetail?.desc}</p>
                    <ArrowDownOutlined style={{ margin: 10 }}/>
                    <TextArea
                        value={descTextValue}
                        onChange={e => setDescTextValue(e.target.value)}
                        placeholder="변경할 자기소개를 입력하세요"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={checkBox}
                    />
                    <Checkbox checked={checkBox} onChange={e => setCheckBox(e.target.checked)}>빈칸으로 두기</Checkbox>
                </div>
            </Modal>
            <Modal
                open={isMailModalOpen}
                title="이메일 편집"
                onOk={() => {
                    (async () => {
                        try {
                            await updateReCoveryEmail();
                            await getAbuserDetail();
                        } catch (error) {
                            message.error(`${error}`);
                        }
                    })();
                    setIsMailModalOpen(false);
                    setEmailGroupTextValue({
                        recoveryEmail: '',
                        recoveryEmailPass: ''
                    });
                }}
                onCancel={() => {
                    setIsMailModalOpen(false);
                    setEmailGroupTextValue({
                        recoveryEmail: '',
                        recoveryEmailPass: ''
                    });
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div>이메일: {accountDetail?.email}</div>
                    <div>리커버리 메일: {accountDetail?.recoveryEmail}</div>
                    <div>리커버리 비번: {accountDetail?.recoveryEmailPass}</div>
                    <ArrowDownOutlined style={{ margin: 20 }}/>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                        <div>이메일: {accountDetail?.email}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                        <div>리커버리 메일:</div>
                        <Input
                            value={emailGroupTextValue.recoveryEmail}
                            onChange={e => setEmailGroupTextValue(prev => {
                                return {
                                    ...prev,
                                    recoveryEmail: e.target.value,
                                }
                            })}
                            placeholder="리커버리 메일을 입력하세요"
                            style={{ width: 300, marginLeft: 10 }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div>리커버리 비번:</div>
                        <Input
                            value={emailGroupTextValue.recoveryEmailPass}
                            onChange={e => setEmailGroupTextValue(prev => {
                                return {
                                    ...prev,
                                    recoveryEmailPass: e.target.value,
                                }
                            })}
                            placeholder="리커버리 비번을 입력하세요"
                            style={{ width: 300, marginLeft: 10 }}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                open={isInstaPasswordModalOpen}
                title="인스타 비밀번호 변경"
                onOk={() => {
                    if (instaPasswordTextValue === '') {
                        return;
                    } else {
                        (async () => {
                            try {
                                await updateInstaPassword();
                                await getAbuserDetail();
                            } catch (error) {
                                message.error(`${error}`);
                            }
                        })();
                        setIsInstaPasswordModalOpen(false);
                        setInstaPasswordTextValue('');
                    }
                }}
                onCancel={() => {
                    setInstaPasswordTextValue('');
                    setIsInstaPasswordModalOpen(false);
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <p>{accountDetail?.instaPassword}</p>
                    <ArrowDownOutlined style={{ margin: 10 }}/>
                    <Input
                        value={instaPasswordTextValue}
                        onChange={e => setInstaPasswordTextValue(e.target.value)}
                        placeholder="변경할 비밀번호를 입력하세요"
                    />
                </div>
            </Modal>
            <Modal
                open={isEmailPasswordModalOpen}
                title="이메일 비밀번호 변경"
                onOk={() => {
                    if (mailPasswordTextValue === '') {
                        return;
                    } else {
                        (async () => {
                            try {
                                await updateEmailPassword();
                                await getAbuserDetail();
                            } catch (error) {
                                message.error(`${error}`);
                            }
                        })();
                        setIsEmailPasswordModalOpen(false);
                        setMailPasswordTextValue('');
                    }
                }}
                onCancel={() => {
                    setMailPasswordTextValue('');
                    setIsEmailPasswordModalOpen(false);
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <p>{accountDetail?.emailPassword}</p>
                    <ArrowDownOutlined style={{ margin: 10 }}/>
                    <Input
                        value={mailPasswordTextValue}
                        onChange={e => setMailPasswordTextValue(e.target.value)}
                        placeholder="변경할 비밀번호를 입력하세요"
                    />
                </div>
            </Modal>
            <Modal
                open={isAbuserStateModalOpen}
                title="계정 상태 변경"
                onOk={() => {
                    (async () => {
                        try {
                            await updateAbuserState(abuserStateValue);
                            await getAbuserDetail();
                        } catch (error) {
                            message.error(`${error}`);
                        }
                    })();
                    setIsAbuserStateModalOpen(false);
                }}
                onCancel={() => {
                    setIsAbuserStateModalOpen(false);
                }}
            >
                <Radio.Group onChange={(e) => setAbuserStateValue(e.target.value)} value={abuserStateValue} >
                    <Space direction="vertical">
                        { accountStatusType.length > 0 && accountStatusType.map((d) => (
                            <Radio value={d}>{d}</Radio>
                        ))}
                    </Space>
                </Radio.Group>
            </Modal>
            <Modal
                open={isCookieModalOpen}
                title="쿠키 업데이트"
                onOk={() => {
                    if (cookieTextValue === '') {
                        return;
                    } else {
                        (async () => {
                            try {
                                await updateCookie();
                                await getAbuserDetail();
                            } catch (error) {
                                message.error(`${error}`);
                            }
                        })();
                        setIsCookieModalOpen(false);
                        setCookieTextValue('');
                    }
                }}
                onCancel={() => {
                    setCookieTextValue('');
                    setIsCookieModalOpen(false);
                }}
            >
                <Input
                    value={cookieTextValue}
                    onChange={e => setCookieTextValue(e.target.value)}
                    placeholder="업데이트할 쿠키를 입력해주세요"
                />
            </Modal>
            <Modal
                open={isMemoModalOpen}
                title="쿠키 업데이트"
                onOk={() => {
                    (async () => {
                        try {
                            await updateMemo();
                            await getAbuserDetail();
                        } catch (error) {
                            message.error(`${error}`);
                        }
                    })();
                    setIsMemoModalOpen(false);
                    setMemoTextValue('');
                }}
                onCancel={() => {
                    setMemoTextValue('');
                    setIsMemoModalOpen(false);
                }}
            >
                <div>메모: {accountDetail?.memo}</div>
                <Input
                    value={memoTextValue}
                    onChange={e => setMemoTextValue(e.target.value)}
                    placeholder="업데이트할 메모를 입력해주세요"
                />
            </Modal>
            <Drawer
                title="history (위쪽일수록 최신)"
                placement="right"
                onClose={() => setIsHistoryDrawerOpen(false)}
                open={isHistoryDrawerOpen}
            >
                { historyList.length > 0 ? historyList.map((history, i) => (
                    <div style={{ borderBottom: '1px lightgray solid', padding: '10px 0' }}>
                        <div style={{ fontWeight: 'bold' }}>{`${i + 1}. ${history.changeColumn}`}</div>
                        { Object.entries(history.prevValue).map(entry => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ color: 'gray' }}>{entry[0]}</div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ display: 'flex', flex: 1, paddingRight: 10, flexDirection: 'row',
                                        justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{entry[1]}</div>
                                        <SwapRightOutlined />
                                    </div>
                                    <div style={{ flex: 1 }}>{//@ts-ignore
                                    history.newValue[entry[0]]}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    )) : <div>empty</div>
                }
            </Drawer>
            <SettingDiv>
                <div>
                    <table>
                        { accountDetail.lastLoginedDt && (
                            <LeftInfoBottomDottedTr style={{ borderBottom: '1px lightgray solid' }}>
                                <LeftInfoFirstTd>마지막 로그인</LeftInfoFirstTd>
                                <LeftInfoSecondTd>{moment(accountDetail.lastLoginedDt).format('YYYY년 MM월 DD일 HH시 mm분')}</LeftInfoSecondTd>
                            </LeftInfoBottomDottedTr>
                        )}
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>계정 상태</LeftInfoFirstTd>
                            <LeftInfoSecondTd>
                                { accountDetail.accountStatus }
                                { accountDetail.accountStatus === 'active' && <CheckCircleOutlined style={{ fontSize: 16, color: '#52c41a', marginRight: 5 }} /> }
                                { accountDetail.accountStatus === 'banned' && <CloseCircleOutlined style={{ fontSize: 16, color: '#eb2f96', marginRight: 5 }} /> }
                                { accountDetail.accountStatus === 'preparing' && <DashboardOutlined style={{ fontSize: 16, color: '#F77737', marginRight: 5 }} /> }
                                { accountDetail.accountStatus === 'locked' && <LockOutlined style={{ color: '#F77737', marginRight: 5 }} /> }
                            </LeftInfoSecondTd>
                            <td>
                                <Button
                                    onClick={() => {
                                        setAbuserStateValue(accountDetail.accountStatus);
                                        setIsAbuserStateModalOpen(true);
                                    }}
                                >
                                    <EditOutlined />
                                </Button>
                            </td>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>비공개 계정</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.isPrivateAccount ? 'O' : 'X'}</LeftInfoSecondTd>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>아이디</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.abuserId}</LeftInfoSecondTd>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>인스타 닉네임</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.instaName}</LeftInfoSecondTd>
                            <td>
                                <Button
                                    onClick={() => showReassignAbuserNamesConfirm()}
                                >
                                    <RedoOutlined />
                                </Button>
                            </td>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>인스타 이름</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.baseName}</LeftInfoSecondTd>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>이메일</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.email}</LeftInfoSecondTd>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr style={{ backgroundColor: '#dddddd' }}>
                            <LeftInfoFirstTd>리커버리 메일</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.recoveryEmail}</LeftInfoSecondTd>
                            <td>
                                <Button
                                    onClick={() => setIsMailModalOpen(true)}
                                >
                                    <EditOutlined />
                                </Button>
                            </td>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr style={{ backgroundColor: '#dddddd' }}>
                            <LeftInfoFirstTd>리커버리 비번</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.recoveryEmailPass}</LeftInfoSecondTd>
                            <td></td>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>인스타 비번</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.instaPassword}</LeftInfoSecondTd>
                            <td>
                                <Button
                                    onClick={() => setIsInstaPasswordModalOpen(true)}
                                >
                                    <EditOutlined />
                                </Button>
                            </td>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>이메일 비밀번호</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.emailPassword}</LeftInfoSecondTd>
                            <td>
                                <Button
                                    onClick={() => setIsEmailPasswordModalOpen(true)}
                                >
                                    <EditOutlined />
                                </Button>
                            </td>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>자기소개</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.desc}</LeftInfoSecondTd>
                            <td>
                                <Button
                                    onClick={() => setIsDescModalOpen(true)}
                                >
                                    <EditOutlined />
                                </Button>
                            </td>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>쿠키</LeftInfoFirstTd>
                            <LeftInfoSecondTd></LeftInfoSecondTd>
                            <td>
                                <Button
                                    onClick={() => setIsCookieModalOpen(true)}
                                >
                                    <EditOutlined />
                                </Button>
                            </td>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>메모</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.memo}</LeftInfoSecondTd>
                            <td>
                                <Button
                                    onClick={() => setIsMemoModalOpen(true)}
                                >
                                    <EditOutlined />
                                </Button>
                            </td>
                        </LeftInfoBottomDottedTr>
                        <LeftInfoBottomDottedTr>
                            <LeftInfoFirstTd>에이징</LeftInfoFirstTd>
                            <LeftInfoSecondTd>{accountDetail?.agingCnt}</LeftInfoSecondTd>
                            <td>
                                <Button
                                    onClick={() => {
                                        const answer = window.prompt('agingCnt를 입력해주세요.', `${accountDetail?.agingCnt}`);
                                        if (answer) {
                                            (async () => {
                                                await repository.updateAgingCnt(accountDetail?.abuserId, parseInt(answer));
                                                await getAbuserDetail();
                                                message.success('agingCnt 업데이트에 성공하였습니다.');
                                            })();
                                        } else {
                                            message.error('agingCnt 업데이트에 실패하였습니다.');
                                        }
                                    }}
                                >
                                    <EditOutlined />
                                </Button>
                            </td>
                        </LeftInfoBottomDottedTr>
                    </table>
                </div>
                <SettingToggleContainer>
                    <table>
                        <BottomDottedTr>
                            <ToggleLeftContainer>사진을 다 업로드 하면, isProgressedDoneImages</ToggleLeftContainer>
                            <ToggleRightContainer>
                                <Switch
                                    checked={accountDetail?.isProgressedDoneImages ?? false}
                                    onClick={() => abuserSettingConfirm(ProgressedSettingOption.DONE_IMAGES)}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </ToggleRightContainer>
                        </BottomDottedTr>
                        <BottomDottedTr>
                            <ToggleLeftContainer>프로필 사진 업로드시, isProgressedProfile</ToggleLeftContainer>
                            <ToggleRightContainer>
                                <Switch
                                    checked={accountDetail?.isProgressedProfile ?? false}
                                    onClick={() => abuserSettingConfirm(ProgressedSettingOption.PROFILE)}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </ToggleRightContainer>
                        </BottomDottedTr>
                        <BottomDottedTr>
                            <ToggleLeftContainer>이름이 셋업이 완료 되면, isProgressedUsername</ToggleLeftContainer>
                            <ToggleRightContainer>
                                <Switch
                                    checked={accountDetail?.isProgressedUsername ?? false}
                                    onClick={() => abuserSettingConfirm(ProgressedSettingOption.USERNAME)}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </ToggleRightContainer>
                        </BottomDottedTr>
                        <BottomDottedTr>
                            <ToggleLeftContainer>비공개 처리 셋업이 완료 되면, isProgressedPrivate</ToggleLeftContainer>
                            <ToggleRightContainer>
                                <Switch
                                    checked={accountDetail?.isProgressedPrivate ?? false}
                                    onClick={() => abuserSettingConfirm(ProgressedSettingOption.PRIVATE)}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </ToggleRightContainer>
                        </BottomDottedTr>
                        <BottomDottedTr>
                            <ToggleLeftContainer>사람 이름 처리 셋업이 완료 되면, isProgressedBasename</ToggleLeftContainer>
                            <ToggleRightContainer>
                                <Switch
                                    checked={accountDetail?.isProgressedBasename ?? false}
                                    onClick={() => abuserSettingConfirm(ProgressedSettingOption.BASENAME)}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </ToggleRightContainer>
                        </BottomDottedTr>
                        <BottomDottedTr>
                            <ToggleLeftContainer>자기소개 셋업이 완료 되면, isProgressedDesc</ToggleLeftContainer>
                            <ToggleRightContainer>
                                <Switch
                                    checked={accountDetail?.isProgressedDesc ?? false}
                                    onClick={() => abuserSettingConfirm(ProgressedSettingOption.DESC)}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </ToggleRightContainer>
                        </BottomDottedTr>
                        <BottomDottedTr>
                            <ToggleLeftContainer>첫번째 게시물이 삭제, isProgressedDeletePost</ToggleLeftContainer>
                            <ToggleRightContainer>
                                <Switch
                                    checked={accountDetail?.isProgressedDeletePost ?? false}
                                    onClick={() => abuserSettingConfirm(ProgressedSettingOption.DELETE_POST)}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </ToggleRightContainer>
                        </BottomDottedTr>
                    </table>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                        <Button
                            onClick={() => {
                                window.open(`https://www.instagram.com/${accountDetail.instaName}`, '_blank', 'noreferrer noopener');
                            }}
                        >인스타 들어가기</Button>
                        <Button
                            onClick={() => {
                                (async () => {
                                    try {
                                        await getHistory();
                                    } catch (err) {
                                        message.error(`${err}`);
                                    }
                                })();
                                setIsHistoryDrawerOpen(true);
                            }}
                        >변경내역</Button>
                    </div>
                </SettingToggleContainer>
            </SettingDiv>
            <div style={{ marginTop: 50, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 10 }}>
                <Image.PreviewGroup>
                <button onClick={async () => {
                    if (window.confirm('프로필 사진을 변경합니다.')) {
                        const res = await repository.reasignAbuserProfile(accountDetail?.abuserId);
                        await getAbuserDetail();
                    }
                }}>
                    <ImageWrapper>
                        { accountDetail?.profileImage
                            ? (
                                <ProfileImage
                                    src={`https://static.staging.among.world/${accountDetail?.profileImage.fileUrl}`}
                                />
                            )
                            : (
                                <div style={{
                                    background: '#c8c8c8',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 999,
                                }} />
                            )
                        }
                    </ImageWrapper>
                </button>
                { accountDetail?.personPhotos && accountDetail.personPhotos.map(item => (
                    <ImageWrapper>
                        <HumanImage
                            key={item.id}
                            src={`https://static.staging.among.world/${item.fileUrl}`}
                            alt='person-img'
                            onClick={() => {
                                // if (accountDetail.accountStatus !== 'locked') {
                                //     return;
                                // }

                                if (window.confirm('다시는 이 사진을 볼 수 없습니다.')) {
                                    (async () => {
                                        try {
                                            await repository.lockPersonPhoto(item.personPhotoId);
                                            await getAbuserDetail();
                                            await getAccountStatusType();
                                            message.success('사진을 지우는데 성공하였습니다.');
                                        } catch (err) {
                                            message.error('사진을 지우는데 실패하였습니다.');
                                        }
                                    })();
                                }
                            }}
                        />
                        { accountDetail.progressedImages.findIndex(d => d === item.id) !== -1 &&
                            <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 100 }}>
                                <CheckCircleOutlined style={{ color: 'red', fontSize: 50 }}/>
                            </div>
                        }
                    </ImageWrapper>
                ))}
                </Image.PreviewGroup>
            </div>
            <Button
                onClick={() => {
                    setDeleteImageList([]);
                    setImageSelectMode(prev => !prev);
                }}
                style={{ marginTop: 50, backgroundColor: 'darkred', color: 'white' }}
            >
                { imageSelectMode ? '취소' :  '이미지 선택 삭제 모드'}
            </Button>
            <div style={{ marginTop: 20, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 10, backgroundColor: imageSelectMode ? 'darkgray' : 'white' }}>
                <Image.PreviewGroup>
                { accountDetail?.themePhotos && accountDetail.themePhotos.map(item => (
                    <ImageWrapper>
                        <DefaultImage
                            key={item.id}
                            src={`https://static.staging.among.world/${item.fileUrl}`}
                            onClick={() => {
                                if (accountDetail.progressedImages.findIndex(d => d === item.id) === -1) {
                                    if (imageSelectMode) {
                                        manageImageList(item.id);
                                    } else {
                                        showDeleteConfirm(item);
                                    }
                                }
                            }}
                            selected={ imageSelectMode && deleteImageList.findIndex(list => list === item.id) !== -1 }
                        />
                        { accountDetail.progressedImages.findIndex(d => d === item.id) !== -1 &&
                            <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 100 }}>
                                <CheckCircleOutlined style={{ color: 'red', fontSize: 50 }}/>
                            </div>
                        }
                    </ImageWrapper>
                ))}
                </Image.PreviewGroup>
            </div>
            { deleteImageList.length > 0 && (
                <Button
                    onClick={() => {
                        showDeleteConfirm();
                        setImageSelectMode(false);
                        setDeleteImageList([]);
                    }}
                    style={{ marginTop: 40, marginBottom: 80, backgroundColor: 'blueviolet' }}
                >
                    {`${deleteImageList.length}개 지우기`}
                </Button>
            )}
        </RootContainer>
        </>
    );
};

export default AccountDetail;
