import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import MaUTable from '@mui/material/Table';
import { AbuserDto, DashboardAbuserDto } from '../../apiTypes';
import repository from '../../repository';
import Mask from '../../components/mask';
import { Descriptions, Drawer, Empty, Input, List, message, Modal, Select } from 'antd';
import moment from 'moment';
import { ArrowDownOutlined, ArrowUpOutlined, CheckCircleOutlined, CloseCircleOutlined, DashboardOutlined, LockOutlined, UserAddOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FixedSizeList } from 'react-window';
import getScrollbarWidth from '../../helper/getScrollbarWidth';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Flex from '../../components/flex';
import { isMobileOnly } from 'react-device-detect';
import styled from '@emotion/styled';
import AccountDetail from '../accountDetail';
import InfiniteLoader from 'react-window-infinite-loader';

const ROW_SIZE = 90;

const PRIVATE_ACCOUNT = {
    private: 'O',
    notPrivate: 'X',
};

const Test = styled.div<{ index?: number }>`
.MuiTableRow-root {
    transition: 2s all ease;
}

${props => props.index && `
    .MuiTableRow-root:nth-child(5) {
        background-color: #c5c5c5;
    }
`};
`;

const StyledListItem = styled(List.Item)`
cursor: pointer;
transition: all 0.2s ease;

:hover {
    background-color: #f1f1f1;
}
:active {
    background-color: #f1f1f1;
}
`;

//최댓값은 제외, 최솟값은 포함
function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min)) + min;
};

// @ts-ignore
function Table({ columns, data, getAccountList, isNextAccountLoading, hasNextAccount, currentStatusType }) {
    const defaultColumn = useMemo(() => ({
        width: 200,
    }), []);

    const listRef = useRef<FixedSizeList>(null);
    const [ index, setIndex ] = useState<number>();
    const [ drawerOpen, setDrawerOpen ] = useState<boolean>(false);
    const [ searchParams, setSearchParams ] = useSearchParams();

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        getTableBodyProps,
        totalColumnsWidth,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    const RenderRow = React.useCallback(({ index, style }: { index: number, style: any }) => {
        const row = rows[index];
        prepareRow(row);

        return (
            <TableRow
                {...row.getRowProps({
                    style,
                })}
            >
                {row.cells.map(cell => {
                    if (
                        cell.column.id === 'id' ||
                        // cell.column.id === 'instaName' ||
                        cell.column.id === 'email' ||
                        cell.column.id === 'instaPassword' ||
                        cell.column.id === 'instaId'
                    ) {
                        return (
                            <CopyToClipboard
                                text={cell.value}
                                onCopy={(text, result) => {
                                    if (result) {
                                        message.success(`${cell.value}`);
                                    } else {
                                        message.error(`${cell.value}`);
                                    }
                                }}
                            >
                                <TableCell
                                    {...cell.getCellProps()}
                                    style={{
                                        cursor: 'pointer',
                                        // @ts-ignore
                                        width: cell.column.originalWidth,
                                    }}
                                >
                                    {cell.render('Cell')}
                                </TableCell>
                            </CopyToClipboard>
                        )
                    }

                    return (
                        <TableCell
                            {...cell.getCellProps()}
                            style={{
                                cursor: 'pointer',
                                // @ts-ignore
                                width: cell.column.originalWidth,
                            }}
                        >
                            { cell.value === 'active' && <CheckCircleOutlined style={{ fontSize: 16, color: '#52c41a', marginRight: 5 }} /> }
                            { cell.value === 'banned' && <CloseCircleOutlined style={{ fontSize: 16, color: '#eb2f96', marginRight: 5 }} /> }
                            { cell.value === 'preparing' && <DashboardOutlined style={{ fontSize: 16, color: '#F77737', marginRight: 5 }} /> }
                            { cell.value === 'locked' && <LockOutlined style={{ color: '#F77737', marginRight: 5 }} /> }
                            { cell.column.id === 'numFollowers' && <UserAddOutlined style={{ color: '#0095f6', marginRight: 5 }} /> }
                            { cell.column.id === 'index' ? index + 1 : cell.render('Cell') }
                        </TableCell>
                    )
                })}
            </TableRow>
        );
    }, [ prepareRow, rows ]);

    const scrollBarSize = useMemo(() => getScrollbarWidth(), []);

    useEffect(() => {
        if (index) {
            setTimeout(() => {
                setIndex(undefined);
            }, 500);
        }
    }, [ index ]);

    useEffect(() => {
        const accountId = searchParams.get('id');
        if (accountId) {
            setDrawerOpen(true);
        }
    }, [ searchParams ]);

    return (
        <>
            <MaUTable
                {...getTableProps()}
                stickyHeader
            >
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // @ts-ignore
                                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <div>
                                        {column.render('Filter')}
                                    </div>
                                    <span>
                                        {/* @ts-ignore */}
                                        {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <Test index={index}>
                    <TableBody {...getTableBodyProps()}>
                    <InfiniteLoader
                        // @ts-ignore
                        ref={listRef}
                        isItemLoaded={index => !hasNextAccount || index < data.length}
                        itemCount={hasNextAccount ? data.length + 1 : data.length}
                        loadMoreItems={() => {
                            getAccountList();
                        }}
                    >
                        {({ onItemsRendered, ref }) => {
                            return (
                                <FixedSizeList
                                    ref={ref}
                                    height={window.innerHeight - ROW_SIZE}
                                    itemCount={rows.length}
                                    itemSize={ROW_SIZE}
                                    width={totalColumnsWidth + scrollBarSize}
                                    onItemsRendered={onItemsRendered}
                                >
                                    {RenderRow}
                                </FixedSizeList>
                            )
                        }}
                    </InfiniteLoader>
                    </TableBody>
                </Test>
            </MaUTable>
            <Drawer
                width={isMobileOnly ? '100vw' : 800}
                title='계정 상세'
                placement='right'
                closable={true}
                onClose={() => {
                    setDrawerOpen(false);
                    setSearchParams(prev => {
                        prev.delete('id');
                        return prev;
                    });
                }}
                open={drawerOpen}
            >
                <AccountDetail />
            </Drawer>
        </>
    );
};

function BannedList() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ bannedList, setBannedList ] = useState<Array<DashboardAbuserDto>>([]);
    const [ currentStatusType, setCurrentStatusType ] = useState<string>('none');
    const [ isNextAccountLoading, setIsNextAccountLoading ] = useState<boolean>(false);
    const [ hasNextAccount, setHasNextAccount ] = useState<boolean>(true);

    const columns = React.useMemo(() => [
        {
            Header: 'index',
            accessor: 'index',
            Filter: () => {},
            width: 50,
        },
        {
            Header: 'memo',
            accessor: 'memo',
            Filter: () => {},
            width: 150,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <Input.TextArea
                            value={cell.row.values.memo || 'Empty'}
                            style={{ resize: 'none' }}
                            onClick={() => {
                                const answer = window.prompt('메모를 작성해주세요.', cell.row.values.memo);
                                if (answer) {
                                    (async () => {
                                        await repository.updateMemo(cell.row.values.id, answer);
                                        message.success('메모 업데이트에 성공하였습니다.');
                                        await getAccountList();
                                    })();
                                } else {
                                    message.error('메모 업데이트에 실패하였습니다.');
                                }
                            }}
                        />
                    </div>
                );
            },
        },
        {
            Header: '계정 생성일',
            accessor: 'createdAt',
            Filter: () => {},
            width: 150,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        {moment(cell.row.values.createdAt).format('YYYY/MM/DD HH:mm')}
                    </div>
                );
            },
        },
        {
            Header: '마지막 로그인 일자',
            accessor: 'lastLoginedDt',
            Filter: () => {},
            width: 150,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        {moment(cell.row.values.lastLoginedDt).format('YYYY/MM/DD HH:mm')}
                    </div>
                );
            },
        },
        {
            Header: 'id',
            accessor: 'id',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '처음 닉네임',
            accessor: 'instaId',
            Filter: () => {},
            width: 250,
        },
        {
            Header: '닉네임',
            accessor: 'instaName',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <CopyToClipboard
                            text={cell.row.values.instaName}
                            onCopy={(text, result) => {
                                if (result) {
                                    message.success(`${cell.row.values.instaName}`);
                                } else {
                                    message.error(`${cell.row.values.instaName}`);
                                }
                            }}
                        >
                            <p>
                                {cell.row.values.instaName}
                            </p>
                        </CopyToClipboard>
                        <Link to={`/account/account-list?id=${cell.row.values.id}`}>
                            상세 페이지
                        </Link>
                    </div>
                );
            },
        },
        {
            Header: '이메일',
            accessor: 'email',
            Filter: () => {},
            width: 250,
        },
        {
            Header: 'insta P/W',
            accessor: 'instaPassword',
            Filter: () => {},
        },
        {
            Header: '팔로워',
            accessor: 'numFollowers',
            Filter: () => {},
            width: 100,
        },
        {
            Header: 'aging',
            accessor: 'agingCnt',
            Filter: () => {},
            width: 70,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        {cell.row.values.agingCnt} / 10
                    </div>
                );
            },
        },
        {
            Header: '비공개 계정 여부',
            accessor: 'isPrivateAccount',
            Filter: () => {},
            width: 100,
        },
    ], []);

    const getAccountList = async (id?: string, createdAt?: string, status: string = 'none', refresh?: boolean) => {
        setIsNextAccountLoading(true);
        const bannedList = await repository.getRecentBannedAbuserList();
        bannedList.sort((a, b) => moment(b.lastLoginedDt).diff(a.lastLoginedDt));

        // @ts-ignore
        setBannedList(bannedList.map((listItem, i) => {
            return {
                index: i + 1,
                id: listItem.id,
                instaId: listItem.instaId,
                instaName: listItem.instaName,
                email: listItem.email,
                instaPassword: listItem.instaPassword,
                // lastLoginedDt: moment(listItem.lastLoginedDt).format('YYYY년 MM월 DD일 HH시 mm분'),
                lastLoginedDt: listItem.lastLoginedDt,
                setupMeta: listItem.setupMeta,
                // @ts-ignore
                isPrivateAccount: (listItem.isPrivateAccount === true || listItem.isPrivateAccount === PRIVATE_ACCOUNT.private)
                    ? PRIVATE_ACCOUNT.private
                    : PRIVATE_ACCOUNT.notPrivate,
                memo: listItem.memo,
                createdAt: listItem.createdAt,
                // @ts-ignore
                agingCnt: listItem.agingCnt,
            };
        }));
        setIsNextAccountLoading(false);
    };

    const delta = useMemo(() => {
        const today = moment();
        const startFor24Hours = today.clone().subtract(1, 'day');
        const startFor7Days = today.clone().subtract(7, 'days');
        const startFor30Days = today.clone().subtract(30, 'days');
        const itemsFor24Hours = bannedList.filter(item => moment(item.lastLoginedDt).isBetween(startFor24Hours, today, null, '[]')).length;
        const itemsFor7Days = bannedList.filter(item => moment(item.lastLoginedDt).isBetween(startFor7Days, today, null, '[]')).length;
        const itemsFor30Days = bannedList.filter(item => moment(item.lastLoginedDt).isBetween(startFor30Days, today, null, '[]')).length;
        const itemsChangeFor24Hours = itemsFor24Hours - bannedList.filter(item => moment(item.lastLoginedDt).isBetween(startFor24Hours.clone().subtract(1, 'day'), today.clone().subtract(1, 'day'), null, '[]')).length;
        const itemsChangeFor7Days = itemsFor7Days - bannedList.filter(item => moment(item.lastLoginedDt).isBetween(startFor7Days.clone().subtract(1, 'day'), today.clone().subtract(1, 'day'), null, '[]')).length;
        const itemsChangeFor30Days = itemsFor30Days - bannedList.filter(item => moment(item.lastLoginedDt).isBetween(startFor30Days.clone().subtract(1, 'day'), today.clone().subtract(1, 'day'), null, '[]')).length;

        return {
            day: {
                count: itemsFor24Hours,
                delta: itemsChangeFor24Hours,
            },
            week: {
                count: itemsFor7Days,
                delta: itemsChangeFor7Days,
            },
            month: {
                count: itemsFor30Days,
                delta: itemsChangeFor30Days,
            },
        };
    }, [ bannedList ]);

    useEffect(() => {
        getAccountList();
    }, []);

    const getColor = (delta: number) => {
        if (delta > 0) {
            return '#00dd00';
        }
        if (delta < 0) {
            return '#E1306C';
        }
        return '#c8c8c8';
    }

    if (loading) {
        return <Mask />
    }

    return (
        <>
            <div>
                <Flex style={{ maxWidth: isMobileOnly ? window.innerWidth : 800, padding: '0 60px', gap: 10, justifyContent: 'space-evenly' }}>
                    <div style={{ padding: 10 }}>
                        <div>오늘</div>
                        <Flex>
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>{delta.day.count}</div>
                            (
                            <Flex style={{ color: getColor(delta.day.delta), fontWeight: 'bold' }}>
                                { delta.day.delta > 0
                                    ? <ArrowUpOutlined style={{ color: getColor(delta.day.delta) }} />
                                    : delta.day.delta < 0
                                        ? <ArrowDownOutlined style={{ color: getColor(delta.day.delta) }} />
                                        : '·'
                                }
                                <div style={{ marginLeft: 2 }}>{delta.day.delta > 0 ? `+${delta.day.delta}` : delta.day.delta}</div>
                            </Flex>
                            )
                        </Flex>
                    </div>
                    <div style={{ padding: 10 }}>
                        <div>지난 7일</div>
                        <Flex>
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>{delta.week.count}</div>
                            (
                            <Flex style={{ color: getColor(delta.week.delta), fontWeight: 'bold' }}>
                                { delta.week.delta > 0
                                    ? <ArrowUpOutlined style={{ color: getColor(delta.week.delta) }} />
                                    : delta.week.delta < 0
                                        ? <ArrowDownOutlined style={{ color: getColor(delta.week.delta) }} />
                                        : '·'
                                }
                                <div style={{ marginLeft: 2 }}>{delta.week.delta > 0 ? `+${delta.week.delta}` : delta.week.delta}</div>
                            </Flex>
                            )
                        </Flex>
                    </div>
                    <div style={{ padding: 10 }}>
                        <div>지난 30일</div>
                        <Flex>
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>{delta.month.count}</div>
                            (
                            <Flex style={{ color: getColor(delta.month.delta), fontWeight: 'bold' }}>
                                { delta.month.delta > 0
                                    ? <ArrowUpOutlined style={{ color: getColor(delta.month.delta) }} />
                                    : delta.month.delta < 0
                                        ? <ArrowDownOutlined style={{ color: getColor(delta.month.delta) }} />
                                        : '·'
                                }
                                <div style={{ marginLeft: 2 }}>{delta.month.delta > 0 ? `+${delta.month.delta}` : delta.month.delta}</div>
                            </Flex>
                            )
                        </Flex>
                    </div>
                </Flex>
                { bannedList.length === 0
                    ? <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Empty /></div>
                    : <Table
                        columns={columns}
                        data={bannedList}
                        getAccountList={getAccountList}
                        isNextAccountLoading={isNextAccountLoading}
                        hasNextAccount={hasNextAccount}
                        currentStatusType={currentStatusType}
                    />
                }
            </div>
        </>
    );
};

export default BannedList;
