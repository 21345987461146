import { Button, message, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import Mask from '../../components/mask';
import { useNavigate } from 'react-router-dom';
import repository from '../../repository';
import { authStore } from '../../authStore';
import { CreateStaffDto } from '../../apiTypes';

interface LoginField {
    email: string;
    password: string;
};

const Login = () => {
    const [ form ] = Form.useForm<{ email: string, password: string }>();
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ joinModalOpen, setJoinModalOpen ] = useState<boolean>(false);
    const [ joinModalValue, setJoinModalValue ] = useState<CreateStaffDto>({
        email: '',
        name: '',
        password: '',
        secret: ''
    });
    const navigate = useNavigate();

    if (authStore.token !== null) {
        navigate('/', { replace: true });
    }

    const onFinish = async (values: LoginField) => {
        setLoading(true);
        try {
            const token = await repository.login(values.email, values.password);
            authStore.setToken(token);
            form.resetFields();
        } catch (err) {
            console.log(err);
            message.error('이메일 또는 비밀번호를 확인해주세요.');
        } finally {
            setLoading(false);
        }
    };

    const createStaff = async (payload: CreateStaffDto) => {
        if (payload.email === '' || payload.password === '' || payload.secret === '') {
            message.error('모두 입력해주세요.');
            return;
        }
        try {
            const rs = await repository.createStaff(payload);
            if (rs) {
                message.success('가입이 완료되었습니다.');
                setJoinModalOpen(false);
                setJoinModalValue({
                    email: '',
                    name: '',
                    password: '',
                    secret: ''
                });
            }
        } catch (err) {
            message.error(`${err}`);
        };
    };

    return (
        <div style={{ padding: '50px 20px' }}>
            { loading && <Mask /> }
            <Modal
                open={joinModalOpen}
                title='STAFF 추가'
                onCancel={() => {
                    setJoinModalOpen(false);
                    setJoinModalValue({
                        email: '',
                        name: '',
                        password: '',
                        secret: ''
                    });
                }}
                onOk={() => {
                    (async () => {
                        await createStaff(joinModalValue);
                    })();
                }}
            >
                <div>email :</div>
                <Input
                    value={joinModalValue.email}
                    onChange={e => setJoinModalValue(prev => ({
                        ...prev,
                        email: e.target.value,
                    }))}
                    type='email'
                />
                <div>이름 :</div>
                <Input
                    value={joinModalValue.name}
                    onChange={e => setJoinModalValue(prev => ({
                        ...prev,
                        name: e.target.value,
                    }))}
                    type='text'
                />
                <div>password :</div>
                <Input
                    value={joinModalValue.password}
                    onChange={e => setJoinModalValue(prev => ({
                        ...prev,
                        password: e.target.value,
                    }))}
                    type='password'
                />
                <div>code :</div>
                <Input
                    value={joinModalValue.secret}
                    onChange={e => setJoinModalValue(prev => ({
                        ...prev,
                        secret: e.target.value,
                    }))}
                    type='text'
                />
            </Modal>
            <Form
                form={form}
                name='login'
                onFinish={onFinish}
                autoComplete='off'
            >
                <Form.Item
                    label='email'
                    name='email'
                    rules={[
                        {
                            required: true,
                            message: '이메일을 입력해주세요.',
                        },
                    ]}
                    style={{ marginBottom: 50 }}
                >
                    <Input type='email' />
                </Form.Item>
                <Form.Item
                    label='password'
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: '비밀번호를 입력해주세요.',
                        },
                    ]}
                    style={{ marginBottom: 50 }}
                >
                    <Input type='password' />
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button
                        type='dashed'
                        onClick={() => { setJoinModalOpen(true) }}
                        style={{ marginRight: 20 }}
                    >
                        STAFF 생성
                    </Button>
                    <Button type='primary' htmlType='submit'>
                        로그인
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;