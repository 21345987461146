import { Modal, Radio, message } from 'antd';
import { observer } from 'mobx-react';
import priorityModalStore from '../stores/priorityModalStore';
import repository from '../repository';

enum OrderTaskPriorityType {
    // 긴급
    URGENT = '999',
    // 처리 우선순위 높음
    HIGH = '2',
    // 처리 우선순위 보통
    NORMAL = '1',
    // 처리 우선순위 낮음
    LOW = '0',
};

interface IPriorityModal {
    refresh: (orderTaskId: string) => Promise<void>;
};

const PriorityModal: React.FC<IPriorityModal> = observer((props) => {
    return (
        <Modal
            open={priorityModalStore.modalOpen}
            title='우선순위 설정'
            onOk={async () => {
                try {
                    if (priorityModalStore.priorityModalValue.priority === null) {
                        return;
                    }

                    await repository.updateOrderTaskPriority(
                        priorityModalStore.priorityModalValue.orderTaskId,
                        priorityModalStore.priorityModalValue.priority,
                    );
                    message.success('우선순위 변경에 성공하였습니다.');
                    await props.refresh(priorityModalStore.priorityModalValue.orderTaskId);
                    priorityModalStore.handleModalOpen(false);
                } catch (err) {
                    message.error('우선순위 변경에 실패하였습니다.');
                    priorityModalStore.handleModalOpen(false);
                }
            }}
            onCancel={() => {
                priorityModalStore.handleModalOpen(false);
                priorityModalStore.handlePriorityModalValue({
                    orderTaskId: '',
                    priority: null,
                });
            }}
            confirmLoading={false}
        >
            <Radio.Group
                options={Object.entries(OrderTaskPriorityType).map(([ key, value ]) => {
                    return {
                        label: key,
                        value: value,
                    };
                })}
                onChange={e => {
                    priorityModalStore.handlePriorityModalValue({
                        ...priorityModalStore.priorityModalValue,
                        priority: e.target.value,
                    });
                }}
                value={priorityModalStore.priorityModalValue.priority}
                optionType='button'
            />
        </Modal>
    );
});

export default PriorityModal;