import React, { useEffect, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { CreateOrderMemoDto, OrderMemoDto, OrderProgressDto } from '../../apiTypes';
import repository from '../../repository';
import Mask from '../../components/mask';
import { Button, Descriptions, Empty, Input, message, Modal, Radio, Select, Statistic } from 'antd';
import { SyncOutlined, LockOutlined, SendOutlined, UserAddOutlined, HeartOutlined, PlayCircleOutlined, PauseCircleOutlined, CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import Flex from '../../components/flex';
import { OrderTable, OrderTaskPriorityType } from '../../helper/orderStatusTable';


function CompleteOrderStatus() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ orderList, setOrderList ] = useState<Array<OrderProgressDto>>([]);
    const [ editCountModalOpen, setEditCountModalOpen ] = useState<boolean>(false);
    const [ editCountInitialModalValue, setEditCountInitialModalValue ] = useState<{
        orderTaskId: string;
        progressCnt: number;
        totalCnt: number;
        remainScheduleCnt: number;
        targetId: string;
        targetUrl: string;
    }>({
        orderTaskId: '',
        progressCnt: 0,
        totalCnt: 0,
        remainScheduleCnt: 0,
        targetId: '',
        targetUrl: '',
    });
    const [ editCountModalValue, setEditCountModalValue ] = useState<{
        displayingCnt: number;
        totalCnt: number;
    }>({
        displayingCnt: 0,
        totalCnt: 0,
    });
    const [ priorityModalOpen, setPriorityModalOpen ] = useState<boolean>(false);
    const [ priorityModalValue, setPriorityModalValue ] = useState<{
        orderTaskId: string;
        priority: OrderTaskPriorityType | null;
    }>({
        orderTaskId: '',
        priority: null,
    });

    const [ editOrderModalOpen, setEditOrderModalOpen ] = useState<boolean>(false);
    const [ editOrderModalInitialValues, setEditOrderModalInitialValues ] = useState<{
        targetId?: string;
        targetUrl?: string;
    }>({
        targetId: undefined,
        targetUrl: undefined,
    });
    const [ editOrderModalValue, setEditOrderModalValue ] = useState<{
        orderTaskId: string;
        targetId?: string;
        targetUrl?: string;
    }>({
        orderTaskId: '',
        targetId: undefined,
        targetUrl: undefined,
    });
    const [ editLoading, setEditLoading ] = useState<boolean>(false);
    const [ memoModalOpen, setMemoModalOpen ] = useState<boolean>(false);
    const [ memoModalValue, setMemoModalValue ] = useState<Array<OrderMemoDto>>([]);
    const [ editMemoModalValue, setEditMemoModalValue ] = useState<CreateOrderMemoDto>({
        orderId: '',
        content: {
            text: '',
            date: '',
        }
    });

    const getMemoOrderId = async (orderId: string) => {
        try {
            const rs = await repository.getOrderMemo(orderId);
            if (rs.length > 0) {
                setMemoModalValue(rs);
            } else {
                setMemoModalValue([]);
            }
        } catch (err) {
            message.error('메모를 불러오지 못했습니다.');
        }
    };

    const addMemoOrderId = async (orderId: string) => {
        try {
            const rs = await repository.postAddOrderMemo(orderId, {
                orderId: editMemoModalValue.orderId,
                content: {
                    text: editMemoModalValue.content.text,
                    date: moment().format('YYYY-MM-DD'),
                }
                
            });
            if (rs) {
                message.success('메모가 업데이트되었습니다.');
                setEditMemoModalValue(prev => ({
                    ...prev,
                    content: {
                        text: '',
                        date: '',
                    }
                }));
            }
        } catch (err) {
            message.error('메모를 업데이트하지 못했습니다.');
        }
    };

    const deleteMemoId = async (orderId: string, memoId: string) => {
        try {
            const rs = await repository.deleteOrderMemo(orderId, memoId);
            if (rs) {
                message.success('메모가 삭제되었습니다.');
            }
        } catch (err) {
            message.error('메모를 삭제하지 못했습니다.');
        }
    };

    const editProgressCount = async () => {
        if (!editCountModalValue || !editCountInitialModalValue.orderTaskId
            || !editCountModalValue.totalCnt || !editCountModalValue.displayingCnt) {
            message.error('값에 문제가 있습니다.');
            return false;
        }
        if (editCountModalValue.totalCnt < editCountModalValue.displayingCnt) {
            message.error('실제 갯수가 보이는 갯수보다 커야합니다.');
            return false;
        }
        if ((editCountInitialModalValue.progressCnt + editCountInitialModalValue.remainScheduleCnt) > editCountModalValue.totalCnt) {
            message.error(`실제 갯수가 ${editCountInitialModalValue.progressCnt + editCountInitialModalValue.remainScheduleCnt}보다 더 커야합니다.`);
            return false;
        }
        try {
            const rs = await repository.updateProgressCount(editCountInitialModalValue.orderTaskId, editCountModalValue);
            console.log('rs', rs);
            if (rs) {
                message.success('수정했습니다.');
                return true;
            }
        } catch (err) {
            message.error(`${err}`);
            return false;
        }
    };

    const columns = React.useMemo(() => [
        {
            Header: '',
            accessor: 'priority',
            Filter: () => {},
            width: 50,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <button
                        style={{ height: '100%' }}
                        onClick={() => {
                            setPriorityModalOpen(true);
                            setPriorityModalValue({
                                orderTaskId: cell.row.values.orderTaskId,
                                priority: cell.value,
                            });
                        }}
                    >
                        { parseInt(cell.value) >= parseInt(OrderTaskPriorityType.URGENT) && <div style={{ width: 20, height: 4, borderRadius: 1, backgroundColor: 'rgb(235, 82, 85)', marginBottom: 1 }} /> }
                        { parseInt(cell.value) >= parseInt(OrderTaskPriorityType.HIGH) && <div style={{ width: 20, height: 4, borderRadius: 1, backgroundColor: 'rgb(242, 168, 59)', marginBottom: 1 }} /> }
                        { parseInt(cell.value) >= parseInt(OrderTaskPriorityType.NORMAL) && <div style={{ width: 20, height: 4, borderRadius: 1, backgroundColor: 'rgb(141, 186, 56)', marginBottom: 1 }} /> }
                        { cell.value === OrderTaskPriorityType.LOW && <div style={{ width: 20, height: 4, borderRadius: 1, backgroundColor: '#e9e9e9', marginBottom: 1 }} /> }
                    </button>
                );
            },
        },
        {
            Header: '주문시간',
            accessor: 'orderDate',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <div>
                            {cell.row.values.orderDate}
                        </div>
                        <button
                            onClick={() => {
                                (async () => {
                                    const rs = await repository.getInformationLetterLink(cell.row.values.orderId);
                                    if (rs !== '') {
                                        window.open(rs, '_blank');
                                    }
                                })();
                            }}
                            style={{ color: '#1890FF' }}
                        >알림장</button>
                    </div>
                );
            },
            width: 120,
        },
        {
            Header: '작업 상태',
            accessor: 'taskStatus',
            Filter: (props: any) => {
                const { column: { setFilter } } = props;
                return (
                    <Select
                        onClick={e => e.stopPropagation()}
                        onChange={setFilter}
                        defaultValue='all'
                        style={{ width: 100 }}
                    >
                        <Select.Option value=''>all</Select.Option>
                        <Select.Option value='finished'>finished</Select.Option>
                        <Select.Option value='impossible'>impossible</Select.Option>
                        <Select.Option value='temporary_paused'>temporary_paused</Select.Option>
                        <Select.Option value='locked'>locked</Select.Option>
                        <Select.Option value='not_finished'>not finished</Select.Option>
                        <Select.Option value='wait_external_vendor'>wait external vendor</Select.Option>
                    </Select>
                )
            },
            width: 130,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div onClick={async () => {
                        const { productName, targetId } = cell.row.values;

                        if (cell.value === 'not_finished' || cell.value === 'locked') {
                            if (window.confirm(`${targetId}님의 [${productName}] 주문을 중지하시겠습니까?`)) {
                                try {
                                    await repository.pauseOrderTask(cell.row.values.orderTaskId);
                                    message.success('주문 중지에 성공하였습니다.');
                                    await getOrderList();
                                } catch (err) {
                                    message.error('주문 중지에 실패하였습니다.');
                                }
                            }
                        } else if (cell.value === 'temporary_paused') {
                            if (window.confirm(`${targetId}님의 [${productName}] 주문을 재개하시겠습니까?`)) {
                                try {
                                    await repository.resumeOrderTask(cell.row.values.orderTaskId);
                                    message.success('주문 재개에 성공하였습니다.');
                                    await getOrderList();
                                } catch (err) {
                                    message.error('주문 재개에 실패하였습니다.');
                                }
                            }
                        }
                    }}>
                        <Flex column>
                            <Flex>
                                { cell.value === 'locked' && <LockOutlined style={{ color: '#F77737', marginRight: 5 }} /> }
                                { cell.value === 'finished' && <SendOutlined style={{ color: '#405DE6', marginRight: 5 }} /> }
                                <span>{cell.row.values.taskStatus}</span>
                            </Flex>
                            { (cell.value === 'locked' || cell.value === 'not_finished') && cell.row.values.lockedDt !== null &&
                                <Flex>
                                    <SyncOutlined spin />
                                    <Statistic.Countdown
                                        title=''
                                        format='mm:ss'
                                        valueStyle={{
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            marginLeft: 5,
                                        }}
                                        value={Date.now() + 10 * 60 * 1000 - moment().diff(moment(cell.row.values.lockedDt))}
                                    />
                                </Flex>
                            }
                        </Flex>
                    </div>
                );
            },
        },
        {
            Header: '진행 상황',
            accessor: 'totalCnt',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any>; }) => {
                const {
                    progressCnt,
                    totalCnt,
                    remainScheduleCnt,
                    taskStatus,
                } = cell.row.values;

                return (
                    <div>
                        <div>{`${progressCnt} / ${totalCnt}`}</div>
                        { taskStatus === 'locked' &&
                            <div style={{ color: '#F77737', fontWeight: 'bold', fontSize: 16 }}>
                                { remainScheduleCnt > 0
                                    ? (
                                        <>
                                            <PlayCircleOutlined style={{ marginRight: 4, color: '#F77737' }} />
                                            <span>{remainScheduleCnt > 0 ? remainScheduleCnt : '-'}</span>
                                        </>
                                    )
                                    : (
                                        <PauseCircleOutlined style={{ marginRight: 4, color: '#c2c2c2' }} />
                                    )
                                }
                            </div>
                        }
                    </div>
                );
            },
            width: 130,
        },
        {
            Header: '작업 종류',
            accessor: 'taskType',
            Filter: (props: any) => {
                const { column: { setFilter } } = props;
                const toKorean = (value: string) => {
                    switch (value) {
                        case 'like':
                            return '좋아요';
                        case 'like_reels':
                            return '릴스';
                        case 'follow':
                            return '팔로우';
                    };
                };

                return (
                    <Select
                        onClick={e => e.stopPropagation()}
                        onChange={value => setFilter(toKorean(value))}
                        defaultValue='all'
                        style={{ width: 80 }}
                    >
                        <Select.Option value=''>all</Select.Option>
                        <Select.Option value='follow'>follow</Select.Option>
                        <Select.Option value='like'>like</Select.Option>
                        <Select.Option value='like_reels'>reels</Select.Option>
                    </Select>
                )
            },
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        { cell.value === '좋아요' && <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} /> }
                        { cell.value === '릴스' && <img src='/assets/instagram-reel.svg' width={14} height={14} style={{ marginRight: 5 }} /> }
                        { cell.value === '팔로우' && <UserAddOutlined style={{ color: '#0095f6', marginRight: 5 }} /> }
                        { cell.value }
                    </div>
                );
            },
            width: 100,
        },
        {
            Header: '메모',
            accessor: '',
            Filter: () => {},
            width: 80,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <Button
                        onClick={() => {
                            (async () => {
                                setEditMemoModalValue({
                                    orderId: cell.row.values.orderId,
                                    content: {
                                        text: '',
                                        date: '',
                                    }
                                });
                                await getMemoOrderId(cell.row.values.orderId);
                            })();
                            setMemoModalOpen(true);
                        }}
                    >메모</Button>
                )
            },
        },
        {
            Header: '상품명',
            accessor: 'productName',
            Filter: () => {},
            width: 150,
        },
        {
            Header: 'id',
            accessor: 'targetId',
            Filter: () => {},
            width: 150,
        },
        {
            Header: 'url',
            accessor: 'targetUrl',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '업데이트 시간',
            accessor: 'updatedAt',
            Filter: () => {},
            width: 120,
        },
        {
            Header: '주문 번호',
            accessor: 'orderNo',
            Filter: () => {},
            width: 150,
        },
        {
            Header: 'lockedDt',
            accessor: 'lockedDt',
            Filter: () => {},
            Cell: () => {},
            width: 100,
        },
        {
            Header: '주문 취소',
            accessor: 'orderId',
            Filter: () => {},
            width: 100,
        },
        {
            Header: '수정',
            accessor: 'edit',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <Button
                        onClick={() => {
                            setEditOrderModalValue({
                                orderTaskId: cell.row.values.orderTaskId,
                                targetId: cell.row.values.targetId,
                                targetUrl: cell.row.values.targetUrl,
                            });
                            setEditOrderModalOpen(true);
                            setEditOrderModalInitialValues({
                                targetId: cell.row.values.targetId,
                                targetUrl: cell.row.values.targetUrl,
                            });
                        }}
                    >
                        수정
                    </Button>
                );
            },
            width: 70,
        },
        {
            Header: '작업량수정',
            accessor: 'orderTaskId',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                if (cell.row.values.taskStatus === 'locked' || cell.row.values.taskStatus === 'wait_external_vendor'
                    || cell.row.values.taskStatus === 'not_finished' || cell.row.values.taskStatus === 'temporary_paused') {
                    return (
                        <Button
                            onClick={() => {
                                setEditCountModalValue({
                                    displayingCnt: 0,
                                    totalCnt: cell.row.values.totalCnt,
                                });
                                setEditCountInitialModalValue({
                                    orderTaskId: cell.value,
                                    totalCnt: cell.row.values.totalCnt,
                                    remainScheduleCnt: cell.row.values.remainScheduleCnt,
                                    progressCnt: cell.row.values.progressCnt,
                                    targetId: cell.row.values.targetId,
                                    targetUrl: cell.row.values.targetUrl ?? '',
                                });
                                setEditCountModalOpen(true);
                            }}
                        >
                            작업량수정
                        </Button>
                    );
                }
            },
            width: 120,
        },
        {
            Header: '강제마감',
            accessor: 'finishOrderTask',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                if (cell.row.values.taskStatus !== 'finished') {
                    return (
                        <Button
                            onClick={async () => {
                                try {
                                    if (window.confirm('정말 마감하시겠습니까?')) {
                                        await repository.finishOrderTask(cell.row.values.orderTaskId);
                                        message.success('강제 마감이 완료되었습니다.');
                                    }
                                } catch (err) {
                                    message.error(JSON.stringify(err));
                                }
                            }}
                        >
                            강제마감
                        </Button>
                    );
                }
            },
            width: 120,
        },
        {
            Header: '',
            accessor: 'progressCnt',
            Filter: () => {},
            Cell: () => {},
        },
        {
            Header: '',
            accessor: 'remainScheduleCnt',
            Filter: () => {},
            Cell: () => {},
        },
    ], []);

    const getOrderList = async () => {
        setLoading(true);
        const _orderList = await repository.getCompletionOrderProgress();
        console.log('_orderList', _orderList);
        // @ts-ignore
        // 시간 내림차순으로 정렬
        setOrderList(_orderList.sort((a, b) => {
            const aUpdatedAt = new Date(a.updatedAt).getTime();
            const bUpdatedAt = new Date(b.updatedAt).getTime();
            return aUpdatedAt > bUpdatedAt ? -1 : aUpdatedAt < bUpdatedAt ? 1 : 0;
        }).map((listItem, i) => {
            let text = '';
            switch (listItem.taskType) {
                case 'follow':
                    text = '팔로우';
                    break;
                case 'like':
                    text = '좋아요';
                    break;
                case 'like_reels':
                    text = '릴스';
                    break;
                default:
                    text = '(?)';
            };

            return {
                orderNo: listItem.orderNo,
                orderDate: moment(listItem.orderDate).format('MM/DD (HH:mm)'),
                updatedAt: moment(listItem.updatedAt).format('MM/DD (HH:mm)'),
                productName: listItem.productName,
                progressCnt: listItem.progressCnt,
                remainScheduleCnt: listItem.remainScheduleCnt,
                targetId: listItem.targetId,
                targetUrl: listItem.targetUrl,
                taskStatus: listItem.taskStatus,
                taskType: text,
                totalCnt: listItem.totalCnt,
                orderId: listItem.id,
                edit: null,
                orderTaskId: listItem.orderTaskId,
                finishOrderTask: null,
                lockedDt: listItem.lockedDt,
                priority: listItem.priority,
            };
        }));
        setLoading(false);
    };
    useEffect(() => {
        (async () => {
            await getOrderList();
        })();
    }, []);

    if (loading) {
        return <Mask />
    }

    return (
        <div>
            <Modal
                open={priorityModalOpen}
                title='우선순위 설정'
                onOk={async () => {
                    try {
                        if (priorityModalValue.priority === null) {
                            return;
                        }

                        await repository.updateOrderTaskPriority(
                            priorityModalValue.orderTaskId,
                            priorityModalValue.priority,
                        );
                        message.success('우선순위 변경에 성공하였습니다.');
                        setPriorityModalOpen(false);
                        await getOrderList();
                    } catch (err) {
                        message.error('우선순위 변경에 실패하였습니다.');
                        setPriorityModalOpen(false);
                    }
                }}
                onCancel={() => {
                    setPriorityModalOpen(false);
                    setPriorityModalValue({
                        orderTaskId: '',
                        priority: null,
                    });
                }}
                confirmLoading={false}
            >
                <Radio.Group
                    options={Object.entries(OrderTaskPriorityType).map(([ key, value ]) => {
                        return {
                            label: key,
                            value: value,
                        };
                    })}
                    onChange={e => {
                        setPriorityModalValue(prev => {
                            return {
                                ...prev,
                                priority: e.target.value,
                            };
                        })
                    }}
                    value={priorityModalValue.priority}
                    optionType='button'
                />
            </Modal>
            <Modal
                open={editCountModalOpen}
                title='작업량 수정'
                onOk={() => {
                    ( async () => {
                        try {
                            const rs = await editProgressCount();
                            if (rs === true) {
                                await getOrderList();
                                setEditCountModalValue({
                                    displayingCnt: 0,
                                    totalCnt: 0,
                                });
                                setEditCountInitialModalValue({
                                    orderTaskId: '',
                                    totalCnt: 0,
                                    remainScheduleCnt: 0,
                                    progressCnt: 0,
                                    targetId: '',
                                    targetUrl: '',
                                });
                                setEditCountModalOpen(false);
                            }
                        } catch (error) {
                            message.error(`${error}`);
                        }
                    })();
                }}
                onCancel={() => {
                    setEditCountModalValue({
                        displayingCnt: 0,
                        totalCnt: 0,
                    });
                    setEditCountInitialModalValue({
                        orderTaskId: '',
                        totalCnt: 0,
                        remainScheduleCnt: 0,
                        progressCnt: 0,
                        targetId: '',
                        targetUrl: '',
                    });
                    setEditCountModalOpen(false);
                }}
            >
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10, padding: '0 10px', borderRight: '1px lightgray solid' }}>
                            <div>{`${editCountInitialModalValue.progressCnt} / ${editCountInitialModalValue.totalCnt}`}</div>
                            <div style={{ color: '#F77737', fontWeight: 'bold', fontSize: 16, textAlign: 'center' }}>
                                { editCountInitialModalValue.remainScheduleCnt > 0
                                    ? (
                                        <>
                                            <PlayCircleOutlined style={{ marginRight: 4, color: '#F77737' }} />
                                            <span>{editCountInitialModalValue.remainScheduleCnt > 0 ? editCountInitialModalValue.remainScheduleCnt : '-'}</span>
                                        </>
                                    )
                                    : (
                                        <PauseCircleOutlined style={{ marginRight: 4, color: '#c2c2c2' }} />
                                    )
                                }
                            </div>
                        </div>
                        <div>
                            <div>아이디 : {editCountInitialModalValue.targetId}
                                <a
                                    target='_blank'
                                    href={`https://www.instagram.com/${editCountInitialModalValue.targetId}`}
                                    rel="noreferrer noopener"
                                    style={{ marginLeft: 10 }}
                                >
                                링크</a>
                            </div>
                            { editCountInitialModalValue.targetUrl !== '' &&
                                <div>URL : {editCountInitialModalValue.targetUrl}
                                    <a
                                        target='_blank'
                                        href={`https://www.instagram.com/reel/${editCountInitialModalValue.targetUrl}`}
                                        rel="noreferrer noopener"
                                        style={{ marginLeft: 10 }}
                                    >
                                    링크</a>
                                </div>
                            }
                        </div>
                    </div>
                    <div>보여야 할 갯수</div>
                    <Input
                        placeholder='displayingCnt'
                        value={editCountModalValue.displayingCnt}
                        onChange={e => {
                            setEditCountModalValue(prev => ({
                                displayingCnt: parseInt(e.target.value),
                                totalCnt: Math.ceil(parseInt(e.target.value) * 1.05),
                            }));
                        }}
                        type='number'
                    />
                    <div>실제로 찍을 갯수</div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div>{editCountInitialModalValue.totalCnt}</div>
                        <ArrowRightOutlined style={{ margin: '0 10px' }} />
                        <Input
                            placeholder='totalCnt'
                            value={editCountModalValue.totalCnt}
                            onChange={e => {
                                setEditCountModalValue(prev => ({
                                    ...prev,
                                    totalCnt: parseInt(e.target.value),
                                }));
                            }}
                            type='number'
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                open={editOrderModalOpen}
                title='주문 정보 수정'
                onCancel={() => {
                    setEditOrderModalValue({
                        orderTaskId: '',
                        targetId: '',
                        targetUrl: '',
                    });
                    setEditOrderModalInitialValues({
                        targetId: '',
                        targetUrl: '',
                    });
                    setEditOrderModalOpen(false);
                }}
                footer={[
                    <Button
                        key='back'
                        onClick={() => {
                            setEditOrderModalValue({
                                orderTaskId: '',
                                targetId: '',
                                targetUrl: '',
                            });
                            setEditOrderModalInitialValues({
                                targetId: '',
                                targetUrl: '',
                            });
                            setEditOrderModalOpen(false);
                        }}
                    >
                        취소
                    </Button>,
                    <Button
                        key='submit'
                        type='primary'
                        loading={editLoading}
                        onClick={() => {
                            (async () => {
                                try {
                                    setEditLoading(true);
                                    if (!editOrderModalValue.orderTaskId) {
                                        throw new Error('주문정보 수정을 실패하였습니다.');
                                    }

                                    let payload: any = {};
                                    if (editOrderModalValue.targetId) {
                                        payload.targetId = editOrderModalValue.targetId;
                                    }
                                    if (editOrderModalValue.targetUrl) {
                                        payload.targetUrl = editOrderModalValue.targetUrl;
                                    }
                                    await repository.updateOrder(editOrderModalValue.orderTaskId, payload);
                                    message.success('주문정보 수정이 완료되었습니다.');
                                    setEditLoading(false);
                                    setEditOrderModalOpen(false);
                                } catch (error) {
                                    setEditLoading(false);
                                    setEditOrderModalOpen(false);
                                    message.error(`${error}`);
                                }
                            })();
                            setEditOrderModalValue({
                                orderTaskId: '',
                                targetId: '',
                                targetUrl: '',
                            });
                            setEditOrderModalInitialValues({
                                targetId: '',
                                targetUrl: '',
                            });
                        }}
                    >
                        저장
                    </Button>,
                ]}
            >
                <div>
                    { editOrderModalInitialValues.targetId &&
                        <Input
                            addonBefore={<div>targetId</div>}
                            placeholder='targetId'
                            value={editOrderModalValue.targetId}
                            onChange={e => {
                                setEditOrderModalValue(prev => ({
                                    ...prev,
                                    targetId: e.target.value,
                                }));
                            }}
                        />
                    }
                    <div style={{ height: 10 }} />
                    { editOrderModalInitialValues.targetUrl &&
                        <Input
                            addonBefore={<div>targetUrl</div>}
                            placeholder='targetUrl'
                            value={editOrderModalValue.targetUrl}
                            onChange={e => {
                                setEditOrderModalValue(prev => ({
                                    ...prev,
                                    targetUrl: e.target.value,
                                }));
                            }}
                        />
                    }
                    { editOrderModalInitialValues.targetId &&
                        <>
                            <div style={{ fontWeight: 'bold', marginBottom: 5, marginTop: 30 }}>targetId</div>
                            <Descriptions bordered>
                                <Descriptions.Item label='수정 전'>{editOrderModalInitialValues.targetId}</Descriptions.Item>
                                <Descriptions.Item label='수정 후'>
                                    <div>{editOrderModalValue.targetId}</div>
                                    <a
                                        href={`https://www.instagram.com/${editOrderModalValue.targetId}`}
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        링크
                                    </a>
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    }
                    { editOrderModalInitialValues.targetUrl &&
                        <>
                            <div style={{ fontWeight: 'bold', marginBottom: 5 }}>targetUrl</div>
                            <Descriptions bordered>
                                <Descriptions.Item label='수정 전'>{editOrderModalInitialValues.targetUrl}</Descriptions.Item>
                                <Descriptions.Item label='수정 후'>
                                    <div>{editOrderModalValue.targetUrl}</div>
                                    <a
                                        href={`https://www.instagram.com/p/${editOrderModalValue.targetUrl}`}
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        링크
                                    </a>
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    }
                </div>
            </Modal>
            <Modal
                open={memoModalOpen}
                title='메모'
                onCancel={() => {
                    setMemoModalValue([]);
                    setEditMemoModalValue({
                        orderId: '',
                        content: {
                            text: '',
                            date: '',
                        }
                    });
                    setMemoModalOpen(false);
                }}
                onOk={() => {
                    setMemoModalOpen(false);
                }}
            >
                <div>
                    { memoModalValue.length > 0
                        ? memoModalValue.map((d, i) => 
                            <div
                                style={{ display: 'flex', flexDirection: 'row', padding: '0 5px' }}
                            >
                                <div style={{ marginRight: 5, color: 'gray' }}>{d.content.date}</div>
                                <div style={{ flex: 1 }}>
                                    {d.content.text}
                                </div>
                                <button
                                    onClick={() => {
                                        if (window.confirm('삭제하시겠습니까?')) {
                                            ( async () => {
                                                await deleteMemoId(d.orderId, d.id);
                                                await getMemoOrderId(d.orderId);
                                            })();
                                        }
                                    }}
                                >
                                    <CloseOutlined style={{ color: 'red' }} />
                                </button>
                            </div>
                        ) : <div style={{ textAlign: 'center', color: 'red' }}>메모가 없습니다...</div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <Input
                            addonBefore={<div>새 메모</div>}
                            placeholder='메모를 입력하세요'
                            value={editMemoModalValue.content.text}
                            onChange={e => {
                                setEditMemoModalValue(prev => ({
                                    ...prev,
                                    content: {
                                        text: e.target.value,
                                        date: prev.content.date,
                                    }
                                }));
                            }}
                        />
                        <Button
                            onClick={() => {
                                if (editMemoModalValue.content.text === '') {
                                    return;
                                }
                                (async () => {
                                    await addMemoOrderId(editMemoModalValue.orderId);
                                    await getMemoOrderId(editMemoModalValue.orderId);
                                })();
                            }}
                        >+</Button>
                    </div>
                </div>
            </Modal>

            { orderList.length === 0
                ? <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Empty /></div>
                : <OrderTable
                    columns={columns}
                    data={orderList}
                    refresh={getOrderList}
                />
            }
        </div>
    );
};

export default CompleteOrderStatus;