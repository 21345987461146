import { action, computed, makeObservable, observable } from 'mobx';
import { AutopilotParameter, MagicValueType } from '../apiTypes';
import repository from '../repository';

interface MagicValues {
    WORKER_COUNT: number;
    SCHEDULE_MAX_FOLLOW_WORKFLOW: number;
    DIVIDE_RATE: number;
    SCHEDULE_MAX_WORKFLOW: number;
    SETUP_WORKER_SPEED: number;
    AGING_WEIGHT: number;
    SETTING_WEIGHT: number;
    UNLOCK_WEIGHT: number;
    SCHEDULE_LIMIT_LIKE_GROUP1: number;
    SCHEDULE_LIMIT_LIKE_GROUP2: number;
    SCHEDULE_LIMIT_LIKE_GROUP3: number;
    SCHEDULE_LIMIT_LIKE_DEFAULT: number;
    SCHEDULE_LIMIT_FOLLOW_GROUP1: number;
    SCHEDULE_LIMIT_FOLLOW_GROUP2: number;
    SCHEDULE_LIMIT_FOLLOW_GROUP3: number;
    SCHEDULE_LIMIT_FOLLOW_DEFAULT: number;
};

interface ISettingStore {
    magicValues: MagicValues | null;
    canAutoPilot: boolean;
    autoPilotParameter: AutopilotParameter | null;
};

const INITIAL_STATES: ISettingStore = {
    magicValues: null,
    canAutoPilot: false,
    autoPilotParameter: null,
};

class SettingStore implements ISettingStore {
    private _magicValues = INITIAL_STATES.magicValues;
    private _canAutoPilot = INITIAL_STATES.canAutoPilot;
    private _autoPilotParameter = INITIAL_STATES.autoPilotParameter;

    constructor() {
        makeObservable<
            SettingStore,
            '_magicValues' | '_canAutoPilot' | '_autoPilotParameter'
        >(this, {
            _magicValues: observable,
            _canAutoPilot: observable,
            _autoPilotParameter: observable,
            magicValues: computed,
            canAutoPilot: computed,
            autoPilotParameter: computed,
            initialize: action.bound,
            updateMagicValues: action.bound,
            updateAutoPilot: action.bound,
        });
    };

    public get magicValues() {
        return this._magicValues;
    };

    public get canAutoPilot() {
        return this._canAutoPilot;
    };

    public get autoPilotParameter() {
        return this._autoPilotParameter;
    };

    public async initialize() {
        const _magicValues = await repository.getMagicValues();
        Object.keys(_magicValues).forEach(v => {
            // @ts-ignore
            const parsed = parseFloat(_magicValues[v]);
            // @ts-ignore
            _magicValues[v] = isNaN(parsed) ? null : parsed;
        });
        // @ts-ignore
        this._magicValues = _magicValues;

        const canAutoPilot = await repository.getCanAutoPilot();
        this._canAutoPilot = canAutoPilot;

        const autoPilotParameter = await repository.getAutopilotParameter();
        this._autoPilotParameter = autoPilotParameter;
    };

    public async updateMagicValues(updateType: MagicValueType, value: string) {
        const updatedValue = await repository.updateMagicValues(updateType, `${value}`);
        if (this._magicValues) {
            this._magicValues[updateType] = updatedValue;
        } else {
            await this.initialize();
        }
    };

    public async updateAutoPilot(canAutoPilot: boolean) {
        if (canAutoPilot === true) {
            await repository.activateAutoPilot();
        }
        if (canAutoPilot === false) {
            await repository.deactivateAutoPilot();
        }
        await this.initialize();
    };

    public async updateAutoPilotParameters(key: keyof AutopilotParameter, value: any) {
        const autoPilotParameter = await repository.getAutopilotParameter();

        await repository.updateAutopilotParameter({
            ...autoPilotParameter,
            [key]: value,
        });

        await this.initialize();
    };
};

const settingStore = new SettingStore();
export default settingStore;