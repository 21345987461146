import { action, computed, makeObservable, observable } from 'mobx';
import { AutopilotParameter, MagicValueType } from '../apiTypes';
import repository from '../repository';

type EditOrderModalValue = {
    orderTaskId: string;
    targetId?: string;
    targetUrl?: string;
};

type EditOrderModalInitialValue = {
    targetId?: string;
    targetUrl?: string;
};

interface IEditOrderStore {
    editOrderModalOpen: boolean;
    editOrderModalValue: EditOrderModalValue;
    editOrderModalInitialValues: EditOrderModalInitialValue;
};

const INITIAL_STATES: IEditOrderStore = {
    editOrderModalOpen: false,
    editOrderModalValue: {
        orderTaskId: '',
        targetId: undefined,
        targetUrl: undefined,
    },
    editOrderModalInitialValues: {
        targetId: undefined,
        targetUrl: undefined,
    },
};

class EditOrderStore implements IEditOrderStore {
    private _editOrderModalOpen = INITIAL_STATES.editOrderModalOpen;
    private _editOrderModalValue = INITIAL_STATES.editOrderModalValue;
    private _editOrderModalInitialValues = INITIAL_STATES.editOrderModalInitialValues;

    constructor() {
        makeObservable<
            EditOrderStore,
            '_editOrderModalOpen' | '_editOrderModalValue' | '_editOrderModalInitialValues'
        >(this, {
            _editOrderModalOpen: observable,
            _editOrderModalValue: observable,
            _editOrderModalInitialValues: observable,
            editOrderModalOpen: computed,
            editOrderModalValue: computed,
            editOrderModalInitialValues: computed,
            handleEditOrderModalOpen: action.bound,
            handleEditOrderModalValue: action.bound,
            handleEditOrderModalInitialValue: action.bound,
        });
    };

    get editOrderModalOpen() {
        return this._editOrderModalOpen;
    };

    get editOrderModalValue() {
        return this._editOrderModalValue;
    };

    get editOrderModalInitialValues() {
        return this._editOrderModalInitialValues;
    };

    handleEditOrderModalOpen(open: boolean) {
        this._editOrderModalOpen = open;
    };

    handleEditOrderModalValue(value: EditOrderModalValue) {
        this._editOrderModalValue = value;
    };

    handleEditOrderModalInitialValue(value: EditOrderModalInitialValue) {
        this._editOrderModalInitialValues = value;
    };
};

const editOrderStore = new EditOrderStore();
export default editOrderStore;