const getNormalizeIGPath = (postUrl: string): string => {
    try {
        const parsedUrl = new URL(postUrl);
        const match = /[^/]+(?=\/$|$)/.exec(parsedUrl.pathname);
        if (!match || match === undefined || match === null) {
            return '';
        }
        if (match.length < 0) {
            return '';
        }
        return match[0].slice(0, 11);
    } catch (err) {
        console.log('normalizeIGPath err', err);
        return '';
    };
};

export default getNormalizeIGPath;