import { TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import MaUTable from '@mui/material/Table';
import { AccountSetupInfoDto } from '../../apiTypes';
import repository from '../../repository';
import Mask from '../../components/mask';
import { Empty, message } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CircularProgressbar } from 'react-circular-progressbar';
import getScrollbarWidth from '../../helper/getScrollbarWidth';
import { FixedSizeList } from 'react-window';

// @ts-ignore
function Table({ columns, data }) {
    const defaultColumn = useMemo(() => ({
        width: 200,
    }), []);
    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        getTableBodyProps,
        totalColumnsWidth,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    const RenderRow = React.useCallback(({ index, style }: { index: number, style: any }) => {
        const row = rows[index];
        prepareRow(row);

        return (
            <TableRow
                {...row.getRowProps({
                    style,
                })}
            >
                {row.cells.map(cell => {
                    if (
                        cell.column.id === 'id' ||
                        cell.column.id === 'instaName' ||
                        cell.column.id === 'email' ||
                        cell.column.id === 'instaPassword'
                    ) {
                        return (
                            <CopyToClipboard
                                text={cell.value}
                                onCopy={(text, result) => {
                                    if (result) {
                                        message.success(`${cell.value}`);
                                    } else {
                                        message.error(`${cell.value}`);
                                    }
                                }}
                            >
                                <TableCell
                                    {...cell.getCellProps()}
                                    style={{
                                        cursor: 'pointer',
                                        // @ts-ignore
                                        width: cell.column.originalWidth,
                                    }}
                                >
                                    {cell.render('Cell')}
                                </TableCell>
                            </CopyToClipboard>
                        )
                    }

                    return (
                        <TableCell
                            {...cell.getCellProps()}
                            style={{
                                cursor: 'pointer',
                                // @ts-ignore
                                width: cell.column.originalWidth,
                            }}
                        >
                            { cell.column.id === 'index' ? index + 1 : cell.render('Cell') }
                        </TableCell>
                    )
                })}
            </TableRow>
        );
    }, [ prepareRow, rows ]);

    const scrollBarSize = useMemo(() => getScrollbarWidth(), []);

    return (
        <MaUTable
            {...getTableProps()}
            stickyHeader
        >
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // @ts-ignore
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <div>
                                    {column.render('Filter')}
                                </div>
                                <span>
                                    {/* @ts-ignore */}
                                    {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                </span>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                <FixedSizeList
                    height={window.innerHeight - 90}
                    itemCount={rows.length}
                    itemSize={90}
                    width={totalColumnsWidth + scrollBarSize}
                >
                    {RenderRow}
                </FixedSizeList>
            </TableBody>
        </MaUTable>
    );
};

function UploadPost() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ uploadPostList, setUploadPostList ] = useState<Array<AccountSetupInfoDto>>([]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'index',
                accessor: 'index',
                width: 50,
                Filter: () => {},
            },
            {
                Header: '진행 현황',
                accessor: 'progressedImages',
                width: 80,
                Filter: () => {},
                Cell: ({ cell }: { cell: Cell<object, any> }) => {
                    console.log('cell', cell.row.values.progressedImages)
                    return (
                        <div>
                            { cell.row.values.progressedImages.map((image: string, i: number) => {
                                return (
                                    <img
                                        // src={`https://static.staging.among.world/${image}`}
                                    />
                                )
                            })}
                            { `${cell.row.values.progressedImages?.length} / ${cell.row.values.totalImages?.length}` }
                        </div>
                    );
                }
            },
            {
                Header: '',
                accessor: 'totalImages',
                width: 0,
                Filter: () => {},
                Cell: () => <div />,
            },
            {
                Header: 'id',
                accessor: 'id',
                width: 150,
                Filter: () => {},
            },
            {
                Header: '이메일',
                accessor: 'email',
                width: 250,
                Filter: () => {},
            },
            {
                Header: 'insta P/W',
                accessor: 'instaPassword',
                Filter: () => {},
            },
            {
                Header: '닉네임',
                accessor: 'instaName',
                Filter: () => {},
            },
            {
                Header: '기본이름',
                accessor: 'baseName',
                Filter: () => {},
            },
        ],
        []
    );
    useEffect(() => {
        (async () => {
            setLoading(true);
            const _uploadPostList = await repository.getUploadPostList();
            console.log('_uploadPostList', _uploadPostList);
            // @ts-ignore
            setUploadPostList(_uploadPostList.map((listItem, i) => {
                return {
                    index: i + 1,
                    id: listItem.abuserId,
                    email: listItem.email,
                    instaPassword: listItem.instaPassword,
                    instaName: listItem.instaName,
                    baseName: listItem.baseName,
                    isProgressedDoneImages: listItem.isProgressedDoneImages,
                    progressedImages: listItem.progressedImages,
                    totalImages: [...listItem.themePhotos, ...listItem.personPhotos],
                };
            }));
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Mask />
    }

    return (
        <div>
            { uploadPostList.length === 0
                ? <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Empty /></div>
                : (
                    <div>
                        <Table
                            columns={columns}
                            data={uploadPostList}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default UploadPost;
