import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSortBy, useTable, Cell } from 'react-table';
import MaUTable from '@mui/material/Table';
import { AccountSetupInfoDto } from '../../apiTypes';
import repository from '../../repository';
import Mask from '../../components/mask';
import { Empty, Input, message } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import ECharts from 'echarts-for-react';
import { themeName } from '../../helper/echartTheme';
import { Link } from 'react-router-dom';
import moment from 'moment';

const PRIVATE_ACCOUNT = {
    private: 'O',
    notPrivate: 'X',
};

// @ts-ignore
function Table({ columns, data }) {
    const { getTableProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
    );

    return (
        <MaUTable
            style={{ minWidth: 1800 }}
            {...getTableProps()}
            stickyHeader
        >
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // @ts-ignore
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {/* @ts-ignore */}
                                    {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                </span>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <TableRow {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <CopyToClipboard
                                        text={cell.value}
                                        onCopy={(text, result) => {
                                            if (result) {
                                                message.success(`${cell.value}`);
                                            } else {
                                                message.error(`${cell.value}`);
                                            }
                                        }}
                                    >
                                        <TableCell
                                            {...cell.getCellProps()}
                                            style={{
                                                cursor: 'pointer',
                                                // @ts-ignore
                                                width: cell.column.originalWidth,
                                            }}
                                        >
                                            { cell.value === true && <CheckCircleOutlined style={{ fontSize: 16, color: '#52c41a' }} /> }
                                            { cell.value === false && <CloseCircleOutlined style={{ fontSize: 16, color: '#eb2f96' }} /> }
                                            { typeof cell.value !== 'boolean' && cell.render('Cell') }
                                        </TableCell>
                                    </CopyToClipboard>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </MaUTable>
    );
};

function TrainingCenter() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ agingList, setAgingList ] = useState<Array<AccountSetupInfoDto>>([]);

    const getAgingList = async () => {
        setLoading(true);
        const _agingList = (await repository.searchAbusers('accountStatus', 'aging')) ?? [];
            // @ts-ignore
        _agingList.sort((a, b) => b.agingCnt - a.agingCnt);
        // @ts-ignore
        setAgingList(_agingList.map((listItem, i) => {
            return {
                index: i + 1,
                id: listItem.id,
                instaId: listItem.instaId,
                instaName: listItem.instaName,
                email: listItem.email,
                instaPassword: listItem.instaPassword,
                accountStatus: listItem.accountStatus,
                lastLoginedDt: `${moment(listItem.lastLoginedDt).format('YYYY-MM-DD HH:mm')} (${-moment.duration(moment(listItem.lastLoginedDt).diff(moment())).asHours().toFixed()}시간 전)`,
                setupMeta: listItem.setupMeta,
                // @ts-ignore
                isPrivateAccount: (listItem.isPrivateAccount === true || listItem.isPrivateAccount === PRIVATE_ACCOUNT.private)
                    ? PRIVATE_ACCOUNT.private
                    : PRIVATE_ACCOUNT.notPrivate,
                memo: listItem.memo,
                createdAt: listItem.createdAt,
                // @ts-ignore
                agingCnt: listItem.agingCnt,
            };
        }));
        setLoading(false);
    };

    useEffect(() => {
        getAgingList();
    }, []);

    const columns = React.useMemo(() => [
        {
            Header: 'index',
            accessor: 'index',
            width: 50,
        },
        {
            Header: 'memo',
            accessor: 'memo',
            Filter: () => {},
            width: 200,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <Input.TextArea
                            value={cell.row.values.memo || 'Empty'}
                            style={{ resize: 'none' }}
                            onClick={() => {
                                const answer = window.prompt('메모를 작성해주세요.', cell.row.values.memo);
                                if (answer) {
                                    (async () => {
                                        await repository.updateMemo(cell.row.values.id, answer);
                                        message.success('메모 업데이트에 성공하였습니다.');
                                        await getAgingList();
                                    })();
                                } else {
                                    message.error('메모 업데이트에 실패하였습니다.');
                                }
                            }}
                        />
                    </div>
                );
            },
        },
        {
            Header: '훈련 횟수',
            accessor: 'agingCnt',
            Filter: () => {},
            width: 150,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        {cell.row.values.agingCnt} / 10
                    </div>
                );
            },
        },
        {
            Header: '처음 닉네임',
            accessor: 'instaId',
            Filter: () => {},
            width: 100,
        },
        {
            Header: '닉네임',
            accessor: 'instaName',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <CopyToClipboard
                            text={cell.row.values.instaName}
                            onCopy={(text, result) => {
                                if (result) {
                                    message.success(`${cell.row.values.instaName}`);
                                } else {
                                    message.error(`${cell.row.values.instaName}`);
                                }
                            }}
                        >
                            <p>
                                {cell.row.values.instaName}
                            </p>
                        </CopyToClipboard>
                        <Link to={`/account/training?id=${cell.row.values.id}`}>
                            상세 페이지
                        </Link>
                    </div>
                );
            },
            width: 100,
        },
        {
            Header: '마지막 활성화 일자',
            accessor: 'lastLoginedDt',
            Filter: () => {},
            width: 200,
        },
        {
            Header: '비공개 계정 여부',
            accessor: 'isPrivateAccount',
            Filter: () => {},
            width: 200,
        },
        {
            Header: 'id',
            accessor: 'id',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '이메일',
            accessor: 'email',
            Filter: () => {},
            width: 250,
        },
        {
            Header: 'insta P/W',
            accessor: 'instaPassword',
            Filter: () => {},
            width: 250,
        },
    ], []);

    const charts = React.useMemo(() => {
        return [
            agingList.filter(a => a.agingCnt === 0).length,
            agingList.filter(a => a.agingCnt === 1).length,
            agingList.filter(a => a.agingCnt === 2).length,
            agingList.filter(a => a.agingCnt === 3).length,
            agingList.filter(a => a.agingCnt === 4).length,
            agingList.filter(a => a.agingCnt === 5).length,
            agingList.filter(a => a.agingCnt === 6).length,
            agingList.filter(a => a.agingCnt === 7).length,
            agingList.filter(a => a.agingCnt === 8).length,
            agingList.filter(a => a.agingCnt === 9).length,
            agingList.filter(a => a.agingCnt >= 10).length,
        ];
    }, [ agingList ]);

    if (loading) {
        return <Mask />
    }

    return (
        <div>
            { agingList.length === 0
                ? <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Empty /></div>
                : (
                    <>
                        <div>
                            <ECharts
                                option={{
                                    tooltip: {
                                        trigger: 'axis',
                                        axisPointer: {
                                            type: 'shadow',
                                        },
                                    },
                                    xAxis: {
                                        type: 'category',
                                        data: [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ],
                                        axisLine: {
                                            show: true,
                                        },
                                        axisLabel: {
                                            show: true,
                                        },
                                        axisTick: {
                                            show: true,
                                        },
                                    },
                                    yAxis: {
                                        type: 'value',
                                        axisLine: {
                                            show: true,
                                        },
                                        axisLabel: {
                                            show: true,
                                        },
                                        axisTick: {
                                            show: true,
                                        },
                                    },
                                    series: [
                                        {
                                            data: charts,
                                            type: 'bar',
                                        },
                                    ],
                                }}
                                theme={themeName}
                                opts={{
                                    renderer: 'canvas',
                                    width: 'auto',
                                }}
                                style={{ width: 360, marginLeft: 40 }}
                            />
                        </div>
                        <Table
                            columns={columns}
                            data={agingList}
                        />
                    </>
                )
            }
        </div>
    );
};

export default TrainingCenter;