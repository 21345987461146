export const TOKEN_KEY = 'token';

class AuthStore {
    private _token: string | null = null;

    public get token() {
        const _token = localStorage.getItem(TOKEN_KEY);
        if (_token && this._token === null) {
            this.setToken(_token);
        }
        return this._token;
    };

    public setToken(token: string) {
        localStorage.setItem(TOKEN_KEY, token);
        this._token = token;
    };

    public deleteToken() {
        localStorage.removeItem(TOKEN_KEY);
        const _token = localStorage.getItem(TOKEN_KEY);
        this._token = _token;
    };
};

export const authStore = new AuthStore();