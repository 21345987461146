import { CheckCircleFilled, ClockCircleFilled, PauseCircleFilled, QuestionOutlined, StopFilled, StopOutlined, SyncOutlined, WifiOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { ToggleButton } from "@mui/material";
import { Button, message, Modal, Switch, Tooltip } from "antd";
import confirm from "antd/lib/modal/confirm";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import repository from "../../repository";

export enum ProxyStatus {
    HEALTH ='health',
    HALTED = 'halted',
    IP_CHANGING = 'ipChanging',
    SHUTDOWN = 'shutdown',
};

export interface ProxyInfo {
    name: string;
    status: string;
    proxy: {
        proxy_id: string;
        proxy_static_addr: string;
        proxy_api_addr: string;
        proxy_ssh_addr: string;
        proxy_group: string;
    };
    quota: number;
    // info?: {
    //     allocatedIp: string;
    //     createdAt: string;
    //     deletedAt: null
    //     id: string;
    //     isWorking: boolean;
    //     proxyAgentStatus: string;
    //     proxyName: string;
    //     proxyStaticAddr: string;
    //     updatedAt: string;
    // }
};

interface EditProxyInfo {
    production: Array<ProxyInfo>,
    staging: Array<ProxyInfo>,
    prober: Array<ProxyInfo>,
};

enum EditProxyInfoGroup {
    Production = 'production',
    Staging =  'staging',
    Prober = 'prober',
};

const RootContainer = styled.div`
display: flex;
flex-direction: column;
padding: 50px;
@media screen and (max-width: 500px) {
    padding: 50px 20px;
}
`;

const ContextContainer = styled.div`
display: flex;
flex-direction: row;
align-items: flex-end;
flex-wrap: wrap;
@media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
}
`;

const IPContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
@media screen and (max-width: 500px) {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 5px;
}
`;

const IPItemContainer = styled.div`
width: 180px;
@media screen and (max-width: 500px) {
    width: 230px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
`;

const IPAddress = styled.div`
@media screen and (max-width: 500px) {
    width: 180px;
    text-align: left;
}
`;

const TestDiv = styled.div<{ divColor: boolean }>`
border-radius: 5px;
background-color: ${({ divColor }) => divColor ? '#aaffaa' : '#ffffff' };
padding: 5px;
touch-action: none;
border: 1px solid gray;
cursor: move;
user-select: none;
:active {
    border: 1px dashed gray;
    color: gray;
};
:touch {
    border: 1px dashed gray;
    color: gray;
};
`;

const TestFloatDiv = styled.div<{ visible: boolean, x: number, y: number }>`
position: fixed;
z-index: 100;
display: ${({ visible }) => visible ? '' : 'none'};
border-radius: 5px;
padding: 5px;
touch-action: none;
border: 1px solid gray;
left: ${({ x }) => x}px;
top: ${({ y }) => y}px;
background-color: #aaaaaa;
color: white;
user-select: none;
`;

function ProxyControl() {
    const groupBox = useRef<Array<HTMLDivElement>>([]);
    const [ productionList, setProductionList ] = useState<Array<ProxyInfo>>([]);
    const [ stagingList, setStagingList ] = useState<Array<ProxyInfo>>([]);
    const [ proberList, setProberList ] = useState<Array<ProxyInfo>>([]);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ groupEditMode, setGroupEditMode ] = useState<boolean>(false);
    const [ editGroupInitialList, setEditGroupInitialList ] = useState<EditProxyInfo>({
        production: [],
        staging: [],
        prober: [],
    });
    const [ editGroupList, setEditGroupList ] = useState<EditProxyInfo>({
        production: [],
        staging: [],
        prober: [],
    });
    const dragTarget = useRef<any>(null);
    const cloneDragTarget = useRef<any>(null);
    const [ onDrag, setOnDrag ] = useState<boolean>(false);
    const [ dragElementInfo, setDragElementInfo ] = useState<{
        name: string;
        x: number;
        y: number;
    }>({
        name: '',
        x: 0,
        y: 0,
    });

    const updateProductionProxy = async () => {
        setLoading(true);
        try {
            const list = await repository.getProxy();
            console.log('list', list);
            if (list) {
                const entries = Object.entries(list);
                const productList_ = entries.map(entry => (
                    {
                        name: entry[0],
                        // @ts-ignore
                        quota: entry[1]['Quota'],
                        // @ts-ignore
                        proxy: entry[1]['Proxy'],
                        // @ts-ignore
                        status: entry[1]['Status'],
                        // info: promiseResult.find(d => d?.proxyName === entry[0]) ?? undefined,
                    }
                ));
                const sortedProductList_ = productList_.sort((a, b) => {
                    return parseInt(a.name.replace(/[^0-9]/g, '')) > parseInt(b.name.replace(/[^0-9]/g, ''))
                        ?  1 : parseInt(a.name.replace(/[^0-9]/g, '')) < parseInt(b.name.replace(/[^0-9]/g, ''))
                        ? -1 : 0;
                });
                setProductionList(sortedProductList_.filter(d => d.proxy.proxy_group === 'production'));
                setStagingList(sortedProductList_.filter(d => d.proxy.proxy_group === 'staging'));
                setProberList(sortedProductList_.filter(d => d.proxy.proxy_group === 'prober'));
            } else {
                message.error('프로덕션 프록시 불러오기 실패');
            }
        } catch (error) {
            message.error(`${error}`);
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const showShutdownProxyConfirm = (proxyId: string) => {
        confirm({
            title: '프록시를 끄시겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.postShutdownProxy(proxyId);
                        if (response) {
                            message.success(proxyId);
                            await updateProductionProxy();
                        }
                    } catch (error) {
                        console.log('error', error);
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const showHealthCheckProxyConfirm = (proxyInfo: ProxyInfo) => {
        if (proxyInfo.status !== 'health') {
            message.error('status가 health일때 보내주세요!!!!!');
            return;
        }
        confirm({
            title: '헬스 체크를 하겠습니까?',
            content: '',
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const response = await repository.healthCheckProxy(
                            proxyInfo.proxy.proxy_static_addr.split(':')[0],
                            parseInt(proxyInfo.proxy.proxy_static_addr.split(':')[1])
                        );
                        if (response) {
                            console.log('response', response);
                            message.success(`${response}`);
                            // await updateProductionProxy();
                        }
                    } catch (error) {
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const showChangeGroupProxyConfirm = () => {
        if (editGroupInitialList === editGroupList) {
            message.error('변경된 그룹이 없습니다.');
            return;
        }
        const productionChangedList = editGroupList.production.filter(d => 
            editGroupInitialList.production.findIndex(data => data.name === d.name) === -1
        );
        const stagingChangedList = editGroupList.staging.filter(d => 
            editGroupInitialList.staging.findIndex(data => data.name === d.name) === -1
        );
        const proberChangedList = editGroupList.prober.filter(d => 
            editGroupInitialList.prober.findIndex(data => data.name === d.name) === -1
        );
        console.log('productionChangedList, stagingChangedList, proberChangedList', productionChangedList, stagingChangedList, proberChangedList);
        confirm({
            title: `그룹을 변경하시겠습니까? ${productionChangedList.length + stagingChangedList.length + proberChangedList.length ?? 0}개`,
            content:
                <div>
                    {
                        productionChangedList.length > 0
                        ? productionChangedList.map(d => <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1 }}>{d.name}</div>
                                <div>{d.proxy.proxy_group}</div>
                                <div>{'-> Production'}</div>
                            </div>
                        ): <></>
                    }
                    {
                        stagingChangedList.length > 0
                        ? stagingChangedList.map(d => <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1 }}>{d.name}</div>
                                <div>{d.proxy.proxy_group}</div>
                                <div>{'-> Staging'}</div>
                            </div>
                        ): <></>
                    }
                    {
                        proberChangedList.length > 0
                        ? proberChangedList.map(d => <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1 }}>{d.name}</div>
                                <div>{d.proxy.proxy_group}</div>
                                <div>{'-> Prober'}</div>
                            </div>
                        ): <></>
                    }
                </div>,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                (async () => {
                    try {
                        const changedList: Array<{ proxyId:string, group: EditProxyInfoGroup }> = [
                            ...productionChangedList.map(d => { return { proxyId: d.proxy.proxy_id, group: EditProxyInfoGroup.Production }}),
                            ...stagingChangedList.map(d => { return { proxyId: d.proxy.proxy_id, group: EditProxyInfoGroup.Staging }}),
                            ...proberChangedList.map(d => { return { proxyId: d.proxy.proxy_id, group: EditProxyInfoGroup.Prober }})
                        ];
                        console.log('changedList', changedList);
                        const response = (await Promise.all(changedList.map(d => {
                            return repository.updateProxyGroup(d.proxyId, d.group);
                        })));
                        if (response.findIndex(d => d === false) === -1) {
                            message.success('성공적으로 변경했습니다.');
                        } else {
                            console.log('response', response);
                            message.error('실패된 변경이 있습니다.');
                        }
                    } catch (error) {
                        console.log('proxy group 변경 error', error);
                        message.error(`${error}`);
                    }
                })();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const onChangeEditMode = (checked: boolean) => {
        console.log(`switch to ${checked}`);
        if (checked) {
            setEditGroupInitialList({
                production: productionList,
                staging: stagingList,
                prober: proberList,
            });
            setEditGroupList({
                production: productionList,
                staging: stagingList,
                prober: proberList,
            });   
        }
        setGroupEditMode(checked);
    };

    const checkTouchStart = (touchEvent: React.TouchEvent<HTMLDivElement>, data: ProxyInfo) => {
        //@ts-ignore
        console.log('touchEvent', touchEvent.targetTouches[0]);
        //@ts-ignore
        const target = touchEvent.target;
        dragTarget.current = target;
        //@ts-ignore
        cloneDragTarget.current = target.cloneNode(true);
        console.log('touchEvent', touchEvent);
            // 선택한 element가 움직이는 동안 표시할 placeholder element를 생성
        setDragElementInfo({
            //@ts-ignore
            name: touchEvent.target.value,
            //@ts-ignore
            x: touchEvent.targetTouches[0].clientX - target.getBoundingClientRect().left,
            //@ts-ignore
            y: touchEvent.targetTouches[0].clientY - target.getBoundingClientRect().top,
        });
    };

    const checkTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        window.scrollBy(0, 2);
        if (!cloneDragTarget.current) {
            console.log('return');
            return;
        }
        if (!onDrag) {
            console.log('onDrag!');
            setOnDrag(true);
            //@ts-ignore
            dragTarget.current.parentNode.insertBefore(cloneDragTarget.current, dragTarget.current.nextSibling);
            //@ts-ignore
            cloneDragTarget.current.style.height = `${dragTarget.current.getBoundingClientRect().height}px`;
            cloneDragTarget.current.style.width = `${dragTarget.current.getBoundingClientRect().width}px`;
            cloneDragTarget.current.style.backgroundColor = '#aaaaaa';
        }
        dragTarget.current.style.height = `${dragTarget.current.getBoundingClientRect().height}px`;
        dragTarget.current.style.width = `${dragTarget.current.getBoundingClientRect().width}px`;
        dragTarget.current.style.position = 'fixed';
        dragTarget.current.style.top = e.targetTouches[0].clientY - dragElementInfo.y + "px";
        dragTarget.current.style.left = e.targetTouches[0].clientX - dragElementInfo.x + "px";
         // screen move
        // const percent = (e.targetTouches[0].clientY / window.screen.height) * 100;
        // if (percent >= 95) {
        //     window.scrollBy(0, 2);
        // }
        // if (percent <= 20) {
        //     window.scrollBy(0, -2);
        // }
    };

    const checkTouchEnd = (e: React.TouchEvent<HTMLDivElement>, data: ProxyInfo) => {
        if (!onDrag) {
            return;
        }
        console.log('cloneDragTarget.current', cloneDragTarget.current);
        if (cloneDragTarget.current) {
            cloneDragTarget.current.parentNode.removeChild(cloneDragTarget.current);
            cloneDragTarget.current = null;
        }
        console.log('checkTouchEnd', e);
        // dragTarget.current.style.removeProperty('top');
        // dragTarget.current.style.removeProperty('left');
        dragTarget.current.style.removeProperty('height');
        dragTarget.current.style.removeProperty('width');
        dragTarget.current.style.removeProperty('position');
        setDragElementInfo({
            name: '',
            x: 0,
            y: 0,
        });
        setOnDrag(false);
        const prevEditGroup = editGroupList;
        const subtractEditGroup = {
            production: prevEditGroup.production.find(d => d.name === data.name)
                ? prevEditGroup.production.filter(d => d.name !== data.name)
                : prevEditGroup.production,
            staging: prevEditGroup.staging.find(d => d.name === data.name)
                ? prevEditGroup.staging.filter(d => d.name !== data.name)
                : prevEditGroup.staging,
            prober: prevEditGroup.prober.find(d => d.name === data.name)
                ? prevEditGroup.prober.filter(d => d.name !== data.name)
                : prevEditGroup.prober,
        };
        const positionY = e.changedTouches[0].clientY;

        if (positionY >= groupBox.current[0].getBoundingClientRect().top
            && positionY <= groupBox.current[0].getBoundingClientRect().bottom) {
            console.log('1번 박스');
            setEditGroupList({
                ...subtractEditGroup,
                production: [...subtractEditGroup.production, data],
            });
            dragTarget.current = null;
            return;
        }
        if (positionY >= groupBox.current[1].getBoundingClientRect().top
            && positionY <= groupBox.current[1].getBoundingClientRect().bottom) {
            console.log('2번 박스');
            setEditGroupList({
                ...subtractEditGroup,
                staging: [...subtractEditGroup.staging, data],
            });
            dragTarget.current = null;
            return;
        }
        if (positionY >= groupBox.current[2].getBoundingClientRect().top
            && positionY <= groupBox.current[2].getBoundingClientRect().bottom) {
            console.log('3번 박스');
            setEditGroupList({
                ...subtractEditGroup,
                prober: [...subtractEditGroup.prober, data],
            });
            dragTarget.current = null;
            return;
        }
    };

    const checkDragEnd = (dragEvent: React.DragEvent<HTMLDivElement>, data: ProxyInfo ) => {
        console.log('checkDragEnd');
        const prevEditGroup = editGroupList;
        const subtractEditGroup = {
            production: prevEditGroup.production.find(d => d.name === data.name)
                ? prevEditGroup.production.filter(d => d.name !== data.name)
                : prevEditGroup.production,
            staging: prevEditGroup.staging.find(d => d.name === data.name)
                ? prevEditGroup.staging.filter(d => d.name !== data.name)
                : prevEditGroup.staging,
            prober: prevEditGroup.prober.find(d => d.name === data.name)
                ? prevEditGroup.prober.filter(d => d.name !== data.name)
                : prevEditGroup.prober,
        };
        console.log('dragEvent', dragEvent);
        //@ts-ignore
        const positionY = dragEvent.clientY;

        if (positionY >= groupBox.current[0].getBoundingClientRect().top
            && positionY <= groupBox.current[0].getBoundingClientRect().bottom) {
            console.log('1번 박스');
            setEditGroupList({
                ...subtractEditGroup,
                production: [...subtractEditGroup.production, data],
            });
            return;
        }
        if (positionY >= groupBox.current[1].getBoundingClientRect().top
            && positionY <= groupBox.current[1].getBoundingClientRect().bottom) {
            console.log('2번 박스');
            setEditGroupList({
                ...subtractEditGroup,
                staging: [...subtractEditGroup.staging, data],
            });
            return;
        }
        if (positionY >= groupBox.current[2].getBoundingClientRect().top
            && positionY <= groupBox.current[2].getBoundingClientRect().bottom) {
            console.log('3번 박스');
            setEditGroupList({
                ...subtractEditGroup,
                prober: [...subtractEditGroup.prober, data],
            });
            return;
        }
    };

    useEffect(() => {
        ( async () => {
            await updateProductionProxy();
        })();
    }, []);

    useEffect(() => {
        const timerId = setInterval(() => {
            ( async () => {
                await updateProductionProxy();
            })();
            // console.('새로고침');
        }, 10 * 1000);
        return () => clearInterval(timerId);
    }, []);

    return (
        <>
            <RootContainer>
                <Switch
                    checkedChildren="편집중"
                    unCheckedChildren="그룹편집"
                    checked={groupEditMode}
                    onChange={onChangeEditMode}
                />
                { groupEditMode
                    ? (
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <div style={{ borderBottom: '1px solid gray' }}>production</div>
                                <div style={{ backgroundColor: '#dddddd', padding: 20, display: 'grid',
                                        gap: 5, gridTemplateColumns: `repeat(auto-fill, minmax(20%, auto))`,
                                        
                                    }}
                                    ref={e => {
                                        if (e) {
                                            groupBox.current[0] = e
                                        }
                                    }}
                                    onDragOver={e => e.preventDefault()}
                                >
                                    { editGroupList?.production?.map((d) => (
                                        <TestDiv
                                            divColor={editGroupInitialList.production.findIndex(data => data.name === d.name) === -1}
                                            draggable
                                            onDragEnd={(e) => {
                                                checkDragEnd(e, d);
                                            }}
                                            onTouchStart={(e) => {
                                                checkTouchStart(e, d);
                                            }}
                                            onTouchEnd={(e) => {
                                                checkTouchEnd(e, d);
                                            }}
                                            onTouchMove={(e) => {
                                                checkTouchMove(e);
                                            }}
                                        >
                                            {d.name}
                                        </TestDiv>
                                    )) ?? <>없습니다.</>}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <div style={{ borderBottom: '1px solid gray' }}>staging</div>
                                <div style={{ backgroundColor: '#dddddd', padding: 20, display: 'grid',
                                        gap: 5, gridTemplateColumns: `repeat(auto-fill, minmax(20%, auto))`
                                    }}
                                    ref={e => {
                                        if (e) {
                                            groupBox.current[1] = e
                                        }
                                    }}
                                    onDragOver={e => e.preventDefault()}
                                >
                                    { editGroupList?.staging?.map((d) => (
                                        <div
                                            style={{ borderRadius: 5,
                                                backgroundColor: editGroupInitialList.staging.find(data => d.name === data.name) ? '#ffffff' : '#aaffaa',
                                                padding: 5, touchAction: 'none', border: '1px solid gray',
                                            }}
                                            draggable
                                            onDragEnd={(e) => {
                                                // console.log('@@', e.target);
                                                checkDragEnd(e, d);
                                            }}
                                            onTouchStart={(e) => {
                                                checkTouchStart(e, d);
                                            }}
                                            onTouchEnd={(e) => {
                                                checkTouchEnd(e, d);
                                            }}
                                            onTouchMove={(e) => {
                                                checkTouchMove(e);
                                            }}
                                        >
                                            {d.name}
                                        </div>
                                    )) ?? <>없습니다.</>}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <div style={{ borderBottom: '1px solid gray' }}>prober</div>
                                <div style={{ backgroundColor: '#dddddd', padding: 20, display: 'grid',
                                        gap: 5, gridTemplateColumns: `repeat(auto-fill, minmax(20%, auto))`
                                    }}
                                    ref={e => {
                                        if (e) {
                                            groupBox.current[2] = e
                                        }
                                    }}
                                    onDragOver={e => e.preventDefault()}
                                >
                                    { editGroupList?.prober?.map((d) => (
                                        <div
                                            style={{ borderRadius: 5,
                                                backgroundColor: editGroupInitialList.prober.find(data => d.name === data.name) ? '#ffffff' : '#aaffaa',
                                                padding: 5, touchAction: 'none', border: '1px solid gray',
                                            }}
                                            draggable
                                            onDragEnd={(e) => {
                                                // console.log('@@', e.target);
                                                checkDragEnd(e, d);
                                            }}
                                            onTouchStart={(e) => {
                                                checkTouchStart(e, d);
                                            }}
                                            onTouchEnd={(e) => {
                                                checkTouchEnd(e, d);
                                            }}
                                            onTouchMove={(e) => {
                                                checkTouchMove(e);
                                            }}
                                        >
                                            {d.name}
                                        </div>
                                    )) ?? <>없습니다.</>}
                                </div>
                            </div>
                            <Button
                                style={{ marginTop: 20 }}
                                onClick={() => showChangeGroupProxyConfirm()}
                            >
                                완료
                            </Button>
                        </div>
                    ) : (
                        <div style={{ display: 'flex' }}>
                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <button
                            onClick={() => {
                                ( async () => {
                                    await updateProductionProxy();
                                })();
                            }}
                            style={{ backgroundColor: 'lightblue', marginTop: 10, padding: 5 }}
                        >프로덕션 새로고침
                            <SyncOutlined
                                spin={loading}
                                style={{ fontSize: 14, marginLeft: 5 }}
                            />
                        </button>
                        { productionList && productionList.map(d => (
                            <div style={{ border: '1px gray solid', padding: '10px 20px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        {d.name}
                                        <button
                                            onClick={() => showHealthCheckProxyConfirm(d)}
                                            style={{ border: '1px lightgray solid', padding: '2px 5px', fontSize: 10, marginLeft: 10 }}
                                        ><WifiOutlined style={{ color: 'gray' }} /></button>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => showShutdownProxyConfirm(d.proxy.proxy_id)}
                                            style={{ border: '1px lightgray solid', padding: '2px 5px', fontSize: 10 }}
                                        >off <StopOutlined style={{ color: 'red' }} /></button>
                                    </div>
                                </div>
                                <ContextContainer>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 110 }}>
                                            <div style={{ fontSize: 20 }}>
                                                {d.status === ProxyStatus.HEALTH && <CheckCircleFilled style={{ color: '#52c41a' }}/>}
                                                {d.status === ProxyStatus.IP_CHANGING && <ClockCircleFilled style={{ color: '#F77737' }}/>}
                                                {d.status === ProxyStatus.SHUTDOWN && <StopFilled />}
                                                {d.status === ProxyStatus.HALTED && <PauseCircleFilled style={{ color: '#eb2f96'}}/>}
                                            </div>
                                            <div style={{ marginLeft: 10 }}>
                                                {d.status}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', padding: `5px 20px`, lineHeight: 1 }}>
                                            <div style={{ fontSize: 20, marginRight: 5 }}>{d.quota}</div>
                                            <div style={{ color: 'gray' }}>/ 4</div>
                                        </div>
                                    </div>
                                    <IPContainer>
                                        <IPItemContainer>
                                            <div style={{ color: 'gray' }}>static</div>
                                            <IPAddress>{d.proxy['proxy_static_addr']}</IPAddress>
                                        </IPItemContainer>
                                        <IPItemContainer>
                                            <div style={{ color: 'blue' }}>api</div>
                                            <IPAddress>{d.proxy['proxy_api_addr']}</IPAddress>
                                        </IPItemContainer>
                                        <IPItemContainer>
                                            <div style={{ color: 'green' }}>ssh</div>
                                            <IPAddress>{d.proxy['proxy_ssh_addr']}</IPAddress>
                                        </IPItemContainer>
                                    </IPContainer>
                                </ContextContainer>
                            </div>
                        ))
                        }
                        <button
                            onClick={() => {
                                ( async () => {
                                    await updateProductionProxy();
                                })();
                            }}
                            style={{ backgroundColor: 'lightblue', marginTop: 10, padding: 5 }}
                        >스테이징 새로고침
                            <SyncOutlined
                                spin={loading}
                                style={{ fontSize: 14, marginLeft: 5 }}
                            />
                        </button>
                        { stagingList && stagingList.map(d => (
                            <div style={{ border: '1px gray solid', padding: '10px 20px', backgroundColor: '#eeeeee' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        {d.name}
                                        <button
                                            onClick={() => showHealthCheckProxyConfirm(d)}
                                            style={{ border: '1px lightgray solid', padding: '2px 5px', fontSize: 10, marginLeft: 10 }}
                                        ><WifiOutlined style={{ color: 'gray' }} /></button>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => showShutdownProxyConfirm(d.proxy.proxy_id)}
                                            style={{ border: '1px lightgray solid', padding: '2px 5px', fontSize: 10 }}
                                        >off <StopOutlined style={{ color: 'red' }} /></button>
                                    </div>
                                </div>
                                <ContextContainer>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 110 }}>
                                            <div style={{ fontSize: 20 }}>
                                                {d.status === ProxyStatus.HEALTH && <CheckCircleFilled style={{ color: '#52c41a' }}/>}
                                                {d.status === ProxyStatus.IP_CHANGING && <ClockCircleFilled style={{ color: '#F77737' }}/>}
                                                {d.status === ProxyStatus.SHUTDOWN && <StopFilled />}
                                                {d.status === ProxyStatus.HALTED && <PauseCircleFilled style={{ color: '#eb2f96'}}/>}
                                            </div>
                                            <div style={{ marginLeft: 10 }}>
                                                {d.status}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', padding: `5px 20px`, lineHeight: 1 }}>
                                            <div style={{ fontSize: 20, marginRight: 5 }}>{d.quota}</div>
                                            <div style={{ color: 'gray' }}>/ 4</div>
                                        </div>
                                    </div>
                                    <IPContainer>
                                        <IPItemContainer>
                                            <div style={{ color: 'gray' }}>static</div>
                                            <IPAddress>{d.proxy['proxy_static_addr']}</IPAddress>
                                        </IPItemContainer>
                                        <IPItemContainer>
                                            <div style={{ color: 'blue' }}>api</div>
                                            <IPAddress>{d.proxy['proxy_api_addr']}</IPAddress>
                                        </IPItemContainer>
                                        <IPItemContainer>
                                            <div style={{ color: 'green' }}>ssh</div>
                                            <IPAddress>{d.proxy['proxy_ssh_addr']}</IPAddress>
                                        </IPItemContainer>
                                        {/* <IPItemContainer>
                                            <div style={{ color: 'orange' }}>realIP</div>
                                            <IPAddress>{d.info?.allocatedIp ?? '-'}</IPAddress>
                                        </IPItemContainer> */}
                                    </IPContainer>
                                </ContextContainer>
                            </div>
                        ))
                        }
                        <button
                            onClick={() => {
                                ( async () => {
                                    await updateProductionProxy();
                                })();
                            }}
                            style={{ backgroundColor: 'lightblue', marginTop: 10, padding: 5 }}
                        >프로버 새로고침
                            <SyncOutlined
                                spin={loading}
                                style={{ fontSize: 14, marginLeft: 5 }}
                            />
                        </button>
                        { proberList && proberList.map(d => (
                            <div style={{ border: '1px gray solid', padding: '10px 20px', backgroundColor: '#f0ffad' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        {d.name}
                                        <button
                                            onClick={() => showHealthCheckProxyConfirm(d)}
                                            style={{ border: '1px lightgray solid', padding: '2px 5px', fontSize: 10, marginLeft: 10 }}
                                        ><WifiOutlined style={{ color: 'gray' }} /></button>
                                    </div>
                                    <button
                                        onClick={() => showShutdownProxyConfirm(d.proxy.proxy_id)}
                                        style={{ border: '1px lightgray solid', padding: '2px 5px', fontSize: 10 }}
                                    >off <StopOutlined style={{ color: 'red' }} /></button>
                                </div>
                                <ContextContainer>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 110 }}>
                                            <div style={{ fontSize: 20 }}>
                                                {d.status === ProxyStatus.HEALTH && <CheckCircleFilled style={{ color: '#52c41a' }}/>}
                                                {d.status === ProxyStatus.IP_CHANGING && <ClockCircleFilled style={{ color: '#F77737' }}/>}
                                                {d.status === ProxyStatus.SHUTDOWN && <StopFilled />}
                                                {d.status === ProxyStatus.HALTED && <PauseCircleFilled style={{ color: '#eb2f96'}}/>}
                                            </div>
                                            <div style={{ marginLeft: 10 }}>
                                                {d.status}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', padding: `5px 20px`, lineHeight: 1 }}>
                                            <div style={{ fontSize: 20, marginRight: 5 }}>{d.quota}</div>
                                            <div style={{ color: 'gray' }}>/ 100</div>
                                        </div>
                                    </div>
                                    <IPContainer>
                                        <IPItemContainer>
                                            <div style={{ color: 'gray' }}>static</div>
                                            <IPAddress>{d.proxy['proxy_static_addr']}</IPAddress>
                                        </IPItemContainer>
                                        <IPItemContainer>
                                            <div style={{ color: 'blue' }}>api</div>
                                            <IPAddress>{d.proxy['proxy_api_addr']}</IPAddress>
                                        </IPItemContainer>
                                        <IPItemContainer>
                                            <div style={{ color: 'green' }}>ssh</div>
                                            <IPAddress>{d.proxy['proxy_ssh_addr']}</IPAddress>
                                        </IPItemContainer>
                                    </IPContainer>
                                </ContextContainer>
                            </div>
                        ))
                        }
                        </div>
                    </div>
                    )
                }
            </RootContainer>
        </>
    );
};

export default ProxyControl;