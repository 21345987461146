import { Spin } from 'antd';

const Mask = () => {
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: '#ffffff90',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: Number.MAX_SAFE_INTEGER,
            }}
        >
            <Spin size='large' />
        </div>
    );
};

export default Mask;