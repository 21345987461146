import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import MaUTable from '@mui/material/Table';
import { AbuserDto, DashboardAbuserDto } from '../../apiTypes';
import repository from '../../repository';
import Mask from '../../components/mask';
import { Button, Descriptions, Drawer, Empty, Input, List, message, Modal, Select } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, CheckCircleOutlined, CloseCircleOutlined, DashboardOutlined, LockOutlined, UserAddOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FixedSizeList } from 'react-window';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Flex from '../../components/flex';
import { isMobileOnly } from 'react-device-detect';
import styled from '@emotion/styled';
import AccountDetail from '../accountDetail';
import moment from 'moment';

const ROW_SIZE = 90;

const photoDuplicated = [
    '61f29674-9bcf-4977-a785-a9736afcac21',
    'd1ce7376-1117-46ac-b21e-3c6c68193d37',
    '95d42e8d-ae7c-4eba-a6f5-ec222f0b85b1',
    '783e8847-f8ad-4aae-83b6-f213d1dfb26e',
    'ca48d416-339b-4c86-ba86-ac8d45eb4a1a',
    '5f1e459b-853d-498e-befc-6b206c1275e0',
    'e0282b19-c4ca-4eb1-b4e2-52df4a93046d',
    'e3c1ae91-3325-48a4-811c-6bac60e895cf',
    'ca47be7f-cf50-4a03-bb85-9de345531bf1',
    '2712172f-5b4e-4107-b040-86927c52f55e',
    '983b4042-ad37-47c3-b428-9a3b4c2ca3e6',
    'fc8f3085-943e-4586-a1eb-223f1d873bbf',
    '40d81665-3fa8-4a96-9510-f21f2768942d',
    '3fd61ca7-ad6b-4d04-988a-f0781020e857',
    '48ce0571-1959-4963-8b09-ca273e688efa',
    'fa6223c5-a347-486b-a91b-89cb1c3512e0',
    'd804dfec-d17f-4cca-9025-ee61af5e43d0',
    '19aae024-62ee-4385-b424-311705e68ec9',
    'fe9f3c2b-0835-4752-a7e1-dd73330c6bab',
    'abace4e9-bb92-4da0-8780-6df1e9291f74',
    '434d4b18-0d42-4886-8477-4ed6d7e96da1',
    '48c7f6a6-4584-4469-bba6-ec7925bea4e9',
    '4d52aee0-5eb5-47a9-aca0-0393ca8b2e80',
    '130acf21-cf29-4bb7-b841-7ba27959ac65',
    '363b23df-af71-4659-b514-6a1c43afa1e1',
    '989b9653-e401-4fe4-9005-75c9571cd579',
    'ba0cf664-5804-4c1b-a7a0-c5788ec77bd9',
    'fccabda6-fa56-4507-b8ab-0e940bfd01ba',
    'a462d1a5-ef7e-4e93-9aca-80a61b055efc',
    'f6987ff5-55e7-4709-9bac-b55005f608d9',
    'a95f3bac-aa0d-4f99-99a8-8ba7fa59e013',
    '55ca7529-64b9-409c-a6af-85320d4a67ba',
    '3c7873f9-9b11-4acc-af61-7b401039a2b4',
    '73ca754e-c3c4-4557-8e3c-ef235a4ada3e',
    '5a6737f9-7e8a-451d-b882-779516003bba',
    'f932180c-a6bb-4834-91c5-c79107236abc',
    'c1f4a557-cfbe-4ffc-bcde-7a761a59f9aa',
    '4a548b15-9f41-45ce-8673-e5825ac4f3f0',
    '4361eedd-4360-4632-b871-bea7a13598e7',
    'f50ce5e0-755f-4938-80e0-d50e907a9cf3',
    '98582226-ff45-44d2-90bc-bfeac221b8a9',
    '534506ff-18ff-4493-bb92-d44908121d0a',






    '6fc7e143-373a-48b9-bd2f-e3c3cb51e6bd',
    '2821c6d0-54ca-44e9-9d52-c6a1b0378852',
    'dd4e5bbc-6b95-408a-9650-0eae29181063',
    '839d411f-df3e-4cd8-ad8f-0f8fc41248b9',
    'd741b82e-6746-4698-b51a-b7f2fabe4346',
    '10cebe7c-dbb1-4d18-8645-4f91fefe392c',
    '2716dcb0-47df-4fb9-acd9-835fe29e1761',
    '3709807c-1a2a-4c72-9336-51afb7399a49',
    '30a254f5-80e4-4eed-a647-f9fbce82379c',
    '8aec3bc7-846f-4814-b902-cf2c326f5b40',
    '4367b42b-836e-4bc2-979a-c838d2cca388',
    '7abd4391-39b7-467d-a237-6846929062e2',
    '060ed224-9786-4031-bee3-4281f0b08d2d',
    '955a2abd-04f3-4fb7-8e28-07fe71cd7006',
    'dcfc7a9e-d311-44d8-b44e-f57380738bbc',
    '2fb62289-d893-4e90-9b95-6f9781b82f1b',
    '9bd94bf6-4651-4379-8b67-27df6f05177c',
    '6733615b-540f-40eb-933d-a5a8b00fb190',
    '51b6b4af-aa72-4bf0-b498-78d3d68057ac',
    '90de2f83-25fa-45d7-8f1b-fa0870765e87',
    '54d8fd85-4ae4-4166-9f76-0e5b23e85844',
    'f6fde79a-3408-4f6d-ad11-8ee831a15055',
    '28be4b49-1cf6-4a3a-9a3e-817dfe93a03e',
    '22a9041e-0740-4fc7-97ec-ddd374da78c9',
    'c39bffc4-758c-4cff-bba7-0cc2cd219da4',
    '572b8808-0a91-4069-8537-d00d60221b72',
    '72d915f1-47aa-4c25-808d-d6e8b54d1699',
    'dc2c2f3a-fde5-44e5-967a-d65c498ce6e8',
    '2a2b677e-f1df-44d5-aa33-f045b432be18',
    'cb22d3be-bed8-483c-a817-f29f68372dc6',
    'd5dd2d85-ba38-44d6-bf63-a2b3b97bc4a4',
];

const mapper = (x: DashboardAbuserDto) => {
    return {
        id: x.id,
        memo: x.memo,
        // @ts-ignore
        agingCnt: Math.max(...[filterer(x.memo), x.agingCnt]),
        instaName: x.instaName,
        page: `https://www.instagram.com/${x.instaName}`,
        lastLoginedDt: x.lastLoginedDt,
        hourBefore: moment.duration(moment(x.lastLoginedDt).diff(moment())).asHours(),
    }
};
const sortByOlder = (a: string, b: string) => moment(a).diff(moment(b));

const PRIVATE_ACCOUNT = {
    private: 'O',
    notPrivate: 'X',
};

const Test = styled.div<{ index?: number }>`
.MuiTableRow-root {
    transition: 2s all ease;
}

${props => props.index && `
    .MuiTableRow-root:nth-child(5) {
        background-color: #c5c5c5;
    }
`};
`;

const StyledListItem = styled(List.Item)`
cursor: pointer;
transition: all 0.2s ease;

:hover {
    background-color: #f1f1f1;
}
:active {
    background-color: #f1f1f1;
}
`;

//최댓값은 제외, 최솟값은 포함
function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min)) + min;
}

// @ts-ignore
function Table({ columns, data }) {
    const defaultColumn = useMemo(() => ({
        width: 200,
    }), []);

    const listRef = useRef<FixedSizeList>(null);
    const navigate = useNavigate();

    const [ index, setIndex ] = useState<number>();
    const [ drawerOpen, setDrawerOpen ] = useState<boolean>(false);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ searchType, setSearchType ] = useState<string>('id');
    const [ searchResultModalOpen, setSearchResultModalOpen ] = useState<boolean>(false);
    const [ searchResult, setSearchResult ] = useState<Array<AbuserDto>>([]);

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        getTableBodyProps,
        totalColumnsWidth,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    const onSearch = async (searchText: string) => {
        const searchResult = await repository.searchUser(searchType, searchText.toLowerCase());
        setSearchResult(searchResult);
        setSearchResultModalOpen(true);
    };

    useEffect(() => {
        const accountId = searchParams.get('id');
        if (accountId) {
            setDrawerOpen(true);
        }
    }, [ searchParams ]);

    return (
        <>
            <Flex>
                <Flex style={{ maxWidth: isMobileOnly ? window.innerWidth : 500, padding: '0 60px', gap: 10 }}>
                    <div style={{ flex: 1 }}>
                        <Select
                            onClick={e => e.stopPropagation()}
                            onChange={value => setSearchType(value)}
                            defaultValue='id'
                            style={{ width: 80 }}
                        >
                            { ['id', 'email', 'igid', 'memo'].map(type => (
                                <Select.Option
                                    key={type}
                                    value={type}
                                >{type}</Select.Option>
                            ))}
                        </Select>
                    </div>
                    <Input.Search
                        size='large'
                        placeholder='검색'
                        onSearch={onSearch}
                    />
                </Flex>
            </Flex>
            <MaUTable
                {...getTableProps()}
                stickyHeader
            >
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // @ts-ignore
                                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <div>
                                        {column.render('Filter')}
                                    </div>
                                    <span>
                                        {/* @ts-ignore */}
                                        {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);

                        return (
                            <TableRow
                                {...row.getRowProps({

                                })}
                            >
                                {row.cells.map(cell => {
                                    if (
                                        cell.column.id === 'id' ||
                                        // cell.column.id === 'instaName' ||
                                        cell.column.id === 'email' ||
                                        cell.column.id === 'instaPassword' ||
                                        cell.column.id === 'instaId'
                                    ) {
                                        return (
                                            <CopyToClipboard
                                                text={cell.value}
                                                onCopy={(text, result) => {
                                                    if (result) {
                                                        message.success(`${cell.value}`);
                                                    } else {
                                                        message.error(`${cell.value}`);
                                                    }
                                                }}
                                            >
                                                <TableCell
                                                    {...cell.getCellProps()}
                                                    style={{
                                                        cursor: 'pointer',
                                                        // @ts-ignore
                                                        width: cell.column.originalWidth,
                                                    }}
                                                >
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            </CopyToClipboard>
                                        )
                                    }

                                    return (
                                        <TableCell
                                            {...cell.getCellProps()}
                                            style={{
                                                cursor: 'pointer',
                                                // @ts-ignore
                                                width: cell.column.originalWidth,
                                            }}
                                        >
                                            { cell.value === 'active' && <CheckCircleOutlined style={{ fontSize: 16, color: '#52c41a', marginRight: 5 }} /> }
                                            { cell.value === 'banned' && <CloseCircleOutlined style={{ fontSize: 16, color: '#eb2f96', marginRight: 5 }} /> }
                                            { cell.value === 'preparing' && <DashboardOutlined style={{ fontSize: 16, color: '#F77737', marginRight: 5 }} /> }
                                            { cell.value === 'locked' && <LockOutlined style={{ color: '#F77737', marginRight: 5 }} /> }
                                            { cell.column.id === 'numFollowers' && <UserAddOutlined style={{ color: '#0095f6', marginRight: 5 }} /> }
                                            { cell.column.id === 'index' ? i + 1 : cell.render('Cell') }
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </MaUTable>
            <Drawer
                width={isMobileOnly ? '100vw' : 800}
                title='계정 상세'
                placement='right'
                closable={true}
                onClose={() => {
                    setDrawerOpen(false);
                    setSearchParams(prev => {
                        prev.delete('id');
                        return prev;
                    });
                }}
                open={drawerOpen}
            >
                <AccountDetail />
            </Drawer>
            <Modal
                visible={searchResultModalOpen}
                title='검색 결과'
                width={'100%'}
                onCancel={() => setSearchResultModalOpen(false)}
            >
                <List
                    header={<div>{`검색 결과 (총 ${searchResult.length}개)`}</div>}
                    bordered
                    dataSource={searchResult}
                    renderItem={(item) => (
                        <StyledListItem onClick={() => {
                            setSearchResultModalOpen(false);
                            setSearchResult([]);
                            navigate(`/account/training?id=${item.id}`);
                        }}>
                            <Descriptions title={item.baseName} bordered>
                                <Descriptions.Item label="아이디">{item.id}</Descriptions.Item>
                                <Descriptions.Item label="이메일">{item.email}</Descriptions.Item>
                                <Descriptions.Item label="닉네임">{item.instaName}</Descriptions.Item>
                                <Descriptions.Item label="처음 닉네임">{item.instaId}</Descriptions.Item>
                                <Descriptions.Item label="메모">{item.memo}</Descriptions.Item>
                            </Descriptions>
                        </StyledListItem>
                    )}
                />
            </Modal>
        </>
    );
}

function Training() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ accountList, setAccountList ] = useState<Array<DashboardAbuserDto>>([]);

    console.log(accountList)

    const columns = React.useMemo(() => [
        {
            Header: 'index',
            accessor: 'index',
            Filter: () => {},
            width: 50,
        },
        {
            Header: '계정 확인',
            accessor: 'empty',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <div style={{ marginBottom: 10 }}>
                            <Button
                                onClick={() => {
                                    window.open(`https://www.instagram.com/${cell.row.values.instaName}`, '_blank', 'noreferrer noopener');
                                }}
                            >인스타 들어가기</Button>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Link to={`/account/training?id=${cell.row.values.id}`}>
                                상세 페이지
                            </Link>
                        </div>
                    </div>
                );
            },
            width: 150,
        },
        {
            Header: '투입',
            accessor: 'empty2',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <Button
                            onClick={async () => {
                                try {
                                    if (window.confirm('active 상태로 변경하시겠습니까?')) {
                                        const response = await repository.updateAbuserStatus(cell.row.values.id, 'active');
                                        await getAccountList();
                                        if (response) {
                                            message.success(`active 상태로 변경되었습니다.`);
                                        } else {
                                            message.error('변경 실패');
                                        }
                                    }
                                } catch (error) {
                                    message.error(`${error}`);
                                }
                            }}
                        >active 투입</Button>
                    </div>
                );
            },
            width: 150,
        },
        {
            Header: 'memo',
            accessor: 'memo',
            Filter: () => {},
            width: 150,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <Input.TextArea
                            value={cell.row.values.memo || 'Empty'}
                            style={{ resize: 'none' }}
                            onClick={() => {
                                const answer = window.prompt('메모를 작성해주세요.', cell.row.values.memo);
                                if (answer) {
                                    (async () => {
                                        await repository.updateMemo(cell.row.values.id, answer);
                                        message.success('메모 업데이트에 성공하였습니다.');
                                        await getAccountList();
                                    })();
                                } else {
                                    message.error('메모 업데이트에 실패하였습니다.');
                                }
                            }}
                        />
                    </div>
                );
            },
        },
        {
            Header: '마지막 활성화 일자',
            accessor: 'lastLoginedDt',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '비공개 계정 여부',
            accessor: 'isPrivateAccount',
            Filter: () => {},
            width: 100,
        },
        {
            Header: 'id',
            accessor: 'id',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '처음 닉네임',
            accessor: 'instaId',
            Filter: () => {},
            width: 250,
        },
        {
            Header: '닉네임',
            accessor: 'instaName',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <CopyToClipboard
                            text={cell.row.values.instaName}
                            onCopy={(text, result) => {
                                if (result) {
                                    message.success(`${cell.row.values.instaName}`);
                                } else {
                                    message.error(`${cell.row.values.instaName}`);
                                }
                            }}
                        >
                            <p>
                                {cell.row.values.instaName}
                            </p>
                        </CopyToClipboard>
                        <Link to={`/account/training?id=${cell.row.values.id}`}>
                            상세 페이지
                        </Link>
                    </div>
                );
            },
        },
        {
            Header: '이메일',
            accessor: 'email',
            Filter: () => {},
            width: 250,
        },
        {
            Header: 'insta P/W',
            accessor: 'instaPassword',
            Filter: () => {},
        },
    ], []);

    const getAccountList = async () => {
        const fetchedList = await repository.getFinishedAbusers();
        const _accountList = fetchedList
            .filter(user => !photoDuplicated.includes(user.id))
            .sort((a, b) => sortByOlder(a.lastLoginedDt, b.lastLoginedDt))
            .map((listItem, i) => {
                return {
                    index: i + 1,
                    id: listItem.id,
                    instaId: listItem.instaId,
                    instaName: listItem.instaName,
                    email: listItem.email,
                    instaPassword: listItem.instaPassword,
                    accountStatus: listItem.accountStatus,
                    lastLoginedDt: `${moment(listItem.lastLoginedDt).format('YYYY-MM-DD HH:mm')} (${-moment.duration(moment(listItem.lastLoginedDt).diff(moment())).asHours().toFixed()}시간 전)`,
                    setupMeta: listItem.setupMeta,
                    // @ts-ignore
                    isPrivateAccount: (listItem.isPrivateAccount === true || listItem.isPrivateAccount === PRIVATE_ACCOUNT.private)
                        ? PRIVATE_ACCOUNT.private
                        : PRIVATE_ACCOUNT.notPrivate,
                    memo: listItem.memo,
                    createdAt: listItem.createdAt,
                    // @ts-ignore
                    agingCnt: listItem.agingCnt,
                    empty: '',
                    empty2: '',
                };
            });

        // @ts-ignore
        setAccountList(_accountList);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getAccountList();
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Mask />
    }

    return (
        <>
            <div>
                <Flex style={{ marginBottom: 30 }}>
                    <Button
                        type='primary'
                        onClick={async () => {
                            function sleep(delay: number) {
                                return new Promise((resolve) => setTimeout(resolve, delay));
                            };

                            try {
                                if (window.confirm('함승주에게 허가를 받으셨습니까?')) {
                                    if (window.confirm('진짜입니까?')) {
                                        const accounts = accountList.filter(u => u.memo === '닉넴');
                                        if (window.confirm(`${accounts.length}개`)) {
                                            setLoading(true);
                                            for (const account of accounts) {
                                                await repository.updateMemo(account.id, '닉넴 ing');
                                                await repository.resetUserName([account.id]);
                                                await sleep(500);
                                            }
                                            await getAccountList();
                                            setLoading(false);
                                        }
                                    }
                                }
                            } catch (error) {
                                message.error(`${error}`);
                            }
                        }}
                    >닉넴 초기화</Button>
                </Flex>
                { accountList.length === 0
                    ? <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Empty /></div>
                    : <Table
                        columns={columns}
                        data={accountList}
                    />
                }
            </div>
        </>
    );
}

export default Training;
