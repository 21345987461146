import { CreateManualPromotionDto, ManualOrderDataPlatformTypeEnum, ManualOrderDataTaskTypeEnum, ShopOrderResponse } from '../../apiTypes';
import repository from '../../repository';
import { Button, Checkbox, Divider, Form, Input, message, Radio } from 'antd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import styled from '@emotion/styled';
import confirm from 'antd/lib/modal/confirm';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { useSearchParams } from 'react-router-dom';
import getRandomOrderNumber from '../../helper/getRandomOrderNumber';

interface FormData {
    orders: Array<{
        platformType: ManualOrderDataPlatformTypeEnum;
        taskType: ManualOrderDataTaskTypeEnum;
        progressCnt: string | undefined;
        displayingCnt: string | undefined;
        targetId: string;
        targetUrl: string;
        isForeigner: boolean;
        purchaseCnt: string | undefined;
        isService: boolean;
    }>;
    // targetId: string;
    needAligoSMS: boolean;
    customerName: string;
    orderPhone: string;
    price: string | undefined;
    orderNo: number;
    autoTextArea: string;
    memNo: number;
    promotionTargetId: string;
};

interface Payload {
    orders: Array<{
        platformType: ManualOrderDataPlatformTypeEnum;
        taskType: ManualOrderDataTaskTypeEnum;
        progressCnt: number;
        displayingCnt: number;
        targetUrl: string;
        targetId: string;
        isForeigner: boolean;
        purchaseCnt: number;
        isService: boolean;
    }>;
    needAligoSMS: boolean;
    customerName: string;
    orderPhone: string;
    price: number;
    orderNo: number;
};

const RootContainer = styled.div`
display: flex;
flex-direction: column;
padding: 50px;
@media screen and (max-width: 500px) {
    padding: 50px 20px;
}
`;

const ErrorP = styled.p`
color: red;
`;

function ManualOrderAutoForm() {
    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        reset,
        resetField,
    } = useForm<FormData>({
        defaultValues: {
            orders: [],
        },
    });
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'orders'
    });

    const [ searchKey, setSearchKey ] = useSearchParams();
    const [ parsedInfo, setParsedInfo ] = useState<ShopOrderResponse>();
    const [ showPromotion, setShowPromotion] = useState<boolean>(false);

    const deleteConfirm = (index: number) => {
        confirm({
            title: '이 필드를 지우시겠습니까?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                remove(index);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const autoOrderMake = (text: string) => {
        resetField('orders');
        resetField('customerName');
        resetField('orderPhone');
        resetField('price');
        // resetField('targetId');
        resetField('needAligoSMS');
        resetField('orderNo');

        const lines = text.split('\n');
        const orderNo = lines[0].split(':')[1].trim();
        const customerName = lines[1].split(':')[1].trim();
        const orderPhone = lines[2].split(':')[1].trim().replaceAll('-', '');
        const price = lines[3].split(':')[1].trim();
        const targetId = lines[4].split(':')[1].trim();
        const urlLines = lines.slice(5, lines.length);
        setValue('customerName', customerName);
        setValue('orderPhone', orderPhone);
        setValue('price', price);
        // setValue('targetId', targetId);
        // 알림톡은 기본으로 트루
        setValue('needAligoSMS', true);
        setValue('orderNo', parseInt(orderNo));

        if (urlLines.length < 1) {
            message.error('1게시글 이상이 필요합니다.');
            return ;
        }
        const orderItems = urlLines.map((d, i) => {
            const url = d.split(' : ')[0].trim();
            console.log('url', url);
            const count = parseInt(d.split(' : ')[1].trim());
            return {
                // @ts-ignore
                platformType: 'insta',
                // @ts-ignore
                taskType: url.includes('reel') ? 'like_reels' : 'like',
                progressCnt: Math.ceil(count * 1.05),
                displayingCnt: count,
                targetId: targetId,
                targetUrl: url,
                // targetId: '',
                isForeigner: false,
                purchaseCnt: '1',
                isService: false,
            }
        });
        // console.log('orderItems', orderItems);
        // @ts-ignore
        setValue('orders', orderItems.map(d => d));
    };

    const autoOrderMakeV2 = (text: string) => {
        resetField('orders');
        resetField('customerName');
        resetField('orderPhone');
        resetField('price');
        resetField('needAligoSMS');
        resetField('orderNo');
        resetField('memNo');
        resetField('promotionTargetId');

        const lines = text.split('\n');
        const orderNo = lines[0].split(':')[1].trim();
        const customerName = lines[1].split(':')[1].trim();
        const orderPhone = lines[2].split(':')[1].trim().replaceAll('-', '');
        const price = lines[3].split(':')[1].trim();
        // console.log('lines', lines);
        let orderList: Array<{
            goodsNm: string;
            goodsCount: number;
            id: string;
            url: Array<string>;
        }> = [];
        let orderLine: Array<string> = [];
        lines.forEach((line, i) => {
            if (i < 5) {
                return;
            }
            orderLine.push(line);
            console.log('orderLine', orderLine);
            if (i === lines.length - 1 || lines[i + 1].includes('상품명')) {
                orderList.push({
                    goodsNm: orderLine[0].split(':')[1].trim(),
                    goodsCount: parseInt(orderLine[1].split(':')[1].trim()),
                    id: orderLine[2].split(':')[1].trim(),
                    url: orderLine.filter((text, index) => index > 2 && text !== ''),
                });
                orderLine = [];
            }
        });
        // console.log('orderList', orderList);
        // const targetId = lines[3].split(':')[1].trim();
        // const urlLines = lines.slice(4, lines.length);
        setShowPromotion(false);

        const orderItems = orderList.map((item, i) => {
            // 팔로워인 경우
            if (item.goodsNm.includes('팔로워')) {
                // 무료 팔로워인 경우
                if (item.goodsNm.includes('무료')) {
                    setValue('memNo', parsedInfo?.memNo ?? 0);
                    setValue('promotionTargetId', item.id);
                    setShowPromotion(true);
                    return undefined;
                    // return {
                    //     platformType: 'insta',
                    //     taskType: 'follow',
                    //     progressCnt: 3,
                    //     displayingCnt: 3,
                    //     targetUrl: '',
                    //     targetId: item.id,
                    //     isForeigner: false,
                    //     purchaseCnt: '1',
                    //     isService: false,
                    // }
                }
                // 일반 팔로워인경우
                const count = parseInt(item.goodsNm.split(' ')[2]) * item.goodsCount;
                return {
                    platformType: 'insta',
                    taskType: 'follow',
                    progressCnt: item.goodsNm.includes('외국인')
                        ? Math.ceil(count * 1.35) : Math.ceil(count * 1.05),
                    displayingCnt: count,
                    targetUrl: '',
                    targetId: item.id,
                    isForeigner: item.goodsNm.includes('외국인'),
                    purchaseCnt: '1',
                    isService: false,
                }
            }
            // 좋아요인 경우
            if (item.goodsNm.split(' ')[1] === '좋아요') {
                const splitedLikeOrders = item.url.map((url, index) => {
                    const count = url.split(':').length > 2
                        ? parseInt(url.split(':')[url.split(':').length - 1].replace(/[^0-9]/g, '').trim())
                        : (parseInt(item.goodsNm.split(' ')[2]) * item.goodsCount) / item.url.length
                    return {
                        platformType: 'insta',
                        taskType: url.includes('reel') ? 'like_reels' : 'like',
                        progressCnt: item.goodsNm.includes('외국인')
                            ? Math.ceil(count * 1.35) : Math.ceil(count * 1.05),
                        displayingCnt: Math.ceil(count),
                        targetId: item.id,
                        targetUrl: url.split(':').length > 2
                            ? url.substring(0, url.lastIndexOf(':')).trim() : url.trim(),
                        isForeigner: item.goodsNm.includes('외국인'),
                        purchaseCnt: '1',
                        isService: false,
                    }
                })
                return splitedLikeOrders;
            }
        }).flat().filter(d => d !== undefined);

        console.log('orderItems', orderItems);

        setValue('orderNo', parseInt(orderNo));
        setValue('customerName', customerName);
        setValue('orderPhone', orderPhone);
        setValue('price', price);
        // setValue('targetId', targetId);
        // 알림톡은 기본으로 트루
        setValue('needAligoSMS', true);
        // @ts-ignore
        setValue('orders', orderItems.map(d => d));

        // console.log('autoOrderMake text', text);
        // console.log('split', text.split('\n'));
    }

    const _PostManualOrder = async (data: Payload) => {
        try {
            if (window.confirm('주문하시겠습니까?')) {
                await repository.postManualOrder(data);
                message.success('수동주문 성공');
                reset();
            }
        } catch (err) {
            console.log('err', err);
            message.error(`_PostManualOrder ${err}`);
        }
    };

    const makePromotion = async (data: CreateManualPromotionDto) => {
        try {
            if (window.confirm('무료 팔로워를 넣으시겠습니까?')) {
                const rs = await repository.createManualPromotion(data);
                if (rs) {
                    message.success('무료 팔로워 생성완료');
                }
            }
        } catch (err) {
            console.log('err', err);
            message.error(`makePromotion ${err}`);
        };
    };

    const registerSubmit = async (data: FormData) => {
        if (data.orders.length < 1 && !showPromotion) {
            message.error('필드가 없습니다.');
            return;
        }
        
        // 무료 팔로워가 있다면
        if (showPromotion && data.memNo && data.promotionTargetId) {
            const promotionPayload = {
                customerName: data.customerName,
                customerPhone: data.orderPhone,
                targetId: data.promotionTargetId,
                orderNo: (data.orders && data.orders.length > 0)
                    ? getRandomOrderNumber() : data.orderNo,
                memNo: data.memNo,
            };
            console.log(promotionPayload);
            await makePromotion(promotionPayload);
        }
        if (data.orders.length > 0) {
            const payload = {
                orders: data.orders.map((order) => ({
                    platformType: order.platformType,
                    taskType: order.taskType,
                    progressCnt: parseInt(order.progressCnt ?? '0'),
                    displayingCnt: parseInt(order.displayingCnt ?? '0'),
                    targetUrl: order.taskType === 'follow' ? '' : order.targetUrl,
                    targetId: order.targetId,
                    isForeigner: order.isForeigner,
                    purchaseCnt: parseInt(order.purchaseCnt ?? '0'),
                    isService: order.isService,
                })),
                needAligoSMS: data.needAligoSMS,
                customerName: data.customerName,
                orderPhone: data.orderPhone ?? '',
                price: parseInt(data.price ?? '0'),
                orderNo: data.orderNo,
            };
            console.log(payload);
            await _PostManualOrder(payload);
        }
    };

    const getBiscuit = async () => {
        const orderNo = searchKey.get('key');
        if (!orderNo) {
            return;
        }
        const rs = await repository.getBiscuitFromKey(orderNo);
        console.log('rs', rs);
        if (rs) {
            setParsedInfo(rs);
            const addFieldParse = JSON.parse(rs.addField);
            const basicInfo = `주문번호: ${rs.orderNo}\n고객명: ${rs.orderInfoData.orderName}\n전화번호: ${rs.orderInfoData.orderCellPhone}\n명목상구매가격: ${rs.settlePrice}\n`;
            const goodsNmList = Array.isArray(rs.orderGoodsData) ? rs.orderGoodsData : [rs.orderGoodsData];
            // console.log(goodsNmList);
            // console.log('addFieldParse', addFieldParse);
            // console.log('objectEntry', Object.entries(addFieldParse).map(d => d[1]));
            const simpleParsedInfo: any = Object.entries(addFieldParse).map(d => d[1]);
            // console.log('simpleParsedInfo', simpleParsedInfo);
            // console.log(Object.entries(simpleParsedInfo[0].goodsNm));
            const parsedInfo = goodsNmList.map(goodsNm => {
                return {
                    goodsNm: goodsNm.goodsNm,
                    goodsCount: goodsNm.goodsCnt,
                    id: Object.values(simpleParsedInfo.find((info: any) => (Object.values(info.goodsNm)[0] === goodsNm.goodsNm)
                        && info.name && info.name.includes('ID')).data)[0],
                    url: goodsNm.goodsNm.includes('좋아요') && simpleParsedInfo.find((info: any) => (Object.values(info.goodsNm)[0] === goodsNm.goodsNm)
                            && info.name && info.name.includes('URL'))
                        ? Object.values(simpleParsedInfo.find((info: any) => (Object.values(info.goodsNm)[0] === goodsNm.goodsNm)
                            && info.name && info.name.includes('URL')).data)[0]
                        : '',
                }
            });
            const indentedParsedInfo = parsedInfo.map((d: any) => ({
                ...d,
                url: d.url.split('<br/>').filter((text: any) => text !== ''),
            }));
            // console.log('parsedInfo', parsedInfo);
            console.log('indentedParsedInfo', indentedParsedInfo);
            setValue('autoTextArea', `${basicInfo}${indentedParsedInfo.map(d => {
                return `\n상품명: ${d.goodsNm}\n구매갯수: ${d.goodsCount}\nid: ${d.id}${d.url.length > 0
                    ? d.url.map((url: any) => `\n${url}`).join('')
                    : ''}`
            }).join('\n')}`);
        }
    };

    useEffect(() => {
        setValue('orderPhone', watch('orderPhone')?.replaceAll('-', ''));
    }, [ watch('orderPhone') ]);

    useEffect(() => {
        (async () => {
            await getBiscuit();
        })();
    }, []);

    return (
        <>
            <RootContainer>
                <form onSubmit={handleSubmit(registerSubmit)}>
                    <Divider orientation='left' style={{ fontWeight: 'bold' }}>고객 정보</Divider>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Controller
                            name='orderNo'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Form.Item
                                    label='주문번호'
                                    labelCol={{ span: 24 }}
                                >
                                    <Input
                                        {...register('orderNo', {
                                            required: '주문번호를 입력해주세요. 주문번호가 없는 주문은 랜덤을 눌러주세요.',
                                        })}
                                        value={value}
                                        onChange={onChange}
                                        placeholder='orderNo'
                                        type='number'
                                        id='orderNo'
                                    />
                                    <Button
                                        onClick={() => setValue('orderNo', getRandomOrderNumber())}
                                    >랜덤</Button>
                                </Form.Item>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='orderNo'
                            render={({ message }) => <ErrorP>{message}</ErrorP>}
                        />
                        <Controller
                            name='customerName'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Form.Item
                                    label='고객명'
                                    labelCol={{ span: 24 }}
                                >
                                    <Input
                                        value={value}
                                        onChange={onChange}
                                        placeholder='customerName'
                                        type='text'
                                        id='customerName'
                                    />
                                </Form.Item>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='customerName'
                            render={({ message }) => <ErrorP>{message}</ErrorP>}
                        />
                        <Controller
                            name='orderPhone'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Form.Item
                                    label='전화번호'
                                    labelCol={{ span: 24 }}
                                >
                                    <Input
                                        {...register('orderPhone', {
                                            validate: (value) => ((value && watch('needAligoSMS')) || !watch('needAligoSMS'))
                                            || '번호를 입력해주세요'
                                        })}
                                        value={value}
                                        onChange={e => {
                                            setValue('needAligoSMS', e.target.value !== '');
                                            onChange(e);
                                        }}
                                        placeholder='orderPhone'
                                        type='phone'
                                        id='orderPhone'
                                    />
                                </Form.Item>
                            )}
                        />
                        <Controller
                            name='needAligoSMS'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Checkbox
                                    checked={value}
                                    defaultChecked={false}
                                    id='needAligoSMS'
                                    onChange={e => onChange(e.target.checked)}
                                    style={{ marginBottom: 24 }}
                                >
                                    알림톡
                                </Checkbox>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='orderPhone'
                            render={({ message }) => <ErrorP>{message}</ErrorP>}
                        />
                        <Controller
                            name='price'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Form.Item
                                    label='명목상 구매가격'
                                    labelCol={{ span: 24 }}
                                >
                                    <Input
                                        {...register('price', {
                                            required: '가격을 입력해주세요',
                                        })}
                                        value={value}
                                        onChange={onChange}
                                        placeholder='price'
                                        type='number'
                                        id='price'
                                    />
                                </Form.Item>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name={`price`}
                            render={({ message }) => <ErrorP>{message}</ErrorP>}
                        />
                        <div style={{ height: 20 }} />
                        <Divider orientation='left' style={{ fontWeight: 'bold' }}>Auto "링크:갯수", 줄바꿈으로구분</Divider>
                        <Controller
                            name='autoTextArea'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextArea
                                    placeholder='ex) https://www.instagram.com/p/asdf/?dfsds : 10'
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    onChange={onChange}
                                    value={value}
                                    id='autoTextArea'
                                />
                            )}
                        />
                        <Button
                            type='dashed'
                            onClick={() => {
                                autoOrderMake(watch('autoTextArea'))
                            }}
                            style={{ backgroundColor: 'lightgray' }}
                        >
                            복사한거 붙여넣어서 자동 생성 v1
                        </Button>
                        <Button
                            type='dashed'
                            onClick={() => {
                                autoOrderMakeV2(watch('autoTextArea'))
                            }}
                            style={{ backgroundColor: 'lightgreen' }}
                        >
                            버튼눌러서 온 경우 자동 생성 v2
                        </Button>
                        { showPromotion &&
                            <div style={{
                                position: 'relative',
                                border: '1px solid #d9d9d9',
                                padding: 20,
                                margin: '20px 0',
                                borderRadius: 4,
                                backgroundColor: 'lightgreen'
                            }}>
                                <button
                                    type='button'
                                    onClick={() => {
                                        confirm({
                                            title: '이 필드를 지우시겠습니까?',
                                            okText: 'Yes',
                                            okType: 'danger',
                                            cancelText: 'No',
                                            onOk() {
                                                resetField('memNo');
                                                resetField('promotionTargetId');
                                                setShowPromotion(false);
                                            },
                                            onCancel() {
                                                console.log('Cancel');
                                            },
                                        });
                                    }}
                                    style={{
                                        padding: 10,
                                        position: 'absolute',
                                        top: -20,
                                        right: -20,
                                    }}
                                >
                                    <CloseCircleOutlined style={{
                                        fontSize: 26,
                                        color: '#a9a9a9',
                                        backgroundColor: 'white',
                                    }} />
                                </button>
                                <div style={{ fontWeight: 'bold' }}>무료 팔로워</div>
                                <Controller
                                    name='memNo'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Item
                                            label='회원번호'
                                            labelCol={{ span: 24 }}
                                        >
                                            <Input
                                                {...register('memNo', {
                                                    validate: (value) => ((value && showPromotion) || !showPromotion)
                                                    || '회원번호를 입력해주세요'
                                                })}
                                                value={value}
                                                onChange={onChange}
                                                placeholder='memNo'
                                                type='number'
                                                id='memNo'
                                            />
                                        </Form.Item>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`memNo`}
                                    render={({ message }) => <ErrorP>{message}</ErrorP>}
                                />
                                <Controller
                                    name='promotionTargetId'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Item
                                            label='프로모션 ID'
                                            labelCol={{ span: 24 }}
                                        >
                                            <Input
                                                {...register(`promotionTargetId`, {
                                                    validate: (value) => ((value && showPromotion) || !showPromotion)
                                                    || 'targetId를 입력해주세요'
                                                })}
                                                value={value}
                                                onChange={onChange}
                                                placeholder='promotionTargetId'
                                                type='text'
                                                id='promotionTargetId'
                                            />
                                        </Form.Item>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name='promotionTargetId'
                                    render={({ message }) => <ErrorP>{message}</ErrorP>}
                                />
                            </div>
                        }
                        <div>
                            { fields.map(({ id }, index) => (
                                <div style={{
                                    position: 'relative',
                                    border: '1px solid #d9d9d9',
                                    padding: 20,
                                    marginBottom: 20,
                                    borderRadius: 4,
                                }}>
                                    { index > 0 &&
                                        <button
                                            type='button'
                                            onClick={() => deleteConfirm(index)}
                                            style={{
                                                padding: 10,
                                                position: 'absolute',
                                                top: -20,
                                                right: -20,
                                            }}
                                        >
                                            <CloseCircleOutlined style={{
                                                fontSize: 26,
                                                color: '#a9a9a9',
                                                backgroundColor: 'white',
                                            }} />
                                        </button>
                                    }
                                    <Controller
                                        name={`orders.${index}.taskType`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Form.Item
                                                    label='주문종류'
                                                    labelCol={{ span: 24 }}
                                                >
                                                    <Radio.Group
                                                        options={[
                                                            { label: '좋아요', value: 'like' },
                                                            { label: '팔로우', value: 'follow' },
                                                            { label: '릴스', value: 'like_reels' },
                                                            // { label: '댓글', value: 'comment' },
                                                        ]}
                                                        onChange={e => onChange(e.target.value)}
                                                        value={watch('orders')[index].taskType}
                                                        optionType='button'
                                                    />
                                                </Form.Item>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={`orders.${index}.isForeigner`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Checkbox
                                                    checked={value}
                                                    defaultChecked={false}
                                                    id='isForeigner'
                                                    onChange={e => onChange(e.target.checked)}
                                                    style={{ marginBottom: 24 }}
                                                >
                                                    외국인
                                                </Checkbox>
                                            )
                                        }}
                                    />
                                    <div>
                                        <Controller
                                            name={`orders.${index}.isService`}
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox
                                                    checked={value}
                                                    defaultChecked={false}
                                                    id='isService'
                                                    onChange={e => onChange(e.target.checked)}
                                                    style={{ marginBottom: 24 }}
                                                >
                                                    서비스
                                                </Checkbox>
                                            )}
                                        />
                                    </div>
                                    <Controller
                                        name={`orders.${index}.progressCnt`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='실제 개수'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.progressCnt`, {
                                                        required: '개수를 입력해주세요',
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='progressCnt'
                                                    type='number'
                                                    id={`orders.${index}.progressCnt`}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.progressCnt`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Controller
                                        name={`orders.${index}.displayingCnt`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='외부용 개수'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.displayingCnt`, {
                                                        required: '개수를 입력해주세요',
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='displayingCnt'
                                                    type='number'
                                                    id={`orders.${index}.displayingCnt`}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.displayingCnt`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Controller
                                        name={`orders.${index}.targetId`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='아이디'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.targetId`, {
                                                        required: '닉네임을 입력해주세요',
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='targetId'
                                                    type='text'
                                                    id={`orders.${index}.targetId`}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.targetId`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Controller
                                        name={`orders.${index}.targetUrl`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='타겟 URL'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.targetUrl`, {
                                                        validate: (value) => ((value && (watch(`orders.${index}.taskType`) !== 'follow'))
                                                            || watch(`orders.${index}.taskType`) === 'follow')
                                                        || 'url을 입력해주세요'
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='targetUrl'
                                                    type='text'
                                                    id={`orders.${index}.targetUrl`}
                                                    disabled={watch(`orders.${index}.taskType`) === 'follow'}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.targetUrl`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Controller
                                        name={`orders.${index}.purchaseCnt`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Form.Item
                                                label='구매개수'
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input
                                                    {...register(`orders.${index}.purchaseCnt`, {
                                                        required: '개수를 입력해주세요',
                                                    })}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='purchaseCnt'
                                                    type='number'
                                                    id={`orders.${index}.purchaseCnt`}
                                                />
                                            </Form.Item>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`orders.${index}.purchaseCnt`}
                                        render={({ message }) => <ErrorP>{message}</ErrorP>}
                                    />
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            append({
                                                // @ts-ignore
                                                platformType: 'insta',
                                                // @ts-ignore
                                                taskType: watch(`orders.${index}.taskType`),
                                                progressCnt: watch(`orders.${index}.progressCnt`),
                                                displayingCnt: watch(`orders.${index}.displayingCnt`),
                                                targetUrl: '',
                                                targetId: watch(`orders.${index}.targetId`),
                                                isForeigner: watch(`orders.${index}.isForeigner`),
                                                purchaseCnt: '1',
                                            })
                                        }}
                                        icon={<PlusOutlined />}
                                        style={{ flex: 1 }}
                                    >
                                        복제하기
                                    </Button>
                                </div>
                            ))}
                        </div>
                        <Button
                            type="dashed"
                            onClick={() => append({
                                // @ts-ignore
                                platformType: 'insta',
                                // @ts-ignore
                                taskType: 'like',
                                progressCnt: undefined,
                                displayingCnt: undefined,
                                targetUrl: '',
                                targetId: '',
                                isForeigner: false,
                                purchaseCnt: '1',
                            })}
                            icon={<PlusOutlined />}
                            style={{ flex: 1 }}
                        >
                            주문 추가
                        </Button>
                        { (fields.length > 0 || showPromotion)
                            && <Button
                                type='primary'
                                htmlType='submit'
                                style={{ marginTop: 40 }}
                            >
                                제출하기
                            </Button>
                        }
                    </div>
                </form>
            </RootContainer>
        </>
    );
};

export default ManualOrderAutoForm;
