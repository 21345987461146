import { RightOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal, Divider } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChangeStaffPasswordDto, StaffDto } from '../../apiTypes';
import { authStore } from '../../authStore';
import Mask from '../../components/mask';
import repository from '../../repository';

const AdminSetting = () => {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ staffInfo, setStaffInfo ] = useState<StaffDto>();
    const [ changePasswordModal, setChangePasswordModal ] = useState<boolean>(false);
    const [ changePasswordValue, setChangePasswordValue ] = useState<ChangeStaffPasswordDto>({
        curPass: '',
        newPass: '',
    });
    const navigate = useNavigate();

    const logoutAdmin = () => {
        if (window.confirm('로그아웃을 하시겠습니까?')) {
            setLoading(true);
            authStore.deleteToken();
            console.log('authStore.token', authStore.token);
            if (authStore.token === null) {
                navigate('/login', { replace: true });
            }
            setLoading(false);
        }
    };

    const getStaffInfo = async () => {
        try {
            const rs = await repository.getStaff();
            if (rs) {
                console.log('rs', rs);
                setStaffInfo(rs);
            }
        } catch (err) {
            message.error(`${err}`);
            console.log('err', err);
        };
    };

    const changePassword = async (payload: ChangeStaffPasswordDto) => {
        if (payload.curPass === '' || payload.newPass === '') {
            message.error('모두 입력해주세요.');
            return;
        }
        try {
            const rs = await repository.changeStaffPassword(payload);
            if (rs) {
                console.log(rs);
                message.success('비밀번호 변경 성공');
                setChangePasswordModal(false);
                setChangePasswordValue({
                    curPass: '',
                    newPass: '',
                });
            }
        } catch (err) {
            message.error(`${err}`);
            console.log('err', err);
        };
    };

    useEffect(() => {
        (async () => {
            await getStaffInfo();
        })();   
    }, []);

    return (
        <>
            <Modal
                open={changePasswordModal}
                title='비밀번호 변경'
                onCancel={() => {
                    setChangePasswordModal(false);
                    setChangePasswordValue({
                        curPass: '',
                        newPass: '',
                    });
                }}
                onOk={() => {
                    (async () => {
                        await changePassword(changePasswordValue);
                    })();
                }}
            >
                <div>현재 비밀번호</div>
                <Input
                    value={changePasswordValue.curPass}
                    onChange={e => setChangePasswordValue(prev => ({
                        ...prev,
                        curPass: e.target.value,
                    }))}
                    type='password'
                />
                <div style={{ marginTop: 5 }}>변경할 패스워드</div>
                <Input
                    value={changePasswordValue.newPass}
                    onChange={e => setChangePasswordValue(prev => ({
                        ...prev,
                        newPass: e.target.value,
                    }))}
                    type='password'
                />
                <div style={{ color: 'gray', fontSize: 12 }}>비밀번호는 15자리 이상, 영어 대소문자 포함, 숫자 포함, 특수문자를 포함해야합니다.</div>
            </Modal>
            <div style={{ padding: 50, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                { loading && <Mask /> }
                { staffInfo &&
                    <div style={{ display: 'flex', borderRadius: 5, border: '1px lightgray solid', backgroundColor: '#fafafa' }}>
                        {/* <div style={{ fontWeight: 'bold' }}>staff 정보</div> */}
                        <div style={{ padding: 20, flex: 1 }}>
                            <div style={{ fontSize: 20 }}>{staffInfo.name}</div>
                            <div style={{ color: 'gray' }}>{staffInfo.email}</div>
                            <div style={{ height: 1, backgroundColor: 'lightgray', margin: '10px 0' }}></div>
                            <div style={{ color: 'gray' }}>{staffInfo.id}</div>
                        </div>
                    </div>
                }
                <div style={{ marginTop: 30, display: 'flex', flex: 1, justifyContent: 'flex-start', flexDirection: 'column' }}>
                    <Divider orientation='left'>설정</Divider>
                    <button
                        style={{ padding: '10px 20px', borderBottom: '1px lightgray solid', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                        onClick={() => {
                            setChangePasswordModal(true);
                        }}
                    >
                        <div>비밀번호 변경 </div>
                        <div><RightOutlined /></div>
                    </button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 50 }}>
                    <button
                        style={{ padding: 5, backgroundColor: '#efefef' }}
                        onClick={logoutAdmin}
                    >
                        로그아웃
                    </button>
                </div>
            </div>
        </>
    );
};

export default AdminSetting;