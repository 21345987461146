import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";

type EditReservedModalValue = {
    orderTaskId: string;
    reservedDt: string | undefined;
    initialReservedDt: string;
};

interface IEditReservedStore {
    editReservedModalOpen: boolean;
    editReservedModalValue: EditReservedModalValue;
};

const INITIAL_STATES: IEditReservedStore = {
    editReservedModalOpen: false,
    editReservedModalValue: {
        orderTaskId: '',
        reservedDt: moment().format(),
        initialReservedDt: '',
    },
};

class EditReservedStore implements IEditReservedStore {

    private _editReservedModalOpen = INITIAL_STATES.editReservedModalOpen;
    private _editReservedModalValue = INITIAL_STATES.editReservedModalValue;

    constructor() {
        makeObservable<
            EditReservedStore,
            '_editReservedModalOpen' | '_editReservedModalValue'
        >(this, {
            _editReservedModalOpen: observable,
            _editReservedModalValue: observable,
            editReservedModalOpen: computed,
            editReservedModalValue: computed,
            handleEditReservedModalOpen: action.bound,
            handleEditReservedModalValue: action.bound,
        });
    };

    get editReservedModalOpen() {
        return this._editReservedModalOpen;
    };

    get editReservedModalValue() {
        return this._editReservedModalValue;
    };

    handleEditReservedModalOpen(open: boolean) {
        this._editReservedModalOpen = open;
    };

    handleEditReservedModalValue(value: EditReservedModalValue) {
        this._editReservedModalValue = value;
    };

    clear() {
        this._editReservedModalOpen = INITIAL_STATES.editReservedModalOpen;
        this._editReservedModalValue = INITIAL_STATES.editReservedModalValue;
    };

};

const editReservedStore = new EditReservedStore();
export default editReservedStore;