import styled from "@emotion/styled";
import { Button, Checkbox, Input, message, Modal } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from "react-table";
import { CustomerDto } from '../../apiTypes';
import repository from "../../repository";
import MaUTable from '@mui/material/Table';
import { TableBody, TableCell, TableRow, TableHead } from "@mui/material";
import { ArrowDownOutlined, ArrowUpOutlined, SyncOutlined, UndoOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

const TopToolBar = styled.div`
display: flex;
justify-content: center;
flex-direction: row;
margin: 10px 0;
@media screen and (max-width: 500px) {
    flex-direction: column;
}
`;

// @ts-ignore
const Table = ({ columns, data, refresh }) => {
    const {
        getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
    } = useTable({ columns, data },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    return (
        <>
        <TopToolBar>
        <button
            onClick={() => {
                ( async () => {
                    await refresh();
                    message.success('새로고침');
                })();
            }}
            style={{ border: '1px lightgray solid', padding: '5px 20px', flex: 1 }}
        >새로고침
            <SyncOutlined
                spin={false}
                style={{ fontSize: 14, marginLeft: 5 }}
            />
        </button>
        </TopToolBar>
        <MaUTable {...getTableProps()} stickyHeader>
            <TableHead style={{ backgroundColor: '#fafafa' }}>
            {headerGroups.map(header => (
                <TableRow {...header.getHeaderGroupProps()}>
                {header.headers.map(col => (
                    // @ts-ignore
                    <TableCell {...col.getHeaderProps(col.getSortByToggleProps())}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {col.render('Header')}
                            <div>
                                {col.render('Filter')}
                            </div>
                            <span>
                                {/* @ts-ignore */}
                                {col.isSorted ? col.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                            </span>
                        </div>
                    </TableCell>
                ))}
                </TableRow>
            ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                return (
                <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => (
                        <CopyToClipboard
                            text={cell.value}
                            onCopy={(text, result) => {
                                if (result) {
                                    message.success(`${cell.value}`);
                                } else {
                                    message.error(`${cell.value}`);
                                }
                            }}
                        >
                            <TableCell
                                {...cell.getCellProps()}
                                style={{
                                    cursor: 'pointer',
                                    // @ts-ignore
                                    width: cell.column.originalWidth,
                                    padding: 10,
                                }}
                            >
                                {cell.render('Cell')}
                            </TableCell>
                        </CopyToClipboard>
                    ))}
                </TableRow>
                );
            })}
        </TableBody>
        </MaUTable>
        </>
    );
};

const Customer = () => {

    const [ customerList, setCustomerList ] = useState<Array<CustomerDto>>();
    const [ selectedCustomer, setSelectedCustomer ] = useState<CustomerDto>();
    const [ settingModal, setSettingModal ] = useState<boolean>(false);
    const [ changePasswordModal, setChangePasswordModal ] = useState<boolean>(false);
    const [ changePasswordValue, setChangePasswordValue ] = useState<string>('');
    const [ changeCustomerInfoModal, setChangeCustomerInfoModal ] = useState<boolean>(false);
    const [ changeCustomerInfoValue, setChangeCustomerInfoValue ] = useState<{
        phone: string;
        needChangePassword: boolean;
    }>({
        phone: '',
        needChangePassword: true,
    });

    const getCustomerList = useCallback(async () => {
        try {
            const rs = await repository.getCustomers();
            if (rs) {
                console.log('rs', rs);
                setCustomerList(rs);
            }
        } catch (err) {
            console.log('getCustomerList err', err);
        };
    }, [ ]);

    const changePassword = useCallback(async (customerId: string, newPassword: string) => {
        try {
            const payload = {
                customerId: customerId,
                newPassword: newPassword,
            }
            const rs = await repository.changeCustomerPassword(payload);
            if (rs) {
                message.success('비밀번호 변경에 성공했습니다.');
                return;
            }
        } catch (err) {
            console.log('changePassword err', err);
            message.error('비밀번호 변경에 실패했습니다.');
        };
    }, []);

    const resetPassword = useCallback(async (customerId: string, phoneNumber: string) => {
        try {
            if (window.confirm('비밀번호를 리셋하시겠습니까?')){
                const payload = {
                    customerId: customerId,
                    newPassword: phoneNumber,
                }
                const rs = await repository.changeCustomerPassword(payload);
                if (rs) {
                    message.success('비밀번호 리셋에 성공했습니다.');
                    return;
                }
            }
            return;
        } catch (err) {
            console.log('resetPassword err', err);
            message.error('비밀번호 리셋에 실패했습니다.');
        };
    }, []);

    const changeCustomerInfo = useCallback(async (customerId: string, info: {
        phone: string;
        needChangePassword: boolean;
    }) => {
        try {
            const payload = {
                id: customerId,
                phone: info.phone,
                needChangePassword: info.needChangePassword,
            };
            const rs = await repository.updateCustomerInfo(payload);
            if (rs) {
                message.success('고객정보 수정에 성공했습니다.');
                return;
            }
            return;
        } catch (err) {
            console.log('changeCustomerInfo err', err);
            message.error('고객정보 수정에 실패했습니다.');
        };
    }, []);

    const columns = useMemo(() => [
        {
            accessor: 'phone',
            Header: '번호',
            Filter: () => {},
            width: 100,
        },
        {
            accessor: 'needChangePassword',
            Header: '',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                const { phone } = cell.row.values;
                return (
                    <div>
                        <Button
                            onClick={e => {
                                e.stopPropagation();
                                openCustomerPage(phone);
                            }}
                        >링크</Button>
                        <Button
                            style={{ backgroundColor: cell.value ? '#ffdddd' : '#ddffdd' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                //@ts-ignore
                                setSelectedCustomer(cell.row.values);
                                setSettingModal(true);
                            }}
                        >설정</Button>
                    </div>
                );
            },
            width: 150,
        },
        {
            accessor: 'id',
            Header: 'id',
            Filter: () => {},
            width: 250,
        },
    ], []);

    const openCustomerPage = useCallback(async (phone: string) => {
        try {
            const res = await repository.getCustomerToken(phone);
            if (!res?.customer?.id) {
                throw new Error('cannot found customer id.');
            }

            window.open(`${process.env.REACT_APP_LANDING_PAGE_URL}/customers/${res.customer.id}`);
        } catch (err) {
            console.log(err);
            message.error(JSON.stringify(err));
        }
    }, []);

    const tableData = useMemo(() =>
        customerList,
    [customerList]);

    useEffect(() => {
        const receiveTokenFromASite = async (event: any) => {
            if (event.origin !== `${process.env.REACT_APP_LANDING_PAGE_URL}`) {
                return;
            }

            const message = JSON.parse(event.data);
            if (message.type === 'ADMIN_CHECKED' && message.customerId) {
                const customers = await repository.getCustomers();
                const target = customers?.find(d => d.id === message.customerId);
                if (!target?.phone) {
                    return;
                }
                const res = await repository.getCustomerToken(target.phone);
                if (!res.token) {
                    throw new Error('cannot found token.');
                }

                if (!res?.customer?.id) {
                    throw new Error('cannot found customer id.');
                }


                const dataToSend = {
                    customerToken: res.token,
                    customerId: message.customerId,
                };
                event.source?.postMessage(dataToSend, `${process.env.REACT_APP_LANDING_PAGE_URL}`);
            }
        };

        window.addEventListener('message', receiveTokenFromASite);
        return () => {
            window.removeEventListener('message', receiveTokenFromASite);
        };
    }, []);

    useEffect(() => {
        (async () => {
            await getCustomerList();
        })();
    }, [ ]);

    return (
        <>
            <Modal
                open={settingModal}
                title={'설정'}
                onOk={() => {
                    setSettingModal(false);
                    setSelectedCustomer(undefined);
                }}
                onCancel={() => {
                    setSettingModal(false);
                    setSelectedCustomer(undefined);
                }}
            >
                <div>{`번호: ${selectedCustomer?.phone}`}</div>
                <div style={{ marginBottom: 20 }}>{`id: ${selectedCustomer?.id}`}</div>
                <Button
                    style={{ backgroundColor: '#ff6666', color: 'white' }}
                    onClick={() => {
                        setChangePasswordModal(true);
                        setSettingModal(false);
                    }}
                >
                    비밀번호 수정
                </Button>
                <Button
                    onClick={() => {
                        setChangeCustomerInfoModal(true);
                        setSettingModal(false);
                    }}
                >
                    고객정보 수정
                </Button>
            </Modal>
            <Modal
                open={changePasswordModal}
                title={'비밀번호 수정'}
                onOk={() => {
                    if (!selectedCustomer?.id || changePasswordValue === ''){
                        message.error('값을 확인해주세요');
                        return;
                    }
                    if (changePasswordValue.length < 4) {
                        message.error('비밀번호는 4자리 이상이어야 합니다.');
                        return;
                    }
                    ( async () => {
                        await changePassword(selectedCustomer.id, changePasswordValue);
                        await getCustomerList();
                        setChangePasswordValue('');
                    })();
                    // setChangePasswordModal(false);
                }}
                onCancel={() => {
                    setChangePasswordModal(false);
                    setChangePasswordValue('');
                }}
            >
                <div>{`번호: ${selectedCustomer?.phone}`}</div>
                <div>{`id: ${selectedCustomer?.id}`}</div>
                <Button
                    style={{ backgroundColor: '#aaffaa' }}
                    onClick={() => {
                        if (!selectedCustomer?.id || !selectedCustomer?.phone ){
                            message.error('값이 이상합니다.');
                            return;
                        }
                        ( async () => {
                            await resetPassword(selectedCustomer.id, selectedCustomer.phone);
                        })();
                    }}
                >
                    비밀번호 초기화 <UndoOutlined />
                </Button>
                <div style={{ color: 'red', marginTop: 20 }}>비밀번호 설정, 웬만하면 초기화 버튼을 사용하세요</div>
                <Input
                    placeholder='4자리 이상 문자가 필요합니다.'
                    value={changePasswordValue}
                    onChange={(e) => {
                        setChangePasswordValue(e.target.value);
                    }}
                />
            </Modal>
            <Modal
                open={changeCustomerInfoModal}
                title={'회원정보 수정'}
                onOk={() => {
                    if (!selectedCustomer?.id || changeCustomerInfoValue.phone === ''){
                        message.error('값을 확인해주세요');
                        return;
                    }
                    ( async () => {
                        await changeCustomerInfo(selectedCustomer.id, changeCustomerInfoValue);
                        await getCustomerList();
                        setChangeCustomerInfoValue({
                            phone: '',
                            needChangePassword: true,
                        });
                    })();
                }}
                onCancel={() => {
                    setChangeCustomerInfoModal(false);
                    setChangeCustomerInfoValue({
                        phone: '',
                        needChangePassword: true,
                    });
                }}
            >
                <div>{`번호: ${selectedCustomer?.phone}`}</div>
                <div>{`id: ${selectedCustomer?.id}`}</div>
                <div style={{ marginTop: 20 }}>핸드폰 번호</div>
                <Input
                    placeholder={selectedCustomer?.phone}
                    value={changeCustomerInfoValue.phone}
                    onChange={e =>
                        setChangeCustomerInfoValue(prev => ({
                            ...prev,
                            phone: e.target.value,
                        }))
                    }
                />
                <Checkbox
                    checked={changeCustomerInfoValue.needChangePassword}
                    onChange={e => setChangeCustomerInfoValue(prev => ({
                        ...prev,
                        needChangePassword: e.target.checked,
                        }))
                    }
                    style={{ marginTop: 10 }}
                >
                    비밀번호 변경 필요
                </Checkbox>
            </Modal>
            { tableData && tableData.length > 0
                ?  <Table
                    columns={columns}
                    data={tableData}
                    refresh={getCustomerList}
                />
                : <div>고객정보가 없습니다.</div>
            }
        </>
    );
};

export default Customer;