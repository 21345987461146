import { ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined, CheckOutlined, ClockCircleOutlined, ExclamationCircleOutlined, ExportOutlined, HeartOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Descriptions, Input, message, Modal } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from "react-table";
import { ExternalServiceJobDto, OrderProgressDto } from "../../apiTypes";
import repository from "../../repository";
import MaUTable from '@mui/material/Table';
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import editOrderStore from "../../stores/editOrderStore";
import { observer } from "mobx-react";
import editCountStore from "../../stores/editCountStore";

interface IEditOrderModal {
    refresh: () => Promise<void>;
};

const EditOrderModal: React.FC<IEditOrderModal> = observer((props) => {
    const [ loading, setLoading ] = useState<boolean>(false);

    const handleTargetIdChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        editOrderStore.handleEditOrderModalValue({
            ...editOrderStore.editOrderModalValue,
            targetId: e.target.value,
        });
    }, []);

    const handleTargetUrlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        editOrderStore.handleEditOrderModalValue({
            ...editOrderStore.editOrderModalValue,
            targetUrl: e.target.value,
        });
    }, []);

    return (
        <Modal
            open={editOrderStore.editOrderModalOpen}
            title='주문 정보 수정'
            onCancel={() => {
                editOrderStore.handleEditOrderModalValue({
                    orderTaskId: '',
                    targetId: '',
                    targetUrl: '',
                });
                editOrderStore.handleEditOrderModalInitialValue({
                    targetId: '',
                    targetUrl: '',
                });
                editOrderStore.handleEditOrderModalOpen(false);
            }}
            footer={[
                <Button
                    key='back'
                    onClick={() => {
                        editOrderStore.handleEditOrderModalValue({
                            orderTaskId: '',
                            targetId: '',
                            targetUrl: '',
                        });
                        editOrderStore.handleEditOrderModalInitialValue({
                            targetId: '',
                            targetUrl: '',
                        });
                        editOrderStore.handleEditOrderModalOpen(false);
                    }}
                >
                    취소
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    loading={loading}
                    onClick={() => {
                        (async () => {
                            try {
                                setLoading(true);
                                if (!editOrderStore.editOrderModalValue.orderTaskId) {
                                    throw new Error('주문정보 수정을 실패하였습니다.');
                                }

                                let payload: any = {};
                                if (editOrderStore.editOrderModalValue.targetId) {
                                    payload.targetId = editOrderStore.editOrderModalValue.targetId;
                                }
                                if (editOrderStore.editOrderModalValue.targetUrl) {
                                    payload.targetUrl = editOrderStore.editOrderModalValue.targetUrl;
                                }
                                await repository.updateOrder(editOrderStore.editOrderModalValue.orderTaskId, payload);
                                message.success('주문정보 수정이 완료되었습니다.');
                                setLoading(false);
                                editOrderStore.handleEditOrderModalOpen(false);
                                await props.refresh();
                                editOrderStore.handleEditOrderModalValue({
                                    orderTaskId: '',
                                    targetId: '',
                                    targetUrl: '',
                                });
                                editOrderStore.handleEditOrderModalInitialValue({
                                    targetId: '',
                                    targetUrl: '',
                                });
                            } catch (error) {
                                setLoading(false);
                                editOrderStore.handleEditOrderModalOpen(false);
                                message.error(`${error}`);
                            }
                        })();
                    }}
                >
                    저장
                </Button>,
            ]}
        >
            <div>
                { editOrderStore.editOrderModalInitialValues.targetId &&
                    <Input
                        addonBefore={<div>targetId</div>}
                        placeholder='targetId'
                        value={editOrderStore.editOrderModalValue.targetId}
                        onChange={handleTargetIdChange}
                    />
                }
                <div style={{ height: 10 }} />
                { editOrderStore.editOrderModalInitialValues.targetUrl &&
                    <Input
                        addonBefore={<div>targetUrl</div>}
                        placeholder='targetUrl'
                        value={editOrderStore.editOrderModalValue.targetUrl}
                        onChange={handleTargetUrlChange}
                    />
                }
                { editOrderStore.editOrderModalInitialValues.targetId &&
                    <>
                        <div style={{ fontWeight: 'bold', marginBottom: 5, marginTop: 30 }}>targetId</div>
                        <Descriptions bordered>
                            <Descriptions.Item label='수정 전'>{editOrderStore.editOrderModalInitialValues.targetId}</Descriptions.Item>
                            <Descriptions.Item label='수정 후'>
                                <div>{editOrderStore.editOrderModalValue.targetId}</div>
                                <a
                                    href={`https://www.instagram.com/${editOrderStore.editOrderModalValue.targetId}`}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    링크
                                </a>
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                }
                { editOrderStore.editOrderModalInitialValues.targetUrl &&
                    <>
                        <div style={{ fontWeight: 'bold', marginBottom: 5 }}>targetUrl</div>
                        <Descriptions bordered>
                            <Descriptions.Item label='수정 전'>{editOrderStore.editOrderModalInitialValues.targetUrl}</Descriptions.Item>
                            <Descriptions.Item label='수정 후'>
                                <div>{editOrderStore.editOrderModalValue.targetUrl}</div>
                                <a
                                    href={`https://www.instagram.com/p/${editOrderStore.editOrderModalValue.targetUrl}`}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    링크
                                </a>
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                }
            </div>
        </Modal>
    );
});

interface ICountModal {
    refresh: () => Promise<void>;
}

const EditCountModal: React.FC<ICountModal> = observer((props) => {

    const handleDisplayingAndTotalCntChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        editCountStore.handleEditCountModalValue({
            displayingCnt: parseInt(e.target.value),
            totalCnt: Math.ceil(parseInt(e.target.value) * 1.35),
        });
    }, []);

    const handleTotalCntChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        editCountStore.handleEditCountModalValue({
            ...editCountStore.editCountModalValue,
            totalCnt: parseInt(e.target.value),
        });
    }, []);

    return (
        <Modal
            open={editCountStore.editCountModalOpen}
            title='작업량 수정'
            onOk={() => {
                if (!editCountStore.editCountModalValue || !editCountStore.editCountInitialModalValue.orderTaskId
                    || !editCountStore.editCountModalValue.totalCnt || !editCountStore.editCountModalValue.displayingCnt) {
                    message.error('값에 문제가 있습니다.');
                    return false;
                }
                if (editCountStore.editCountModalValue.totalCnt < editCountStore.editCountModalValue.displayingCnt) {
                    message.error('실제 갯수가 보이는 갯수보다 커야합니다.');
                    return false;
                }
                if ((editCountStore.editCountInitialModalValue.progressCnt + editCountStore.editCountInitialModalValue.remainScheduleCnt) > editCountStore.editCountModalValue.totalCnt) {
                    message.error(`실제 갯수가 ${editCountStore.editCountInitialModalValue.progressCnt + editCountStore.editCountInitialModalValue.remainScheduleCnt}보다 더 커야합니다.`);
                    return false;
                }
                ( async () => {
                    try {
                        const rs = await repository.updateProgressCount(editCountStore.editCountInitialModalValue.orderTaskId, editCountStore.editCountModalValue);
                        console.log('rs', rs);
                        if (rs) {
                            message.success('수정했습니다.');
                            await props.refresh();
                            editCountStore.clear();
                        }
                    } catch (err) {
                        message.error(`${err}`);
                    }
                })();
            }}
            onCancel={() => {
                editCountStore.clear();
            }}
        >
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10, padding: '0 10px', borderRight: '1px lightgray solid' }}>
                        <div>{`${editCountStore.editCountInitialModalValue.progressCnt} / ${editCountStore.editCountInitialModalValue.totalCnt}`}</div>
                        <div style={{ color: '#F77737', fontWeight: 'bold', fontSize: 16, textAlign: 'center' }}>
                            { editCountStore.editCountInitialModalValue.remainScheduleCnt > 0
                                ? (
                                    <>
                                        <PlayCircleOutlined style={{ marginRight: 4, color: '#F77737' }} />
                                        <span>{editCountStore.editCountInitialModalValue.remainScheduleCnt > 0 ? editCountStore.editCountInitialModalValue.remainScheduleCnt : '-'}</span>
                                    </>
                                )
                                : (
                                    <PauseCircleOutlined style={{ marginRight: 4, color: '#c2c2c2' }} />
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <div>아이디 : {editCountStore.editCountInitialModalValue.targetId}
                            <a
                                target='_blank'
                                href={`https://www.instagram.com/${editCountStore.editCountInitialModalValue.targetId}`}
                                rel="noreferrer noopener"
                                style={{ marginLeft: 10 }}
                            >
                            링크</a>
                        </div>
                        { editCountStore.editCountInitialModalValue.targetUrl !== '' &&
                            <div>URL : {editCountStore.editCountInitialModalValue.targetUrl}
                                <a
                                    target='_blank'
                                    href={`https://www.instagram.com/reel/${editCountStore.editCountInitialModalValue.targetUrl}`}
                                    rel="noreferrer noopener"
                                    style={{ marginLeft: 10 }}
                                >
                                링크</a>
                            </div>
                        }
                    </div>
                </div>
                <div>보여야 할 갯수</div>
                <Input
                    placeholder='displayingCnt'
                    value={editCountStore.editCountModalValue.displayingCnt}
                    onChange={handleDisplayingAndTotalCntChange}
                    type='number'
                />
                <div>실제로 찍을 갯수</div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div>{editCountStore.editCountInitialModalValue.totalCnt}</div>
                    <ArrowRightOutlined style={{ margin: '0 10px' }} />
                    <Input
                        placeholder='totalCnt'
                        value={editCountStore.editCountModalValue.totalCnt}
                        onChange={handleTotalCntChange}
                        type='number'
                    />
                </div>
            </div>
        </Modal>
    );
});

// @ts-ignore
function ForeignerTable({ columns, data, refresh}) {
    
    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        getTableBodyProps,
    } = useTable(
        {
            columns,
            data,
        },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    return (
        <MaUTable
            {...getTableProps()}
            stickyHeader
        >
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // @ts-ignore
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <div>
                                    {column.render('Filter')}
                                </div>
                                <span>
                                    {/* @ts-ignore */}
                                    {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                </span>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);

                    return (
                        <TableRow {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <TableCell
                                        {...cell.getCellProps()}
                                        style={{
                                            cursor: 'pointer',
                                            // @ts-ignore
                                            width: cell.column.originalWidth,
                                            padding: 10,
                                        }}
                                    >
                                        { cell.render('Cell') }
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </MaUTable>
    )
};

function ForeignerOrder() {

    const [ foreignerProgressList, setForeignerProgressList ] = useState<Array<OrderProgressDto>>();
    const [ externalVendorList, setExternalVendorList ] = useState<Array<ExternalServiceJobDto>>();
    const [ mergedList, setMergedList ] = useState<Array<any>>();

    const finishOrder = async (orderTaskId: string) => {
        try {
            await repository.finishOrderTask(orderTaskId);
            message.success('강제 마감이 완료되었습니다.');
        } catch (err) {
            message.error(`${err}`);
        }
    };

    const cancelExternalVendor = async (jobId: string) => {
        try {
            const rs = await repository.cancelExternalVendors(jobId);
            console.log('rs', rs);
            message.success('외부요청 취소 성공');
        } catch (err) {
            message.error(`${err}`);
        };
    };

    const confirmAction = useCallback(async (id: string, targetId: string, targetUrl: string) => {
        if (window.confirm(`${targetId}, ${targetUrl}의 주문을 취소하시겠습니까?`)) {
            try {
                const rs = await repository.deleteOrder(id);
                if (rs.status === 'success') {
                    message.success('주문취소 성공');
                    await getMergedList();
                } else {
                    throw new Error('취소 처리가 실패했습니다.');
                }
            } catch(err) {
                console.log(err);
                message.error(`${err}`);
            }
        } else {
            return;
        }
    }, []);

    const getMergedList = async () => {
        try {
            const rs = await repository.getForeignerOrderProgresses();
            // console.log('rs', rs);
            if (rs && rs.length === 0) {
                setMergedList(undefined);
            }
            if (rs && rs.length > 0) {
                const sortedList = rs.sort((a, b) => {
                    return moment(a.orderDate) > moment(b.orderDate) ? -1 :
                        moment(a.orderDate) < moment(b.orderDate) ? 1: 0;
                });
                // console.log('sortedList', sortedList);
                setForeignerProgressList(sortedList);
                const externalRs = await repository.getExternalVendors();
                // console.log('externalRs', externalRs);
                // console.log('externalRs', externalRs);
                if (externalRs && externalRs.length > 0) {
                    setExternalVendorList(externalRs);
                    const a = sortedList.map(d => {
                        const matchedItem = externalRs.find(data => data.orderTaskId === d.orderTaskId);
                        return {
                            ...d,
                            externalId: matchedItem?.id,
                            status: matchedItem?.status,
                            externalVendor: matchedItem?.externalVendor,
                            queuedAt: matchedItem?.queuedAt,
                            finishedAt: matchedItem?.finishedAt,
                            channel: matchedItem?.channel,
                            messageTs: matchedItem?.messageTs,
                            jobId: matchedItem?.jobId,
                            reason: matchedItem?.reason,
                        }
                    });
                    // console.log('mergedList', a);
                    setMergedList(a);
                }
            }
        } catch (err) {
            message.error(`${err}`);
        };
    };

    const columns = React.useMemo(() => [
        {
            Header: '',
            accessor: 'orderDate',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>{moment(cell.value).format('MM/DD (HH:mm)')}</div>
                );
            },
            width: 60,
        },
        {
            Header: '',
            accessor: 'taskType',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            { cell.value === 'like' && <HeartOutlined style={{ color: '#E1306C', marginRight: 5 }} /> }
                            { cell.value === 'like_reels' && <img src='/assets/instagram-reel.svg' width={14} height={14} style={{ marginRight: 5 }} /> }
                            { cell.value === 'follow' && <UserAddOutlined style={{ color: '#0095f6', marginRight: 5 }} /> }
                            { cell.row.values.totalCnt }
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {cell.row.values.status === 'wait' && <ClockCircleOutlined style={{ marginRight: 5, color: 'orange' }} />}
                            {cell.row.values.status === 'fail' && <ExclamationCircleOutlined style={{ marginRight: 5, color: 'red' }} /> }
                            {cell.row.values.status === 'success' && <CheckOutlined style={{ marginRight: 5, color: 'green' }}/>}
                            {cell.row.values.status === 'cancel' && <StopOutlined style={{ marginRight: 5, color: 'red' }}/>}
                            {cell.row.values.status}
                        </div>
                    </div>
                );
            },
            width: 100,
        },
        {
            Header: '주문정보',
            accessor: 'targetId',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <div>
                            <a
                                target='_blank'
                                href={`https://www.instagram.com/${cell.row.values.targetId}`}
                                rel="noreferrer noopener"
                                style={{ color: 'black' }}
                            >{cell.row.values.targetId}</a>
                        </div>
                        { cell.row.values.targetUrl &&
                            <div>
                                <a
                                    target='_blank'
                                    href={cell.row.values.taskType.includes('릴스')
                                            ? `https://www.instagram.com/reel/${cell.row.values.targetUrl}`
                                            : `https://www.instagram.com/p/${cell.row.values.targetUrl}`}
                                    rel="noreferrer noopener"
                                    style={{ color: '#aaaaaa' }}
                                >{cell.row.values.targetUrl}</a>
                            </div>
                        }
                    </div>
                );
            },
            width: 150,
        },
        {
            Header: '강제마감',
            accessor: 'orderTaskId',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <Button
                        style={{ padding: '0 5px', backgroundColor: '#baffb5' }}
                        onClick={async () => {
                            try {
                                if (window.confirm('정말 마감하시겠습니까?')) {
                                    await finishOrder(cell.value);
                                    await getMergedList();
                                }
                            } catch (err) {
                                message.error(JSON.stringify(err));
                            }
                        }}
                    >
                        강제마감
                    </Button>
                );
            },
            width: 90,
        },
        {
            Header: '작업량 수정',
            accessor: 'totalCnt',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <Button
                        style={{ padding: '0 5px' }}
                        onClick={() => {
                            editCountStore.handleEditCountModalOpen(true);
                            editCountStore.handleEditCountInitialModalValue({
                                orderTaskId: cell.row.values.orderTaskId,
                                totalCnt: cell.value,
                                remainScheduleCnt: 0,
                                progressCnt: 0,
                                targetId: cell.row.values.targetId,
                                targetUrl: cell.row.values.targetUrl,
                            });
                            editCountStore.handleEditCountModalValue({
                                displayingCnt: 0,
                                totalCnt: cell.value,
                            });
                        }}
                    >작업량 수정</Button>
                );
            },
            width: 90,
        },
        {
            Header: '작업정보수정',
            accessor: 'status',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <Button
                        style={{ padding: '0 5px' }}
                        onClick={() => {
                            editOrderStore.handleEditOrderModalValue({
                                orderTaskId: cell.row.values.orderTaskId,
                                targetId: cell.row.values.targetId,
                                targetUrl: cell.row.values.targetUrl,
                            });
                            editOrderStore.handleEditOrderModalOpen(true);
                            editOrderStore.handleEditOrderModalInitialValue({
                                targetId: cell.row.values.targetId,
                                targetUrl: cell.row.values.targetUrl,
                            });
                        }}
                    >
                        작업정보수정
                    </Button>
                );
            },
            width: 90,
        },
        {
            Header: '헬퍼큐취소',
            accessor: 'externalId',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <Button
                        style={{ padding: '0 5px' }}
                        onClick={async () => {
                            try {
                                if (window.confirm('정말 헬퍼에 넣은 큐 요청을 취소하시겠습니까?')) {
                                    await cancelExternalVendor(cell.value);
                                    await getMergedList();
                                }
                            } catch (err) {
                                message.error(JSON.stringify(err));
                            }
                        }}
                    >
                        외부취소
                    </Button>
                );
            },
            width: 90,
        },
        {
            Header: '주문 취소',
            accessor: 'id',
            Filter: () => {},
            width: 90,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <button
                        style={{ margin: 5 }}
                        onClick={() => confirmAction(cell.value, cell.row.values.targetId, cell.row.values.targetUrl)}
                    >
                        <div style={{ color: 'red' }}>
                            주문 취소
                        </div>
                    </button>
                )
            }
        },
        {
            Header: '큐시각',
            accessor: 'queuedAt',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>{cell.value ? moment(cell.value).format('MM/DD (HH:mm)') : ''}</div>
                );
            },
            width: 90,
        },
        {
            Header: '종료시각',
            accessor: 'finishedAt',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>{cell.value ? moment(cell.value).format('MM/DD (HH:mm)') : ''}</div>
                );
            },
            width: 90,
        },
        {
            Header: '주문번호',
            accessor: 'orderNo',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        <div>{cell.value}</div>
                        <a
                            target='_blank'
                            href={`http://gdadmin.amongkr.godomall.com/order/order_view.php?orderNo=${cell.value}`}
                            rel="noreferrer noopener"
                        >
                        링크</a>
                    </div>
                );
            },
            width: 150,
        },
        {
            Header: '이유',
            accessor: 'reason',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>{cell.value ? cell.value : ''}</div>
                );
            },
            width: 300,
        },
        {
            Header: '알림장',
            accessor: 'targetUrl',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <button
                        style={{ color: '#1890FF' }}
                        onClick={() => {
                            (async () => {
                                const rs = await repository.getInformationLetterLink(cell.row.values.id);
                                if (rs !== '') {
                                    window.open(rs, '_blank');
                                }
                            })();
                        }}
                    >알림장</button>
                );
            },
            width: 80,
        },
    ], [ getMergedList ]);

    useEffect(() => {
        ( async () => {
            // await getForeignerList();
            // await getExternalVendorList();
            await getMergedList();
        })();
    }, []);

    return (
        <>
            <EditCountModal
                refresh={getMergedList}
            />
            <EditOrderModal
                refresh={getMergedList}
            />
            { mergedList && mergedList.length > 0
                ? <ForeignerTable
                    columns={columns}
                    data={mergedList}
                    refresh={getMergedList}
                />
                : <div>진행중인 외국인 주문이 없습니다.</div>
            }
        </>
    );
};

export default ForeignerOrder;