import { Button, Descriptions, Input, Modal, message } from 'antd';
import { observer } from 'mobx-react';
import repository from '../repository';
import { useCallback, useState } from 'react';
import editOrderStore from '../stores/editOrderStore';

interface IEditOrderModal {
    refresh: (orderTaskId: string) => Promise<void>;
}

const EditOrderModal: React.FC<IEditOrderModal> = observer((props) => {
    const [ loading, setLoading ] = useState<boolean>(false);

    const handleTargetIdChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        editOrderStore.handleEditOrderModalValue({
            ...editOrderStore.editOrderModalValue,
            targetId: e.target.value,
        });
    }, []);

    const handleTargetUrlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        editOrderStore.handleEditOrderModalValue({
            ...editOrderStore.editOrderModalValue,
            targetUrl: e.target.value,
        });
    }, []);

    return (
        <Modal
            open={editOrderStore.editOrderModalOpen}
            title='주문 정보 수정'
            onCancel={() => {
                editOrderStore.handleEditOrderModalValue({
                    orderTaskId: '',
                    targetId: '',
                    targetUrl: '',
                });
                editOrderStore.handleEditOrderModalInitialValue({
                    targetId: '',
                    targetUrl: '',
                });
                editOrderStore.handleEditOrderModalOpen(false);
            }}
            footer={[
                <Button
                    key='back'
                    onClick={() => {
                        editOrderStore.handleEditOrderModalValue({
                            orderTaskId: '',
                            targetId: '',
                            targetUrl: '',
                        });
                        editOrderStore.handleEditOrderModalInitialValue({
                            targetId: '',
                            targetUrl: '',
                        });
                        editOrderStore.handleEditOrderModalOpen(false);
                    }}
                >
                    취소
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    loading={loading}
                    onClick={() => {
                        (async () => {
                            try {
                                setLoading(true);
                                if (!editOrderStore.editOrderModalValue.orderTaskId) {
                                    throw new Error('주문정보 수정을 실패하였습니다.');
                                }

                                let payload: any = {};
                                if (editOrderStore.editOrderModalValue.targetId) {
                                    payload.targetId = editOrderStore.editOrderModalValue.targetId;
                                }
                                if (editOrderStore.editOrderModalValue.targetUrl) {
                                    payload.targetUrl = editOrderStore.editOrderModalValue.targetUrl;
                                }
                                await repository.updateOrder(editOrderStore.editOrderModalValue.orderTaskId, payload);
                                message.success('주문정보 수정이 완료되었습니다.');
                                setLoading(false);
                                editOrderStore.handleEditOrderModalOpen(false);
                                await props.refresh(editOrderStore.editOrderModalValue.orderTaskId);
                                editOrderStore.handleEditOrderModalValue({
                                    orderTaskId: '',
                                    targetId: '',
                                    targetUrl: '',
                                });
                                editOrderStore.handleEditOrderModalInitialValue({
                                    targetId: '',
                                    targetUrl: '',
                                });
                            } catch (error) {
                                setLoading(false);
                                editOrderStore.handleEditOrderModalOpen(false);
                                message.error(`${error}`);
                            }
                        })();
                    }}
                >
                    저장
                </Button>,
            ]}
        >
            <div>
                { editOrderStore.editOrderModalInitialValues.targetId &&
                    <Input
                        addonBefore={<div>targetId</div>}
                        placeholder='targetId'
                        value={editOrderStore.editOrderModalValue.targetId}
                        onChange={handleTargetIdChange}
                    />
                }
                <div style={{ height: 10 }} />
                { editOrderStore.editOrderModalInitialValues.targetUrl &&
                    <Input
                        addonBefore={<div>targetUrl</div>}
                        placeholder='targetUrl'
                        value={editOrderStore.editOrderModalValue.targetUrl}
                        onChange={handleTargetUrlChange}
                    />
                }
                { editOrderStore.editOrderModalInitialValues.targetId &&
                    <>
                        <div style={{ fontWeight: 'bold', marginBottom: 5, marginTop: 30 }}>targetId</div>
                        <Descriptions bordered>
                            <Descriptions.Item label='수정 전'>{editOrderStore.editOrderModalInitialValues.targetId}</Descriptions.Item>
                            <Descriptions.Item label='수정 후'>
                                <div>{editOrderStore.editOrderModalValue.targetId}</div>
                                <a
                                    href={`https://www.instagram.com/${editOrderStore.editOrderModalValue.targetId}`}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    링크
                                </a>
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                }
                { editOrderStore.editOrderModalInitialValues.targetUrl &&
                    <>
                        <div style={{ fontWeight: 'bold', marginBottom: 5 }}>targetUrl</div>
                        <Descriptions bordered>
                            <Descriptions.Item label='수정 전'>{editOrderStore.editOrderModalInitialValues.targetUrl}</Descriptions.Item>
                            <Descriptions.Item label='수정 후'>
                                <div>{editOrderStore.editOrderModalValue.targetUrl}</div>
                                <a
                                    href={`https://www.instagram.com/p/${editOrderStore.editOrderModalValue.targetUrl}`}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    링크
                                </a>
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                }
            </div>
        </Modal>
    );
});

export default EditOrderModal;