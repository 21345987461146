import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import React, { useMemo } from "react";
import repository from "../repository";
import { TableCell, TableHead, TableBody, TableRow } from '@mui/material';
import CopyToClipboard from "react-copy-to-clipboard";
import { FixedSizeList } from "react-window";
import getScrollbarWidth from './getScrollbarWidth';
import { ArrowDownOutlined, ArrowUpOutlined, SyncOutlined } from '@ant-design/icons';
import MaUTable from '@mui/material/Table';
import { message } from 'antd';

export enum OrderTaskPriorityType {
    // 긴급
    URGENT = '999',
    // 처리 우선순위 높음
    HIGH = '2',
    // 처리 우선순위 보통
    NORMAL = '1',
    // 처리 우선순위 낮음
    LOW = '0',
};

export function checkDeleteOrderDisable(value: string) {
    if (value === 'locked' || value === 'not_finished' || value === 'wait_external_vendor') {
        return false;
    } else {
        return true;
    }
};

// @ts-ignore
export function OrderTable({ columns, data, refresh}) {
    const defaultColumn = useMemo(() => ({
        width: 200,
    }), []);

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        getTableBodyProps,
        totalColumnsWidth,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    const confirmAction = async (orderId: string, orderNumber: number) => {
        if (window.confirm(`${orderId} \n ${orderNumber}의 주문을 취소하시겠습니까?`)) {
            try {
                const rs = await repository.deleteOrder(orderId);
                if (rs.status === 'success') {
                    await refresh();
                } else {
                    throw new Error('취소 처리가 실패했습니다.');
                }
            } catch(err) {
                console.log(err);
            }
        } else {
            return;
        }
    };

    const RenderRow = React.useCallback(({ index, style }: { index: number, style: any }) => {
        const row = rows[index];
        prepareRow(row);

        const {
            progressCnt,
            totalCnt,
            taskStatus,
        } = row.values;
        const almostDone = totalCnt - progressCnt < 5 && taskStatus !== 'finished';

        const getBackgroundColor = () => {
            if (taskStatus === 'finished' || taskStatus === 'impossible') {
                return '#405DE610';
            }

            if (almostDone) {
                return 'rgba(242, 242, 0, 0.1)';
            }

            if (taskStatus === 'temporary_paused') {
                return '#c8c8c8';
            }

            return undefined;
        };

        return (
            <TableRow
                {...row.getRowProps({
                    style: {
                        ...style,
                        backgroundColor: getBackgroundColor(),
                        opacity: taskStatus === 'temporary_paused' ? 0.3 : 1,
                    },
                })}
            >
                {row.cells.map(cell => {
                    if (
                        cell.column.id === 'targetId' ||
                        cell.column.id === 'orderNo' ||
                        cell.column.id === 'targetUrl'
                    ) {
                        return (
                            <CopyToClipboard
                                text={cell.value}
                                onCopy={(text, result) => {
                                    if (result) {
                                        message.success(`${cell.value}`);
                                    } else {
                                        message.error(`${cell.value}`);
                                    }
                                }}
                            >
                                <TableCell
                                    {...cell.getCellProps()}
                                    style={{
                                        cursor: 'pointer',
                                        // @ts-ignore
                                        width: cell.column.originalWidth,
                                    }}
                                >
                                    {cell.render('Cell')}
                                    { cell.value &&
                                        <div>
                                            <a
                                                target='_blank'
                                                href={
                                                    cell.column.id === 'targetId'
                                                        ? `https://www.instagram.com/${cell.value}`
                                                        : cell.column.id === 'targetUrl'
                                                            ? cell.row.values.taskType.includes('릴스')
                                                                ? `https://www.instagram.com/reel/${cell.value}`
                                                                : `https://www.instagram.com/p/${cell.value}`
                                                            : `http://gdadmin.amongkr.godomall.com/order/order_view.php?orderNo=${cell.value}`
                                                }
                                                rel="noreferrer noopener"
                                            >
                                            링크</a>
                                        </div>
                                    }
                                </TableCell>
                            </CopyToClipboard>
                        )
                    } if (cell.column.id === 'orderId') {
                        return (
                            <TableCell
                                {...cell.getCellProps()}
                                style={{
                                    cursor: 'pointer',
                                    // @ts-ignore
                                    width: cell.column.originalWidth,
                                }}
                            >
                                <button
                                    onClick={() => confirmAction(cell.value, row.cells.find(d => d.column.id === 'orderNo')?.value)}
                                    disabled={checkDeleteOrderDisable(row.cells.find(d => d.column.id === 'taskStatus')?.value)}
                                >
                                    <div style={{ color: 'red' }}>
                                        주문 취소
                                    </div>
                                </button>
                            </TableCell>
                        )
                    }

                    return (
                        <TableCell
                            {...cell.getCellProps()}
                            style={{
                                cursor: 'pointer',
                                // @ts-ignore
                                width: cell.column.originalWidth,
                            }}
                        >
                            { cell.render('Cell') }
                        </TableCell>
                    )
                })}
            </TableRow>
        );
    }, [ prepareRow, rows ]);

    const scrollBarSize = useMemo(() => getScrollbarWidth(), []);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                <button
                    onClick={() => {
                        ( async () => {
                            await refresh();
                        })();
                    }}
                    style={{ border: '1px lightgray solid', margin: '10px 0', padding: '5px 20px', flex: 1 }}
                >새로고침
                    <SyncOutlined
                        spin={false}
                        style={{ fontSize: 14, marginLeft: 5 }}
                    />
                </button>
            </div>
            <MaUTable
                {...getTableProps()}
                stickyHeader
            >
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow style={{ background: 'gray' }} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // @ts-ignore
                                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <div>
                                        {column.render('Filter')}
                                    </div>
                                    <span>
                                        {/* @ts-ignore */}
                                        {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    <FixedSizeList
                        height={window.innerHeight - 90}
                        itemCount={rows.length}
                        itemSize={70}
                        width={totalColumnsWidth + scrollBarSize}
                    >
                        {RenderRow}
                    </FixedSizeList>
                </TableBody>
            </MaUTable>
        </>
    );
};