import { Button, Input, Typography, message } from 'antd';
import { useState } from 'react';
import { AbuserDto } from '../../apiTypes';
import repository from '../../repository';

function Lab() {
    const [ instaId, setInstaId ] = useState<string>();
    const [ lostAbusers, setLostAbusers ] = useState<Array<AbuserDto>>();

    const searchLostAbusers = async () => {
        if (!instaId) {
            message.error('인스타그램 아이디를 입력해주세요.');
            return;
        }

        try {
            const lostAbusers = await repository.getLostAbusers(instaId);
            setLostAbusers(lostAbusers);
        } catch (err) {
            // @ts-ignore
            message.error(err.response?.data?.message);
        }
    };

    return (
        <div style={{ padding: '50px 20px 0 50px' }}>
            <Typography.Title level={5}>팔로워 이탈량 조회</Typography.Title>
            <Input
                value={instaId}
                onChange={e => setInstaId(e.target.value)}
                placeholder='인스타그램 아이디'
            />
            <Button onClick={searchLostAbusers}>이탈량 조회</Button>
            { lostAbusers &&
                <div>
                    <div>{lostAbusers.length}회</div>
                    <textarea
                        style={{
                            width: '100%',
                            height: 1000,
                        }}
                        value={JSON.stringify(lostAbusers, null, 2)}
                    />
                </div>
            }
        </div>
    );
}

export default Lab;
