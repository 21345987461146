import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Cell, useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import MaUTable from '@mui/material/Table';
import { OrderMemoDto, OrderProgressDto, ShopOrderResponse } from '../../apiTypes';
import repository from '../../repository';
import Mask from '../../components/mask';
import { Button, Divider, Empty, Input, message, Modal, Select, Statistic } from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    SyncOutlined,
    UserAddOutlined,
    HeartOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
    CloseOutlined,
    ContainerFilled,
    SettingOutlined,
} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import Flex from '../../components/flex';
import { InView } from 'react-intersection-observer';
import { observer } from 'mobx-react';
import editOrderStore from '../../stores/editOrderStore';
import priorityModalStore from '../../stores/priorityModalStore';
import editCountStore from '../../stores/editCountStore';
import editReservedStore from '../../stores/editReservedStore';
import PriorityModal from '../../modals/priorityModal';
import EditCountModal from '../../modals/editCountModal';
import EditOrderModal from '../../modals/editOrderModal';
import EditReservedModal from '../../modals/editReservedModal';

enum OrderTaskPriorityType {
    // 긴급
    URGENT = '999',
    // 처리 우선순위 높음
    HIGH = '2',
    // 처리 우선순위 보통
    NORMAL = '1',
    // 처리 우선순위 낮음
    LOW = '0',
};

function checkDeleteOrderDisable(value: string) {
    if (value === 'locked' || value === 'not_finished' || value === 'wait_external_vendor') {
        return false;
    } else {
        return true;
    }
};

// @ts-ignore
function CanceledOrderTable({ columns, data, refresh}) {

    const defaultColumn = useMemo(() => ({
        width: 200,
    }), []);

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        getTableBodyProps,
        totalColumnsWidth,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy,
        useBlockLayout,
    );

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                <button
                    onClick={() => {
                        ( async () => {
                            await refresh();
                        })();
                    }}
                    style={{ border: '1px lightgray solid', margin: '10px 0', padding: '5px 20px', flex: 1 }}
                >새로고침
                    <SyncOutlined
                        spin={false}
                        style={{ fontSize: 14, marginLeft: 5 }}
                    />
                </button>
            </div>
            <MaUTable
                {...getTableProps()}
                stickyHeader
            >
                <TableHead>
                    {headerGroups.map((headerGroup, i) => (
                        <TableRow
                            style={{ background: 'gray' }}
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map(column => (
                                // @ts-ignore
                                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <div>
                                        {column.render('Filter')}
                                    </div>
                                    <span>
                                        {/* @ts-ignore */}
                                        {column.isSorted ? column.isSortedDesc ? <ArrowDownOutlined style={{ fontSize: 16, marginLeft: 5, color: '#eb2f96' }} /> : <ArrowUpOutlined style={{ fontSize: 16, marginLeft: 5, color: '#52c41a' }} /> : ''}
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        const {
                            status: {
                                totalCnt,
                                progressCnt,
                                remainScheduleCnt,
                            },
                            taskInfo: {
                                taskStatus,
                            },
                            reservedDt,
                        } = row.values;
                        const almostDone = totalCnt - progressCnt - remainScheduleCnt < 5 && taskStatus !== 'finished';

                        const getBackgroundColor = () => {
                            if (taskStatus === 'finished' || taskStatus === 'impossible') {
                                return '#405DE610';
                            }
                            if (reservedDt && moment(reservedDt) > moment()) {
                                return '#11ff1130';
                            }
                            if (almostDone) {
                                return 'rgba(242, 242, 0, 0.1)';
                            }
                            if (taskStatus === 'temporary_paused') {
                                return '#c8c8c8';
                            }
                            return undefined;
                        };

                        return (
                            <TableRow
                                {...row.getRowProps({
                                    style: {
                                        // ...style,
                                        height: 70,
                                        backgroundColor: getBackgroundColor(),
                                        opacity: taskStatus === 'temporary_paused' ? 0.3 : 1,
                                    },
                                })}
                            >
                                {row.cells.map(cell => {
                                    if (
                                        cell.column.id === 'targetId' ||
                                        cell.column.id === 'orderNo' ||
                                        cell.column.id === 'targetUrl'
                                    ) {
                                        return (
                                            <CopyToClipboard
                                                text={cell.value}
                                                onCopy={(text, result) => {
                                                    if (result) {
                                                        message.success(`${cell.value}`);
                                                    } else {
                                                        message.error(`${cell.value}`);
                                                    }
                                                }}
                                            >
                                                <TableCell
                                                    {...cell.getCellProps()}
                                                    style={{
                                                        cursor: 'pointer',
                                                        // @ts-ignore
                                                        width: cell.column.originalWidth,
                                                        padding: 10,
                                                    }}
                                                >
                                                    {cell.render('Cell')}
                                                    { cell.value &&
                                                        <div>
                                                            <a
                                                                target='_blank'
                                                                href={
                                                                    cell.column.id === 'targetId'
                                                                        ? `https://www.instagram.com/${cell.value}`
                                                                        : cell.column.id === 'targetUrl'
                                                                            ? cell.row.values.taskType.includes('릴스')
                                                                                ? `https://www.instagram.com/reel/${cell.value}`
                                                                                : `https://www.instagram.com/p/${cell.value}`
                                                                            : `http://gdadmin.amongkr.godomall.com/order/order_view.php?orderNo=${cell.value}`
                                                                }
                                                                rel="noreferrer noopener"
                                                            >
                                                            링크</a>
                                                        </div>
                                                    }
                                                </TableCell>
                                            </CopyToClipboard>
                                        )
                                    }

                                    return (
                                        <TableCell
                                            {...cell.getCellProps()}
                                            style={{
                                                cursor: 'pointer',
                                                // @ts-ignore
                                                width: cell.column.originalWidth,
                                                padding: 10,
                                            }}
                                        >
                                            { cell.render('Cell') }
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </MaUTable>
        </>
    );
};

const addMemoOrderId = async (orderId: string, text: string) => {
    try {
        const rs = await repository.postAddOrderMemo(orderId, {
            orderId: orderId,
            content: {
                text: text,
                date: moment().format('YYYY-MM-DD'),
            }

        });
        if (rs) {
            message.success('메모가 업데이트되었습니다.');
        }
    } catch (err) {
        message.error('메모를 업데이트하지 못했습니다.');
    }
};

const deleteMemoId = async (orderId: string, memoId: string) => {
    try {
        const rs = await repository.deleteOrderMemo(orderId, memoId);
        if (rs) {
            message.success('메모가 삭제되었습니다.');
        }
    } catch (err) {
        message.error('메모를 삭제하지 못했습니다.');
    }
};

const getIsReservedNow = (reservedDt: string | null) => {
    if (reservedDt === null) {
        return false;
    }

    return moment().isBefore(moment(reservedDt));
};

const MemoAlertButton = ({ orderId }: { orderId: string }) => {

    const [ memos, setMemos ] = useState<Array<OrderMemoDto>>();
    const [ memoModalOpen, setMemoModalOpen ] = useState<boolean>(false);
    const [ editMemoModalValue, setEditMemoModalValue ] = useState<string>('');

    const getMemoOrderId = async (orderId: string) => {
        try {
            const rs = await repository.getOrderMemo(orderId);
            if (rs.length > 0) {
                setMemos(rs);
                return;
            }
            setMemos([]);
        } catch (err) {
            message.error('메모를 불러오지 못했습니다.');
        }
    };

    return (
        <>
            <Modal
                open={memoModalOpen}
                title='메모'
                onCancel={() => {
                    setEditMemoModalValue('');
                    setMemoModalOpen(false);
                }}
                onOk={() => {
                    setMemoModalOpen(false);
                }}
            >
                <div>
                    { memos && memos.length > 0
                        ? memos.map((d, i) =>
                            <div
                                key={i}
                                style={{ display: 'flex', flexDirection: 'row', padding: '0 5px' }}
                            >
                                <div style={{ marginRight: 5, color: 'gray' }}>{d.content.date}</div>
                                <div style={{ flex: 1 }}>
                                    {d.content.text}
                                </div>
                                <button
                                    onClick={() => {
                                        if (window.confirm('삭제하시겠습니까?')) {
                                            ( async () => {
                                                await deleteMemoId(d.orderId, d.id);
                                                await getMemoOrderId(d.orderId);
                                            })();
                                        }
                                    }}
                                >
                                    <CloseOutlined style={{ color: 'red' }} />
                                </button>
                            </div>
                        ) : <div style={{ textAlign: 'center', color: 'red' }}>메모가 없습니다...</div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <Input
                            addonBefore={<div>새 메모</div>}
                            placeholder='메모를 입력하세요'
                            value={editMemoModalValue}
                            onChange={e => setEditMemoModalValue(e.target.value)}
                        />
                        <Button
                            onClick={() => {
                                if (editMemoModalValue === '') {
                                    return;
                                }
                                (async () => {
                                    await addMemoOrderId(orderId, editMemoModalValue);
                                    await getMemoOrderId(orderId);
                                    setEditMemoModalValue('');
                                })();
                            }}
                        >+</Button>
                    </div>
                </div>
            </Modal>
            <InView
                onChange={(inView, entry) => {
                    if (inView && memos === undefined) {
                        // getMemoOrderId(orderId);
                    }
                }}
            >
                <button
                    // disabled={!memos}
                    onClick={() => {
                        (async () => {
                            setEditMemoModalValue('');
                            await getMemoOrderId(orderId);
                        })();
                        setMemoModalOpen(true);
                    }}
                    style={{ color: '#1890FF' }}
                >
                    <ContainerFilled style={{ color: (memos ?? []).length > 0 ? 'red' : 'lightgray' }}/>
                </button>
            </InView>
        </>
    );
};

const CanceledOrderStatus = observer(() => {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ orderList, setOrderList ] = useState<Array<OrderProgressDto>>([]);
    const [ settingModalOpen, setSettingModalOpen ] = useState<boolean>(false);
    const [ settingModalValues, setSettingModalValues ] = useState<any>();

    const handleSettingModal = (open: boolean, cellValues?: any) => {
        setSettingModalOpen(open);
        setSettingModalValues(open ? cellValues : {});
    }

    // const updateOrderTaskFromOrderList = useCallback(async (orderTaskId: string) => {
    //     const newOrderTask = await repository.getOrderTask(orderTaskId);
    //     const indexToReplace = orderList.findIndex(item => item.orderTaskId === orderTaskId);
    //     if (indexToReplace !== -1) {
    //         const updatedOrderList = [
    //             ...orderList.slice(0, indexToReplace),
    //             orderProgressMapper(newOrderTask),
    //             ...orderList.slice(indexToReplace + 1),
    //         ];

    //         // @ts-ignore
    //         setOrderList(updatedOrderList);
    //     };
    // }, [ orderList ]);

    const confirmAction = useCallback(async (orderId: string, targetId: string, targetUrl: string) => {
        if (window.confirm(`${targetId}, ${targetUrl}의 주문을 취소하시겠습니까?`)) {
            try {
                const rs = await repository.deleteOrder(orderId);
                if (rs.status === 'success') {
                    await getCanceledOrderList();
                } else {
                    throw new Error('취소 처리가 실패했습니다.');
                }
            } catch(err) {
                console.log(err);
            }
        } else {
            return;
        }
    }, []);

    const orderProgressMapper = useCallback((orderProgress: OrderProgressDto) => {
        let text = '';
        switch (orderProgress.taskType) {
            case 'follow':
                text = '팔로우';
                break;
            case 'like':
                text = '좋아요';
                break;
            case 'like_reels':
                text = '릴스';
                break;
            default:
                text = '(?)';
        };

        return {
            orderNo: orderProgress.orderNo,
            productName: orderProgress.productName,
            taskInfo: {
                taskStatus: orderProgress.taskStatus,
                orderDate: moment(orderProgress.orderDate).format('M/DD (HH:mm)'),
            },
            updatedAt: moment(orderProgress.updatedAt).format('MM/DD (HH:mm)'),
            target: {
                targetId: orderProgress.targetId,
                targetUrl: orderProgress.targetUrl,
            },
            taskType: orderProgress.taskType,
            status: {
                totalCnt: orderProgress.totalCnt,
                progressCnt: orderProgress.progressCnt,
                remainScheduleCnt: orderProgress.remainScheduleCnt,
            },
            orderId: orderProgress.id,
            edit: null,
            orderTaskId: orderProgress.orderTaskId,
            finishOrderTask: null,
            lockedDt: orderProgress.lockedDt,
            priority: orderProgress.priority,
            settleKind: orderProgress.settleKind,
            reservedDt: orderProgress.reservedDt,
        };
    }, []);

    const getCanceledOrderList = useCallback(async () => {
        setLoading(true);
        const _orderList = await repository.getCanceledOrderProgress();
        // @ts-ignore
        // 시간 내림차순으로 정렬
        setOrderList(_orderList.sort((a, b) => {
            const aUpdatedAt = new Date(a.updatedAt).getTime();
            const bUpdatedAt = new Date(b.updatedAt).getTime();
            return aUpdatedAt > bUpdatedAt ? -1 : aUpdatedAt < bUpdatedAt ? 1 : 0;
        }).map(orderProgressMapper));
        setLoading(false);
    }, []);

    const columns = React.useMemo(() => [
        {
            Header: '',
            accessor: 'priority',
            Filter: () => {},
            width: 40,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <Flex
                        column
                        gap={6}
                        style={{ height: '100%' }}
                    >
                        <button
                            style={{ height: '100%' }}
                            onClick={() => {
                                priorityModalStore.handleModalOpen(true);
                                priorityModalStore.handlePriorityModalValue({
                                    orderTaskId: cell.row.values.orderTaskId,
                                    priority: cell.value,
                                });
                            }}
                        >
                            { parseInt(cell.value) >= parseInt(OrderTaskPriorityType.URGENT) && <div style={{ width: 20, height: 4, borderRadius: 1, backgroundColor: 'rgb(235, 82, 85)', marginBottom: 1 }} /> }
                            { parseInt(cell.value) >= parseInt(OrderTaskPriorityType.HIGH) && <div style={{ width: 20, height: 4, borderRadius: 1, backgroundColor: 'rgb(242, 168, 59)', marginBottom: 1 }} /> }
                            { parseInt(cell.value) >= parseInt(OrderTaskPriorityType.NORMAL) && <div style={{ width: 20, height: 4, borderRadius: 1, backgroundColor: 'rgb(141, 186, 56)', marginBottom: 1 }} /> }
                            { cell.value === OrderTaskPriorityType.LOW && <div style={{ width: 20, height: 4, borderRadius: 1, backgroundColor: '#e9e9e9', marginBottom: 1 }} /> }
                        </button>
                        <Flex>
                            <button onClick={() => handleSettingModal(true, cell.row.values)}>
                                <SettingOutlined />
                            </button>
                        </Flex>
                    </Flex>
                );
            },
        },
        {
            Header: '작업정보',
            accessor: 'taskInfo',
            Filter: () => {},
            width: 100,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                const { productName, target, lockedDt, reservedDt } = cell.row.values;
                const { taskStatus, orderDate } = cell.value;

                return (
                    <div
                        onClick={async () => {
                            if (taskStatus === 'not_finished' || taskStatus === 'locked') {
                                if (window.confirm(`${target.targetId}님의 [${productName}] 주문을 중지하시겠습니까?`)) {
                                    try {
                                        await repository.pauseOrderTask(cell.row.values.orderTaskId);
                                        message.success('주문 중지에 성공하였습니다.');
                                        await getCanceledOrderList();
                                    } catch (err) {
                                        message.error('주문 중지에 실패하였습니다.');
                                    }
                                }
                            } else if (taskStatus === 'temporary_paused') {
                                if (window.confirm(`${target.targetId}님의 [${productName}] 주문을 재개하시겠습니까?`)) {
                                    try {
                                        await repository.resumeOrderTask(cell.row.values.orderTaskId);
                                        message.success('주문 재개에 성공하였습니다.');
                                        await getCanceledOrderList();
                                    } catch (err) {
                                        message.error('주문 재개에 실패하였습니다.');
                                    }
                                }
                            }
                        }}
                        style={{ height: '100%' }}
                >
                        <Flex
                            column
                            alignItems='flex-start'
                            gap={2}
                            style={{ height: '100%' }}
                        >
                            <div>
                                {orderDate}
                            </div>
                            { getIsReservedNow(reservedDt)
                                ? (
                                    <Flex>
                                        <SyncOutlined spin />
                                        <Statistic.Countdown
                                            title=''
                                            format={'HH:mm:ss'}
                                            valueStyle={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                marginLeft: 5,
                                            }}
                                            value={-moment().diff(moment(reservedDt)) + Date.now()}
                                        />
                                    </Flex>
                                )
                                : (
                                    (taskStatus === 'locked' || taskStatus === 'not_finished') && lockedDt !== null &&
                                    <Flex>
                                        <SyncOutlined spin />
                                        <Statistic.Countdown
                                            title=''
                                            format={'mm:ss'}
                                            valueStyle={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                marginLeft: 5,
                                            }}
                                            value={Date.now() + 10 * 60 * 1000 - moment().diff(moment(lockedDt))}
                                        />
                                    </Flex>
                                )
                            }
                        </Flex>
                    </div>
                );
            },
        },
        {
            Header: '진행 상황',
            accessor: 'taskType',
            Filter: (props: any) => {
                const { column: { setFilter } } = props;
                return (
                    <Select
                        onClick={e => e.stopPropagation()}
                        onChange={value => setFilter(value)}
                        defaultValue='all'
                        style={{ width: 80 }}
                    >
                        <Select.Option value=''>all</Select.Option>
                        <Select.Option value='follow'>follow</Select.Option>
                        <Select.Option value='like'>like</Select.Option>
                        <Select.Option value='like_reels'>reels</Select.Option>
                    </Select>
                )
            },
            Cell: ({ cell }: { cell: Cell<object, any>; }) => {
                const {
                    taskInfo: { taskStatus },
                    taskType,
                    status: {
                        progressCnt,
                        totalCnt,
                        remainScheduleCnt,
                    },
                } = cell.row.values;

                return (
                    <Flex
                        justifyContent='flex-start'
                        gap={10}
                        style={{ height: '100%' }}
                    >
                        <div>
                            { taskType === 'like' && <HeartOutlined style={{ color: 'rgb(255, 48, 64)', marginRight: 5, fontSize: 18 }} /> }
                            { taskType === 'like_reels' && <img src='/assets/instagram-reel.svg' width={18} height={18} style={{ marginRight: 5 }} /> }
                            { taskType === 'follow' && <UserAddOutlined style={{ color: 'rgb(0, 149, 246)', marginRight: 5, fontSize: 18 }} /> }
                        </div>
                        <div>
                            <div>{`${progressCnt} / ${totalCnt}`}</div>
                            { taskStatus === 'locked' &&
                                <Flex
                                    justifyContent='flex-start'
                                    style={{
                                        color: '#F77737',
                                        fontWeight: 'bold',
                                        fontSize: 16,
                                        height: 22
                                    }}
                                >
                                    { remainScheduleCnt > 0
                                        ? (
                                            <>
                                                <PlayCircleOutlined style={{ marginRight: 4, color: '#F77737', fontSize: 12 }} />
                                                <span>{remainScheduleCnt > 0 ? remainScheduleCnt : '-'}</span>
                                            </>
                                        )
                                        : (
                                            <PauseCircleOutlined style={{ marginRight: 4, color: '#c2c2c2', fontSize: 12 }} />
                                        )
                                    }
                                </Flex>
                            }
                        </div>
                    </Flex>
                );
            },
            width: 140,
        },
        {
            Header: '작업대상',
            accessor: 'target',
            Filter: () => {},
            width: 150,
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <Flex
                        style={{ height: '100%' }}
                        column
                        alignItems='flex-start'
                    >
                        <Flex
                            justifyContent='flex-start'
                            gap={8}
                        >
                            <a
                                href={`https://www.instagram.com/${cell.value.targetId}`}
                                target='_blank'
                                rel='noreferrer noopener'
                                style={{ color: 'black' }}
                            >
                                <div style={{ width: 130, overflow: 'hidden', textOverflow: 'ellipsis', }}>{cell.value.targetId}</div>
                            </a>
                        </Flex>
                        { cell.value.targetUrl &&
                            <Flex
                                justifyContent='flex-start'
                                gap={8}
                                style={{ marginTop: 6 }}
                            >
                                <a
                                    href={cell.row.values.taskType.includes('reels')
                                        ? `https://www.instagram.com/reel/${cell.value.targetUrl}`
                                        : `https://www.instagram.com/p/${cell.value.targetUrl}`
                                    }
                                    target='_blank'
                                    rel='noreferrer noopener'
                                    style={{ color: '#c8c8c8' }}
                                >
                                    <div>{cell.value.targetUrl}</div>
                                </a>
                            </Flex>
                        }
                    </Flex>
                )
            },
        },
        {
            Header: '상품명',
            accessor: 'productName',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '업데이트 시간',
            accessor: 'updatedAt',
            Filter: () => {},
            width: 120,
        },
        {
            Header: '주문 번호',
            accessor: 'orderNo',
            Filter: () => {},
            width: 150,
        },
        {
            Header: '오더테스크ID',
            accessor: 'lockedDt',
            Filter: () => {},
            Cell: ({ cell }: { cell: Cell<object, any> }) => {
                return (
                    <div>
                        {cell.row.values.orderTaskId}
                    </div>
                )
            },
            width: 200,
        },
        {
            Header: '',
            accessor: 'orderId',
            Filter: () => {},
            Cell: () => {},
        },
        {
            Header: '',
            accessor: 'edit',
            Filter: () => {},
            Cell: () => {},
        },
        {
            Header: '',
            accessor: 'orderTaskId',
            Filter: () => {},
            Cell: () => {},
        },
        {
            Header: '',
            accessor: 'finishOrderTask',
            Filter: () => {},
            Cell: () => {},
        },
        {
            Header: '',
            accessor: 'reservedDt',
            Filter: () => {},
            Cell: () => {},
        },
        {
            Header: '',
            accessor: 'progressCnt',
            Filter: () => {},
            Cell: () => {},
        },
        {
            Header: '',
            accessor: 'remainScheduleCnt',
            Filter: () => {},
            Cell: () => {},
        },
        {
            Header: '',
            accessor: 'settleKind',
            Filter: () => {},
            Cell: () => {},
        },
        {
            Header: '',
            accessor: 'status',
            Filter: () => {},
            Cell: () => {},
        },
    ], [ getCanceledOrderList ]);

    useEffect(() => {
        ( async () => {
            await getCanceledOrderList();
        })();
    }, []);

    if (loading) {
        return <Mask />
    }

    return (
        <div>
            <PriorityModal refresh={getCanceledOrderList} />
            <EditCountModal refresh={getCanceledOrderList} />
            <EditOrderModal refresh={getCanceledOrderList} />
            <EditReservedModal refresh={getCanceledOrderList} />
            <Modal
                open={settingModalOpen}
                title='주문 설정'
                onOk={async () => {
                    handleSettingModal(false);
                }}
                onCancel={() => {
                    handleSettingModal(false);
                }}
                destroyOnClose
                maskClosable={false}
                confirmLoading={false}
            >
                { settingModalValues &&
                    <div>
                        <h3>
                            <div>아이디: {settingModalValues?.target?.targetId}</div>
                            <div>상품명: {settingModalValues?.productName}</div>
                        </h3>
                        <div>
                            { (settingModalValues?.taskInfo?.taskStatus === 'locked' ||
                            settingModalValues?.taskInfo?.taskStatus === 'wait_external_vendor' ||
                            settingModalValues?.taskInfo?.taskStatus === 'not_finished' ||
                            settingModalValues?.taskInfo?.taskStatus === 'temporary_paused') &&
                                <Button
                                    onClick={() => {
                                        editCountStore.handleEditCountModalOpen(true);
                                        editCountStore.handleEditCountInitialModalValue({
                                            orderTaskId: settingModalValues.orderTaskId,
                                            totalCnt: settingModalValues.status.totalCnt,
                                            remainScheduleCnt: settingModalValues.status.remainScheduleCnt,
                                            progressCnt: settingModalValues.status.progressCnt,
                                            targetId: settingModalValues.target.targetId,
                                            targetUrl: settingModalValues.target.targetUrl,
                                        });
                                        editCountStore.handleEditCountModalValue({
                                            displayingCnt: 0,
                                            totalCnt: settingModalValues.status.totalCnt,
                                        });
                                        handleSettingModal(false);
                                    }}
                                >작업량 수정</Button>
                            }
                            <Divider style={{ margin: '10px 0' }} />
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    editOrderStore.handleEditOrderModalValue({
                                        orderTaskId: settingModalValues.orderTaskId,
                                        targetId: settingModalValues.target.targetId,
                                        targetUrl: settingModalValues.target.targetUrl,
                                    });
                                    editOrderStore.handleEditOrderModalOpen(true);
                                    editOrderStore.handleEditOrderModalInitialValue({
                                        targetId: settingModalValues.target.targetId,
                                        targetUrl: settingModalValues.target.targetUrl,
                                    });
                                    handleSettingModal(false);
                                }}
                            >
                                작업대상 수정
                            </Button>
                            <Divider style={{ margin: '10px 0' }} />
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    confirmAction(
                                        settingModalValues.orderId,
                                        settingModalValues.target.targetId,
                                        settingModalValues.target.targetUrl,
                                    );
                                }}
                                disabled={checkDeleteOrderDisable(settingModalValues?.taskInfo?.taskStatus)}
                            >
                                <div style={{ color: 'red' }}>
                                    주문 취소
                                </div>
                            </button>
                            <Divider style={{ margin: '10px 0' }} />
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    editReservedStore.handleEditReservedModalOpen(true);
                                    editReservedStore.handleEditReservedModalValue({
                                        orderTaskId: settingModalValues.orderTaskId,
                                        reservedDt: moment().format(),
                                        initialReservedDt: settingModalValues.reservedDt,
                                    });
                                    handleSettingModal(false);
                                }}
                            >
                                예약변경
                            </Button>
                            <Divider style={{ margin: '10px 0' }} />
                        </div>
                        <div>
                            <Button
                                onClick={async () => {
                                    try {
                                        if (window.confirm('정말 마감하시겠습니까?')) {
                                            await repository.finishOrderTask(settingModalValues.orderTaskId);
                                            message.success('강제 마감이 완료되었습니다.');
                                            await getCanceledOrderList();
                                        }
                                    } catch (err) {
                                        message.error(JSON.stringify(err));
                                    }
                                    handleSettingModal(false);
                                }}
                            >
                                강제마감
                            </Button>
                            <Divider style={{ margin: '10px 0' }} />
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    (async () => {
                                        const rs = await repository.getInformationLetterLink(settingModalValues.orderId);
                                        if (rs !== '') {
                                            window.open(rs, '_blank');
                                        }
                                    })();
                                }}
                            >알림장</Button>
                            <Divider style={{ margin: '10px 0' }} />
                        </div>
                        <div>
                            <Button
                                onClick={() => handleSettingModal(false)}
                            >
                                <MemoAlertButton orderId={settingModalValues?.orderId} />
                            </Button>
                            <Divider style={{ margin: '10px 0' }} />
                        </div>
                        <div>
                            <a
                                href={`http://gdadmin.amongkr.godomall.com/order/order_view.php?orderNo=${settingModalValues?.orderNo}`}
                                target='_blank'
                                rel="noreferrer noopener"
                            >
                                <Button>
                                    고도몰
                                </Button>
                            </a>
                        </div>
                    </div>
                }
            </Modal>
            { orderList.length === 0
                ? <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Empty /></div>
                : <CanceledOrderTable
                    columns={columns}
                    data={orderList}
                    refresh={getCanceledOrderList}
                />
            }
        </div>
    );
});

export default CanceledOrderStatus;
